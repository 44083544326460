import {InputGroup, TextArea } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { Business, ItemsInCart, Order, TangoBusinessSettings, TangoMenu, User } from "../types/types";
import { calculateDeliveryDistance, calculateSubtotalInOrder, calculateTaxOnOrder } from "../utils/functions";
import moment from "moment";
import PhoneInput from "../components/PhoneInput";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import WalletPayments from "../walletPayments/WalletPayments";
import StripeCardDetails from "../stripe/StripeCardDetails";
import ChangeOrderType from "./modals/ChangeOrderType";
import SchedulePickup from "./modals/SchedulePickup";
import { fetchUser } from "../db/GetData";
var stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY ? process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY : "test");

interface PayProps {
    business: Business
    businessSettings: TangoBusinessSettings;
    deliverectToken: string
    itemsInCart: ItemsInCart[];
    menus: TangoMenu[];
    taxRate: number;
    user: User;
    tipAmount: number;
    setOpenPayDialog: (open:boolean) => void;
    setSuccessfulOrder: (success:boolean) => void;
    setUser:(user:User) => void
}

const Pay = ({business, businessSettings, deliverectToken, itemsInCart, menus, taxRate, tipAmount, user, setOpenPayDialog, setSuccessfulOrder, setUser}: PayProps) => {

    const [apt, setApt] = useState("");
    const [appliedGiftCardAmount, setAppliedGiftCardAmount] = useState<number>(0);
    const [availableOrderTypes, setAvailableOrderTypes] = useState<string[]>([''])
    const [businessClosedWarning, setBusinessClosedWarning] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [deliveryAddress, setDeliveryAddress] = useState("");
    const [deliveryFee, setDeliveryFee] = useState(businessSettings.deliveryFee ? businessSettings.deliveryFee : 0);
    const [isGiftCardAppliedFully, setIsGiftCardAppliedFully] = useState<boolean>(false);
    const [newTaxRate, setNewTaxRate] = useState(taxRate);
    const [openSchedulePickup, setOpenSchedulePickup] = useState(false);
    const [orderType, setOrderType] = useState('carryOut');
    const [pickupWarning, setPickupWarning] = useState("");
    const [showChangeOrderTypeModal, setShowChangeOrderTypeModal] = useState<boolean>(false);
    //@ts-ignore
    const [selectedGiftCard, setSelectedGiftCard] = useState<GiftCardUser>(null);
    const [showExpressCheckout, setShowExpressCheckout] = useState(true);
    const [totalAmount, setTotalAmount] = useState(0.0); //default to grand total in order
    const [unavailableWarning, setUnavailableWarning] = useState("");
    const [waitTimes, setWaitTime] = useState([15, 25]); //to do - update these on load component
    const [order, setOrder] = useState<Order>({
        DSP: null,
        amount: {
          currency: business.currency === "CAD" || business.currency === "cad" ? "CAD" : "USD",
          deliveryFee: 0,
          discountTotal: 0,
          grossTotal: 0,
          netTotal: 0,
          serviceChargeTotal: 0,
          subTotal: calculateSubtotalInOrder(itemsInCart),
          tax: 0,
          tip: Math.round(tipAmount * 100), //convert back to cents
        },
        businessId: business.id,
        cancelled: false,
        completed: true, //default to true
        createdAt: new Date(),
        deleted: false,
        deliverect: null,
        deliveryAddress: null,
        discountsAppliedToOrder: [],
        discountedStaffMemberId: '',
        drawerId: null,
        id: '',
        isPrinted: false,
        isStaffMemberDiscount: false,
        orderApp: "pwa",
        orderChannel: orderType === "dineIn" ? "tangoPOS" : "mobile", //if dine in change to tangoPOS?
        orderNumber: JSON.stringify(Math.floor(Math.random() * 99999) + 1),
        orderType: orderType,
        pickupTime: null,
        products: itemsInCart,
        renderOrderInQueue: true,
        serviceAreaId: "none",
        sessionId: null,
        specialInstructions: "",
        staffId: "",
        status: "received",
        tabId: "",
        tableId: "",
        tableNumber: null,
        updatedAt: new Date(),
        turnaroundTime: waitTimes,
        customerName: "",
        extraInformation: null,
        voidedProducts: []
    });

    //if new tip is passed to pay screen reset value in order
    useEffect(() => {
        // console.log('new tip passed to pay screen', tipAmount)
        order.amount.tip = Math.round(tipAmount * 100)
        setTotalAmount(calculateSubtotalInOrder(order.products) + calculateTaxOnOrder(order.products, taxRate) + order.amount.tip)
    },[tipAmount])

    useEffect(() => {
        //set to stripe CA on load pay
        if (business &&  business.location && business.location.country === "Canada" || business.location.country === "CA") {
            stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CANADA_LIVE_PUBLISHABLE_KEY ? process.env.REACT_APP_STRIPE_CANADA_LIVE_PUBLISHABLE_KEY : "test");
        } 
        //set available order types user can pick from
        const orderTypes: string[] = [];
        business.tangoProducts.forEach((tangoProduct: any) => {
            if (tangoProduct.name === "mobile") {
                tangoProduct.orderTypes.forEach((type: any) => {
                    if (type.name === orderType) {
                      setNewTaxRate(type.taxRate);
                    }
                })
                tangoProduct.orderTypes.forEach((orderType: any) => {
                    if ((orderType.name === "carryOut" || orderType.name === "curbside") && orderType.enabled) {
                      orderTypes.push(orderType.name);
                    }
                });
            }
        });
        setAvailableOrderTypes(orderTypes);
        order.amount.tax = calculateTaxOnOrder(order.products, taxRate)
        order.amount.netTotal = order.amount.subTotal + order.amount.tax 
        setTotalAmount(calculateSubtotalInOrder(order.products) + calculateTaxOnOrder(order.products, taxRate) + order.amount.tip)
        
        //need to refactor check availablity
        // checkProductAvailability()

        if(!user.stripe.id || user.stripe.cards.length < 1){
          reloadUser()
        }
    },[])

    const reloadUser = async () => {
        try {
          const reloadedUser = await fetchUser();
          if (reloadedUser) {
            setUser(reloadedUser);
            setCustomerName(user.name);
            setCustomerPhone(user.phone);
            setCustomerEmail(user.email);
          }
        } catch (e) {
          console.error("error getting user", e);
        }
    };
      
    const handleSelectAddress = (addressObject: any) => {
        let formatted = {
          displayAddress: addressObject.formatted_address,
          extraAddressInfo: addressObject.formatted_address,
          streetNumber: '',
          street: '',
          postalCode: '',
          city: ''
        };
    
        addressObject.address_components.map((component: any) => {
          if (component.types.includes("street_number")) {
            formatted.streetNumber = component.short_name;
          }
          if (component.types.includes("route")) {
            formatted.street = component.short_name;
          }
          if (component.types.includes("postal_code")) {
            formatted.postalCode = component.short_name;
          }
          if (component.types.includes("locality")) {
            formatted.city = component.short_name;
          }
        });    
        order.deliveryAddress = formatted;
    };
 
    const handleSuccessfulPayment = (success: Boolean, user: any, createdOrder: any) => {
        if (success) {
            setSuccessfulOrder(true)
            setOpenPayDialog(false)
        }
    };

    const checkProductAvailability = () => {
        if (order.pickupTime) {
          setUnavailableWarning("");
          setPickupWarning("");
          setBusinessClosedWarning(""); 
          let pickupDay = moment(order.pickupTime).format("dddd").toLowerCase();
          let pickupTime = parseFloat(moment(order.pickupTime).format("HH.mm"));
          itemsInCart.forEach((product: ItemsInCart) => {
            let menuDays: string[] = [];
            menus.forEach((menu: TangoMenu) => {
              if (product.menuName === menu.nameExternal) {
                menu.hours.forEach((timeslot: any) => {
                  menuDays.push(timeslot.day);
                  if (timeslot.day === pickupDay) {
                    if (pickupTime < timeslot.openTime || pickupTime > timeslot.closeTime) {
                      setPickupWarning("Items from " + menu.nameExternal + " menu are not available at that pickup time");
                    }
                  }
                });
              }
            });
            if (!menuDays.includes(pickupDay)) {
              setPickupWarning("Items in your order are not available at that pickup time");
            }
          });
        } else{
            setPickupWarning("");
            let warning = "";
            itemsInCart.forEach((product: ItemsInCart) => {
                menus.forEach((menu: TangoMenu) => {
                    if (menu.currentlyUnavailable && product.menuName === menu.nameExternal) {
                        console.log("product in order is on a menu currently unavailable");
                        warning = "Items from " + menu.nameExternal + " menu are currently unavailable - please schedule a delivery or pickup time";
                    }
                });
            });
            setUnavailableWarning(warning);
        }
    };

    return (
        <div style={{ width: "70%", borderRight: "1px solid #f5f5f5", backgroundColor: "#F9F9F9", paddingTop: "24px" }}>
        <div style={{ paddingRight: "90px", paddingLeft: "90px" }}>
          
            {/* unavailable warnings */}
            {unavailableWarning && !businessClosedWarning && 
                <div onClick={() => setOpenSchedulePickup(true)} style={{ borderRadius: "3px", color: "white", backgroundColor: "#daa520", textAlign: "center", cursor: "pointer" }}>
                    <h4 style={{ padding: "6px" }}>{unavailableWarning}</h4>
                </div>
            }
            {pickupWarning && 
                <div onClick={() => setOpenSchedulePickup(true)} style={{ borderRadius: "3px", color: "white", backgroundColor: "#daa520", textAlign: "center", cursor: "pointer" }}>
                    <h4 style={{ padding: "6px" }}>{pickupWarning}</h4>
                </div>
            }
            {businessClosedWarning && !order.pickupTime && 
                <div onClick={() => setOpenSchedulePickup(true)} style={{ borderRadius: "3px", color: "white", backgroundColor: "#daa520", textAlign: "center", cursor: "pointer" }}>
                    <h4 style={{ padding: "6px" }}>{businessClosedWarning}</h4>
                </div>
            }

            {/* ORDER TYPE AND PICKUP TIMES */}
            <div style={{ backgroundColor: "#fff", paddingTop: "26px", paddingLeft: "35px", paddingRight: "35px", paddingBottom: "35px", marginBottom: "20px" }}>
                <div style={{ fontSize: "19px", fontFamily: "Lato-Extrabold", color: "#231F20" }}>Order</div>
                <div style={{ fontSize: "12px", fontFamily: "Lato-Bold", color: "#888888", marginTop: "6px", marginBottom: "25px" }}>Choose pickup or delivery</div>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#F9F9F9", paddingTop: "20px", paddingBottom: "20px", paddingRight: "30px", paddingLeft: "30px", borderRadius: "5px", marginBottom: "6px" }}>
                    <div style={{ display: "flex" }}>
                    <img src={require("../assets/images/delivery.svg").default} style={{ marginRight: "20px" }} />
                    <div>
                        <div style={{ fontSize: "14px", fontFamily: "Lato-Extrabold", color: "#231F20" }}> {orderType === "carryOut" ? "Carry Out" : orderType === "delivery" ? "Delivery" : orderType === "curbside" ? "Curbside" : orderType === "dineIn" ? "Dine In" : orderType === "bar" ? "Bar" : orderType}</div>
                        <div style={{ fontSize: "12px", fontFamily: "Lato-Extrabold", color: "#888888" }}>{orderType === "carryOut" ? business.location.address : ""}</div>
                    </div>
                    </div>
                    <div style={{ fontSize: "12px", fontFamily: "Lato-Semibold", color: "#4587B2", cursor: "pointer" }} onClick={() => setShowChangeOrderTypeModal(true)}>
                    Change
                    </div>
            </div>

            <ChangeOrderType
                isOpen={showChangeOrderTypeModal}
                closeModal={() => setShowChangeOrderTypeModal(false)}
                selectedOrderType={orderType}
                availableOrderTypes={availableOrderTypes}
                updateOrderType={(orderType: string) => {
                    if(orderType === "delivery"){
                        //and if previous selection is not delivery
                        if (order.orderType !== "delivery") {
                            order.amount.deliveryFee = deliveryFee;                  
                            setTotalAmount(totalAmount + deliveryFee);
                        }
                    }else{
                        //changing from delivery to carryout, curbside, dine in
                        if (order.orderType === "delivery") {
                            order.amount.deliveryFee = 0;
                            setTotalAmount(totalAmount - deliveryFee);
                        }
                    }
                    order.orderType = orderType;
                    setOrderType(orderType);
                    setShowChangeOrderTypeModal(false);
                }}
            />

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#F9F9F9", paddingTop: "20px", paddingBottom: "20px", paddingRight: "30px", paddingLeft: "30px", borderRadius: "5px" }}>
              <div style={{ display: "flex" }}>
                <img src={require("../assets/images/clock.svg").default} style={{ marginRight: "20px" }} />
                <div>

                  <div style={{ fontSize: "14px", fontFamily: "Lato-Extrabold", color: "#231F20" }}>{order.pickupTime ? "Scheduled" : "ASAP"}</div>

                  <div style={{ fontSize: "12px", fontFamily: "Lato-Extrabold", color: "#888888" }}>{order.pickupTime ? `Your order will be ready on ${moment(order.pickupTime).format(" dddd, MMMM Do [at] hh:mm a")}` : `Your order will be ready in ${waitTimes[0]} - ${waitTimes[1]}m`}</div>
                </div>
              </div>

              {businessSettings && businessSettings.scheduledOrders && order && order.products.length && (orderType === "carryOut" || orderType === "curbside" || orderType === "delivery") && (
                <div style={{ fontSize: "12px", fontFamily: "Lato-Semibold", color: "#4587B2", cursor: "pointer" }} onClick={() => setOpenSchedulePickup(true)}>
                  Change
                </div>
              )}
            </div>
          </div>

            <SchedulePickup
                business={business}     
                isOpen={openSchedulePickup}
                closeModal={() => setOpenSchedulePickup(false)}
                updatePickupTime={(pickupTime: string) => {
                    order.pickupTime = pickupTime;
                    setOpenSchedulePickup(false);
                    // checkProductAvailability()
                }}      
                setOpenSchedulePickup={setOpenSchedulePickup} 
            />

          {/* CUSTOMER DETAILS INPUTS */}
          <div style={{ backgroundColor: "#fff", paddingTop: "26px", paddingLeft: "35px", paddingRight: "35px", paddingBottom: "35px", marginBottom: "20px" }}>
            <div style={{ fontSize: "19px", fontFamily: "Lato-Extrabold", color: "#231F20" }}>Contact Info</div>
            <div style={{ fontSize: "12px", fontFamily: "Lato-Bold", color: "#888888", marginTop: "6px", marginBottom: "25px" }}>Enter your name, phone number, and email</div>
            <InputGroup
              onChange={(e: any) => {
                if (e.currentTarget.value.includes(" ")) {
                  return;
                } else {
                  setCustomerEmail(e.target.value);
                  {/* @ts-ignore */}
                  user.email = e.target.value;
                }
              }}
              fill
              large
              value={user && user.email ? user.email : ""}
              placeholder={"Email"}
              style={{
                backgroundColor: "#F9F9F9",
                marginTop: "10px",
                boxShadow: "none",
                border: "1px solid #F2F2F2",
                fontSize: "12px",
                fontFamily: "Lato-Medium",
                borderRadius: "5px",
              }}
            />
            
            <PhoneInput
              phoneNumber={user && user.phone ? user.phone : ""}
              onChange={(number) => {
                //dont let them enter letters for phone
                if((/[a-zA-Z]/).test(number)){
                  return
                }
                setCustomerPhone(number)
                user.phone = number
              }}
              style={{
                backgroundColor: "#F9F9F9",
                marginTop: "10px",
                paddingLeft: ".5em",
                boxShadow: "none",
                border: "1px solid #F2F2F2",
                fontSize: "12px",
                fontFamily: "Lato-Medium",
                borderRadius: "5px",
                paddingTop: '15px',
                paddingBottom: '15px',
                width: '100%',
                outline: 'none'
              }}
              placeholder={"Phone"}
            />

            <InputGroup
              onChange={(e: any) => {
                setCustomerName(e.target.value);
                user.name = e.target.value;
              }}
              style={{
                outline: "none",
                backgroundColor: "#F9F9F9",
                marginTop: "10px",
                boxShadow: "none",
                border: "1px solid #F2F2F2",
                fontSize: "12px",
                fontFamily: "Lato-Medium",
                borderRadius: "5px",
              }}
              fill
              large
              value={user && user.name ? user.name : ""}
              placeholder={"Full Name"}
            />

            {/* SPECIAL INSTRUCTIONS INPUT */}
            {businessSettings && businessSettings.orderSpecialInstructions && 
              <div>
                <TextArea
                  maxLength={100}
                  fill
                  placeholder="Please indicate any allergies or preferences"
                  growVertically={true}
                  style={{ width: "100%", marginTop: "10px", fontSize: "12px", fontFamily: "Lato-Medium", backgroundColor: "#F9F9F9", boxShadow: "none", border: "1px solid #F2F2F2" }}
                  large={true}
                  onChange={(e: any) => {
                    order.specialInstructions = e.target.value;
                  }}
                />
              </div>
            }
          </div>

            {/* DINE TABLE NUMBER INPUTS */}
            {orderType === "dineIn" &&
                <div style={{ backgroundColor: "#fff", padding: "13px 19px", marginBottom: "20px" }}>
                <div style={{ fontSize: "19px", fontFamily: "Lato-Extrabold", color: "#231F20" }}>Table Info</div>
                <div style={{ fontSize: "12px", fontFamily: "Lato-Bold", color: "#888888", marginTop: "6px", marginBottom: "25px" }}>Enter Table Number</div>
                <InputGroup
                    onChange={(e: any) => {
                    order.tableNumber = e.target.value;
                    }}
                    style={{
                    outline: "none",
                    backgroundColor: "#F9F9F9",
                    marginTop: "10px",
                    boxShadow: "none",
                    border: "1px solid #F2F2F2",
                    fontSize: "12px",
                    fontFamily: "Lato-Medium",
                    borderRadius: "5px",
                    }}
                    fill
                    large
                    placeholder={"Table #"}
                />
                </div>
            }

            {/* SELECT DELIVERY ADDRESS */}
            {orderType === "delivery" && (
                <div style={{ backgroundColor: "#fff", paddingTop: "26px", paddingLeft: "35px", paddingRight: "35px", paddingBottom: "35px", marginBottom: "20px" }}>
                <div style={{ fontSize: "19px", fontFamily: "Lato-Extrabold", color: "#231F20" }}>Delivery Address</div>
                <div style={{ fontSize: "12px", fontFamily: "Lato-Bold", color: "#888888", marginTop: "6px", marginBottom: "25px" }}>Where do you want this order delivered to?</div>
                <div>
                    <div style={{ fontSize: "11px", paddingTop: "20px", paddingBottom: "8px", fontFamily: "Axiforma-Regular", color: "#000" }}>Delivery Address *</div>

                    <PlacesAutocomplete
                    onChange={(address) => setDeliveryAddress(address)}
                    value={deliveryAddress}
                    shouldFetchSuggestions={deliveryAddress.length > 2}
                    onSelect={async (address) => {
                        const results = await geocodeByAddress(address);
                        const latlong = await getLatLng(results[0]);
                        const valid = await calculateDeliveryDistance(latlong.lat, latlong.lng, business.location.lat, business.location.lon, businessSettings.deliveryRadius ? businessSettings.deliveryRadius : 5, business);
                        if (valid) {
                        setDeliveryAddress(results[0].formatted_address);
                        handleSelectAddress(results[0]);
                        }
                    }}
                    >
                    {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                        return (
                        <div className="Demo__search-bar-container">
                            <div className="Demo__search-input-container">
                            <input
                                {...getInputProps({
                                placeholder: "Select Address...",
                                className: "Demo__search-input",
                                })}
                            />
                            {deliveryAddress.length > 0 && (
                                <button
                                className="Demo__clear-button"
                                onClick={() => {
                                    setDeliveryAddress("");
                                    //@ts-ignore
                                    order.deliveryAddress = null;
                                }}
                                >
                                x
                                </button>
                            )}
                            </div>
                            {suggestions.length > 0 && (
                            <div className="Demo__autocomplete-container">
                                {suggestions.map((suggestion: any) => {
                                const className = suggestion.active ? "Demo__suggestion-item--active" : "Demo__suggestion-item";

                                return (
                                    <div {...getSuggestionItemProps(suggestion, { className })}>
                                    <strong>{suggestion.formattedSuggestion.mainText}</strong> <small>{suggestion.formattedSuggestion.secondaryText}</small>
                                    </div>
                                );
                                })}
                            </div>
                            )}
                        </div>
                        );
                    }}
                    </PlacesAutocomplete>

                    <div style={{ width: "25%" }}>
                    <div style={{ fontSize: "11px", paddingTop: "1em", fontFamily: "Axiforma-Regular", color: "#000" }}>Apt #</div>
                        <InputGroup
                            onChange={(e: any) => {
                            setApt(e.target.value);
                            }}
                            fill
                            large
                            placeholder={""}
                            style={{
                            backgroundColor: "#fcfcfc",
                            marginTop: "10px",
                            paddingLeft: ".5em",
                            boxShadow: "none",
                            border: "1px solid #F2F2F2",
                            fontSize: "12px",
                            fontFamily: "Lato-Medium",
                            borderRadius: "5px",
                            }}
                        />
                    </div>

                </div>
                </div>
            )}

            {business && user.stripe.id && totalAmount ?
                <>
                    {showExpressCheckout ? (
                        <>
                            <div
                            style={{
                                backgroundColor: "#fff",
                                paddingTop: "26px",
                                paddingLeft: "35px",
                                paddingRight: "35px",
                                paddingBottom: "35px",
                            }}
                            >
                            <div style={{ fontSize: "19px", fontFamily: "Lato-Extrabold", color: "#231F20" }}>Express Checkout</div>
                            <div
                                style={{
                                fontSize: "12px",
                                fontFamily: "Lato-Bold",
                                color: "#888888",
                                marginTop: "6px",
                                marginBottom: "25px",
                                }}
                            >
                              Pay with Apple or Google Pay
                            </div>
                            <Elements stripe={stripePromise}>
                                <WalletPayments 
                                  total={totalAmount} 
                                  user={{name: customerName, phone: customerPhone, email: customerEmail, id: "" }} 
                                  order={order} 
                                  apartmentNumber={apt} 
                                  business={business} 
                                  deliverectToken={deliverectToken} 
                                  businessSettings={businessSettings} 
                                  onChange={handleSuccessfulPayment} 
                                  setShowExpressCheckout={setShowExpressCheckout} 
                                />
                            </Elements>
                            </div>
                            <div className="separator" style={{ marginTop: "20px" }}>
                            or pay with card
                            </div>
                        </>
                    ) : null}

                    <div 
                        style={{
                            backgroundColor: "#fff",
                            paddingTop: "26px",
                            paddingLeft: "35px",
                            paddingRight: "35px",
                            paddingBottom: "35px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <div style={{ fontSize: "19px", fontFamily: "Lato-Extrabold", color: "#231F20" }}>Card Payment</div>
                        <div
                            style={{
                            fontSize: "12px",
                            fontFamily: "Lato-Bold",
                            color: "#888888",
                            marginTop: "6px",
                            marginBottom: "25px",
                            }}
                        >
                            All transactions are secure and encrypted
                        </div>
                     
                        <Elements stripe={stripePromise}>
                            <StripeCardDetails
                                user={user}
                                order={order}
                                apt={apt} //apt # to send to deliverect
                                business={business}
                                businessSettings={businessSettings}
                                deliverectToken={deliverectToken}
                                onChange={handleSuccessfulPayment}
                                isGiftCardAppliedFully={isGiftCardAppliedFully}
                                appliedGiftCardAmount={appliedGiftCardAmount}
                                selectedGiftCard={selectedGiftCard}
                            />
                        </Elements>
                    </div>
                </>
                :
                null
            }

        </div>
      </div>
    )
}

export default Pay