import React, {useEffect, useState} from 'react';
import './index.css';
import {Link, useLocation, useParams, useHistory} from 'react-router-dom';
import {Button, Icon} from "@blueprintjs/core";
import CardImage from '../assets/images/card-image.png'
import {loadStripe} from '@stripe/stripe-js';
import { fetchSingleGiftCard, fetchUsersCard, GiftCard, GiftCardUser } from '../db/Cards';
import { getGiftCards } from '../db/GetData';
import {createStructuredSelector} from "reselect";
import {selectBusinessDetails} from "../reducers/global/selectors";
import {connect} from "react-redux";
import {compose} from "redux";
import Header from './Header';
import MobileHeader from './MobileHeader';
interface StateType{
    card: GiftCardUser | null;
    userCards: GiftCardUser[] | null;
}

const SendMoney = (props:any) => {
    const [isForMySelf, setIsForMySelf] = useState<boolean>(false);
    const [card, setCard] = useState<GiftCardUser| null>(null);
    const [businessId, setBusinessId] = useState<string>('');
    const [userCards, setUserCards] = useState<GiftCardUser[]>([]);
    const [businessGiftCards, setBusinessGiftCards] = useState<GiftCard[]>([]);
    const [selectedCard, setSelectedCard]= useState<GiftCardUser | GiftCard | null>(null);
    const location = useLocation();
    const history = useHistory();
    const state  = location.state as StateType;
    const {id} = useParams<{id: string}>();

    useEffect(()=>{
        setBusinessId(props.businessDetails.id);
        const getAllGiftCards = async () => {
            const giftCardsResponse = await getGiftCards(props.businessDetails.id)
            setBusinessGiftCards(giftCardsResponse as GiftCard[])
        }
        const fetchData = async()=> {
            const userId  = localStorage.getItem('tango-mobile-userId');
            if(userId && businessId){
               const response =  await Promise.all([fetchSingleGiftCard(id), fetchUsersCard(userId, businessId), getGiftCards()])
                if(response[0].data){
                    setCard(response[0].data);
                }
                if(response[1].data){
                    setUserCards(response[1].data);
                }
            }
        }
        if(state){
           if(state.card){
               setCard(state.card)
           }
           if(state.userCards){
               setUserCards(state.userCards)
           }
        }else{
            fetchData();
        }
        getAllGiftCards();
    }, [props.businessDetails.id])
    if(window.innerWidth <= 960){
        return (
            <div className='gift-cards-mobile'>
                <MobileHeader/>
                <div className="card-list-title">
                    DIGITAL CARDS
                </div>
                <div className="cards-container">
                   {
                        businessGiftCards.map((item, index)=>{
                            if(item.id !== id){
                                return(
                                    <div key={index} onClick={()=>  history.push(`/sendMoney/checkout/${id}/${item.id}?isForMyself=false`)}>
                                        <img src={item.imageUrl}/>
                                    </div>
                                )
                            }
                        })
                   }
                </div>
            </div>
        )
    }
    return (
        <div className="gift-cards-page">
            <Header path="cards" />
            <div className="body">
                <div className="left-part">
                    <div className="side-nav">
                        <div className="nav-title">Send Money</div>
                        <div className={`side-nav-item ${!isForMySelf && 'active'}`} onClick={()=> setIsForMySelf(false)}>For A Friend</div>
                        <div className={`side-nav-item ${isForMySelf && 'active'}`} onClick={()=> setIsForMySelf(true)}>For Myself</div>
                    </div>
                </div>
                <div className="right-part">
                    <>
                        <div className="select-card-header">
                            {
                                isForMySelf ?
                                <>
                                    <div className="text-center extra-bold f19">Add Money</div>
                                    <div className="text-center bold f12">Choose a card to increase its balance</div>
                                </>
                                :
                                <>
                                    <div className="text-center extra-bold f19">Select a card design</div>
                                    <div className="text-center bold f12">Please select a gift card below to get started</div>
                                </>
                            }
                        </div>
                        <div className="card-list-title">
                            {isForMySelf ? 'MY CARDS':'DIGITAL CARDS'}
                        </div>
                        <div className="cards-container">
                            {
                                isForMySelf && userCards.map((item, index)=>{
                                    if(item.id !== id){
                                        return(
                                            <div key={index} onClick={()=> setSelectedCard(item)}>
                                                <img src={item.imageUrl}/>
                                                {
                                                    selectedCard === item && (
                                                        <>
                                                            <div className="check-icon">
                                                                <Icon icon="tick" color="#fff"/>
                                                            </div>
                                                            <div className="overlay"></div>
                                                        </>

                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                })
                            }
                            {
                                !isForMySelf &&  businessGiftCards.map((item, index)=>{
                                    if(item.id !== id){
                                        return(
                                            <div key={index} onClick={()=> setSelectedCard(item)}>
                                                <img src={item.imageUrl}/>
                                                {
                                                    selectedCard === item && (
                                                        <>
                                                            <div className="check-icon">
                                                                <Icon icon="tick" color="#fff"/>
                                                            </div>
                                                            <div className="overlay"></div>
                                                        </>

                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                        <Button text={"Continue"} className="add-money" disabled={!selectedCard} onClick={()=> history.push(`/sendMoney/checkout/${id}/${selectedCard?.id}?isForMyself=${isForMySelf}`)}/>
                    </>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
  businessDetails: selectBusinessDetails()
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(SendMoney);
