import React, {useState} from 'react';
import './index.css';
import {Button, Dialog, Icon} from "@blueprintjs/core";
import ReactCodeInput from "react-code-input";
interface Props{
    isOpen: boolean;
    closeModal: () => void;
    openModal: (modalName: string) => void;
    verifyOtp: (code:string) => void;
}
const OtpModal = ({isOpen, closeModal, openModal, verifyOtp}:Props) => {
    const [code, setCode] = useState<string>('');
    return (
        <Dialog
            isOpen={isOpen}
            hasBackdrop
            onClose={closeModal}
            className="gift-card-login-modal"
            portalClassName="gift-card-modal-portal"
        >
            <div className="gift-card-modal-body">
                <div className="modal-title">Enter Code</div>
                <div className="modal-subtitle">Please enter the code we sent you below</div>
                <Icon icon="cross" className="close-icon" iconSize={30} color={"#231F20"} onClick={closeModal}/>
                <ReactCodeInput
                    name="code-input"
                    inputMode="numeric"
                    type="text"
                    fields={6}
                    onChange={(code)=> setCode(code)}
                />
                <Button text="Continue" className="gift-card-login-button" onClick={()=> {
                   verifyOtp(code)
                }}/>
            </div>
        </Dialog>
    )
}
export  default OtpModal;