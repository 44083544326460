import {Button, Classes, Drawer, InputGroup, Position, TextArea } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { Business, ItemsInCart, Order, TangoBusinessSettings, TangoMenu, User } from "../../types/types";
import { calculateDeliveryDistance} from "../../utils/functions";
import PhoneInput from "../../components/PhoneInput";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import WalletPayments from "../../walletPayments/WalletPayments";
import StripeCardDetails from "../../stripe/StripeCardDetails";
var stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY ? process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY : "test");

interface PayProps {
    business: Business
    businessSettings: TangoBusinessSettings;
    deliverectToken: string
    itemsInCart: ItemsInCart[];
    menus: TangoMenu[];
    taxRate: number;
    user: User;
    tipAmount: number;
    order: Order;
    setOpenViewCart: (open:boolean) => void;
    setOpenPayDialog: (open:boolean) => void;
    setSuccessfulOrder: (success:boolean) => void;
}

const Pay = ({business, businessSettings, deliverectToken, itemsInCart, menus, taxRate, tipAmount, user, order, setOpenViewCart, setOpenPayDialog, setSuccessfulOrder}: PayProps) => {

  if (business.location.country === "Canada" || business.location.country === "CA") {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CANADA_LIVE_PUBLISHABLE_KEY ? process.env.REACT_APP_STRIPE_CANADA_LIVE_PUBLISHABLE_KEY : '');
  }

    const [apt, setApt] = useState("");
    const [customerEmail, setCustomerEmail] = useState(user.email);
    const [customerPhone, setCustomerPhone] = useState(user.phone);
    const [customerName, setCustomerName] = useState(user.name);
    const [deliveryAddress, setDeliveryAddress] = useState("");
    const [deliveryFee, setDeliveryFee] = useState(businessSettings.deliveryFee ? businessSettings.deliveryFee : 0);
    const [showExpressCheckout, setShowExpressCheckout] = useState(true);
    const [totalAmount, setTotalAmount] = useState((order.amount.netTotal + order.amount.deliveryFee + order.amount.tip));

    const handleSelectAddress = (addressObject: any) => {
        let formatted = {
          displayAddress: addressObject.formatted_address,
          extraAddressInfo: addressObject.formatted_address,
          streetNumber: '',
          street: '',
          postalCode: '',
          city: ''
        };
    
        addressObject.address_components.map((component: any) => {
          if (component.types.includes("street_number")) {
            formatted.streetNumber = component.short_name;
          }
          if (component.types.includes("route")) {
            formatted.street = component.short_name;
          }
          if (component.types.includes("postal_code")) {
            formatted.postalCode = component.short_name;
          }
          if (component.types.includes("locality")) {
            formatted.city = component.short_name;
          }
        });    
        order.deliveryAddress = formatted;
    };

    const handleSuccessfulPayment = (success: Boolean, user: any, createdOrder: any) => {
        if (success) {
            console.log("successful payment in pay component mobile view", success, user, createdOrder);
            setSuccessfulOrder(true)
            setOpenPayDialog(false)
        }
    };

    return (
      <Drawer
        isOpen={true}
        onClose={() => {
            setOpenViewCart(false)
            setOpenPayDialog(false);
        }}
        size="100%"
        position={Position.BOTTOM}
      >
        <div style={{ backgroundColor: "#F5F8FA", position: "fixed", top: "0px", width: "100%", zIndex: 1 }}>
          <Button
            onClick={() => {
                setOpenViewCart(false)
                setOpenPayDialog(false);
            }}
            icon="arrow-left"
            style={{ position: "absolute", left: "10px", top: "11px" }}
            className={`${Classes.MINIMAL} order-from-button`}
          >
            {" "}
          </Button>
          <h3 style={{ textAlign: "center", fontFamily: "Axiforma-ExtraBold", fontSize: "17px" }}>Payment</h3>
        </div>

        <div style={{ marginTop: "10%", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", overflowY: "scroll", paddingBottom: "100px", backgroundColor: "#f9f9f9" }}>
          <div style={{ backgroundColor: "#fff", padding: "13px 19px", marginBottom: "20px", marginTop: "20px" }}>
            <div style={{ fontSize: "19px", fontFamily: "Lato-Extrabold", color: "#231F20" }}>Contact Info</div>
            <div style={{ fontSize: "12px", fontFamily: "Lato-Bold", color: "#888888", marginTop: "6px", marginBottom: "25px" }}>Enter your name, phone number, and email</div>
            <InputGroup
              onChange={(e: any) => {
                if (e.currentTarget.value.includes(" ")) {
                  return;
                } else {
                  setCustomerEmail(e.target.value);
                  user.email = e.target.value;
                }
              }}
              fill
              large
              value={user && user.email ? user.email : ""}
              placeholder={"Email"}
              style={{
                backgroundColor: "#F9F9F9",
                marginTop: "10px",
                boxShadow: "none",
                border: "1px solid #F2F2F2",
                fontSize: "12px",
                fontFamily: "Lato-Medium",
                borderRadius: "5px",
              }}
            />
            <PhoneInput
                className="gift-card-modal-input"
                phoneNumber={user && user.phone ? user.phone : ""}
                onChange={(number)=> {
                  setCustomerPhone(number)
                  user.phone = number
                }}
                style={{
                  backgroundColor: "#F9F9F9",
                  marginTop: "10px",
                  paddingLeft: ".5em",
                  boxShadow: "none",
                  border: "1px solid #F2F2F2",
                  fontSize: "12px",
                  fontFamily: "Lato-Medium",
                  borderRadius: "5px",
                  paddingTop: '15px',
                  paddingBottom:'15px',
                  width:'100%',
                  outline:'none'
                }}
                placeholder={"Phone"}
            />
            <InputGroup
              onChange={(e: any) => {
                setCustomerName(e.target.value);
                user.name = e.target.value;
              }}
              style={{
                outline: "none",
                backgroundColor: "#F9F9F9",
                marginTop: "10px",
                boxShadow: "none",
                border: "1px solid #F2F2F2",
                fontSize: "12px",
                fontFamily: "Lato-Medium",
                borderRadius: "5px",
              }}
              fill
              large
              value={user && user.name ? user.name : ""}
              placeholder={"Full Name"}
            />
            
            {businessSettings && businessSettings.orderSpecialInstructions && 
              <div>
                <TextArea
                  maxLength={100}
                  fill
                  placeholder="Please indicate any allergies or preferences"
                  growVertically={true}
                  style={{ width: "100%", marginTop: "10px", fontSize: "12px", fontFamily: "Lato-Medium", backgroundColor: "#F9F9F9", boxShadow: "none", border: "1px solid #F2F2F2" }}
                  large={true}
                  onChange={(e: any) => {
                    order.specialInstructions = e.target.value;
                  }}
                />
              </div>
            }
          </div>

          {order.orderType === "delivery" && (
            <div style={{ backgroundColor: "#fff", padding: "13px 19px", marginBottom: "20px" }}>
              <div style={{ fontSize: "19px", fontFamily: "Lato-Extrabold", color: "#231F20" }}>Delivery Address</div>
              <div style={{ fontSize: "12px", fontFamily: "Lato-Bold", color: "#888888", marginTop: "6px", marginBottom: "25px" }}>Where do you want this order delivered to?</div>
              <div>
                <div style={{ fontSize: "11px", paddingTop: "20px", paddingBottom: "8px", fontFamily: "Axiforma-Regular", color: "#000" }}>Delivery Address *</div>

                <PlacesAutocomplete
                  onChange={(address) => setDeliveryAddress(address)}
                  value={deliveryAddress}
                  shouldFetchSuggestions={deliveryAddress.length > 2}
                  onSelect={async (address) => {
                    const results = await geocodeByAddress(address);
                    const latlong = await getLatLng(results[0]);
                    console.log(latlong);

                    const valid = await calculateDeliveryDistance(latlong.lat, latlong.lng, business.location.lat, business.location.lon, businessSettings.deliveryRadius ? businessSettings.deliveryRadius : 5, business);
                    if (valid) {
                      console.log("select address", results[0]);
                      setDeliveryAddress(results[0].formatted_address);
                      handleSelectAddress(results[0]);
                    }
                  }}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                    return (
                      <div className="Demo__search-bar-container">
                        <div className="Demo__search-input-container">
                          <input
                            {...getInputProps({
                              placeholder: "Select Address...",
                              className: "Demo__search-input",
                            })}
                          />
                          {deliveryAddress.length > 0 && (
                            <button
                              className="Demo__clear-button"
                              onClick={() => {
                                setDeliveryAddress("");
                                order.deliveryAddress = null;
                              }}
                            >
                              x
                            </button>
                          )}
                        </div>
                        {suggestions.length > 0 && (
                          <div className="Demo__autocomplete-container">
                            {suggestions.map((suggestion: any) => {
                              const className = suggestion.active ? "Demo__suggestion-item--active" : "Demo__suggestion-item";

                              return (
                                <div {...getSuggestionItemProps(suggestion, { className })}>
                                  <strong>{suggestion.formattedSuggestion.mainText}</strong> <small>{suggestion.formattedSuggestion.secondaryText}</small>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  }}
                </PlacesAutocomplete>

                <div>
                  <div style={{ fontSize: "11px", paddingTop: "1em", fontFamily: "Axiforma-Regular", color: "#000" }}>Apt #</div>
                  
                  <InputGroup
                    onChange={(e: any) => {
                      setApt(e.target.value);
                    }}
                    fill
                    large
                    placeholder={""}
                    style={{
                      backgroundColor: "#fcfcfc",
                      marginTop: "10px",
                      paddingLeft: ".5em",
                      boxShadow: "none",
                      border: "1px solid #F2F2F2",
                      fontSize: "12px",
                      fontFamily: "Lato-Medium",
                      borderRadius: "5px",
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {showExpressCheckout ? (
            <>
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "13px 19px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <div style={{ fontSize: "19px", fontFamily: "Lato-Extrabold", color: "#231F20" }}>Express Checkout</div>
                <div
                  style={{
                    fontSize: "12px",
                    fontFamily: "Lato-Bold",
                    color: "#888888",
                    marginTop: "6px",
                    marginBottom: "25px",
                  }}
                >
                  Pay with Apple or Google Pay
                </div>
                <Elements stripe={stripePromise}>
                  <WalletPayments 
                    total={totalAmount} 
                    user={{name: customerName, phone: customerPhone, email: customerEmail, id: "" }} 
                    order={order} 
                    apartmentNumber={apt} 
                    business={business} 
                    deliverectToken={deliverectToken} 
                    businessSettings={businessSettings} 
                    onChange={handleSuccessfulPayment} 
                    setShowExpressCheckout={setShowExpressCheckout} 
                  />
                </Elements>
              </div>
              <div className="separator" style={{ marginTop: "20px" }}>
                or pay with card
              </div>
            </>
          ) : null}
          <div
            style={{
              backgroundColor: "#fff",
              padding: "13px 19px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <div style={{ fontSize: "19px", fontFamily: "Lato-Extrabold", color: "#231F20" }}>Card Payment</div>
            <div
              style={{
                fontSize: "12px",
                fontFamily: "Lato-Bold",
                color: "#888888",
                marginTop: "6px",
                marginBottom: "25px",
              }}
            >
              All transactions are secure and encrypted
            </div>
            <div>
              {user.stripe && user.stripe.id &&
                <Elements stripe={stripePromise}>
                  <StripeCardDetails
                    user={user}
                    order={order}
                    apt={apt} //apt # to send to deliverect
                    business={business}
                    businessSettings={businessSettings}
                    deliverectToken={deliverectToken}
                    total={totalAmount}
                    onChange={handleSuccessfulPayment}
                  />
                </Elements>
              }
            </div>
          </div>
        </div>
      </Drawer>
    )
}

export default Pay