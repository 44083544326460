import firebase from '../config/firebase'
import {PaymentInfoGiftCardUsers} from "../types/types";
const db = firebase.firestore();

export interface GiftCardUser{
  id: string;
  businessId: string;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  deleted: boolean;
  enabled: boolean;
  userId: string;
  paymentInfo: Array<PaymentInfoGiftCardUsers>;
  balance: number;
  expirationDate: null;
  boughtForSelf: boolean;
  receiverPhoneNumber: string;
  receiverName: string;
  receiverEmail: string;
  senderPhoneNumber: string;
  senderName: string;
  senderEmail: string;
  message: string;
  imageUrl: string;
  qrCodeImageUrl: null | string;
  isPrimary: boolean;
}

export interface GiftCard{
  id:string;
  businessId:string;
  imageUrl:string;
  themeName:string;
  createdAt: string;
  updatedAt:string;
  deleted:boolean;
  enabled:boolean;
}
export interface GiftCardTransaction{
  businessId: string;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  deleted: boolean;
  enabled: boolean;
  giftCardId: string;
  transactionType: TransactionType,
  amount:number;
  totalBalance:number;
  userId:string;
  orderId:string|null;
}

export enum TransactionType{
  send= 'send',
  reload='reload',
  spend='spend',
  refund='refund',
}
export const fetchUsersCard = async(userId:string, businessId:string) => {
  try{
    const querySnapShot = await db.collection('GiftCardUsers').where('userId', '==', userId).where('businessId','==', businessId).get();

    const cards = querySnapShot.docs.map((doc)=>  doc.data() as GiftCardUser).sort((item)=> item.isPrimary?-1:1);
    return {data: cards}
  }catch(error:any){
    return {error: error.message}
  }
}

export const fetchSingleGiftCard = async(cardId: string)=> {
  try{
    const documentSnapShot = await db.collection('GiftCardUsers').doc(cardId).get();

    return {data: documentSnapShot.data() as GiftCardUser}
  }catch(error:any){
    return {error: error.message}
  }
}


export const getCardsByIds = async(senderCardId:string, recipientCardId:string, isForMySelf:string)=> {
  try{
    const docSnapShot1 = await db.collection('GiftCardUsers').doc(senderCardId).get();
    const docSnapShot2 = await db.collection(`${isForMySelf === 'false'?'GiftCards':'GiftCardUsers'}`).doc(recipientCardId).get();
    let recipientCardData:GiftCard|GiftCardUser|null;
    if(isForMySelf === 'false'){
      recipientCardData = docSnapShot2.data() as GiftCard
    }else{
      recipientCardData = docSnapShot2.data() as GiftCardUser

    }
    return {data: {senderCard: docSnapShot1.data() as GiftCardUser, recipientCard: recipientCardData}}
  }catch(error:any){
    return {error: error.message}
  }
}

export const addGiftCardTransaction = async(cardData: Partial<GiftCardTransaction>)=> {
  const docRef = db.collection('GiftCardTransactionHistory').doc();
  try{
    await docRef.set({...cardData, id: docRef.id})
    return {data: docRef.id}
  }catch(error:any){
    return {error: error.message}
  }
}

export const updateGiftCardUsersDoc = async(data:Partial<GiftCardUser>, docId:string)=> {
  try{
    await db.collection('GiftCardUsers').doc(docId).update(data);
  }catch(error:any){
    return {error: error.message}
  }
}

export const fetchCardByUserId = async(imageUrl:string, businessId:string, recieverId:string) => {
  const userId = localStorage.getItem('tango-mobile-userId');
  try{
    const querySnapShot = await db
    .collection('GiftCardUsers')
    .where('imageUrl','==', imageUrl)
    .where('businessId', '==',businessId)
    .where('userId', '==', recieverId)
    .get();
    console.log(querySnapShot.docs)
    const cards = querySnapShot.docs.map((doc)=> doc.data() as GiftCardUser);
    return {data: cards}
  }
  catch(error:any){
    return {error: error.message}
  }
}
export const fetchTransactionData = async(transactionId:string) => {
  try{
    const documentSnapshot = await db.collection('GiftCardTransactionHistory').doc(transactionId).get();
    return {data: documentSnapshot.data() as GiftCardTransaction}
  }
  catch(error:any){
    return {error: error.message}
  }
}
