import React, { useEffect, useState } from 'react';
import { createStructuredSelector } from "reselect";
import { selectBusinessDetails } from "../reducers/global/selectors";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Drawer, Icon } from '@blueprintjs/core';
import firebase from '../config/firebase';
import './mobileHeader.css';
import { useHistory } from 'react-router-dom';

const MobileHeader = (props: any) => {
  const [business, setBusiness] = useState();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    setBusiness(props.businessDetails);
  }, [props.businessDetails])

  return (
    <div className='mobile-header'>
      {/* @ts-ignore */}
      <img src={business?.profileImageUrl} />
      <Icon icon="menu" color='#231F20' iconSize={18} onClick={() => setIsOpen(!isOpen)} />
      <Drawer isOpen={isOpen} hasBackdrop onClose={() => setIsOpen(false)} className='side-drawer' size="80%">
        <div className='drawer-menu-item' onClick={() => history.push('/')}>Order</div>
        
        {/* <div  className='drawer-menu-item'>Reservations</div>
        <div  className='drawer-menu-item' onClick={()=> history.push('/cards')}>Cards</div>
        <div  className='drawer-menu-item' onClick={()=> history.push('/gift')}>Gift</div> */}
       
        <div  className='drawer-menu-item' onClick={()=> history.push('/jobs')}>Jobs</div>
        <div className='button-group'>
          {
            firebase.auth().currentUser ? <Button text="Logout" className='auth-button red-button' onClick={async () => {
              await firebase.auth().signOut();
              history.replace('/login')
            }} />
              :
              <>
                <Button text="Log in" className='auth-button' onClick={() => history.push('/login')} />
                <Button text="Join now" className='auth-button red-button' onClick={() => history.push('/signup')} />
              </>
          }
        </div>
      </Drawer>
    </div>
  )
}
const mapStateToProps = createStructuredSelector({
  businessDetails: selectBusinessDetails()
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(MobileHeader);

