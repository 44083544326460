import axios from "axios";
import firebase from "../config/firebase";
import { DiscountDoc, ScheduledDiscount, Order, ItemsInCart, SelectedModifiers, Business, TangoProduct, TangoModifier, ModifierOption } from "../types/types";
import moment from "moment";
import momentTZ from "moment-timezone";
import _ from "lodash";
import { Tab } from "@blueprintjs/core";
const { auth } = firebase;
const db = firebase.firestore();

export const sendText = async (phone: string, message: string) => {
  phone = phone.replace('+1', '') //twilio text doesn't accept country code

  try {
    const text = await axios
    .post("https://us-central1-tango-2.cloudfunctions.net/sendTwilioText", {
      phone: phone,
      message: message,
    })
    console.log('sent text ', text.data);
    return text
  } catch (error) {
    console.log('text error ',error);
    return error
  }
};

export const formatAndSendTextMessage = (orderId: string, user:any, business:any, order:any) => {
  let waitTime = [10, 20]; //default value in case no wait time exists
  business.tangoProducts.forEach((tangoProduct: any) => {
    if (tangoProduct.name === "mobile") {
      tangoProduct.orderTypes.forEach((orderType: any) => {
        if (orderType.name === order.orderType && orderType.waitTime) {
          waitTime = orderType.waitTime;
        }
      });
    }
  });

  if (order.orderType === "bar") {
    return sendText(user.phone, "Hi, " + user.name + "! Thanks for placing your bar order at " + business.businessName + ". We'll send another text when it's ready. To see your receipt go to https://run-tango.web.app/order/" + orderId);
  }
  if (order.orderType === "dineIn") {
    return sendText(user.phone, "Hi, " + user.name + "! Thanks for placing your dine in order at " + business.businessName + ". We'll deliver it to your table in about " + waitTime[0] + "-" + waitTime[1] + " minutes. To see your receipt go to https://run-tango.web.app/order/" + orderId);
  }
  if (order.orderType === "carryOut" && !order.pickupTime) {
    return sendText(user.phone, "Hi, " + user.name + "! Thanks for placing your carry out order at " + business.businessName + ". It will be ready in about " + waitTime[0] + "-" + waitTime[1] + " minutes. To see your receipt go to https://run-tango.web.app/order/" + orderId);
  }

  if (order.orderType === "carryOut" && order.pickupTime) {
    return sendText(user.phone, "Hi, " + user.name + "! Thanks for placing your carry out order at " + business.businessName + ". It will be ready at " + moment(order.pickupTime).format("MMM DD hh:mm A") + " To see your receipt go to https://run-tango.web.app/order/" + orderId);
  }

  if (order.orderType === "curbside") {
    return sendText(user.phone, "Hi, " + user.name + "! Thanks for placing your curbside order at " + business.businessName + ". We'll send another text when it's ready. Swing by in " + waitTime[0] + "-" + waitTime[1] + " minutes " + "and click this link when you arrive https://run-tango.web.app/order/" + orderId);
  }

  if (order.orderType === "delivery") {
    return sendText(user.phone, "Hi, " + user.name + "! Thanks for placing your delivery order at " + business.businessName + ". We'll send another text when it's out for delivery in " + waitTime[0] + "-" + waitTime[1] + " minutes. To see your receipt go to https://run-tango.web.app/order/" + orderId);
  }
};

export const sendEmailReceipt = async(business: any, user: any, order: any) => {
  try {
    const email = await axios
    .post("https://us-central1-tango-2.cloudfunctions.net/sendgridEmail", {
      recipients: [user.email],
      emailTemplateName: "receipt",
      business: business,
      user: user,
      order: order,
    })
    
    console.log('sent email receipt ',email.data);
    return email
  } catch (error) {
    console.log('email error', error);
    return error
  }
};

export const clearTableSessionItems = async (sessionId: string, userId: string) => {
  return db
    .collection("TableSessions")
    .doc(sessionId)
    .get()
    .then((doc) => {
      console.log("clearing selected items in table session");
      if (doc.exists) {
        //@ts-ignore
        let previousSelectedItems = doc.data().selectedItemsForPayment;
        previousSelectedItems.map((item: any) => {
          if (item.userId === userId) {
            return db
              .collection("TableSessions")
              .doc(sessionId)
              .update({
                selectedItemsForPayment: firebase.firestore.FieldValue.arrayRemove(item),
              });
          }
        });
      } else {
        console.log("No table session document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
      return error;
    });
};

export const checkForScheduledDiscounts = (
  /** productAndPrices[0] is the productId and productAndPrices[1] is the price */
  productsAndPrices: [string, number][],
  existingDiscounts: DiscountDoc[],
  startDate: Date | null = null,
  endDate: Date | null = null,
  /** startTime is a string in HH:mm form - if null all day will be checked */
  startTime: string | null = null,
  /** endTime is a string in HH:mm form - if null all day will be checked */
  endTime: string | null = null,
  day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday" | "everyday" | null = null,
  orderType: string | null = null,
  discountAlgo = "min"
) => {
  const filteredDiscounts =
    startDate && endDate
      ? existingDiscounts
        .filter((discount: DiscountDoc) => !!discount.scheduledDiscount && discount.enabled)
        .filter((discount: DiscountDoc) =>
          discount.hours.some(
            (schedule: ScheduledDiscount) =>
              (checkDatesToDatesToExcludeForConflict(moment(startDate).startOf("day").add(4, "hours").toDate(), moment(endDate).endOf("day").add(4, "hours").toDate(), moment(schedule.startDate).startOf("day").add(4, "hours").toDate(), moment(schedule.endDate).endOf("day").add(4, "hours").toDate(), discount.datesToExclude, []) ||
                schedule.day === "everyday" ||
                checkDayBetweenRange(schedule.day, getDayFromDate(startDate), getDayFromDate(endDate))) &&
              (orderType ? !discount.orderTypesToExclude.includes(orderType) : true) &&
              /*
                  If any discount is set to run on some short time frame
                  then pass in a startTime and endTime to allow checking
                  if that discount is allowed to be shown during that
                  time.
              */
              (startTime && endTime && schedule.startTime && schedule.endTime ? checkTimeForInclusion(startTime, endTime, schedule.startTime, schedule.endTime) : true)
          )
        )
      : day
        ? existingDiscounts
          .filter((discount: DiscountDoc) => !!discount.scheduledDiscount)
          .filter((discount: DiscountDoc) =>
            discount.hours.some((schedule: ScheduledDiscount) => (schedule.day === day || schedule.day === "everyday") && (orderType ? !discount.orderTypesToExclude.includes(orderType) : true) && (startTime && endTime && schedule.startTime && schedule.endTime ? checkTimeForInclusion(startTime, endTime, schedule.startTime, schedule.endTime) : true))
          )
        : null;

  if (filteredDiscounts) {
    return productsAndPrices
      .filter((product) => {
        // Find any discount with that product and price
        return filteredDiscounts.findIndex((discount) => discount.scheduledDiscount?.productsToDiscount.includes(product[0])) !== -1;
      })
      .map((product) => {
        // Use the discountAlgo to determine the price of the product after the discount
        const discounts = filteredDiscounts.filter((discount) => discount.scheduledDiscount?.productsToDiscount.includes(product[0]));
        const prices = discounts.map((discount) => ({
          discountedPrice: findDiscountAmount(discount.scheduledDiscount?.discountType || null, discount.scheduledDiscount?.discountAmount || 0, product[1]),
          discountId: discount.id,
          discountName: discount.nameExternal,
        }));

        if (discountAlgo === "min") {
          const cheapestDiscount = _.minBy(prices, function (o) {
            return o.discountedPrice;
          });
          return { [product[0]]: cheapestDiscount };
        } else if (discountAlgo === "max") {
          const mostExpensiveDiscount = _.minBy(prices, function (o) {
            return o.discountedPrice;
          });
          return { [product[0]]: mostExpensiveDiscount };
        }
        return { [product[0]]: prices[0] };
      });
  }

  return {};
};

export const findDiscountAmount = (discountType: "priceOff" | "percentOff" | "newPrice" | null, discountAmount: number, price: number) => {
  switch (discountType) {
    case "priceOff":
      return price - discountAmount;
    case "percentOff":
      return price * (1 - discountAmount / 100);
    case "newPrice":
      return discountAmount;
    default:
      return price;
  }
};

// export const checkForCouponDiscounts = (
//   orderDoc: Order,
//   existingDiscounts: DiscountDoc[],
//   menus: any[],
//   menuCategories: any[],
//   products: any[],
//   /** If promoCode is not null, then only that discount will be checked */
//   promoCode: string | null = null,
//   startDate: Date | null = null,
//   endDate: Date | null = null,
//   orderType: string | null = null
// ): { discountId: string | null; order: Order; discountApplied: boolean; discountConditionMet: boolean } => {

//   const menuRef: FirebaseCollectionRef[] = menus.map((menu) => ({ id: menu.id, name: menu.nameExternal, items: menu.categories }));
//   const menuCategoriesRef: FirebaseCollectionRef[] = menuCategories.map((menuCategory) => ({
//     id: menuCategory.id,
//     name: menuCategory.name,
//     items: menuCategory.products,
//   }));

//   if (promoCode) {
//     const discountIndex = existingDiscounts.findIndex((discount: DiscountDoc) => {
//       if (discount.rewardOrCouponDiscount) {
//         if (discount.rewardOrCouponDiscount.promoCode === promoCode) return true;
//       }
//       return false;
//     });
//     if (discountIndex !== -1) {
//       // TODO: Check for discount triggers
//       const foundDiscount = existingDiscounts[discountIndex];
//       if (foundDiscount.enabled && !foundDiscount.deleted && !foundDiscount.orderTypesToExclude.includes(orderType || "")) {
//         if (startDate && endDate) {
//           if (
//             checkDatesToDatesToExcludeForConflict(
//               moment(startDate).startOf("day").add(4, "hours").toDate(),
//               moment(endDate).endOf("day").add(4, "hours").toDate(),
//               moment(foundDiscount.hours[0].startDate).startOf("day").add(4, "hours").toDate(),
//               moment(foundDiscount.hours[0].endDate).endOf("day").add(4, "hours").toDate(),
//               foundDiscount.datesToExclude,
//               []
//             )
//           ) {
//             return applyDiscountToOrder(orderDoc, foundDiscount, products, menuRef, menuCategoriesRef);
//           }
//         } else {
//           /*
//               If start date and end date not specified then the date 
//               range for the existing discounts will not be checked
//               */
//           return applyDiscountToOrder(orderDoc, foundDiscount, products, menuRef, menuCategoriesRef);
//         }
//       }
//     }
//   } else {
//     // TODO: Check for discount triggers
//     // TODO: Remove all without promoCodes
//     const allViableDiscountModels = existingDiscounts.filter((discount) => {
//       if (discount.enabled && !discount.deleted && !discount.orderTypesToExclude.includes(orderType || "") && !!discount.rewardOrCouponDiscount) {
//         if (discount.rewardOrCouponDiscount.promoCode) return false;
//         if (startDate && endDate) {
//           if (checkDatesToDatesToExcludeForConflict(moment(startDate).startOf("day").add(4, "hours").toDate(), moment(endDate).endOf("day").add(4, "hours").toDate(), moment(discount.hours[0].startDate).startOf("day").add(4, "hours").toDate(), moment(discount.hours[0].endDate).endOf("day").add(4, "hours").toDate(), discount.datesToExclude, [])) {
//             return true;
//           }
//         } else {
//           /*
//               If start date and end date not specified then the date 
//               range for the existing discounts will not be checked
//               */
//           return true;
//         }
//       }
//       return false;
//     });

//     if (allViableDiscountModels.length > 0) {
//       const allOrdersWithDiscount: {
//         [T: string]: { order: { discountId: string | null; order: Order; discountApplied: boolean; discountConditionMet: boolean }; discount: DiscountDoc };
//       } = allViableDiscountModels.reduce(
//         (acc, discount) => ({
//           ...acc,
//           [discount.id]: {
//             order: applyDiscountToOrder(orderDoc, discount, products, menuRef, menuCategoriesRef),
//             discount: discount,
//           },
//         }),
//         {}
//       );

//       const allDiscountOrders = Object.fromEntries(Object.entries(allOrdersWithDiscount).filter(([_, v]) => v.order.discountApplied !== false));

//       const allDiscountSubTotals: { id: string; total: number; numberOfProducts: number }[] = Object.keys(allDiscountOrders).map((discountId) => ({
//         id: discountId,
//         total: accumulateTotal(allDiscountOrders[discountId].order.order, allDiscountOrders[discountId].discount),
//         numberOfProducts: allDiscountOrders[discountId].order.order.products.length,
//       }));
//       const cheapestTotal = _.minBy(allDiscountSubTotals, (discount) => discount.total);
//       const multipleCheapestCheck = allDiscountSubTotals.filter((discount) => discount.total === cheapestTotal?.total);
//       if (multipleCheapestCheck.length > 1) {
//         const mostItems = _.maxBy(multipleCheapestCheck, (discount) => discount.numberOfProducts);
//         if (mostItems) {
//           return {
//             discountConditionMet: true,
//             discountApplied: true,
//             order: allDiscountOrders[mostItems.id].order.order,
//             discountId: mostItems.id,
//           };
//         }
//       }

//       if (cheapestTotal) {
//         return {
//           discountConditionMet: true,
//           discountApplied: true,
//           order: allDiscountOrders[cheapestTotal.id].order.order,
//           discountId: cheapestTotal.id,
//         };
//       }
//       return {
//         discountConditionMet: true,
//         discountApplied: true,
//         order: allDiscountOrders[Object.keys(allDiscountOrders)[0]].order.order,
//         discountId: Object.keys(allDiscountOrders)[0],
//       };
//     }
//   }
//   return { discountId: null, discountConditionMet: false, discountApplied: false, order: orderDoc };
// };

const checkDateRangeForInclusion = (newStartDate: Date, newEndDate: Date, existingStartDate: Date | null, existingEndDate: Date | null) => {
  /*
  S - start, E - end.
  No conflict is S E S E
  Conflict is S S E E
  Check for inclusion is the first two S S
  */
  if (existingStartDate && existingEndDate) {
    let allDates = [newStartDate, newEndDate, existingStartDate, existingEndDate];
    // @ts-ignore
    allDates.sort((d1, d2) => d1 - d2);
    if ((allDates[0] === newStartDate && allDates[1] === existingStartDate) || (allDates[1] === newStartDate && allDates[0] === existingStartDate)) {
      return true;
    }
  }
  return false;
};

const getDayFromDate = (date: Date) => {
  return ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"][date.getDay()];
};

const checkDayBetweenRange = (day: string | null, startDay: string, endDay: string) => {
  if (day) {
    const allDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    const dayToCheck = allDays.findIndex((givenDay) => givenDay === day.toLowerCase());
    const lowerDay = allDays.findIndex((givenDay) => givenDay === startDay.toLowerCase());
    const upperDay = allDays.findIndex((givenDay) => givenDay === endDay.toLowerCase());

    return (upperDay >= dayToCheck && lowerDay <= dayToCheck) || (upperDay <= dayToCheck && lowerDay >= dayToCheck);
  }
  return false;
};

const checkTimeForInclusion = (newStartTime: string | null, newEndTime: string | null, existingStartTime: string | null, existingEndTime: string | null) => {
  /*
    S - start, E - end.
    if (S > E) i.e. E is past midnight
        E += 1 day
    Hence,
        a no conflict would mean S E S E
        and the condition guarantees to only look for S S E E
    */
  if (newStartTime && newEndTime && existingStartTime && existingEndTime) {
    let firstStartDate: Date | moment.Moment = moment().add({ hours: parseFloat(newStartTime.split(":")[0]), minutes: parseFloat(newStartTime.split(":")[1]) });
    let secondStartDate: Date | moment.Moment = moment().add({
      hours: parseFloat(existingStartTime.split(":")[0]),
      minutes: parseFloat(existingStartTime.split(":")[1]),
    });
    let firstEndDate: Date | moment.Moment = moment().add({ hours: parseFloat(newEndTime.split(":")[0]), minutes: parseFloat(newEndTime.split(":")[1]) });
    let secondEndDate: Date | moment.Moment = moment().add({
      hours: parseFloat(existingEndTime.split(":")[0]),
      minutes: parseFloat(existingEndTime.split(":")[1]),
    });

    if (!firstStartDate.isBefore(firstEndDate)) {
      firstEndDate = firstEndDate.add(1, "day");
    }

    if (!secondStartDate.isBefore(secondEndDate)) {
      secondEndDate = secondEndDate.add(1, "day");
    }

    firstStartDate = firstStartDate.toDate();
    secondStartDate = secondStartDate.toDate();
    firstEndDate = firstEndDate.toDate();
    secondEndDate = secondEndDate.toDate();

    return checkDateRangeForInclusion(firstStartDate, firstEndDate, secondStartDate, secondEndDate);
  }

  return false;
};

const checkDatesToDatesToExcludeForConflict = (firstStartDate: Date, firstEndDate: Date, secondStartDate: Date, secondEndDate: Date, existingDatesToExclude: ScheduledDiscount[], newDatesToExclude: ScheduledDiscount[]) => {
  /*
    S - start, E - end.
    A no conflict would mean S E S E
    The condition guarantees to only look for S S E E
    And we check datesToExclude for
        S S E E
          ^ ^
          | |
        exclusion
    otherwise conflict
    */

  if (moment(firstStartDate).isSame(firstEndDate)) {
    firstEndDate = moment(firstEndDate).endOf("day").toDate();
  }
  if (moment(secondStartDate).isSame(secondEndDate)) {
    secondEndDate = moment(secondEndDate).endOf("day").toDate();
  }

  let allDates = [firstStartDate, firstEndDate, secondStartDate, secondEndDate];
  // @ts-ignore
  allDates.sort((d1, d2) => d1 - d2);

  if ((allDates[0] === firstStartDate && allDates[1] === secondStartDate) || (allDates[0] === secondStartDate && allDates[1] === firstStartDate)) {
    for (let m = moment(allDates[1]); m.diff(moment(allDates[2]).startOf("day"), "days") <= 0; m.add(1, "days")) {
      // Check whether the new or existing datesToExclude was included for this day
      if (!existingDatesToExclude.some((d: ScheduledDiscount) => checkForDateExclusion(m, d.startDate, d.endDate)) && !newDatesToExclude.some((d: ScheduledDiscount) => checkForDateExclusion(m, d.startDate, d.endDate))) {
        return true;
      }
    }
  }
  return false;
};

const checkForDateExclusion = (date: moment.Moment, startDate: Date | null, endDate: Date | null) => {
  if (startDate && endDate) return date.isBetween(startDate, endDate) || date.isSame(startDate) || date.isSame(endDate);
  return false;
};

// const applyDiscountToOrder = (order: Order, discount: DiscountDoc, allProducts: any[], menuRef: FirebaseCollectionRef[], menuCategoryRef: FirebaseCollectionRef[]): { discountId: string | null; order: Order; discountApplied: boolean; discountConditionMet: boolean } => {
//   let discountConditionMet = false;

//   const getNamesFromIds = (itemsToExclude: string[], itemsRef: FirebaseCollectionRef[]) => {
//     return itemsToExclude
//       .map((id) => {
//         const index = itemsRef.findIndex((item) => item.id === id);
//         if (index !== -1) {
//           return itemsRef[index].name;
//         }
//         return null;
//       })
//       .filter((i) => !!i);
//   };

//   if (discount.rewardOrCouponDiscount) {
//     // Check if discount trigger is met
//     if (discount.rewardOrCouponDiscount.discountTriggerType === "minimumDollarSpent") {
//       // TODO: Find out if the price or the discountedPrice should be aggregated
//       if (discount.orderItemsToExclude && discount.orderItemsToExclude.length > 0) {
//         const total = order.products.reduce((acc, val) => acc + (discount.orderItemsToExclude.includes(val.productId) ? 0 : val.discountedPrice || val.discountedPrice === 0 ? val.discountedPrice * val.quantity : (val.price + val.selectedModifiers.reduce((tot, mod) => tot + mod.additionalCost, 0)) * val.quantity), 0);

//         if (total > (discount.rewardOrCouponDiscount.discountTriggerAmount || 0)) {
//           discountConditionMet = true;
//         }
//         // Use the subTotal if the items to exclude list is empty for products ordered
//       } else {
//         if (order.amount.subTotal > (discount.rewardOrCouponDiscount.discountTriggerAmount || 0)) {
//           discountConditionMet = true;
//         }
//       }

//       // All the items are bought to trigger a discount
//     } else if (discount.rewardOrCouponDiscount.discountTriggerType === "specificItemsDiscounted") {
//       if (
//         _.difference(
//           discount.rewardOrCouponDiscount.discountTriggerItems,
//           order.products.map((product: any) => product.productId)
//         ).length === 0
//       ) {
//         discountConditionMet = true;
//       }
//       // Any single item is bought to trigger a discount
//     } else if (discount.rewardOrCouponDiscount.discountTriggerType === "anyItemsDiscounted") {
//       if (order.products.some((product) => (discount.rewardOrCouponDiscount?.discountTriggerItems || []).includes(product.productId))) {
//         discountConditionMet = true;
//       }
//     }

//     // Give reward if discountConditionMet is true
//     if (discountConditionMet) {
//       if (discount.rewardOrCouponDiscount.rewardType === "freeItem") {
//         // Comp the free item(s)
//         const items = discount.rewardOrCouponDiscount.rewardDiscountItems || [];
//         // const quantityRef: { [T: string]: number } = items.reduce(
//         //   (acc, productId) => ({ ...acc, [productId]: items.filter((i) => i === productId).length }),
//         //   {},
//         // );

//         const bestDiscount = _.maxBy(
//           order.products.filter((product) => items.includes(product.productId)),
//           (product) => product.price
//         );

//         // Check if bestDiscount isn't undefined
//         if (bestDiscount) {
//           return {
//             discountConditionMet: discountConditionMet,
//             discountApplied: true,
//             discountId: discount.id,
//             order: {
//               ...order,
//               discountsAppliedToOrder: [discount.id],
//               products: order.products.map((product) => {
//                 if (product.productId === bestDiscount?.productId) {
//                   // More than 1 item of this product has been purchased
//                   if (bestDiscount.quantity > 2) {
//                     return {
//                       ...bestDiscount,
//                       quantity: bestDiscount.quantity - 1,
//                     };
//                   } else {
//                     // Item given for free
//                     return {
//                       ...bestDiscount,
//                       discountedPrice: 0,
//                     };
//                   }
//                 }
//                 return product;
//               }),
//             },
//           };
//         } else {
//           return {
//             discountId: discount.id,
//             discountConditionMet: discountConditionMet,
//             discountApplied: false,
//             order: order,
//           };
//         }

//         // Don't automatically give a free product
//         /*
//         const products: Product[] = [
//           ...order.products,
//           ...((discount.rewardOrCouponDiscount.rewardDiscountItems || [])
//             .map((productId) => {
//               const productIndex = allProducts.findIndex((existingProduct) => existingProduct.id === productId);
//               const menuCategoryIndex = menuCategoryRef.findIndex((existingMenuCategory) => existingMenuCategory.items.includes(productId));
//               if (productIndex !== -1 && menuCategoryIndex !== -1) {
//                 const menuCategoryInfo = menuCategoryRef[menuCategoryIndex];
//                 const menuIndex = menuRef.findIndex((existingMenu) => existingMenu.items.includes(menuCategoryInfo.id));
//                 return {
//                   productOrderId: product.quantity + "-" + product.productId + "-" + docRef.id + "-" + uuidv4().replace(/-/g,
//                   productId: productId,
//                   businessId: order.businessId,
//                   imageUrl: allProducts[productIndex].imageUrl || '',
//                   menuName: menuIndex !== -1 ? menuRef[menuIndex].name : '',
//                   menuCategory: menuCategoryInfo.name,
//                   name: allProducts[productIndex].nameExternal || '',
//                   quantity: quantityRef[productId] || 1,
//                   price: allProducts[productIndex].price || 0,
//                   discountedPrice: 0,
//                   taxRate: allProducts[productIndex].taxRate || null,
//                   type: allProducts[productIndex].type || '',
//                   alcohol: allProducts[productIndex].alcohol || false,
//                   discountsAppliedToProduct: [],
//                   selectedModifiers: [],
//                 };
//               }
//               return null;
//             })
//             .filter((i) => !!i) as Product[]),
//         ];
//         return {
//           ...order,
//           discountsAppliedToOrder: [discount.id],
//           products: products,
//         };
//         */
//       } else if (discount.rewardOrCouponDiscount?.rewardType === "discountedAmount") {
//         switch (discount.rewardOrCouponDiscount.rewardDiscountType) {
//           case "priceOffEachViableItem":
//             return {
//               discountId: discount.id,
//               discountConditionMet: discountConditionMet,
//               discountApplied: true,
//               order: {
//                 ...order,
//                 discountsAppliedToOrder: [discount.id],
//                 products: order.products.map((product) => {
//                   if (!discount.productsToExclude.includes(product.productId) && !getNamesFromIds(discount.menusToExclude, menuRef).includes(product.menuName) && !getNamesFromIds(discount.menuCategoriesToExclude, menuCategoryRef).includes(product.menuCategory)) {
//                     return {
//                       ...product,
//                       discountedPrice: product.price + product.selectedModifiers.reduce((acc, val) => acc + val.additionalCost, 0) - (discount.rewardOrCouponDiscount?.rewardDiscountAmount || 0),
//                     };
//                   }
//                   return product;
//                 }),
//               },
//             };
//           case "percentOffEachViableItem":
//             return {
//               discountId: discount.id,
//               discountConditionMet: discountConditionMet,
//               discountApplied: true,
//               order: {
//                 ...order,
//                 discountsAppliedToOrder: [discount.id],
//                 products: order.products.map((product) => {
//                   if (!discount.productsToExclude.includes(product.productId) && !getNamesFromIds(discount.menusToExclude, menuRef).includes(product.menuName) && !getNamesFromIds(discount.menuCategoriesToExclude, menuCategoryRef).includes(product.menuCategory)) {
//                     return {
//                       ...product,
//                       discountedPrice: (product.price + product.selectedModifiers.reduce((acc, val) => acc + val.additionalCost, 0)) * (1 - (discount.rewardOrCouponDiscount?.rewardDiscountAmount || 0) / 100),
//                     };
//                   }
//                   return product;
//                 }),
//               },
//             };
//           case "newPriceForEachViableItem":
//             return {
//               discountId: discount.id,
//               discountConditionMet: discountConditionMet,
//               discountApplied: true,
//               order: {
//                 ...order,
//                 discountsAppliedToOrder: [discount.id],
//                 products: order.products.map((product) => {
//                   if (!discount.productsToExclude.includes(product.productId) && !getNamesFromIds(discount.menusToExclude, menuRef).includes(product.menuName) && !getNamesFromIds(discount.menuCategoriesToExclude, menuCategoryRef).includes(product.menuCategory)) {
//                     return {
//                       ...product,
//                       discountedPrice: discount.rewardOrCouponDiscount?.rewardDiscountAmount || 0,
//                     };
//                   }
//                   return product;
//                 }),
//               },
//             };
//           case "priceOff":
//             return {
//               discountId: discount.id,
//               discountConditionMet: discountConditionMet,
//               discountApplied: true,
//               order: {
//                 ...order,
//                 discountsAppliedToOrder: [discount.id],
//                 amount: {
//                   deliveryFee: order.amount.deliveryFee || 0,
//                   serviceChargeTotal: order.amount.serviceChargeTotal || 0,
//                   tax: order.amount.tax,
//                   grossTotal: order.amount.grossTotal,
//                   subTotal: order.amount.subTotal,
//                   netTotal: order.amount.netTotal - (discount.rewardOrCouponDiscount?.rewardDiscountAmount || 0),
//                   discountTotal: discount.rewardOrCouponDiscount?.rewardDiscountAmount || 0,
//                   currency: "USD",
//                   tip: order.amount.tip || 0,
//                 },
//               },
//             };
//           case "percentOff":
//             return {
//               discountId: discount.id,
//               discountConditionMet: discountConditionMet,
//               discountApplied: true,
//               order: {
//                 ...order,
//                 discountsAppliedToOrder: [discount.id],
//                 amount: {
//                   deliveryFee: order.amount.deliveryFee || 0,
//                   serviceChargeTotal: order.amount.serviceChargeTotal || 0,
//                   tax: order.amount.tax,
//                   grossTotal: order.amount.grossTotal,
//                   subTotal: order.amount.subTotal,
//                   netTotal: order.amount.netTotal * (1 - (discount.rewardOrCouponDiscount?.rewardDiscountAmount || 0) / 100),
//                   discountTotal: order.amount.netTotal * ((discount.rewardOrCouponDiscount?.rewardDiscountAmount || 0) / 100),
//                   currency: "USD",
//                   tip: order.amount.tip || 0,
//                 },
//               },
//             };
//           default:
//             break;
//         }
//       }
//     }
//   }
//   return { discountId: null, discountConditionMet: discountConditionMet, discountApplied: false, order: order };
// };
// const accumulateTotal = (order: Order, discount: DiscountDoc) => {
//   if (discount.rewardOrCouponDiscount?.rewardType === "discountedAmount") {
//     if (discount.rewardOrCouponDiscount.rewardDiscountType === "priceOff" || discount.rewardOrCouponDiscount.rewardDiscountType === "percentOff") {
//       return order.amount.netTotal;
//     }
//   }
//   return order.products.reduce((acc, val) => acc + (val.discountedPrice || val.discountedPrice === 0 ? val.discountedPrice * val.quantity : (val.price + val.selectedModifiers.reduce((tot, mod) => tot + mod.additionalCost, 0)) * val.quantity), 0);
// };

export const calculateDeliveryDistance = async (lat: number, lon: number, bizLat: number, bizLon: number, acceptedRadius: number, biz: any) => {
  var R = 6371; // km
  var dLat = toRad(bizLat - lat);
  var dLon = toRad(bizLon - lon);
  var lat1 = toRad(lat);
  var lat2 = toRad(bizLat);

  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  console.log("distance", d);

  if (biz.location.country && biz.location.country === "USA") {
    d = d * 0.621371; //convert km distance to miles
  }

  function toRad(value: any) {
    return (value * Math.PI) / 180;
  }

  if (d > acceptedRadius) {
    if (biz.location.country === "USA") {
      alert("Please select a delivery address within " + acceptedRadius + " miles from " + biz.businessName + ".");
    } else {
      alert("Please select a delivery address within " + acceptedRadius + " kilometers from " + biz.businessName + ".");
    }
    return false;
  } else {
    return true;
  }
};

export const doordashDriveFormatPhone = (phone:string) =>{
  phone = phone.replace(/-/g, ''); //remove spaces

  if(!phone.includes('+1')){
    phone = '+1'+phone //make sure country code is included
  }
  //for some reason users have +1+1 as phone - some "legacy users" phones are updating incorrectly - need to fix that bug 
  if(phone.includes('+1+1')){
    phone = phone.replace('+1+1', '+1')
  }
  return phone
}

export const doordashDriveFormatAddress = (businessAddress:string, dropoffAddress:string) =>{
  var pickupAddressComponents = businessAddress.split(',')
  var dropoffAddressComponents = dropoffAddress.split(',')
  pickupAddressComponents = pickupAddressComponents.map((component:string)=>{
    return component = component.trim()
  })
  dropoffAddressComponents = dropoffAddressComponents.map((component:string)=>{
    return component = component.trim()
  })
  const obj = { pickupAddressComponents: pickupAddressComponents, dropoffAddressComponents: dropoffAddressComponents};
  return obj;
}

export const doordashDriveClassicEstimate = async(firebaseJWT:string, order:any, business: any, dropoffAddress: any)=>{
  
  const addressDetails = doordashDriveFormatAddress(business.location.address,dropoffAddress)
  if(!addressDetails.dropoffAddressComponents || !addressDetails.pickupAddressComponents){
    return alert('Oops! Looks like that is not a valid Doordash delivery address.')
  }

  try {
      const deliveryPayload = {
        external_delivery_id: firebaseJWT,
        locale: 'en-US',
        pickup_address: {
          street: addressDetails.pickupAddressComponents[0],
          city: addressDetails.pickupAddressComponents[1],
          state: addressDetails.pickupAddressComponents[2].split(' ')[0],
          zip_code: addressDetails.pickupAddressComponents[2].split(' ')[1] + (addressDetails.pickupAddressComponents[2].split(' ')[2]? ' ' + addressDetails.pickupAddressComponents[2].split(' ')[2] : '')
        },
        dropoff_address: {
          street: addressDetails.dropoffAddressComponents[0],
          city: addressDetails.dropoffAddressComponents[1],
          state: addressDetails.dropoffAddressComponents[2].split(' ')[0],
          zip_code: addressDetails.dropoffAddressComponents[2].split(' ')[1] + (addressDetails.dropoffAddressComponents[2].split(' ')[2]? ' ' + addressDetails.dropoffAddressComponents[2].split(' ')[2] : '')
        },
        order_value: order.amount.netTotal,
        external_store_id: business.id,
        external_business_name: business.businessName,
        // delivery_time: order.pickupTime ? moment(order.pickupTime).add(20, 'minutes').toISOString() : moment().add(20, 'minutes').toISOString(),
        pickup_time: order.pickupTime ? moment(order.pickupTime).toISOString() : moment().add(10, 'minutes').toISOString(),
        // promotion_id: ''
      }
      const response = await axios
      .post(`http://localhost:9001/tango-2/us-central1/nestApi/online-orders/doordash-drive-classic/deliveryEstimate`, 
      deliveryPayload,
        {
          headers: {
            'Authorization': `Bearer ${firebaseJWT}`
          }
        }
      )  
      return response.data
    
  } catch (error:any) {
    console.log("error", error);
    return alert('Oops! Looks like that is not a valid Doordash delivery address.')
  }
}

export const doordashDriveClassicCreateDelivery = async(firebaseJWT:string, order:any, business: any, user:any)=>{

  const addressDetails = doordashDriveFormatAddress(business.location.address, order.deliveryAddress.displayAddress)
  if(!addressDetails.dropoffAddressComponents || !addressDetails.pickupAddressComponents){
    return alert('Oops! Looks like that is not a valid Doordash delivery address.')
  }
  
  let businessTurnAroundTime = 15 //defualt number of minutes to make order
  
  business.tangoProducts[0].orderTypes.map((orderType:any)=>{
    if(orderType.name === 'delivery'){
      businessTurnAroundTime = orderType.waitTime[0] //set businessTurnAroundTime time based on the biz setting
    }
  })

  let numberOfItems = 0
  let formattedItems: { name: string; quantity: number}[] = []
  
  order.products.map((product:any)=>{
    numberOfItems = numberOfItems + product.quantity
    formattedItems.push({
      name: product.name,
      quantity: product.quantity,
    })
  })

  const deliveryPayload = {
    pickup_phone_number:  doordashDriveFormatPhone(business.phones[0].number),
    pickup_instructions: order.specialInstructions,
    customer: {
      phone_number: doordashDriveFormatPhone(user.phone),
      business_name: "",
      first_name: user.name,
      last_name: "",
      email: user.email,
      should_send_notifications: true,
      locale: "en-US",
    },
    dropoff_instructions: order.specialInstructions,
    tip: Math.round(order.amount.tip),
    contains_alcohol: false,
    barcode_scanning_required: false,
    num_items: numberOfItems,
    signature_required: false,
    pickup_address: {
      street: addressDetails.pickupAddressComponents[0],
      city: addressDetails.pickupAddressComponents[1],
      state: addressDetails.pickupAddressComponents[2].split(' ')[0],
      zip_code: addressDetails.pickupAddressComponents[2].split(' ')[1] + (addressDetails.pickupAddressComponents[2].split(' ')[2]? ' ' + addressDetails.pickupAddressComponents[2].split(' ')[2] : '')
    },
    dropoff_address: {
      street: addressDetails.dropoffAddressComponents[0],
      city: addressDetails.dropoffAddressComponents[1],
      state: addressDetails.dropoffAddressComponents[2].split(' ')[0],
      zip_code: addressDetails.dropoffAddressComponents[2].split(' ')[1] + (addressDetails.dropoffAddressComponents[2].split(' ')[2]? ' ' + addressDetails.dropoffAddressComponents[2].split(' ')[2] : '')
    },
    order_value: Math.round(order.amount.netTotal + order.amount.deliveryFee + order.amount.tip),
    external_store_id: business.id,
    external_business_name: business.businessName,
    pickup_time: order.pickupTime ? moment(order.pickupTime).toISOString() : moment().add(businessTurnAroundTime, 'minutes').toISOString(),
    // delivery_time: order.pickupTime ? moment(order.pickupTime).add(15, 'minutes').toISOString() : moment().add(businessTurnAroundTime + 15, 'minutes').toISOString(),
    items: formattedItems,
    team_lift_required: false,
    pickup_business_name: business.businessName,
    external_delivery_id: order.orderNumber,
    driver_reference_tag: order.orderNumber,
    requires_catering_setup: false,
    allow_unattended_delivery: false,
    cash_on_delivery: 0,
    delivery_metadata: {},
    allowed_vehicles: 'car',
    is_contactless_delivery: false,
    // promotion_id: ''
  }
 
  try {
    const response = await axios
    .post(`http://localhost:9001/tango-2/us-central1/nestApi/online-orders/doordash-drive-classic/createDelivery`, 
    deliveryPayload,
      {
        headers: {
          'Authorization': `Bearer ${firebaseJWT}`
        }
      }
    )
    return response.data
  } catch (error) {
    console.log('catch error', error)
    return alert('Oops! Looks like there was a problem processing your Doordash delivery order.')
  }
}

export const getDineInProductsPaid = (order: Order, items: any) => {
  const finalProductsPaid = order.products.filter((product: any) => items.includes(product.productOrderId));
  console.log('final products paid')
  return finalProductsPaid;
};

export const getListOfTime = () => {
  let times = [];
  let counter = 0;
  let date = moment("00:00", "hh:mm");
  do {
    times.push(date.format("LT"));
    date = date.add(30, "minutes");
    counter = counter + 1;
  } while (counter < 48);
  return times;
};

export const checkIfOnlineOrderingEnabled = (business: any) => {
  const tangoMobile = (business?.tangoProducts || []).find((product: any) => product.name === 'mobile')
  if (tangoMobile) {
    return tangoMobile?.enabled || false;
  }
  return false;
}

export const checkHours = (hours: any, timezone: string) => {
  //checks if biz is open at current time (if business hours is passed in) or menu is available at current time (if menu hours passed in)

  let isOpen = true //default to being open - switch this to false if not in range
  let shiftDay = false //set this to true for past 12am and do special check
  if (!timezone) {
    timezone = "America/New_York" //biz may not have timezone set // eventually call function to get it's timezone?
  }
  if (!hours) {
    return isOpen //if biz or menu has no hours return availais open = true - should never happen because default is set when biz signup
  }
  let currentDay = momentTZ.tz(timezone).format("dddd").toLowerCase()
  let currentTime = parseFloat(momentTZ.tz(timezone).format("HH:mm").replace(":", ".")) //use float numbers and use comparison operators

  let days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]

  //if current time is between 12am 4 am treat it as the previous day of the week
  //so if its 1:15 am on Tuesday treat it like Monday night at 25:15
  if (currentTime >= 0 && currentTime <= 4) {
    if (days.indexOf(currentDay) !== -1) {
      shiftDay = true
      //treat current day as the previous day - if first item is sunday make it saturday
      if (currentDay === "sunday") {
        currentDay = "saturday"
      } else {
        currentDay = days[days.indexOf(currentDay) - 1] //treat current day as the previous day
      }
      currentTime = currentTime + 24
    }
  }

  // console.log("checking hours for", currentDay, "at", currentTime, "timezone:", timezone)

  let availableToday = false

  hours.forEach((timeSlot: any) => {

    //if hours exist for current day of week it means there are hours to check
    if (currentDay === timeSlot.day) {
      availableToday = true
    }
    //if current day is not open return false
    if (currentDay === timeSlot.day && !timeSlot.open) {
      console.log("biz or menu closed on", currentDay)
      return isOpen = false
    }

    //format each open and close time to float number 
    timeSlot.openTime = parseFloat(timeSlot.openTime.replace(":", "."))
    timeSlot.closeTime = parseFloat(timeSlot.closeTime.replace(":", "."))

    //if using shift day (12am-4am) then add 24 to close time
    if (shiftDay && timeSlot.closeTime >= 0 && timeSlot.closeTime <= 4) {
      timeSlot.closeTime = timeSlot.closeTime + 24
    }

    //finally actually check if current time is in range
    //if current time is before open time or current time is after close time return false
    if ((currentDay === timeSlot.day && currentTime < timeSlot.openTime) || (currentDay === timeSlot.day && currentTime > timeSlot.closeTime)) {
      // console.log("biz or menu closed at this time", currentDay, currentTime, "open:", timeSlot.openTime, "close:", timeSlot.closeTime)
      return isOpen = false
    }
  })

  if (!availableToday) {
    isOpen = false
  }
  // console.log("is biz or menu open?", isOpen, hours)
  return isOpen //true or false
}

export const validateNewPickupTime = (pickupTime:any, business:any) => {  
  console.log("pickup time", pickupTime)
  const day = moment(pickupTime, "YYYY-MM-DD HH:mm:ss").format('dddd')
  const time = parseFloat( moment(pickupTime, "YYYY-MM-DD HH:mm:ss").format('HH').replace(":", "."))

  for (let i = 0; i < business.tangoProducts.length; i++){
    const product = business.tangoProducts[i]
    if(product.name === "mobile" && !product.enabled){
      return false
    }
  }

  for (let i = 0; i < business.hours.length; i++){
    const dayOfWeek = business.hours[i]
    const openTime = dayOfWeek.openTime
    const closeTime = dayOfWeek.closeTime
    if(dayOfWeek.day.toLowerCase() === day.toLowerCase() && dayOfWeek.open && time > openTime && time < closeTime){
      return true
    }
  }


}

export const createFirebaseJWT = async () => {
  try {
    const idToken = await auth().currentUser?.getIdToken();
    if (!idToken) {
      console.log('no current firebase user')
      return '';
    }
    return idToken;
  } catch (e) {
    console.log("error generating firebase JWT", e);
    return ''
  }
};

export const sendEmailsTableSessionClosed = async (order:Order, tab:Tab) => {
  //TO DO - when a table session is closed (ie all items are payed for) send email receipts to all cusotmers
}

export const calculateSubtotalInOrder =  (itemsInCart:ItemsInCart[]) =>{
  const itemTotals = itemsInCart.map((item:ItemsInCart)=>{
      const modifierAdditionalCosts = item.selectedModifiers.map((selectedMod:SelectedModifiers)=>{
          return selectedMod.additionalCost
      })
      return (item.price + modifierAdditionalCosts.reduce((a:number, b:number) => a + b, 0)) * item.quantity
  })
  return Math.round(itemTotals.reduce((a:number, b:number) => a + b, 0))
}

export const calculateTaxOnOrder = (itemsInCart:ItemsInCart[], businessTaxRate:number) =>{
  const itemTax = itemsInCart.map((item:ItemsInCart)=>{
    const taxRate = (item.taxRate ? item.taxRate : businessTaxRate) * 0.01 //3.5 % tax is really .035
      const modifierAdditionalCosts = item.selectedModifiers.map((selectedMod:SelectedModifiers)=>{
          return selectedMod.additionalCost
      })
      return ((item.price + modifierAdditionalCosts.reduce((a:number, b:number) => a + b, 0)) * item.quantity) * taxRate
  })
  return Math.round(itemTax.reduce((a:number, b:number) => a + b, 0))
}

export const getBusinessTaxRate = (business:Business) =>{
  var taxRate = 0.0 
  business.tangoProducts.forEach((tangoProduct: any) => {
      if (tangoProduct.name === "mobile") {
          tangoProduct.orderTypes.forEach((type: any) => {
              if (type.name === 'dineIn') {
                taxRate = type.taxRate;
              }
          })
      }
  })
  return taxRate
}

export const selectModifierOption = (itemInCart:ItemsInCart, modifier:TangoModifier, option:ModifierOption, toggleOff: boolean) => {


  var selectedModifiers = itemInCart.selectedModifiers

  //formatted modifier
  //@ts-ignore
  const formattedSelectedModifier:SelectedModifiers = {
    additionalCost: option.additionalCost,
    modifierId: modifier.id,
    modifierName: modifier.nameExternal,
    optionNames: [option.name],
    options: [
      { 
        name: option.name,
        plu: option.plu ? option.plu : '',
        id: option.id,
        additionalCost: option.additionalCost 
      }
    ]
  }


  //handle radio (ie modifier has max options of one)
  if(modifier.max === 1){
    console.log('handle radio')
    if(modifier.min < 1){
      console.log('handle NO radio selection is required')
      for(let i=0; i<itemInCart.selectedModifiers.length; i++){
        const selectedModifier:SelectedModifiers = itemInCart.selectedModifiers[i]
        for(let j=0; j<selectedModifier.options.length; j++){
          if(selectedModifier.options[j].id === option.id){
            console.log('remove radio since already selected')  
            selectedModifiers.splice(i, 1)
            return selectedModifiers
          }
        }
        if(selectedModifier.modifierId === modifier.id){
          console.log('replace radio already selected with new one')
          selectedModifiers.splice(i, 1, formattedSelectedModifier)
          return selectedModifiers
        }
      }  
      console.log('add radio since none selected')
      selectedModifiers.push(formattedSelectedModifier)
      return selectedModifiers
    }else{
      console.log('handle radio selection is required')
      for(let i=0; i<itemInCart.selectedModifiers.length; i++){
        const selectedModifier:SelectedModifiers = itemInCart.selectedModifiers[i]
        if(selectedModifier.modifierId === modifier.id){
          console.log('replace existing radio already selected')
          selectedModifiers.splice(i, 1, formattedSelectedModifier)
          return selectedModifiers
        }
      }        
      console.log('add required radio since none selected')
      selectedModifiers.push(formattedSelectedModifier)
      return selectedModifiers
    }
  }



  //handle checkbox scenarios (ie modifier has max options of greater than one)
  if(modifier.max > 1){
    console.log('handle checkbox')

    if(modifier.min > 0){
      var counter = 0
      for(let i=0; i<itemInCart.selectedModifiers.length; i++){
        const selectedModifier:SelectedModifiers = itemInCart.selectedModifiers[i]
        if(selectedModifier.modifierId === modifier.id){
          counter = counter + 1
        }
      }
      if(toggleOff && counter === modifier.min){
        console.log('dont remove checkbox since currently at minimum', counter)
        return selectedModifiers
      }
    }

    for(let i=0; i<itemInCart.selectedModifiers.length; i++){
      const selectedModifier:SelectedModifiers = itemInCart.selectedModifiers[i]
      for(let j=0; j<selectedModifier.options.length; j++){
        if(selectedModifier.options[j].id === option.id){
          console.log('remove checkbox since already selected')  
          selectedModifiers.splice(i, 1)
          return selectedModifiers
        }
      }
    }


    let checkboxCount = 0

    for(let i=0; i<itemInCart.selectedModifiers.length; i++){
      const selectedModifier:SelectedModifiers = itemInCart.selectedModifiers[i]
      if(selectedModifier.modifierId === modifier.id){
        checkboxCount = checkboxCount + 1
        console.log('user has already selected this checkbox', selectedModifier)
        if(checkboxCount === modifier.max){
          console.log('replace checkbox since hit max', modifier.max)
          selectedModifiers.splice(i, 1, formattedSelectedModifier)
          return selectedModifiers
        }
      }
      // if(selectedModifier.modifierId === modifier.id && i === modifier.max && toggleOff){
      //   console.log('replace checkbox since hit max, toggle off', i, modifier.max, toggleOff)
      //   selectedModifiers.splice(i, 1, formattedSelectedModifier)
      //   return selectedModifiers
      // }
    
      // if(selectedModifier.modifierId === modifier.id && (i+1) === modifier.max && !toggleOff){
      //   console.log('replace checkbox since hit max,', i, modifier.max, toggleOff)
      //   selectedModifiers.splice(i, 1, formattedSelectedModifier)
      //   return selectedModifiers
      // }
    }


    console.log('add checkbox since max not selected yet, and not removing checkbox')
    selectedModifiers.push(formattedSelectedModifier)
    return selectedModifiers
  }
  else{
    console.log('modifier has a max of 0 - either biz forgot to set max or allows as many selections as possible')
    for(let i=0; i<itemInCart.selectedModifiers.length; i++){
      const selectedModifier:SelectedModifiers = itemInCart.selectedModifiers[i]
      for(let j=0; j<selectedModifier.options.length; j++){
        if(selectedModifier.options[j].id === option.id){
          console.log('remove option since already selected')  
          selectedModifiers.splice(i, 1)
          return selectedModifiers
        }
      }
    }
    selectedModifiers.push(formattedSelectedModifier)
    return selectedModifiers
  }
}

export const validateRequiredModifiers = (itemInCart:ItemsInCart, product:TangoProduct, modifiers:TangoModifier[]) =>{
  const itemModIds = itemInCart.selectedModifiers.map((selectedModifer:SelectedModifiers)=>{
    return selectedModifer.modifierId
  })

  for(let i=0; i<modifiers.length; i++){
    const modifier:TangoModifier = modifiers[i]
    if(product.modifiers.includes(modifier.id)){
      if(modifier.min > 0 && !itemModIds.includes(modifier.id)){
        alert('Please make a selection for ' + modifier.nameExternal)
        return false
      }
    }
  }
  return true
}

export const getBusinessHours = (hours: any) => {
  if(!hours){
    return ""
  }
  const currentDay = moment().format("dddd");
  const selectedDay = hours.filter((item: any) => item.day === currentDay.toLowerCase());
  // console.log(selectedDay)
  if (selectedDay.length && selectedDay[0].open) {
    return currentDay + " Hours:" + " " + moment(selectedDay[0].openTime, "hh:mm").format("hh:mm a") + " - " + moment(selectedDay[0].closeTime, "hh:mm").format("hh:mm a");
  } else {
    return currentDay + " Hours:" + " closed";
  }
};