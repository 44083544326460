import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { connect } from "react-redux";
import { compose } from "redux";
import "./App.css";
import { BrowserRouter, Switch } from "react-router-dom";
import For from "./components/For";
import firebase from "./config/firebase";
import map from "lodash/map";
import { routeConfig } from "./routeConfig";
import ProtectedRoute from "./components/ProtectedRoute";
import { findBusinessDetailsByDomainName } from "./db/GetData";
import { globalContainerCreators } from "./reducers/global/reducer";
import { Business } from "./types/types";

var orderType = "carryOut";
var orderId = "test123";
if (window.location.href.includes("/bar")) {
  orderType = "bar";
}
if (window.location.href.includes("/curbside")) {
  orderType = "curbside";
}
if (window.location.href.includes("/order/")) {
  orderId = window.location.href.split("order/")[1];
}

interface AppPropsType {
  updateBusinessDetails: (business: Business) => {};
}

const ErrorPage = () => (
  <div style={{ textAlign: "center", margin: "6em", color: "black" }}>
    <h2>Error 😔</h2>
    <p>
      We're sorry, something went sideways here. Be sure we are working on it!
    </p>
  </div>
)

function App({ updateBusinessDetails }: AppPropsType) {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>();
  useEffect(() => {
    findBusinessDetailsByDomainName().then((businessDetails) => {
      updateBusinessDetails(businessDetails as Business);
    });
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(!user.isAnonymous);
      } else {
        firebase.auth().signInAnonymously();
        setIsLoggedIn(false);
      }
    });
    return () => unsubscribe();
  }, []);
  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>

      <div className="App">
        {isLoggedIn !== undefined && (
          <BrowserRouter>
            <For
              noParent={false}
              ParentComponent={(props) => <Switch {...props} />}
              of={map(Object.keys(routeConfig))}
              renderItem={(routeKey: string, index: number) => {
                // @ts-ignore
                let Component = routeConfig[routeKey].component;
                // @ts-ignore
                if (window.innerWidth < 960 && routeConfig[routeKey].mobileComponent) {
                  // @ts-ignore
                  Component = routeConfig[routeKey].mobileComponent;
                }

                return (
                  <ProtectedRoute
                    isUserVerified={true}
                    isLoggedIn={isLoggedIn}
                    handleLogout={() => { }}
                    // @ts-ignore
                    exact={routeConfig[routeKey].exact}
                    key={index}
                    // @ts-ignore
                    path={routeConfig[routeKey].route}
                    render={(props: any) => {
                      const updatedProps = {
                        ...props,
                        // @ts-ignore
                        ...routeConfig[routeKey].props,
                        orderId,
                        orderType,
                      };
                      return <Component {...updatedProps} />;
                    }}
                  />
                );
              }}
            />
          </BrowserRouter>
        )}
      </div>
    </Sentry.ErrorBoundary>

  );
}

const mapDispatchToProps = (dispatch: any) => ({
  updateBusinessDetails: (businessDetails: Business) => dispatch(globalContainerCreators.updateBusinessDetails(businessDetails)),
});

const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(App);
