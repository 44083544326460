import React from 'react'

interface  Props{
    phoneNumber: string;
    onChange: (e: string) => void;
    className?: string;
    [x:string]:any;
}
const formatPhoneNumber = (number: string) => {
    const areaCode = number.substring(0, 3);
    const middle = number.substring(3, 6);
    const last = number.substring(6, 10);
    if (number.length > 6) {
        return `(${areaCode}) ${middle}-${last}`;
    } else if (number.length > 3) {
        return `(${areaCode}) ${middle}`;
    } else if (number.length > 0) {
        return `(${areaCode}`;
    }
    return `${number}`;
};
const PhoneInput = ({phoneNumber, onChange, className, ...rest}:Props) => {
    return (
        <input
            {...rest}
            className={ className ? className: `form-control`}
            onChange={(e)=>{
             const number = (e.target.value).replace('(', '').replace(')', '').replace('-', '').replace(' ','');

                // console.log(number);
                if(number.length <= 10){
                    onChange(number);
                }
            }}
            value={formatPhoneNumber(phoneNumber)}
        />
    )
};

export default  PhoneInput;