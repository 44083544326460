import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import './index.css';
import {Link, useHistory} from 'react-router-dom';
import { Button, Icon } from '@blueprintjs/core';
import ReactCodeInput from 'react-code-input';
import { updateUserInDatabase } from '../db/GetData';
import {createStructuredSelector} from "reselect";
import {selectBusinessDetails} from "../reducers/global/selectors";
import {connect} from "react-redux";
import {compose} from "redux";
import MobileHeader from '../GiftCards/MobileHeader';
import PhoneInput from "../components/PhoneInput";


export interface User{
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
}
const SignUp = (props:any) => {
  const [step, setStep] = useState<'welcome' | 'login' | 'signup' | 'otp-confirmation'>('signup');
  const [user, setUser] = useState<User>({firstName:'', lastName:'', phoneNumber:'', email:''});
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [business, setBusiness] = useState();

  useEffect(()=> {
    setBusiness(props.businessDetails);
}, [props.businessDetails])

  const history = useHistory();
    const updateUser = (e:ChangeEvent<HTMLInputElement>) => {
        const clonedUser = {...user}
        //@ts-ignore
        clonedUser[e.target.id] = e.target.value;
        setUser(clonedUser);
    }
    const addUserToDatabase = useCallback(async()=>{
        const clonedUser = {...user}
        let userNumber = clonedUser.phoneNumber;

        clonedUser.phoneNumber = userNumber.substring(0, 0) + '+1' + userNumber.substring(0)

        const response = await updateUserInDatabase(clonedUser);
        if(response.data){
            history.replace('/login');
        }else{
            alert(response.error)
        }
    }, [user])

    useEffect(()=> {
        if(user.email.length > 0 && user.firstName.length > 0 && user.lastName.length > 0 && user.phoneNumber.length > 0 && user.phoneNumber.length === 10){
            setIsButtonDisabled(false);
        }else{
            setIsButtonDisabled(true)
        }
    })
    if(window.innerWidth <= 960){
        return (
            <div className='gift-cards-mobile'>
                <MobileHeader />
              <div className='auth-form-container'>
                  <div className='form-title'>It looks like you are new!</div> 
                  <div className="textOnInput">
                      <label htmlFor="inputText">First Name</label>
                      <input className="form-control" id="firstName"  type="text" value={user.firstName}  onChange={updateUser}  />
                  </div>
                  <div className="textOnInput">
                      <label htmlFor="inputText">Last Name</label>
                      <input className="form-control" id="lastName"  type="text" value={user.lastName}  onChange={updateUser}  />
                  </div>
                  <div className="textOnInput">
                      <label htmlFor="inputText">Phone Number</label>
                      <PhoneInput
                          className="form-control" id="phoneNumber"
                          phoneNumber={user.phoneNumber}
                          onChange={(number)=>{
                              const clonedUser =  {...user}
                              clonedUser.phoneNumber = number
                              setUser(clonedUser);
                          }}
                          style={{width:'100%'}}
                      />
                  </div>
                  <div className="textOnInput">
                      <label htmlFor="inputText">Email</label>
                      <input className="form-control" id="email"   type="text" value={user.email}  onChange={updateUser}  />
                  </div>
                  <div className='terms-condition'>
                    <div className="round">
                        <input type="checkbox" id="checkbox" />
                            <label htmlFor="checkbox">
                        </label>
                    </div>
                    <span style={{marginLeft:'25px', verticalAlign:'sub'}}>I agree to The Coop’s terms of use. <a href='google.com'>Details</a></span>
                  </div>
                  <div className='contact-support'>
                      Need help? <span style={{fontFamily:'Lato-Bold'}}>Contact support.</span>
                  </div>
                <div style={{display:'flex', justifyContent:'flex-end'}}>
                    <Button text="Continue" disabled={isButtonDisabled}   className="login-button" id="login-button" onClick={addUserToDatabase} />
                </div>
              </div>
              <div className='get-free-card-text'>
                  {
                        //@ts-ignore
                       business && <img className="logo" src={business.profileImageUrl} />
                  }
                    <div>
                    Get a free digital card when you join - or log in to view your cards.
                    </div>
              </div>
            </div>
        )
    }
    return (
        <div className="gift-cards-page welcome-screen">
            <div className="header">
                <div className="left-part">
                    <img className="logo" src={"https://firebasestorage.googleapis.com/v0/b/tango-2.appspot.com/o/businesses%2F5f57add1f3555707185dafae%2FprofileImage.jpeg?alt=media&token=3a348ab5-9669-4e66-b47c-ab21d6cce337"} />
                    <div className="menu-item">
                        Order
                    </div>
                    <div className="menu-item">
                        Reservations
                    </div>
                    <div className="menu-item">
                        Cards
                    </div>
                    <div className="menu-item">
                        Gift
                    </div>
                </div>
                <div className="right-part">
                    <div className="menu-item">
                        Locations
                    </div>
                    <Link to="/login">
                        <div className="menu-item active">
                            Sign In
                        </div>
                    </Link>
                    
                    {/* <div className="menu-item active">
                        Account
                    </div> */}
                </div>
            </div>
            <div className="body">
                <div className="left-part">
                    <div className="side-nav">
                        <div className="nav-title">Welcome to The Coop</div>
                        <div className={`side-nav-item`} onClick={()=> history.push('/login')}>Log In</div>
                        <div className={`side-nav-item active`}>Join now</div>
                    </div>
                </div>
                <div className="right-part">
                      {
                       step === 'signup' &&  (
                            <div className="login-form signup-form">
                                <div className="select-card-header">
                                </div>
                                <div className="buy-card-form">
                                    <div className="form-container">
                                    <div className="form-title text-center">Create an account</div>                                    
                                        <div className="input-container">
                                            <div>First Name</div>
                                            <input id="firstName" value={user.firstName} onChange={updateUser}/>
                                        </div>
                                        <div className="input-container">
                                            <div>Last Name</div>
                                            <input id="lastName" value={user.lastName} onChange={updateUser}/>
                                        </div>
                                        <div className="input-container">
                                            <div>Phone Number</div>
                                            <PhoneInput
                                                className="form-control" id="phoneNumber"
                                                phoneNumber={user.phoneNumber}
                                                onChange={(number)=>{
                                                    const clonedUser =  {...user}
                                                    clonedUser.phoneNumber = number
                                                    setUser(clonedUser);
                                                }}
                                                style={{width:'100%'}}
                                            />
                                        </div>
                                        <div className="input-container">
                                            <div>Email Address</div>
                                            <input id="email" value={user.email} onChange={updateUser} />
                                        </div>
                                        <div className='terms-condition'>
                                            <input type="checkbox" /> <span>I agree to the <a href="google.com"> Gift Card Terms of Service</a></span>
                                        </div>
                                        <Button disabled={isButtonDisabled} text="Create Account" className="create-account-button" onClick={addUserToDatabase}/>
                                    </div>
                                    <div className="text-center signup-link" onClick={()=> history.push('/login')}>Already have an account? <span style={{fontFamily:"Lato-ExtraBold", color:"#231F20"}}>Log In</span></div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = createStructuredSelector({
    businessDetails: selectBusinessDetails()
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(SignUp);
