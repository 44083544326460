import routeConstants from './utils/routeConstants';
import GiftCards from './GiftCards/Gift';
import Welcome from './Auth/Welcome';
import Cards from './GiftCards/Cards';
import Reservations from './reservations/index';
import OrderConfirmation from './orderConfirmation/OrderConfirmation';
import DineInLanding from './dineIn/DineInLanding';
import DineInOrder from './dineIn/components/Index';
import OtpConfirm from './Auth/OtpConfirm';
import Card from './GiftCards/Card';
import SendMoney from './GiftCards/Sendmoney';
import SendMoneyForm from './GiftCards/SendMoneyForm';
import SendMoneySuccess from './GiftCards/SendMoneySuccess';
import Login from './Auth/Login';
import SignUp from './Auth/SignUp';
import AddMoney from './GiftCards/AddMoney';
import AddMoneyForm from './GiftCards/AddMoneyForm';
import Landing from './desktop/Landing';
import MobileLanding from './mobile/Landing';
import Jobs from './desktop/Jobs';
import Job from './desktop/Job';
import AddCard from './GiftCards/AddCard';

export const routeConfig = {

  // giftCards: {
  //   component: GiftCards,
  //   ...routeConstants.giftCards,
  // },
  welcome: {
    component: Landing,
    mobileComponent: MobileLanding,
    ...routeConstants.welcome,
  },
  cards: {
    component: Cards,
    ...routeConstants.cards,
  },
  locationsId: {
    component: Landing,
    mobileComponent: MobileLanding,
    ...routeConstants.locationsId,
  },
  ordersOrderId: {
    component: OrderConfirmation,
    ...routeConstants.ordersOrderId,
  },
  tableQrId: {
    component: DineInLanding,
    ...routeConstants.tableQrId,
  },
  tableOrderId: {
    component: DineInOrder,
    ...routeConstants.tableOrderId,
  },
  // giftCardCreate: {
  //   component: Cards,
  //   ...routeConstants.giftCards,
  // },
  otpConfirm: {
    component: OtpConfirm,
    ...routeConstants.confirmOtp,
  },
  cardById: {
    component: Card,
    ...routeConstants.cardById,
  },
  reservations: {
    component: Reservations,
    ...routeConstants.reservations,
  },
  sendMoneyById: {
    component: SendMoney,
    ...routeConstants.sendMoneyById,
  },
  sendMoneyByCardIdAndRecipientCardId: {
    component: SendMoneyForm,
    ...routeConstants.sendMoneyByCardIdAndRecipientCardId,
  },
  checkoutSuccess: {
    component: SendMoneySuccess,
    ...routeConstants.checkoutSuccess,
  },
  login: {
    component: Login,
    ...routeConstants.login,
  },
  signUp: {
    component: SignUp,
    ...routeConstants.signUp,
  },
  addMoney: {
    component: AddMoney,
    ...routeConstants.addMoney,
  },
  addMoneyForm: {
    component: AddMoneyForm,
    ...routeConstants.addMoneyForm,
  },
  locationJobs: {
    component: Jobs,
    ...routeConstants.locationJobs,
  },
  jobs: {
    component: Jobs,
    ...routeConstants.jobs,
  },
  job: {
    component: Job,
    ...routeConstants.job,
  },
  addCard: {
    component: AddCard,
    ...routeConstants.addCard,
  },
};
