/**
 *
 * ProtectedRoute
 *
 */

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import routeConstants from '../../utils/routeConstants';

const ProtectedRoute = ({ isUserVerified, render: C, isLoggedIn, handleLogout, ...rest }) => {
  const isUnprotectedRoute =
    Object.keys(routeConstants)
      .filter((key) => !routeConstants[key].isProtected)
      .map((key) => routeConstants[key].route)
      .includes(rest.path);
  if (rest.path === '/jobs') {
    console.log({isUnprotectedRoute});
  }

  const isLogin =
    Object.keys(routeConstants)
      .filter((key) => routeConstants[key].isLogin)
      .map((key) => routeConstants[key].route)
      .includes(rest.path) && rest.exact;

  // console.log({isUnprotectedRoute, path: rest.path})
  const handleRedirection = (renderProps) => {
    let to;
    if (!isLoggedIn) {
      console.log(isLoggedIn, { isUnprotectedRoute, path: rest.path, route: routeConstants });
      // user is not logged in
      if (!isUnprotectedRoute) {
        to = routeConstants.login.route;
        handleLogout();
      } else {
        // not logged in and trying to access an unprotected route so don't redirect
        return <C {...renderProps} />;
      }
    } else {
      console.log({ isLogin });
      if (isLogin) {
        to = routeConstants.cards.route;
      } else {
        return <C {...renderProps} />;
      }
      // console.log({isUnprotectedRoute, path: rest.path})
      // return <C {...renderProps} />;
      // user is logged in
      // if (isUnprotectedRoute) {
      //   to = routeConstants.cards.route;
      // } else {
      //   // logged in and accessing a protected route
      //   return <C {...renderProps} />;
      // }
    }
    return <Redirect to={to} />;
  };
  return <Route {...rest} render={handleRedirection} />;
};
export default ProtectedRoute;
