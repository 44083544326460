import { Button, Classes } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { Business, ItemsInCart, MenuCategory, ModifierOption, Order, TangoBusinessSettings, TangoMenu, TangoModifier, TangoProduct } from "../../types/types";
import ViewProduct from "./ViewProduct";

interface ViewMenuCategoryProps {
    business: Business;
    businessSettings:TangoBusinessSettings;
    itemsInCart: ItemsInCart[];
    modifiers: TangoModifier[];
    modifierOptions: ModifierOption[];
    popularList: any;
    products: TangoProduct[];
    selectedMenu: TangoMenu;
    selectedMenuCategory: MenuCategory;
    setOpenViewMenuCategory: (shouldShow: boolean) => void;
    addItemToCart: ([]) => void;
}

const ViewMenuCategory = ({business, businessSettings, itemsInCart, modifiers, modifierOptions, popularList, products,selectedMenu, selectedMenuCategory, setOpenViewMenuCategory, addItemToCart}: ViewMenuCategoryProps) => {

    const [selectedProduct, setSelectedProduct] = useState<TangoProduct>({
        alcohol: false,
        businessId: businessSettings.businessId,
        discountedPrice: null,
        imageUrl: '',
        price: 0,
        taxRate: 0,
        type: '',
        plu: '',
        description: '', 
        enabled: true,
        id: '',
        nameExternal: '', 
        modifiers: [],
    });

    const [viewProduct, setOpenViewProduct] = useState(false);
    const [productAddedToCart, setProductAddedToCart] = useState(false);

    useEffect(() => {
        if(productAddedToCart){
            setOpenViewMenuCategory(false)
        }
    },[productAddedToCart])


    if(viewProduct){
        return (
            <ViewProduct
                business={business}
                businessSettings={businessSettings}
                itemsInCart={itemsInCart}
                menu={selectedMenu}
                menuCategory={selectedMenuCategory}
                modifiers={modifiers}
                modifierOptions={modifierOptions}
                selectedProduct={selectedProduct}
                setOpenViewProduct={setOpenViewProduct}
                setProductAddedToCart={setProductAddedToCart}
                addItemToCart={addItemToCart}
            />
        )
    }

    return (
        <div style={{ marginBottom: "6em", marginTop: "25%" }}>
            <div style={{ backgroundColor: "#f5f5f5", width: "100%", padding: ".5em", position: "fixed", top: "0px" }}>
                <Button 
                    onClick={() => {
                        console.log('stop showing menu category')
                        setOpenViewMenuCategory(false)
                    }} 
                    icon="arrow-left" className={`${Classes.MINIMAL} order-from-button`} 
                    style={{ position: "absolute", left: "10px", top: "11px" }}>
                </Button>
                <h3 style={{ textAlign: "center", fontFamily: "Axiforma-ExtraBold", fontSize: "17px" }}>{selectedMenuCategory.name}</h3>
            </div>

            
            {popularList && popularList.length ? (
                <div style={{ paddingLeft: "1em" }}>
                    <h4 style={{ marginBottom: ".5em", marginTop: "3em", fontSize: "14px", fontFamily: "Axiforma-ExtraBold" }}>Popular in {selectedMenuCategory.name}</h4>
                </div>
            ) : null}


            <div style={{ display: "flex", overflow: "scroll", marginLeft: "17px", marginRight: "17px", marginBottom: "20px" }}>
            {popularList &&
                popularList.map((product: any) => {
                return (
                    <div
                    className="popular-item-container"
                    onClick={() => {
                        if (product.enabled) {
                            setSelectedProduct(product);
                            setOpenViewProduct(true)
                        }
                    }}
                    key={product.id}
                    style={{ cursor: "pointer", marginRight: "11px" }}
                    >
                    {product.imageUrl ? <img src={product.imageUrl} alt='' style={{ objectFit: "cover", width: "135px", height: "127px", marginBottom: "5px" }} /> : <div style={{ width: "135px", height: "127px", backgroundColor: "#F2F2F2", marginBottom: "12px" }}></div>}
                    <div style={{ fontSize: "12px", fontFamily: "Axiforma-ExtraBold", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{product.nameExternal}</div>
                    <div style={{ fontSize: "10px", fontFamily: "Axiforma-Regular" }}>${(product.price * 0.01).toFixed(2)}</div>
                    </div>
                );
                })}
            </div>

            {popularList && <hr className="hrLine" />}

            {selectedMenuCategory.products.map((productId: string) => {
                return products.map((prod: TangoProduct) => {
                    if (prod.id === productId) {
                        return (
                            <div
                                onClick={() => {
                                    if (prod.enabled) {
                                        console.log('clicked product')
                                        setSelectedProduct(prod);
                                        setOpenViewProduct(true)
                                    }
                                }}
                                key={prod.id}
                                className="productCard"
                                style={{ backgroundColor: !prod.enabled ? "rgba(0,0,0,0.2)" : "transparent" }}
                            >
                                <div style={{ display: "flex", justifyContent: "space-between", padding: "20px 0px" }}>
                                    <div>
                                    <div id="productName">{prod.nameExternal}</div>

                                    {prod.discountedPrice ? (
                                        <span style={{ fontSize: "12px", color: "#DB3737" }}>
                                        <s>${(prod.price * 0.01).toFixed(2)}</s>
                                        </span>
                                    ) : (
                                        <span style={{ fontSize: "12px" }}>${(prod.price * 0.01).toFixed(2)}</span>
                                    )}

                                    {prod.discountedPrice && (
                                        <span style={{ fontSize: "12px" }}>
                                        {" "}
                                        <br />${(prod.discountedPrice * 0.01).toFixed(2)}
                                        </span>
                                    )}
                                    </div>
                                    <Button icon="arrow-right" className={`${Classes.MINIMAL} order-from-button`}>
                                    {" "}
                                    </Button>
                                </div>
                                <hr className="hrLine" />
                            </div>
                        );
                    }
                });
            })}
        </div>
    )
}

export default ViewMenuCategory