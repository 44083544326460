import React, { useState, useEffect } from 'react';
import { Icon, Button, InputGroup, Intent } from '@blueprintjs/core';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import GoogleMapReact from 'google-map-react';
import Marker from '../mobile/locations/Marker';
import ViewLocation from './ViewLocation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Business } from '../types/types';
import { globalContainerCreators } from '../reducers/global/reducer';
import { connect } from 'react-redux';
import { compose } from 'redux';

const googleMapKey = process.env.REACT_APP_GOOGLE_API_KEY
  ? process.env.REACT_APP_GOOGLE_API_KEY
  : 'test';

const SelectLocation = (props: any) => {
  const orderType = props.orderType;
  const [address, setAddress] = useState('');
  const [business, setBusiness] = useState<Business>();
  const [defaultMapCoords, setDefaultMapCoords] = useState({
    lat: props.businesses[0].location.lat,
    lng: props.businesses[0].location.lon
  });
  const [businesses, setBusinesses] = useState(props.businesses);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      const filteredLocations = processLocations(businesses, {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });
      setBusinesses(filteredLocations);
      setDefaultMapCoords({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });
    });
  }, []);
  const myLocationButton = (
    <Button
      style={{ marginTop: '1em' }}
      minimal
      icon='geolocation'
      intent={Intent.PRIMARY}
      onClick={() => moveToCurrentLocation()}
    />
  );

  const moveToCurrentLocation = () => {
    //not working in chrome??
    var options = {
      maximumAge: Infinity,
      timeout: 1000,
      enableHighAccuracy: false
    };

    function success(pos: any) {
      var crd = pos.coords;
      setDefaultMapCoords({ lat: crd.latitude, lng: crd.longitude });
    }

    function error(err: any) {
      return err;
    }
    navigator.geolocation.getCurrentPosition(success, error, options);
  };

  const handleClickMarker = (locationClicked: any) => {
    //redirect to new app for coop waterdown
    if (locationClicked.id === 'Jv75Vs8UgXDmUPVuY1m4') {
      return (window.location.href =
        'https://order.tangohq.com/order/d3572aa2-de43-4db0-b30b-de1ef46f0938/takeout');
    }
    if (locationClicked.id === 'vkVT6JpeOPrg3hvaNgnv') {
      return (window.location.href =
        'https://order.tangohq.com/order/46df0558-22f6-4de2-84de-b204c696bd15/takeout');
    }
    if (locationClicked.id === 'MbfwBPUC2SRGvDP0SfNl') {
      return (window.location.href =
        'https://order.tangohq.com/order/bd5ab00e-394e-4c16-bd06-64dee089a024/takeout');
    }
    if (locationClicked.id === 'fGREGF5Of7RgncnHqGpf') {
      return (window.location.href =
        'https://order.tangohq.com/order/018c5583-0c43-492d-92cf-3301083467ea/takeout');
    }

    props.updateBusinessDetails(locationClicked);
    setBusiness(locationClicked);
  };

  const handleChangeAddress = (address: string) => {
    setAddress(address);
  };
  const getDistance = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
    unit = 'K'
  ) => {
    if (lat1 === lat2 && lon1 === lon2) {
      return 0;
    } else {
      var radlat1 = (Math.PI * lat1) / 180;
      var radlat2 = (Math.PI * lat2) / 180;
      var theta = lon1 - lon2;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === 'K') {
        dist = dist * 1.609344;
      }
      if (unit === 'N') {
        dist = dist * 0.8684;
      }
      return dist.toFixed(2);
    }
  };
  const processLocations = (locations: any, userCenter: any) => {
    const newLocations = locations.map((location: any) => {
      const distance: any = getDistance(
        userCenter.lat,
        userCenter.lng,
        location.location.lat,
        location.location.lon
      );
      return { ...location, distance };
    });

    const takeoutLocs = newLocations
      .filter((loc: any) => loc.takeoutLive)
      .sort(function (a: any, b: any) {
        return parseFloat(a.distance) - parseFloat(b.distance);
      });

    const noTakeoutLocs = newLocations
      .filter((loc: any) => !loc.takeoutLive)
      .sort(function (a: any, b: any) {
        return parseFloat(a.distance) - parseFloat(b.distance);
      });
    const availableLocation: any = takeoutLocs.concat(noTakeoutLocs);
    return availableLocation;
  };

  const handleSelectAddress = async (address: string) => {
    const results = await geocodeByAddress(address);
    const coords = await getLatLng(results[0]);
    setAddress(results[0].formatted_address);
    let location = {
      address: results[0].formatted_address,
      lat: coords.lat,
      lon: coords.lng,
      googlePlacesId: results[0].place_id
    };
    setDefaultMapCoords({ lat: location.lat, lng: location.lon });
    // setSearchLocation(location)
    const filteredLocations = processLocations(businesses, {
      lat: location.lat,
      lng: location.lon
    });
    setBusinesses(filteredLocations);
    setAddress(address);
  };

  const showToast = (message: string) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      toastId: 'error-toast'
    });
  };

  return (
    <div>
      {!business && (
        <div style={{ display: 'flex' }}>
          <div style={{ width: '40%', padding: '20px' }}>
            <PlacesAutocomplete
              value={address}
              onChange={handleChangeAddress}
              onSelect={handleSelectAddress}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <div style={{ margin: 'auto', paddingBottom: '.5em' }}>
                  <InputGroup
                    {...getInputProps({ className: 'location-search-input' })}
                    id='address'
                    leftIcon={
                      <Icon
                        icon='search'
                        style={{ paddingLeft: '10px', paddingTop: '10px' }}
                        color='black'
                      ></Icon>
                    }
                    placeholder='City, Province or Postal Code'
                    type='text'
                    style={{ height: '3.75em' }}
                  />
                  <div className='autocomplete-dropdown-container'>
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion, index) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      const style = suggestion.active
                        ? {
                            backgroundColor: '#fafafa',
                            cursor: 'pointer',
                            color: 'black',
                            fontSize: '1.1em',
                            padding: '.5em'
                          }
                        : {
                            backgroundColor: '#F5F8FA',
                            cursor: 'pointer',
                            color: 'black',
                            fontSize: '1.1em',
                            padding: '.5em'
                          };
                      return (
                        <div
                          // @ts-ignore
                          key={index}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style
                          })}
                        >
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            <h3 style={{ fontFamily: 'Axiforma-Regular', fontSize: '18px' }}>
              {businesses.length} nearest locations
            </h3>

            <div style={{ height: '80vh', overflowY: 'auto' }}>
              {businesses.map((business: Business) => {
                return (
                  <div
                    onClick={() => {
                      //redirect to new app for coop waterdown
                      if (business.id === 'Jv75Vs8UgXDmUPVuY1m4') {
                        return (window.location.href =
                          'https://order.tangohq.com/order/d3572aa2-de43-4db0-b30b-de1ef46f0938/takeout');
                      }
                      if (business.id === 'vkVT6JpeOPrg3hvaNgnv') {
                        return (window.location.href =
                          'https://order.tangohq.com/order/46df0558-22f6-4de2-84de-b204c696bd15/takeout');
                      }
                      if (business.id === 'MbfwBPUC2SRGvDP0SfNl') {
                        return (window.location.href =
                          'https://order.tangohq.com/order/bd5ab00e-394e-4c16-bd06-64dee089a024/takeout');
                      }
                      if (business.id === 'fGREGF5Of7RgncnHqGpf') {
                        return (window.location.href =
                          'https://order.tangohq.com/order/018c5583-0c43-492d-92cf-3301083467ea/takeout');
                      }
                      if (business.onlineOrderingEnabled) {
                        props.updateBusinessDetails(business);
                        setBusiness(business);
                      }
                    }}
                    key={business.id}
                    style={{
                      cursor: 'pointer',
                      textAlign: 'left',
                      paddingTop: '10px'
                    }}
                  >
                    <div
                      style={{
                        fontSize: '14px',
                        fontFamily: 'Axiforma-ExtraBold'
                      }}
                    >
                      {business.businessName}
                    </div>
                    <p
                      style={{
                        fontFamily: 'Axiforma-Regular',
                        fontSize: '11px',
                        marginTop: '5px'
                      }}
                    >
                      {business.location.address}
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <p
                        style={{
                          color: '#FA4D4F',
                          fontSize: '11px',
                          fontFamily: 'Axiforma-Regular',
                          marginBottom: '0px'
                        }}
                      >
                        Phone: {business.phones[0].number}
                      </p>
                      <Button
                        minimal
                        rightIcon='arrow-right'
                        style={{ fontSize: '11px' }}
                        className='order-from-button'
                      >
                        {business.onlineOrderingEnabled ? (
                          business.currentlyClosed ? (
                            <span>Closed</span>
                          ) : (
                            <span>Order from here</span>
                          )
                        ) : (
                          <span>Online ordering is unavailable</span>
                        )}{' '}
                      </Button>
                    </div>

                    <hr className='hrLine' />
                  </div>
                );
              })}
            </div>
          </div>

          <div style={{ height: '100vh', width: '180vh' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: googleMapKey }}
              center={defaultMapCoords}
              defaultZoom={10}
              yesIWantToUseGoogleMapApiInternals
              options={{ fullscreenControl: false, zoomControl: false }}
            >
              {businesses &&
                businesses.length &&
                businesses.map((location: any, index: number) => {
                  return (
                    <Marker
                      name={'Dolores park'}
                      title={'The marker`s title will appear as a tooltip.'}
                      onChange={handleClickMarker}
                      location={location}
                      key={index}
                      lat={location.location.lat}
                      lng={location.location.lon}
                    />
                  );
                })}
            </GoogleMapReact>
          </div>
        </div>
      )}

      {business && businesses && (
        <>
          <ViewLocation
            allLocations={businesses}
            business={business}
            orderType={orderType}
            showAllLocation={() => setBusiness(businesses[0])}
            showToast={(message: string) => {
              showToast(message);
            }}
          />
          <ToastContainer />
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  updateBusinessDetails: (businessDetails: Business) =>
    dispatch(globalContainerCreators.updateBusinessDetails(businessDetails))
});

const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(SelectLocation);
