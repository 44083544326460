import React, { useEffect, useState } from 'react';
import './index.css';
import firebase from '../config/firebase'
import {Button, Icon} from "@blueprintjs/core";
import {Link, useHistory, useLocation, useParams} from 'react-router-dom';
import { fetchTransactionData, fetchUsersCard, GiftCardTransaction, GiftCardUser, TransactionType } from '../db/Cards';
import Header from './Header';
import MobileHeader from './MobileHeader';


const SendMoneySuccess = (props:any) => {
    const history = useHistory();
    const location = useLocation();
    const {transactionId} = useParams<{transactionId: string}>();
    const [transactionData, setTransactionData] = useState<GiftCardTransaction|null>(null);
    useEffect(()=>{
        const fetchData = async()=> {
           const response =  await fetchTransactionData(transactionId);
           if(response.data){
               setTransactionData(response.data);
           }
        }
        fetchData();
    }, [])
    if(window.innerWidth <= 960){
        return (
            <div className='gift-cards-mobile'>
            <MobileHeader/>
            <div className="select-card-header">
                      <div className="checkout-title">Money Added!</div>
                      <div className="checkout-subtitle">The balance on your card has been succesfully increased.</div>
            </div>
            <div className="buy-card-form checkout-success-container">
                <div className="form-container">
                <div className="check-mark-container">
                    <Icon icon="tick" color="#fff" />
                </div>
                <div className="order-complete-text">Order Complete</div>
                <div className="transaction-number">Transaction #1249912</div>
                <div className="new-card-balance-container">
                    <img src="https://firebasestorage.googleapis.com/v0/b/tango-2.appspot.com/o/gift-card-images%2FGift_6.png?alt=media&token=16ec954b-72cc-4786-91ed-09a9e5ff028e"/>
                    <div>
                        <div className="new-card-balance-text">New Card Balance</div>
                        <div className="new-balance-amount">${transactionData?.totalBalance.toFixed(2)}</div>
                    </div>
                </div>
                <div className="new-card-balance-container" style={{flexDirection:'column'}}>
                    <div className="balance-increased-text">Balance Increased</div>
                    <div className="increased-balance">+${transactionData?.amount.toFixed(2)}</div>
                </div>
            </div>
            </div>
       </div>
        )
    }
    return (
        <div className="gift-cards-page">
           <Header path="cards"  />
            <div className="body">
                <div className="left-part">
                    <div className="side-nav">
                        <div className="nav-title">The Coop Cards</div>
                        <div className={`side-nav-item  active`}>Balance</div>
                        <div className={`side-nav-item`}>Add</div>
                    </div>
                </div>
                <div className="right-part">
                  {/* <div className="select-card-header">
                    <img src={require('../assets/images/check-mark.svg').default} className="header-check-mark" />
                    <div className="text-center extra-bold f19 black-font">Card Sent!</div>
                  </div> */}
                  <div className="select-card-header">
                      <div className="text-center extra-bold f19">Money Added!</div>
                      <div className="text-center bold f12">The balance on your card has been succesfully increased.</div>
                  </div>
                  <div className="buy-card-form checkout-success-container">
                      <div className="form-container">
                        <div className="check-mark-container">
                            <Icon icon="tick" color="#fff" />
                        </div>
                        <div className="order-complete-text">Order Complete</div>
                        <div className="transaction-number">Transaction #1249912</div>
                        <div className="new-card-balance-container">
                            <img src="https://firebasestorage.googleapis.com/v0/b/tango-2.appspot.com/o/gift-card-images%2FGift_6.png?alt=media&token=16ec954b-72cc-4786-91ed-09a9e5ff028e"/>
                            <div>
                                <div className="new-card-balance-text">New Card Balance</div>
                                <div className="new-balance-amount">${transactionData?.totalBalance.toFixed(2)}</div>
                            </div>
                        </div>
                        <div className="new-card-balance-container" style={{flexDirection:'column'}}>
                            <div className="balance-increased-text">Balance Increased</div>
                            <div className="increased-balance">+${transactionData?.amount.toFixed(2)}</div>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
    )
}
export  default  SendMoneySuccess;
