import React, { useState } from "react";
import { Icon , Intent} from "@blueprintjs/core";

const Marker = (props:any) => {
  const [business, setBusiness] = useState(null);

  const handleChange = ()=> {
    if (props.onChange) {
      props.onChange(business);
    }
  };

  if(business){
    console.log("business set with click marker", business)
    handleChange()
  }

  return (
    <div>
      <Icon onClick={()=>{setBusiness(props.location)}} icon="map-marker" iconSize={35} intent={Intent.DANGER}/>
    </div>
  )
}

export default Marker
