import axios from "axios";
import firebase from "../config/firebase";
import { v4 as uuidv4 } from "uuid";
import {Business, CustomerModel, GiftCardUsers, ItemsInCart, Order, Tab, TangoTable, User} from '../types/types'
import _ from "lodash";
import {nestJS_BaseURL} from "../utils/consts";
import { calculateSubtotalInOrder, calculateTaxOnOrder, getBusinessTaxRate, getDineInProductsPaid } from "../utils/functions";
import { fetchTab, fetchTableSession } from "./GetData";
const db = firebase.firestore();

//used for dine in orders - when user "places new order" just append products to existing dining order
export const appendProductsToOrder = async (business:Business, existingOrder: Order, tab:Tab, newProducts: ItemsInCart[], firebaseJWT:string) => {

  //if no regular items were in order return true (ie the dine in order only had alcohol items)
  if(newProducts.length === 0){
    return true
  }
   
  const businessTaxRate = getBusinessTaxRate(business)
  const cartSubtotal = calculateSubtotalInOrder(newProducts)
  const cartTax = calculateTaxOnOrder(newProducts, businessTaxRate)
  const cartGrossTotal = cartSubtotal + cartTax


  // if products are paid for in tab append a new product to the order and dont increment quantity 
  const productIdsPaidFor : string[] = []

  //generate product order id's
  newProducts.forEach((product:ItemsInCart)=>{
    product.productOrderId = product.quantity + "-" + product.productId + "-" + existingOrder.id + "-" + uuidv4().replace(/-/g, "");
    product.sentQuantity = 0  //make sure sent quantity is 0 when appending to exisiting order
    //find product ids already paid for
    tab.customer.forEach((customer:CustomerModel) => {
      customer.productsPaid.forEach((productPaid:any)=>{
        if(productPaid.productId === product.productId){
          productIdsPaidFor.push(productPaid.productId)
        }
      })
    });
  })
   

  //just concat new items to existing order - incrementing the product quantities is buggy
  // const newItemsToSend:ItemsInCart[] = existingOrder.products.concat(newProducts)
  // console.log('newItemsToSend -->', newItemsToSend)


  //increment item quanitites if the item already exist in the order (ie a previous user selected the same product), or create a new item if it doesnt exist, return all items
  const itemsToSend = newProducts.reduce((acc: any, item: any) => {
    const existingProductIndex = existingOrder.products.findIndex((existing: any) => {
      return (
        existing.name === item.name &&
        existing.discountedPrice === item.discountedPrice &&
        _.isEqual(
          _.sortBy(_.flattenDeep(existing.selectedModifiers.map((selectedModifier: any) => selectedModifier.optionNames))),
          _.sortBy(_.flattenDeep(item.selectedModifiers.map((modifier: any) => modifier.optionNames)))
        )
      );
    });
  
    if (existingProductIndex !== -1 && !productIdsPaidFor.includes(item.productId)) {
      return acc.map((product: any, index: any) => {
        if (index === existingProductIndex) {
          // console.log('increment quantity', existingProductIndex, item)
          return {
            ...product,
            quantity: product.quantity + item.quantity, //sentQuanity can be 0 from PWA so increment quanity
            productOrderId: product.productOrderId.replace(product.productOrderId.charAt(0), product.quantity + item.quantity),
            note: product.note ? product.note : null,
          }
        }
        return product;
      });
    } else {
      return [...acc, item];
    }
  }, existingOrder.products);



  // console.log( '\nexisting order',existingOrder, '\nnew items', itemsToSend,)
  
  //increment totals of exisitng order
  const orderValuesToUpdate = {
    "amount.grossTotal": existingOrder.amount.grossTotal + cartGrossTotal,
    "amount.subTotal": existingOrder.amount.subTotal + cartSubtotal,
    "amount.tax": existingOrder.amount.tax + cartTax,
    "amount.netTotal": existingOrder.amount.grossTotal + cartGrossTotal, //same as gross total since to tip or discount being applied yet
    // "amount.deliveryFee" : 0, //Dont update since delivery NA for dine in 
    // "amount.serviceChargeTotal": 0, //Dont update since service charge NA for dine in 
    // "amount.tip": 0//Dont update tip since tip is selected and applied when users pay
    products: itemsToSend,
    updatedAt: new Date()
  }

  try {
    const updatedOrder = await axios.patch(`${nestJS_BaseURL}/order/${existingOrder.id}`,orderValuesToUpdate,{
      headers: {'Authorization': `Bearer ${firebaseJWT}`},
    })
    if(updatedOrder.data && updatedOrder.data.id){
      // console.log('updated dine in order with new items', updatedOrder.data)
      return true
    }else{
      return false
    }
  } catch (error) {
    // console.log('error adding items to dine in order', error)
    return false
  }
};

export const checkForCompletedTab = async (order: Order, tab: Tab, firebaseJWT:string) => {

  const sessionId = order.sessionId ? order.sessionId : ''
  const tabId = tab.id ? tab.id : ''

  const updatedTab = await fetchTab(tabId)
  const allProductsPaid = updatedTab.customer.map((customer:CustomerModel)=>{
    return customer.productsPaid
  })


  const allProductsInAllOrders:ItemsInCart[] = []

  const allOrders = await db.collection('Orders').where('tabId', '==', tabId).get();
  if (allOrders.empty) {
    // console.log('No order for tab');
    return;
  }  

  allOrders.forEach(doc => {
    doc.data().products.forEach((product:ItemsInCart)=>{
        allProductsInAllOrders.push(product)
    })
  })


  //another user may have gotten alert and closed tab
  if(updatedTab.paymentComplete){
      alert('All products have been paid for and your tab has been closed. You will now be redirected to your receipt.')
      
      const newTabData = {
        paymentComplete: true,
        'status.data': 'Tab is closed',
        'status.open': false,
        updatedAt: new Date()
      }
      const expired = await expireTableSession(sessionId, firebaseJWT)
      const closedTab = await updateTab(tabId, newTabData, firebaseJWT)


      allOrders.forEach(doc => {
        updateOrder(doc.id, {completed:true, updatedAt: new Date()}, firebaseJWT)
      })


      if(closedTab && expired){
          return window.location.replace(`https://run-tango.web.app/order/${order.id}`)
      }else{
          console.log('error clsoing tab and session')
          return alert('Please let your server know your tab has been closed.')
      }
  }


  //check if number count of products paid for matches count of products in orders
  //***THIS CHECK WONT WORK ONCE CUSTOMERS CAN PAY FOR SUB ITEMS (ie pay for one of 3 bud lights)
  if (allProductsInAllOrders.length > 0 && allProductsPaid.flat(1).length === allProductsInAllOrders.length){
      alert('All products have been paid for and your tab has been closed. You will now be redirected to your receipt.')
      
      const newTabData = {
        paymentComplete: true,
        'status.data': 'Tab is closed',
        'status.open': false,
        updatedAt: new Date()
      }
      const expired = await expireTableSession(sessionId, firebaseJWT)
      const closedTab = await updateTab(tabId, newTabData, firebaseJWT)

      allOrders.forEach(doc => {
        updateOrder(doc.id, {completed:true, updatedAt: new Date()}, firebaseJWT)
      })
      
      if(closedTab && expired){
          return window.location.replace(`https://run-tango.web.app/order/${order.id}`)
      }else{
          console.log('error clsoing tab and session')
          return alert('Please let your server know your tab has been closed.')
      }
  }else{
    console.log('not all products paid for')
  }

}
export const createDeliverectOrder = async (order: any, user: any, business: any, successfulCharge: any, deliverectToken: string, apt: string, firebaseJWT:string) => {
  // console.log('save deliverect order', order, user, business, successfulCharge);
  // const stagingDeliverectToken = 'getThisFromFirestore'

  try {
    //format order in cloud function (also used by speed and flex so endpoint not in nest js)
    const formattedDeliverectOrder = await axios.post("https://us-central1-tango-2.cloudfunctions.net/buildDeliverectOrder", {
      order: order,
      user: user,
      tab: null, //tab doesnt exist yet so send as null
      business: business,
      customDeliverectItems: null, //function can handle preformatted deliverect items
      apt: apt ? apt : "",
    })

    //deliverect errors without these fields in staging but works in prod?? 
    // formattedDeliverectOrder.data.estimatedPickupTime = moment().add(20, 'minutes')
    // formattedDeliverectOrder.data.deliveryTime = moment().add(40, 'minutes')

    //send payload to deliverect
    const newDeliverectOrderCreated = await axios.post(`https://api.deliverect.com/Tango/order/${business.deliverect.channelLinkId}`,JSON.stringify(formattedDeliverectOrder.data),{
      headers: {'Authorization': `Bearer ${deliverectToken}`},
    })

    const tabDocument:Tab = {
      allSplitTypes: [],
      businessId: business.id,
      createdAt: new Date(),
      updatedAt: new Date(),
      customer: [successfulCharge],
      deleted: false,
      id: "",
      isExplicitTab: false,
      name: user.name,
      paymentComplete: true,
      staffId: "",
      status: {
        open: false,
        data: "Tab is closed",
        error: 0,
        refunded: false,
        refundedAmount: 0,
        deleted: false,
      },
      tableId: "",
      tableNumber: order.tableNumber ? order.tableNumber : "",
      extraInformation: { name: user.name ? user.name : '', phoneNumber: user.phone ? user.phone : '', email: user.email ? user.email : '' },
      sessionId: "",
      numberOfGuests: 0,
      refundPayload: null,
      serviceAreaId: "",
      tabNumber: JSON.stringify(Math.floor(Math.random() * 99999) + 100),
      tableAlias: ''
    };

    //create new tab
    const newTab = await axios.post(`${nestJS_BaseURL}/tab`,tabDocument,{
      headers: {'Authorization': `Bearer ${firebaseJWT}`},
    })
    
    order.tabId = newTab.data.id;
    order.extraInformation =  { name: user.name, phoneNumber: user.phone, email: user.email };
    order.deliverect = newDeliverectOrderCreated.data; //sometimes this is the deliverect order response, other times it's "OK" ?? 
    order.id = formattedDeliverectOrder.data.channelOrderId;
    order.customerName = user.name ? user.name : "Tango Guest";

    //create new order
    const newOrder = await axios.post(`${nestJS_BaseURL}/deliverectOrder`,order,{
      headers: {'Authorization': `Bearer ${firebaseJWT}`},
    })

    if(newTab.data.id && newOrder.data.id){
      return { tabId: newTab.data.id, orderId: newOrder.data.id };
    }else{
      return false;
    }

  } catch (error) {
    console.log('error creating deliverect order and saving tab and order', error)
    return false
  }
};

//create session, tab, and order when landing on dine in page
export const createDineInSessionTabOrder = async (business: any, table: TangoTable, firebaseJWT:string) => {
  console.log("creating new dine in session", business, " ??", table.id, table.tableNumber);

  // May need to check for exisitng sessions again?
  // Check again if a session isn't active with that table number before creating a new
  // session. On Jan 19, 2022 we found an issue where if multiple people
  // scan the QR code at the same time - multiple sessions get created.
  try {
    const newSessionToCreate = {
        id: '',
        sessionId: '',
        businessId: business.id,
        isExpired: false,
        tableIds: [table.id],
        selectedItemsForPayment: [],
        createdAt: new Date(),
        updatedAt: new Date(),
        users: []
    }
    const newTableSession = await axios.post(`${nestJS_BaseURL}/tableSession`,newSessionToCreate,{
      headers: {'Authorization': `Bearer ${firebaseJWT}`},
    })
    return createDineInTabAndOrder(business, newTableSession.data.id, table, firebaseJWT)
  } catch (err) {
    console.log("📛 Error adding tab: ", err);
    return null;
  }
};

//create dine in tab and order after table session has been created
export const createDineInTabAndOrder = async(business:any, sessionId:string, table:TangoTable, firebaseJWT:string)=>{

  const tangoProduct = business.tangoProducts.filter((tangoProduct: any) => tangoProduct.name === "tangoPOS");
  const orderTypes = tangoProduct.length > 0 ? tangoProduct[0].orderTypes.filter((orderType: any) => orderType.name === "dineIn") : null;
  const waitTimes: [number, number] = orderTypes && orderTypes.length > 0 ? orderTypes[0].waitTime : [15, 25];

  try {
    const tabToCreate:Tab = {
      businessId: business.id,
      createdAt: new Date(),
      updatedAt: new Date(),
      allSplitTypes: ["customSplit"],
      customer: [],
      deleted: false,
      extraInformation: null,
      id: null,
      isExplicitTab: true,
      name: "mobile order",
      numberOfGuests: 1, //table.maxCapacity,
      paymentComplete: false,
      refundPayload: null,
      sessionId: sessionId,
      serviceAreaId: 'none',
      staffId: "",
      status: {
        data: "Tab is open",
        deleted: false,
        error: 0,
        open: true,
        refunded: false,
        refundedAmount: 0,
      },
      tabNumber: JSON.stringify(Math.floor(Math.random() * 99999) + 100),
      tableId: table.id,
      tableNumber: table.tableNumber ? table.tableNumber : null,
      voidedProducts: [],
      tableAlias: null
    };
  
    const newTab = await axios.post(`${nestJS_BaseURL}/tab`,tabToCreate,{
      headers: {'Authorization': `Bearer ${firebaseJWT}`},
    })
  
    const orderToCreate:Order = {
      DSP: null,
      amount: {
        currency: business.currency === "CAD" || business.currency === "cad" ? "CAD" : "USD",
        deliveryFee: 0,
        discountTotal: 0,
        grossTotal: 0,
        netTotal: 0,
        serviceChargeTotal: 0,
        subTotal: 0,
        tax: 0,
        tip: 0,
      },
      businessId: business.id,
      cancelled: false,
      completed: false, //not payed for so should be false
      createdAt: new Date(),
      deleted: false,
      deliverect: null,
      deliveryAddress: null,
      discountsAppliedToOrder: [],
      discountedStaffMemberId: '',
      drawerId: null,
      id: "",
      isPrinted: false,
      isStaffMemberDiscount: false,
      orderApp: "pwa",
      orderChannel: "tangoPOS",
      orderNumber: JSON.stringify(Math.floor(Math.random() * 99999) + 1),
      orderType: "dineIn",
      pickupTime: null,
      products: [],
      renderOrderInQueue: false, //supposed to be false to first send to the server
      specialInstructions: "",
      sessionId: sessionId,
      serviceAreaId: "none",
      staffId: "",
      status: "received",
      tabId: newTab.data.id,
      tableId: table.id,
      tableNumber: table.tableNumber ? table.tableNumber : null,
      updatedAt: new Date(),
      turnaroundTime: waitTimes,
      extraInformation: null,
      voidedProducts: []
    };
  
    const newOrder = await axios.post(`${nestJS_BaseURL}/order`,orderToCreate,{
      headers: {'Authorization': `Bearer ${firebaseJWT}`},
    })
  
    if(newTab && newOrder){
      tabToCreate.id = newTab.data.id
      orderToCreate.id =  newOrder.data.id
      const sessionTabOrder = {
        sessionId: sessionId,
        tab: tabToCreate,
        order: orderToCreate,
        sessionEstablished: true,
      };
      return sessionTabOrder;
    }else{
      console.log('error creating tab and order after session created')
      return false;
    }

  } catch (error) {
    console.log('catch error creating tab and order after session created', error)
    return false;
  }
}

//create dine in order for non alcohol items (ie straight to kitchen)
export const createDineInOrder = async(items: ItemsInCart[], tab:Tab, business:Business, sessionId:string, table:any, firebaseJWT:string)=>{

  //if no regular items were in order return true (ie the dine in order only had alcohol items)
  if(items.length === 0){
    return true
  }


  let taxRate = 0.0
  business.tangoProducts.forEach((tangoProduct: any) => {
    if (tangoProduct.name === "mobile") {
      return (
        tangoProduct.orderTypes.forEach((type: any) => {
          if (type.name === 'dineIn') {
            console.log("apply tax rate,", type)
            taxRate = type.taxRate
          }
        })
      );
    }
  });

  console.log('tax rate', taxRate)

  const orderToCreate:Order = {
    DSP: null,
    amount: {
      currency: business.currency === "CAD" || business.currency === "cad" ? "CAD" : "USD",
      deliveryFee: 0,
      discountTotal: 0,
      grossTotal: calculateSubtotalInOrder(items) + calculateTaxOnOrder(items, taxRate), //tip will be applied later
      netTotal: calculateSubtotalInOrder(items) + calculateTaxOnOrder(items, taxRate),
      serviceChargeTotal: 0,
      subTotal: calculateSubtotalInOrder(items),
      tax: calculateTaxOnOrder(items, taxRate),
      tip: 0,//tip will be applied later
    },
    businessId: business.id,
    cancelled: false,
    completed: false,
    createdAt: new Date(),
    deleted: false,
    deliverect: null,
    deliveryAddress: null,
    discountsAppliedToOrder: [],
    discountedStaffMemberId: '',
    drawerId: null,
    id: "",
    isPrinted: false,
    isStaffMemberDiscount: false,
    orderApp: "pwa",
    orderChannel: "tangoPOS",
    orderNumber: JSON.stringify(Math.floor(Math.random() * 99999) + 1),
    orderType: "dineIn",
    pickupTime: null,
    products: items,
    renderOrderInQueue: true,
    specialInstructions: "",
    sessionId: sessionId,
    serviceAreaId: table.serviceArea ? table.serviceArea : "none",
    staffId: "",
    status: "received",
    tabId: tab.id ? tab.id : '',
    tableId: table.id,
    tableNumber: table.tableNumber ? parseInt(table.tableNumber) : null,
    updatedAt: new Date(),
    turnaroundTime: [0, 15],
    extraInformation: null,
    voidedProducts: [],
  }

  const newOrder = await axios.post(`${nestJS_BaseURL}/order`,orderToCreate,{
    headers: {'Authorization': `Bearer ${firebaseJWT}`},
  })
  
  if(newOrder){
    console.log('new order with regular items', newOrder)
    return true;
  }else{
    console.log('error creating tab and order after session created')
    return false;
  }

}

//create regular order for desktop or mobile view
export const createOnlineTabAndOrder = async (user: any, order: any, firebaseJWT:string) => {
  
  //default customer with empty payment values //this gets updated on successful payment
  let customer:CustomerModel = {
    email: user.email,
    firstName: user.name,
    lastName: "",
    orderId: "",
    payment: {
      paymentType: null,
      successfulCharge: {
        id: null,
        amount: null,
        timestampCharged: null,
        paymentVendor: null,
        applicationFeeAmount: null,
      },
      type: "",
    },
    phone: user.phone,
    productsPaid: [],
    userId: user.id,
    splitType: '', 
    orderChannel: '', 
    orderType: order.orderType, 
    isDineInOrder: false, 
    amount: {
      netTotal: 0,
      refundPayload: null,
      refundedAmount: 0,
      tip: 0,
      tax: 0
    }
  };

  let tabDocument:Tab = {
    allSplitTypes: [],
    businessId: order.businessId,
    createdAt: new Date(),
    updatedAt: new Date(),
    customer: [customer],
    deleted: false,
    extraInformation: { name: customer.firstName, phoneNumber: user.phone, email: user.email },
    id: null,
    // isOnlineOrder: true,
    isExplicitTab: false,
    name: user.name,
    numberOfGuests: 0,
    paymentComplete: true,
    refundPayload: null,
    staffId: "",
    status: {
      data: "Tab is closed",
      deleted: false,
      error: 0,
      open: false,
      refunded: false,
      refundedAmount: 0,
    },
    tabNumber: JSON.stringify(Math.floor(Math.random() * 99999) + 100),
    serviceAreaId: 'none',
    tableId: '',
    tableNumber: order.tableNumber ? parseInt(order.tableNumber) : null,
    voidedProducts: [],
    sessionId: '',
    tableAlias: '',
  };

  try {
    //create new tab
    const newTab = await axios.post(`${nestJS_BaseURL}/tab`,tabDocument,{
      headers: {'Authorization': `Bearer ${firebaseJWT}`},
    })

    //add tab fields to the order
    order.tabId = newTab.data.id;
    order.extraInformation =  { name: customer.firstName, phoneNumber: user.phone, email: user.email };
    order.customerName = user.name
    
    //create new order
    const newOrder = await axios.post(`${nestJS_BaseURL}/order`,order,{
      headers: {'Authorization': `Bearer ${firebaseJWT}`},
    })

    if(newTab && newOrder){
      return { tabId: newTab.data.id, orderId: newOrder.data.id };
    }else{
      return false;
    }

  } catch (error) {
    console.log("📛 catch error creating tab and order: ", error);
    return false;
  }

};

export const createDineInPaymentCompleteNotification = async (order: any, firebaseJWT:string) => {
  const newFlexNotificationToCreate = {
    id: '',
    deleted: false,
    businessId: order.businessId,
    type: "paymentComplete",
    description: "Table " + order.tableNumber + " has paid their check. This table will be automatically closed.",
    read: false,
    staffId: order.staffId,
    payload: {
      action: "",
      orderId: order.id,
      tabId: order.tabId,
      tableId: order.tableId,
      tableNumber: order.tableNumber,
    },
    createdAt: new Date(),
    updatedAt: new Date()
  }

  console.log('create this dine in complete flex notification', newFlexNotificationToCreate)


  const newFlexNotification = await axios.post(`${nestJS_BaseURL}/flextNotification`,newFlexNotificationToCreate,
  {
    headers: {'Authorization': `Bearer ${firebaseJWT}`},
  })

  if(newFlexNotification){
    console.log('created flex notification', newFlexNotification)
    return true
  }else{
    return false
  }
};

export const createGiftCardUser = async(giftCardUser: GiftCardUsers, firebaseJWT:string) => {
  try {
    const createdGiftCardUser = await axios.post(`${nestJS_BaseURL}/giftCardUser`, giftCardUser,
      {
        headers: {
          'Authorization': `Bearer ${firebaseJWT}`
        }
      }
    )
      if(createdGiftCardUser){
        console.log('created gift card user in firestore', createdGiftCardUser)
        giftCardUser.id = createdGiftCardUser.data.giftCardUserId
        return giftCardUser
      }else{
        return null
      }
  } catch (error) {
    console.log("Error creating gift card user:", error);
    return null;
  }
};

//order tab gets updated to deleted - really only used if payment fails in normal online order flow
export const deleteTabAndOrder = async (tabId: string, orderId: string, firebaseJWT:string) => {
      const deleteValues = {
        deleted: true,
        updatedAt: new Date()
      }
      //update new tab to deleted
      const deletedTab = await axios.patch(`${nestJS_BaseURL}/tab/${tabId}`,deleteValues,{
        headers: {'Authorization': `Bearer ${firebaseJWT}`},
      })
      const deletedOrder = await axios.patch(`${nestJS_BaseURL}/order/${orderId}`,deleteValues,{
        headers: {'Authorization': `Bearer ${firebaseJWT}`},
      })
  
      if(deletedTab && deletedOrder){
        return { tabId: deletedTab.data.id, orderId: deletedOrder.data.id };
      }else{
        return false;
      }
};

export const removeStripeCard = async (userId: string, country: string, firebaseJWT:string) => {
  if (country === "Canada" || country === "CA") {
    const newCardData = {
      "stripeCanada.cards": [],//set to empty 
      updatedAt: new Date()
    }
    const removedCard = await axios.patch(`${nestJS_BaseURL}/user/${userId}`,newCardData,{
      headers: {'Authorization': `Bearer ${firebaseJWT}`},
    })
  
    if(removedCard){
      return { cardId: removedCard.data.id };
    }else{
      return false;
    }
  } else {
    const newCardData = {
      "stripe.cards": [],//set to empty 
      updatedAt: new Date()
    }

    const removedCard = await axios.patch(`${nestJS_BaseURL}/user/${userId}`,newCardData,{
      headers: {'Authorization': `Bearer ${firebaseJWT}`},
    })
    if(removedCard){
      return { cardId: removedCard.data.id };
    }else{
      return false;
    }
  }
};

export const updateOrder = async (orderId: string, dto: any, firebaseJWT:string) => {
  dto.updatedAt = new Date()
  //update order with values passed as data transfer object
  const updatedOrder = await axios.patch(`${nestJS_BaseURL}/order/${orderId}`,dto,{
    headers: {'Authorization': `Bearer ${firebaseJWT}`},
  })

  if(updatedOrder){
    return { orderId: updatedOrder.data.id };
  }else{
    return false;
  }
};

export const updateTab = async (tabId: string | null, dto: any, firebaseJWT:string) => {
  dto.updatedAt = new Date()
  //update tab with values passed as data transfer object
  const updatedTab = await axios.patch(`${nestJS_BaseURL}/tab/${tabId}`,dto,{
    headers: {'Authorization': `Bearer ${firebaseJWT}`},
  })

  if(updatedTab){
    return { tabId: updatedTab.data.id };
  }else{
    return false;
  }
};

//update dine in tab order and session after a successful payment
export const updateDineInTabOrderSession = async(productsPaidForTab:ItemsInCart[], user:User, customer:any, order:Order, tab:Tab, paymentTotals: any, taxRate:number, firebaseJWT:string)=>{

  //TO DO: pass in any order generated by flex to update that with the tip
  //Currently just using the first default order so the tip will allways be on that one
  console.log('update dine in order', '\nOrder ', order, '\tab ',tab, '\productsPaidForTab ',productsPaidForTab, '\paymentTotals ',paymentTotals)

  try {
    //reset the customer amounts to save in the tab since they did a 'custom payment'
    customer.amount.netTotal = customer.payment.successfulCharge.amount - paymentTotals.tip
    customer.amount.tip = paymentTotals.tip
    customer.amount.tax = paymentTotals.tax
    const incrementTip = {
      'amount.tip': order.amount.tip + paymentTotals.tip,
      updatedAt: new Date()
    }
    const updatedOrder = await updateOrder(order.id, incrementTip, firebaseJWT)
    customer.productsPaid = productsPaidForTab

    const allCustomers: any[] = []
    const mostRecentTab = await fetchTab(order.tabId)
    allCustomers.push(customer)
    mostRecentTab.customer.forEach((customer:any) => {
      allCustomers.push(customer)
    });

    console.log('new customer array to save to tab', allCustomers)

    const tabDTO = {
      customer: allCustomers, //add successful charge to tab.customer
    }
    const updatedTab = await updateTab(tab.id, tabDTO, firebaseJWT)

    //update table session selected items for payment
    const currentTableSession = await fetchTableSession(order.sessionId)
    if(currentTableSession){
      let previousSelectedItems = currentTableSession.selectedItemsForPayment;
      return previousSelectedItems.map(async(item: any) => {
        if (item.userId === user.id) {
          const updatedTableSession = await toggleDineInItemToPay(order.sessionId, item, false, item.userId, firebaseJWT)
          if(updatedTableSession){
            console.log('remove payed item from selected items to pay for', currentTableSession.id)
          }
        }
      });
    }

    if(updatedOrder && updatedTab){
      console.log('successful updated tab and order with dine in products paid', updatedOrder, updatedTab)
      return {
        sessionId: order.sessionId,
        tabId: tab.id,
        orderId: order.id 
      }
    }else{
      return false
    }
    
  } catch (error) {
    console.log('error updating dine in tab and order', error)
    return false
  }

}

export const joinTableSession = async (sessionId: string, userId: string, firebaseJWT:string) => {
  const currentSession = await axios.get(`${nestJS_BaseURL}/tableSession/${sessionId}`)
  if(!currentSession.data){
    return false
  }else{


    if(!currentSession.data.users){
      const dto = { 
        updatedAt: new Date(),
        users: [userId]
      }
      const updatedTableSession = await axios.patch(`${nestJS_BaseURL}/tableSession/${sessionId}`,dto,{
        headers: {'Authorization': `Bearer ${firebaseJWT}`},
      })

      if(updatedTableSession.data){
        // console.log('updated table session with new user', updatedTableSession.data)
        return true
      }else{
        console.log('failed to join session with new user id')
        return false
      }
    }

    if(!currentSession.data.users.includes(userId)){
      const usersInSession = currentSession.data.users
      usersInSession.push(userId)
      const dto = { 
        updatedAt: new Date(),
        users: usersInSession
      }
      console.log('update with new users array', dto)
      const updatedTableSession = await axios.patch(`${nestJS_BaseURL}/tableSession/${sessionId}`,dto,{
        headers: {'Authorization': `Bearer ${firebaseJWT}`},
      })
      if(updatedTableSession.data){
        // console.log('updated table session with new user', updatedTableSession.data)
        return true
      }else{
        console.log('failed to join session with new user id')
        return false
      }
    }else{
      console.log('user already in session')
      return false
    }
  }
};

export const updateTableSession = async (sessionId: string, dto: any, firebaseJWT:string) => {

  dto.updatedAt = new Date()

  const updatedTableSession = await axios.patch(`${nestJS_BaseURL}/tableSession/${sessionId}`,dto,{
    headers: {'Authorization': `Bearer ${firebaseJWT}`},
  })
  if(updatedTableSession.data){
    console.log('updated table session', updatedTableSession.data)
  }
};

export const expireTableSession = async (sessionId: string, firebaseJWT:string) => {
  let dto = {
    isExpired: true,
    updatedAt: new Date()
  }
  const updatedTableSession = await axios.patch(`${nestJS_BaseURL}/tableSession/${sessionId}`,dto,{
    headers: {'Authorization': `Bearer ${firebaseJWT}`},
  })
  if(updatedTableSession.data){
    console.log('updated table session to expired', updatedTableSession.data)
    return true
  }else{
    return false
  }

};
export const toggleDineInItemToPay = async (sessionId: string | null, item: any, select: boolean, userId:string, firebaseJWT:string) => {
  const dto = {
    select,
    item
  }
  const currentSession = await axios.get(`${nestJS_BaseURL}/tableSession/${sessionId}`)

  const responseTime = 350 //350 milliseconds response time to check db and write to db
  var queueTime = 1 //default as 1 milliseconds to 'wait in line to select items'
  if(currentSession.data && currentSession.data.users.includes(userId)){
    //queueTime incremented based on when user joined (ie first user has lowest queueTime (1) and last user has longest queueTime)
    queueTime = queueTime * (currentSession.data.users.indexOf(userId) + 1)
  }
  const delay = responseTime * queueTime //350, 700, 1050, etc
  // const delay = Math.floor(Math.random() * (3000 - 5 + 1) + 5)//.05 - 3 sec delay

  return setTimeout(async() => {
    try {
      const querySession = await axios.get(`${nestJS_BaseURL}/tableSession/${sessionId}`)
      if(!querySession.data){
        return false
      }else{  
        //check if item selected has already been selected by other user (usually because 2 people clicked at about the same time)
        //user with longer delays wil ltheoretically hit this step
        for (let i = 0; i<querySession.data.selectedItemsForPayment.length; i++){
          const alreadySelectedItem = querySession.data.selectedItemsForPayment[i]
          if(alreadySelectedItem.productOrderId === item.productOrderId && alreadySelectedItem.userId != item.userId && parseInt(alreadySelectedItem.productOrderId.charAt(0)) === 1){
            console.log('single item already selected by different user')
            return false
          }
        }
  
        //if the item has not been selected by different write the new item to db 
        //user with the shortest delay should 'win the race' and make this update first
        const updatedTableSession = await axios.patch(`${nestJS_BaseURL}/tableSession/${sessionId}/item`,dto,{
          headers: {'Authorization': `Bearer ${firebaseJWT}`},
        })
        if(updatedTableSession.data){          
          return updatedTableSession.data
        }else{
          return false
        }
      }
    } catch (error) {
      console.log('error updating selected items for payment',error)
      return false
    }
  }, delay)




};

export const createAuthenticatedUser = async (newUser: any, firebaseJWT:string) => {
  try {
    console.log('create authenticated customer for', newUser)
    const findUsers = await axios.get(`${nestJS_BaseURL}/authenticatedUsers`);
    if (findUsers) {

      //format new customer phone and check if matches any existing users
      if(!newUser.phone.includes('+1')){
        newUser.phone = "+1" + newUser.phone
      }

      const allUsers = findUsers.data.users;
      const existingPhones: string[] = [];
      var userAlreadyExists = { uid: "" };

      allUsers.map((user: any) => {
        existingPhones.push(user.phoneNumber);
        if (newUser.phone === user.phoneNumber) {
          userAlreadyExists = user;
        }
      });

      if (!existingPhones.includes(newUser.phone)) {
        const newAuthenticatedUser = await axios.post(`${nestJS_BaseURL}/authenticatedUser`,newUser,{
          headers: {'Authorization': `Bearer ${'firebaseJWT'}`},
        })
        if(newAuthenticatedUser){
          console.log('successful new Authenticated User', newAuthenticatedUser)
        }else{
          console.log('error creating new Authenticated User')
        }
      } else {
        const updateUser = await axios.patch(`${nestJS_BaseURL}/user/${newUser.id}`,
          {
            uid: userAlreadyExists.uid,
            updatedAt: new Date(),
          },
          {
            headers: {
                'Authorization': `Bearer ${'firebaseJWT'}`,
            },
          }
        )
        if(updateUser){
          console.log('successful update existing Authenticated User with uid')
        }
      }
    }
  } catch (err) {
    console.error("error checking for existing users", err);
    return false;
  }
};

