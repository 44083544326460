export default {
  welcome: {
    route: '/',
    exact: true,
    isProtected: false,
  },
  locationsId: {
    route: '/locations/:locationId',
    exact: true,
    isProtected: false,
  },
  ordersOrderId: {
    route: '/order/:orderId',
    exact: true,
    isProtected: false,
  },
  tableQrId: {
    route: '/table/:qrId',
    exact: true,
    isProtected: false,
  },
  tableOrderId: {
    route: '/table/:qrId/order/:orderId',
    exact: true,
    isProtected: false,
  },
  giftCardCreate: {
    route: '/gift/card/create',
    exact: true,
    isProtected: true,
  },
  confirmOtp: {
    route: '/confirm-otp',
    exact: true,
    isProtected: false,
    isLogin: true,
  },
  cardById: {
    route: '/card/:id',
    exact: true,
    isProtected: true,
  },
  sendMoneyById: {
    route: '/sendMoney/:id',
    exact: true,
    isProtected: true,
  },
  sendMoneyByCardIdAndRecipientCardId: {
    route: '/sendMoney/checkout/:senderCardId/:recipientCardId',
    exact: true,
    isProtected: true,
  },
  checkoutSuccess: {
    route: '/checkout/success/:transactionId',
    exact: true,
    isProtected: true,
  },
  login: {
    route: '/login',
    exact: true,
    isProtected: false,
    isLogin: true,
  },
  signUp: {
    route: '/signup',
    exact: true,
    isProtected: false,
    isLogin: true,
  },
  cards: {
    route: '/cards',
    exact: true,
    isProtected: false,
  },
  reservations: {
    route: '/reservations',
    exact: true,
    isProtected: false,
  },
  giftCards: {
    route: '/gift',
    exact: true,
    isProtected: false,
  },
  addMoney: {
    route: '/add-money',
    exact: false,
    isProtected: true,
  },
  addMoneyForm: {
    route: '/add-money-form/:cardId',
    exact: false,
    isProtected: true,
  },
  locationJobs: {
    route: '/locations/:locationId/jobs',
    exact: false,
    isProtected: false,
  },
  jobs: {
    route: '/jobs',
    exact: false,
    isProtected: false,
  },
  job: {
    route: '/job/:jobPostingId',
    exact: false,
    isProtected: false,
  },
  addCard: {
    route: '/add-card',
    exact: false,
    isProtected: false,
  },
};
