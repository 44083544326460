import { Button, Card, Classes, Drawer, InputGroup, Position, TextArea } from "@blueprintjs/core";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, {useEffect, useState } from "react";
import { checkForCompletedTab } from "../../db/AddData";
import { fetchUser } from "../../db/GetData";
import StripeCardDineIn from "../../stripe/StripeCardDineIn";
import { Business, ItemsInCart, Order, Tab, TangoBusinessSettings, User } from "../../types/types";
import { createFirebaseJWT } from "../../utils/functions";
import WalletPaymentsDineIn from "../../walletPayments/WalletPaymentsDineIn";

interface PayProps {
    paymentTotals:any;
    productsPaidForTab:ItemsInCart[];
    business: Business;
    businessSettings:TangoBusinessSettings;
    deliverectToken:string;
    existingOrder:Order;
    existingTab: Tab;
    user: User;
    taxRate: number;
    setOpenPayScreen: (shouldShow: boolean) => void;
    setOpenViewCheck: (openViewCheck: boolean) => void;
    setItemsInCart: ([]) => void;
}

const DineInPay = ({ business, businessSettings, deliverectToken, existingOrder,existingTab, taxRate, paymentTotals, productsPaidForTab,user,setOpenPayScreen, setOpenViewCheck, setItemsInCart}: PayProps) => {

    //default ot US STRIPE acoount unless a canadian business
    var stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY ? process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY : "")
    if (business.location.country === "Canada" || business.location.country === "CA") {
        stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CANADA_LIVE_PUBLISHABLE_KEY ? process.env.REACT_APP_STRIPE_CANADA_LIVE_PUBLISHABLE_KEY : '');
    } 
    const sessionId = existingOrder.sessionId ? existingOrder.sessionId : existingTab.sessionId
    // const existingTip = existingOrder.amount.tip
    const [customerEmail, setCustomerEmail] = useState(user.email);
    const [customerPhone, setCustomerPhone] = useState(user.phone);
    const [customerName, setCustomerName] = useState(user.name);
    const [showExpressCheckout, setShowExpressCheckout] = useState(true);//default as false until thoroughly test
    const [successfulPayment, setSuccessfulPayment] = useState(false);

    // console.log('user dine in pay', user)
    // console.log('paymentTotals din in pay', paymentTotals)
    // console.log('pay for these products, dine in pay', productsPaidForTab)


    useEffect(() => {
        console.log('successfulPayment dine in pay ', successfulPayment)
        if(successfulPayment){
            console.log('clear cart')
            setItemsInCart([])
            //checks if all items payed for
            finishPayment()
        }
    },[successfulPayment])


    const finishPayment = async() =>{
        checkForCompletedTab(existingOrder, existingTab, await createFirebaseJWT())
    }

    return (
        <Drawer
            isOpen={true}
            onClose={() => {
                if(successfulPayment){
                    setOpenViewCheck(false)
                }
                return setOpenPayScreen(false);
            }}
            size="100%"
            position={Position.BOTTOM}
            className="drawer"
        >
            
            <div style={{ backgroundColor: "#F5F5F5", padding: ".5em", position: "fixed", top: "0px", width: "100%", zIndex: 1 }}>
                <Button
                    onClick={() => {
                        if(successfulPayment){
                            setOpenViewCheck(false)
                        }
                        return setOpenPayScreen(false);
                    }}
                    icon="arrow-left"
                    style={{ position: "absolute", left: "10px", top: "11px", alignSelf: "center" }}
                    className={`${Classes.MINIMAL} order-from-button`}
                ></Button>
                <h3 style={{ textAlign: "center", fontFamily: "Axiforma-ExtraBold", fontSize: "15px" }}>Payment</h3>
            </div>

            {successfulPayment ?
                <Card style={{marginTop:'40%', textAlign:'center', marginLeft:'10%', marginRight:'10%'}}>
                    <div style={{ fontSize: "25px", fontFamily: "Lato-Extrabold", color: "#231F20" }}>🎉 Success! 🎉</div>
                    <br/>
                    <div style={{ fontSize: "18px", fontFamily: "Lato-Bold", color: "#888888", marginTop: "6px", marginBottom: "25px" }}>
                        Your dine in payment has been processed
                    </div>
                    <br/>
                    <img style={{width:'36%'}} src={business.profileImageUrl}></img>
                    <br/>
                    <br/>
                    <Button onClick={()=>{
                        setOpenViewCheck(false)
                        return setOpenPayScreen(false)
                    }}>OK
                    </Button>
                </Card>
                :
                <div style={{overflowY: "scroll"}}>

                    {/* Customer input fields for name,email,phone,etc */}
                    <div style={{ marginTop: "10%", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", overflowY: "scroll", paddingBottom: "100px", backgroundColor: "#f9f9f9" }}>

                    <div style={{ backgroundColor: "#fff", padding: "13px 19px", marginBottom: "20px", marginTop: "20px" }}>
                    <div style={{ fontSize: "19px", fontFamily: "Lato-Extrabold", color: "#231F20" }}>Contact Info</div>
                    <div style={{ fontSize: "12px", fontFamily: "Lato-Bold", color: "#888888", marginTop: "6px", marginBottom: "25px" }}>Enter your name, phone number, and email</div>
                        <InputGroup
                            onChange={(e: any) => {
                                if (e.currentTarget.value.includes(" ")) {
                                    return;
                                } else {
                                    setCustomerEmail(e.target.value);
                                    user.email = e.target.value;
                                }
                            }}
                            value={user && user.email ? user.email : ""}
                            fill
                            large
                            placeholder={"Email"}
                            inputMode="email"
                            style={{
                                backgroundColor: "#F9F9F9",
                                marginTop: "10px",
                                boxShadow: "none",
                                border: "1px solid #F2F2F2",
                                fontSize: "12px",
                                fontFamily: "Lato-Medium",
                                borderRadius: "5px",
                            }}
                        />
                        <InputGroup
                            onChange={(e: any) => {
                                setCustomerPhone(e.target.value);
                                user.phone = e.target.value;
                            }}
                            value={user && user.phone ? user.phone : ""}
                            fill
                            large
                            placeholder={"Phone"}
                            inputMode="numeric"
                            style={{
                                backgroundColor: "#F9F9F9",
                                marginTop: "10px",
                                paddingLeft: ".5em",
                                boxShadow: "none",
                                border: "1px solid #F2F2F2",
                                fontSize: "12px",
                                fontFamily: "Lato-Medium",
                                borderRadius: "5px",
                            }}
                        />
                        <InputGroup
                            onChange={(e: any) => {
                                setCustomerName(e.target.value);
                                user.name = e.target.value;
                            }}
                            value={user && user.name ? user.name : ""}
                            style={{
                                outline: "none",
                                backgroundColor: "#F9F9F9",
                                marginTop: "10px",
                                boxShadow: "none",
                                border: "1px solid #F2F2F2",
                                fontSize: "12px",
                                fontFamily: "Lato-Medium",
                                borderRadius: "5px",
                            }}
                            fill
                            large
                            placeholder={"Full Name"}
                            inputMode="text"
                        />

                        {businessSettings && businessSettings.orderSpecialInstructions && (
                            <div>
                                <TextArea
                                maxLength={100}
                                fill
                                placeholder="Please indicate any allergies or preferences"
                                growVertically={true}
                                style={{ width: "100%", marginTop: "10px", fontSize: "12px", fontFamily: "Lato-Medium", backgroundColor: "#F9F9F9", boxShadow: "none", border: "1px solid #F2F2F2" }}
                                large={true}
                                onChange={(e: any) => {
                                    //  @ts-ignore
                                    order.specialInstructions = e.target.value;
                                }}
                                />
                            </div>
                        )}
                        </div>

                        {/* Express checkout for wallet payments - setShowExpressCheckout may be false if they have no wallet enabled*/}
                        {showExpressCheckout ? 
                            <>
                                <div
                                    style={{
                                    backgroundColor: "#fff",
                                    padding: "13px 19px",
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    }}
                                >
                                    <div style={{ fontSize: "19px", fontFamily: "Lato-Extrabold", color: "#231F20" }}>Express Checkout</div>
                                    <div
                                        style={{
                                            fontSize: "12px",
                                            fontFamily: "Lato-Bold",
                                            color: "#888888",
                                            marginTop: "6px",
                                            marginBottom: "25px",
                                        }}
                                    >
                                        Pay with Apple or Google Pay
                                    </div>

                                    <Elements stripe={stripePromise}>
                                        <WalletPaymentsDineIn   
                                            user={user}
                                            productsPaidForTab={productsPaidForTab}
                                            order={existingOrder}
                                            tab={existingTab}
                                            taxRate={taxRate}
                                            business={business}
                                            businessSettings={businessSettings}
                                            deliverectToken={deliverectToken}
                                            paymentTotals={paymentTotals}
                                            setSuccessfulPayment={setSuccessfulPayment} 
                                            setShowExpressCheckout={setShowExpressCheckout}                                        
                                        />
                                    </Elements>
                                </div>
                                <div className="separator" style={{ marginTop: "20px" }}>
                                    or pay with card
                                </div>
                            </>
                            : 
                            null
                        }


                        {/* Standard stripe checkout - always displayed */}
                        <div
                        style={{
                            backgroundColor: "#fff",
                            padding: "13px 19px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                        >
                        <div style={{ fontSize: "19px", fontFamily: "Lato-Extrabold", color: "#231F20" }}>Card Payment</div>
                        <div
                            style={{
                                fontSize: "12px",
                                fontFamily: "Lato-Bold",
                                color: "#888888",
                                marginTop: "6px",
                                marginBottom: "25px",
                            }}
                        >
                            All transactions are secure and encrypted
                        </div>

                        <div>
                            <Elements stripe={stripePromise}>
                                <StripeCardDineIn 
                                    user={user} 
                                    productsPaidForTab={productsPaidForTab}
                                    order={existingOrder} 
                                    tab={existingTab} 
                                    sessionId={sessionId} 
                                    taxRate={taxRate} 
                                    business={business} 
                                    businessSettings={businessSettings} 
                                    deliverectToken={deliverectToken} 
                                    paymentTotals={paymentTotals}
                                    setSuccessfulPayment={setSuccessfulPayment}
                                />
                            </Elements>
                        </div>
                    </div>

                    </div>

                </div>
            }
        </Drawer>
    )
}

export default DineInPay;
