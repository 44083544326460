import { useEffect, useState } from "react";
import { createDineInSessionTabOrder, expireTableSession } from '../db/AddData';
import { Spinner } from '@blueprintjs/core';
import { Redirect } from "react-router-dom";
import {nestJS_BaseURL} from "../utils/consts"
import {createFirebaseJWT} from '../utils/functions'
import axios from "axios";
import { Business, Order, Tab, TangoBusinessSettings, TangoTable } from "../types/types";

interface DineInSessionProps {
    business: Business;
    businessSettings:TangoBusinessSettings;
    table: TangoTable;
}

const DineInSession = ({ business, businessSettings, table }: DineInSessionProps) => {

    console.log('businessSettings dineInSession', businessSettings)
    // View states
    const [isSessionActive, setIsSessionActive] = useState(false);
    const [error, setError] = useState(false);

    // Session state
    const [sessionId, setSessionId] = useState('');
    const [existingTab, setExistingTab] = useState<Tab>();
    const [existingOrder, setExistingOrder] = useState<Order>();

    const sucessfullyFoundActiveSession = (sessionId: string) => {
        console.log('found active session', sessionId)
        setIsSessionActive(true);
        setError(false);
        setSessionId(sessionId);
    }

    const successfullyCreatedNewSession = (sessionId: string) => {
        console.log('created new session', sessionId)
        setIsSessionActive(true);
        setError(false);
        setSessionId(sessionId);
    }

    const errorOccuredWhileManagingSession = () => {
        console.log('error with table session', sessionId)
        setIsSessionActive(false);
        setError(true);
        setSessionId('');
    }

    const createNewSession = async (business: Business, table: TangoTable) => {
        const newSessionTabOrder = await createDineInSessionTabOrder(business, table, await createFirebaseJWT());
        if (newSessionTabOrder && newSessionTabOrder.order && newSessionTabOrder.tab && newSessionTabOrder.sessionId) {
            setExistingTab(newSessionTabOrder.tab);
            setExistingOrder(newSessionTabOrder.order);
            successfullyCreatedNewSession(newSessionTabOrder.sessionId);
            console.log('CREATED NEW SESSION TAB ORDER', newSessionTabOrder);
        } else if (newSessionTabOrder && newSessionTabOrder.sessionEstablished && newSessionTabOrder.sessionId) {
            await fetchTabForSession(business, table, newSessionTabOrder.sessionId);
        } else {
            setError(true);
            errorOccuredWhileManagingSession();
        }
    }

    const fetchTabForSession = async (business: Business, table: TangoTable, currentSessionId: string) => {
        try {
            // Fetch any exisitng tab for the table
            const tab = await axios
            .get(`${nestJS_BaseURL}/tabAtTable/${table.id}`, {
              headers: {
                'Authorization': `Bearer ${'firebaseJWT'}`,
              },
            })
            if (!tab.data) {
                // If the session is open but the tab isn't
                // then expire the current session
                console.log('no existing tab for the table ???');
                if (currentSessionId) {
                    await expireTableSession(currentSessionId, await createFirebaseJWT());
                    await createNewSession(business, table)
                }
            } else {
                console.log('found existing tab for the table', tab);
                setExistingTab(tab.data);
                sucessfullyFoundActiveSession(currentSessionId);
            }
        } catch (err) {
            console.log('Error getting tab documents: ', err);
            return setError(true)
        }
    }

    const createOrFindExistingSession = async (business: Business, table:TangoTable) => {
        try {
            // Fetch an existing session to join based on the tableId
            const existingSessionQuerySnapshot = await axios
            .get(`${nestJS_BaseURL}/tableSessionFromTable/${table.id}`, {
              headers: {
                'Authorization': `Bearer ${'firebaseJWT'}`,
              },
            })

            if (!existingSessionQuerySnapshot.data) {
                console.log('No active session found so create new one');
                if(businessSettings.serverMustOpenTable){
                    console.log('customer is not allowed to start session and table')
                    return false
                }else{
                    await createNewSession(business, table)
                }
            } else {
                console.log('Found session so fetch existing tab and order', existingSessionQuerySnapshot.data);
                await fetchTabForSession(business, table, existingSessionQuerySnapshot.data.id ? existingSessionQuerySnapshot.data.id : existingSessionQuerySnapshot.data.sessionId)
            }
        } catch (err) {
            console.log('Error finding table: ', error);
            setError(true);
        }
    };

    useEffect(() => {
        if (business && table && table.id && table.tableNumber) {
            createOrFindExistingSession(business, table)
        }
    }, [business, table])


    //if session is active and data - renders Index.tsx in components folder
    if (business && businessSettings && table && table.id && sessionId && existingTab && isSessionActive) {
        return <Redirect to={{
            pathname: `/table/${table.id}/order/${sessionId}`,
            state: {
                business: business,
                businessSettings: businessSettings,
                table: table,
                tabThatAlreadyExists: existingTab,
                orderThatAlreadyExists: existingOrder,
                sessionId: sessionId
            }
        }} />
    }



    return (
        <div className="session" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            {error ?
                <div>Sorry something went wrong. Please try again.</div>
                :
            businessSettings && businessSettings.serverMustOpenTable && !isSessionActive ?
                <div style={{textAlign:'center', fontFamily: "Axiforma-Regular"}}>
                    <p style={{marginBottom: '10%', marginLeft: '15%', marginRight: '15%', fontSize:'20px'}}>Your server will be with you shortly.</p>
                    <p style={{ fontSize: '15px'}}>Table {table.tableNumber}</p>
                    <br/>
                    <img style={{width:'30%', paddingTop:'5%',  paddingBottom:'10%'}} src={business?.profileImageUrl}></img>
                </div>
            :
                <Spinner />
            }
        </div>
    )
}

export default DineInSession;