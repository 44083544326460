import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import firebase from '../config/firebase';
import {Link} from 'react-router-dom';
import logo from "../assets/images/logo.webp"
import '.././GiftCards/header.css';
import Header from '../GiftCards/Header';
interface Props{
    path?:string;
}

const Reservations = ({path}: Props) => {
  const history = useHistory();
  return (
      <div>
        <Header path="reservations"  />


        <div style={{paddingTop:"15%", textAlign:"center"}}>
            <h1>Reservations coming soon!</h1>
            <h3>This page is under construction</h3>
            <br/><br/><br/><br/>
            <img src={logo}></img>
        </div>

    </div>
  )
}
export default Reservations;