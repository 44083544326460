import React, { useEffect, useState } from 'react';
import './index.css';
import {Button, Icon} from "@blueprintjs/core";
import {Link, useHistory} from 'react-router-dom';
import { fetchUsersCard, GiftCardUser } from '../db/Cards';
import { GiftCardsType } from '../types/types';
import Header from './Header';
import {createStructuredSelector} from "reselect";
import {selectBusinessDetails} from "../reducers/global/selectors";
import {connect} from "react-redux";
import {compose} from "redux";

const AddMoney = (props:any) => {
    const history = useHistory();
    const [showCheckout, setShowCheckout] = useState<boolean>(false);
    const [business, setBusiness] = useState();
    const [cards, setCards] = useState<GiftCardUser[]>([]);
    const [selectedCard, setSelectedCard] = useState<GiftCardUser | null>(null);
    useEffect(()=> {
        setBusiness(props.businessDetails);
    }, [props.businessDetails])
    useEffect(()=>{
        const fetchData = async()=> {
            const userId  = localStorage.getItem('tango-mobile-userId');
            if(userId && business){
                // @ts-ignore
                const response = await fetchUsersCard(userId, business.id);
                if(response.data){
                    setCards(response.data);
                }
            }
        }
        fetchData();
    }, [])
    return (
        <div className="gift-cards-page">
            <Header path="cards" />
            <div className="body">
                <div className="left-part">
                    <div className="side-nav">
                        <div className="nav-title">The Coop Cards</div>
                        <div className={`side-nav-item`} onClick={()=> history.push('/cards')}>Balance</div>
                        <div className={`side-nav-item active`}>Add</div>
                    </div>
                </div>
                <div className="right-part">
                  <div className="select-card-header">
                      <div className="text-center extra-bold f19">Add Money</div>
                      <div className="text-center bold f12">Choose a card to increase its balance</div>
                  </div>
                  <div className="card-list-title">
                      MY CARDS
                  </div>
                  <div className="cards-container">
                      {
                          cards.map((giftCard, index)=>(
                              <div key={index} onClick={()=> setSelectedCard(giftCard)}>
                                  <img src={giftCard.imageUrl}/>
                                  {
                                    selectedCard === giftCard && (
                                        <>
                                            <div className="check-icon">
                                                <Icon icon="tick" color="#fff"/>
                                            </div>
                                            <div className="overlay"></div>
                                        </>

                                    )
                                  }
                              </div>
                          ))
                      }
                  </div>
                  <Button text={"Continue"} className="add-money" disabled={!selectedCard} onClick={()=> history.push('/add-money-form/'+selectedCard?.id)}/>
                </div>
        </div>
        </div>
    )
}
const mapStateToProps = createStructuredSelector({
    businessDetails: selectBusinessDetails()
});
const withConnect = connect(mapStateToProps, null);
export  default  compose(withConnect)(AddMoney);
