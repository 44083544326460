import React, {useEffect, useState} from 'react';
import './index.css';
import {Button, Icon} from "@blueprintjs/core";
import {Link, useHistory} from 'react-router-dom';
import firebase from '../config/firebase';
import Header from './Header';
import GiftCardImage from '../assets/images/card-image.png';

import MobileHeader from './MobileHeader';
import CardImage from '../assets/images/card-image.png';

const AddCard = (props:any) => {
    const history = useHistory();

    if(firebase.auth().currentUser?.isAnonymous){
        if(window.innerWidth <= 960){
            return (
                <div className='gift-cards-mobile'>
                    <MobileHeader />
                    <div className='brand-name'>Coop cards</div>
                    <div style={{paddingLeft:'80px', paddingRight:'80px', marginTop:'14px', paddingBottom:'14px'}}><img src={CardImage} className={"selected-card-image"}/></div>
                    <div className='join-now-box'>
                        <div className="join-now-text">JOIN NOW</div>
                        <div className="join-now-offer-text">
                            Get a free digital card when you join - or log in to view your cards. <span style={{color:"#E12219"}}>Get Started.</span><div/>
                        </div>
                        <div style={{textAlign:'center'}}>
                            <Button text="Sign In" className='sign-in-button' onClick={()=> history.push('/login')}/>
                            <Button text="Join Now" className='join-now-button' onClick={()=> history.push('/signup')}/>
                        </div>
                    </div> 
                </div>
            )
        }
        return (
            <div className="gift-cards-page">
           <Header path="cards"  />
            <div className="body">
                <div className="left-part">
                    <div className="side-nav">
                        <div className="nav-title">The Coop Cards</div>
                    </div>
                </div>
                <div className="right-part">
                   <div className="cards-collection login-card-view">
                        <img src={GiftCardImage} />
                        <div className="offer-text-cards">
                            {   
                                //@ts-ignore
                                business && <img src={business.profileImageUrl}/>
                            }
                            <div>Get a free digital card when you join - or log in to view your cards. Join now. <span style={{fontFamily:"Lato-Bold", color:'#E12219', cursor:'pointer'}}  onClick={()=> history.push('/signup')}>Get Started</span></div>
                        </div>
                   </div>
                   <Button text={"Join Now"} className="add-money" onClick={()=> {
                       history.push('/login')
                    }}/>
                </div>
            </div>
        </div>
        )
    }
    if(window.innerWidth <= 960){
        return (
            <div className='gift-cards-mobile'>
                <MobileHeader />
            
                <div className='link-card-container'>
                    <div className='card-placeholder-container'>
                        <div className='card-image-placeholder'></div>
                        <div style={{flex:1}}>
                            <div className='card-title-placeholder'>

                            </div>
                            <div className='card-subtitle-placeholder'>

                            </div>
                        </div>
                    </div>
                    <div style={{paddingLeft:'33px', paddingRight:'33px', borderTop:'1px solid #F8F8F8', paddingTop:'16px'}}>
                        <div style={{fontSize:'16px', fontFamily:'Lato-Extrabold', color:'#231F20', textAlign:'center'}}>Add New Card</div>
                        <div className="textOnInput">
                                <label htmlFor="inputText">Enter Gift Card Code</label>
                                <input className="form-control" type="text" />
                            </div>
                        </div>
                        <Button text="Add Money" className="add-money-button" />
                    </div>
            </div>
        )
    }
    return (
        <div className="gift-cards-page">
           <Header path="cards"  />
            <div className="body">
                <div className="left-part">
                    <div className="side-nav">
                        <div className="nav-title">The Coop Cards</div>
                        <div className={`side-nav-item  active`}>Balance</div>
                        <div className={`side-nav-item`} onClick={()=> history.push('/add-money')}>Add</div>
                    </div>
                </div>
                <div className="right-part">
                    <div className="select-card-header">

                        <div className="text-center extra-bold f19">Add Card</div>
                        <div className="text-center bold f12">Add a new gift card to your account</div>
                        <div className="back-button" onClick={()=> history.goBack()}>
                            <Icon icon="chevron-left" /> Back
                        </div>
                    </div>
                    <div className='link-card-container'>
                        <div className='card-placeholder-container'>
                            <div className='card-image-placeholder'></div>
                            <div style={{flex:1}}>
                                <div className='card-title-placeholder'>

                                </div>
                                <div className='card-subtitle-placeholder'>

                                </div>
                            </div>
                        </div>
                        <div className="input-container">
                            <div>Enter Gift Card Code Here</div>
                            <input />
                        </div>
                    </div>
                    <div className='link-card-bottom-instruction'>Enter the code that appears on the back of your card</div>
                    <Button text={"Add Card"} className="add-money" />
                </div>
            </div>
        </div>
    )
}
export default (AddCard);