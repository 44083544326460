import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import './index.css';
import {Link, useHistory} from 'react-router-dom';
import { Button, Icon } from '@blueprintjs/core';
import ReactCodeInput from 'react-code-input';
import { findUserByPhoneNumber } from '../db/GetData';
import firebase from '../config/firebase';
import Header from '../GiftCards/Header';
import './mobile.css';
import MobileHeader from '../GiftCards/MobileHeader';
// @ts-ignore
import PhoneInput from "../components/PhoneInput";


const Login = (props:any) => {
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  useEffect(()=>{
    //@ts-ignore
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("login-button",
    {
       size:"invisible"
        // other options
    });
  }, [])

  const checkNumberInDatabase = useCallback(async ()=> {
    let phone = phoneNumber
    if(!phone.includes("+1")){
        phone = "+1" + phone //add the country code to verify login accurately
    }
    const currentUser = firebase.auth().currentUser;
    //@ts-ignore
    const appVerifier = window.recaptchaVerifier;

    if(currentUser){
        const response = await findUserByPhoneNumber(phone);
        if(response && response.phone.length > 0){
            if(response.phone === phone){
                if(response.id === currentUser.uid){
                    //login user with phone number
                    if(currentUser.isAnonymous){
                        //@ts-ignore
                        window.confirmationResult =  await currentUser.linkWithPhoneNumber(phone, appVerifier);
                        history.replace('/confirm-otp')
                    }
                }else{
                    await firebase.auth().signOut();
                    //@ts-ignore
                    window.confirmationResult = await firebase.auth().signInWithPhoneNumber(phone, appVerifier);
                    history.replace('/confirm-otp')
                }
            }else{
                alert('User does not exist.')
            }
        }else{
            alert('User does not exist.')
        }
    }
  }, [phoneNumber])
  if(window.innerWidth <= 960){
      return (
          <div className='gift-cards-mobile'>
            <MobileHeader />

            <div className='auth-form-container'>
                <div className='form-title'>Sign in or create an account</div> 
                <div className="textOnInput">
                    <label htmlFor="inputText">Phone Number</label>
                    <PhoneInput phoneNumber={phoneNumber} onChange={(number)=> setPhoneNumber(number)} />
                </div>
                <div className='contact-support'>
                    Need help? <span style={{fontFamily:'Lato-Bold'}}>Contact support.</span>
                </div>
            <div style={{display:'flex', justifyContent:'flex-end'}}>
                <Button text="Continue" className="login-button" id="login-button" onClick={checkNumberInDatabase} />
            </div>
            </div>
            <div className='join-now-box'>
                <div className="join-now-text">JOIN NOW</div>
                <div className="join-now-offer-text">
                    Get a free digital card when you join - or log in to view your cards. <span style={{color:"#E12219"}}>Get Started.</span><div/>
                </div>
                <div style={{textAlign:'center'}}>
                    <Button text="Join Now" className='join-now-button' onClick={()=> history.push('/signup')}/>
                </div>
            </div>
          </div>
      )
  }
    return (

        <div className="gift-cards-page welcome-screen">
           <Header path="login"/>
            <div className="body">
                <div className="left-part">
                    <div className="side-nav">
                        <div className="nav-title">Welcome!</div>
                        <div className={`side-nav-item active`}>Log In</div>
                        <div className={`side-nav-item`} onClick={()=> history.push('/signup')}>Join now</div>
                    </div>
                </div>
                <div className="right-part">
                    <div className="login-form">
                        <div className="select-card-header">
                        </div>
                        <div className="buy-card-form">
                            <div className="form-container">
                            <div className="form-title text-center">Log In</div>
                                <div className="input-container">
                                    <div>Phone Number</div>
                                    <PhoneInput phoneNumber={phoneNumber} onChange={(number)=> setPhoneNumber(number)} />
                                </div>
                                <Button text="Log in" className="login-button" id="login-button" onClick={checkNumberInDatabase} />
                            </div>
                            <div className="text-center signup-link" onClick={()=> history.push('/signup')}>Don't have an account? <span style={{fontFamily:"Lato-ExtraBold", color:"#E12219"}}>Join Now</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export  default  Login;
