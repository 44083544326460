import { Button, Dialog, Icon } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import { updateTab } from '../db/AddData';
import { fetchBusiness, fetchOrder, fetchTab } from '../db/GetData';
import { calculateSubtotalInOrder, calculateTaxOnOrder, createFirebaseJWT } from '../utils/functions';
import './style.css';
import firebase from "firebase";
import { Business, ItemsInCart, Order, SelectedModifiers, Tab } from '../types/types';
import moment from 'moment';
const db = firebase.firestore();

const OrderConfirmation = (props: any) => {
  const orderId = props.orderId;
  const [error, setError] = useState(false);
  const [order, setOrder] = useState<Order>();
  const [business, setBusiness] = useState<Business>();
  const [carType, setCarType] = useState('');
  const [tab, setTab] = useState<Tab>();
  const [addedCarData, setAddedCarData] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [allProductsInAllDineInOrders, setAllProductsInAllDineInOrders] = useState<any>([]);

  const [itemCount, setItemCount] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [tip, setTip] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);


  useEffect(() => {
    getTabOrderData()
    .catch((error:any)=>{
      // console.log('error fetching tab, order and biz',error)
      setError(true)
    })
  }, []);

 const getTabOrderData = async() =>{
    try {
      

      //fetch order for the order id passed in param
      const order = await fetchOrder(orderId)
      order.createdAt = new Date(order.createdAt._seconds*1000);
      order.updatedAt = new Date(order.updatedAt._seconds*1000);
      setOrder(order)
      setSubtotal(order.amount.subTotal)
      setTax(order.amount.tax)
      setTip(order.amount.tip)
      setDiscountTotal(order.amount.discountTotal)
      const count = order.products.reduce((totalCount:number, item:ItemsInCart)=> {
        return totalCount + item.quantity;
      },0)
      setItemCount(count)
  

      //load all orders based on the first order - really only applicable to dine in since the other order types wont have more than one 
      //this overwrites the values with all orders combined
      getAllOrders(order.tabId)


      //load tab
      const tab:Tab = await fetchTab(order.tabId)
      tab.createdAt = new Date(tab.createdAt._seconds*1000);
      tab.updatedAt = new Date(tab.updatedAt._seconds*1000);
      setTab(tab)
  
      //load business
      const business = await fetchBusiness(order.businessId)
      setBusiness(business)
  
      //@ts-ignore
      if (tab && tab.customer[0] && tab.customer[0].carMakeAndModel) {
        setAddedCarData(true);
      }
      
    } catch (error) {
      console.log('error fetching tab order and biz data', error)
      return setError(true)
    }
  }

  const getAllOrders = async(tabId:string) =>{

    // console.log('fetching all dine in order for tab -->', tabId)
    const allProducts: any[] | ((prevState: never[]) => never[]) = []
    const allSubtotals: any[] = []
    const allTaxes: any[] = []
    const allTips: any[] = []
    const allDiscount: any[] = []

    const snapshot = await db.collection('Orders').where('tabId', '==', tabId).get();
    if (snapshot.empty) {
      console.log('No matching documents.');
      return;
    }  

    snapshot.forEach(doc => {
      console.log('order in dine in session =>', doc.id);
      const order = doc.data()

      allSubtotals.push(order.amount.subTotal)
      allTaxes.push(order.amount.tax)
      allTips.push(order.amount.tip)
      allDiscount.push(order.amount.discountTotal)
      order.products.forEach((product:any) => {
        allProducts.push(product)
      });
    })

    // console.log('subtotals', allSubtotals, 'taxes', allTaxes, 'allTips', allTips, 'allDiscount', allDiscount)

    const productCount = allProducts.reduce((total:number, item:any)=> {
      return total + item.quantity;
    },0)

    const subtotals = allSubtotals.reduce((total:number, subtotalInOrder:number)=> {
      return total + subtotalInOrder;
    },0)

    const taxes = allTaxes.reduce((total:number, subtotalInOrder:number)=> {
      return total + subtotalInOrder;
    },0)
    const tips = allTips.reduce((total:number, tipOnOrder:number)=> {
      return total + tipOnOrder;
    },0)

    const discounts = allDiscount.reduce((total:number, discountOnOrder:number)=> {
      return total + discountOnOrder;
    },0)


    setItemCount(productCount)
    setSubtotal(subtotals)
    setTax(taxes)
    setTip(tips)
    setDiscountTotal(discounts)


    return setAllProductsInAllDineInOrders(allProducts)
  }

  const submitCar = async() => {
    if (carType.length && tab) {
      const customers = [...tab.customer];
      //@ts-ignore
      customers[0]['carMakeAndModel'] = carType;
      try {
        const updateData = {
          customer: customers,
          updatedAt: new Date()
        }
        const tabUpdated = await updateTab(tab.id, updateData, await createFirebaseJWT())
        if(tabUpdated){
          setAddedCarData(true);
          setShowSuccessModal(true);
          setTimeout(() => {
            setShowSuccessModal(false);
          }, 1500);
        }else{
          return alert( 'Oops! There was a problem updating your vehicle information. Please pick up your order inside upon arrival.')
        }
        
      } catch (error) {
        console.log('error update car make model', error)
        return alert( 'Oops! There was a problem updating your vehicle information. Please pick up your order inside upon arrival.')
      }
    }
  };

  return (
    <div>
      {error ? 
        <div style={{width:'50%',margin:'auto', textAlign:'center', marginTop:'25%'}}>
          Oops! There was a problem finding your order.
          {/* <img src={business.profileImageUrl}></img> */}
        </div>
      :
        <div>
          {tab && order && business && 
            <>
              
              {/* curbside order logic */}
              {order.orderType === 'curbside' && 
                <div className="curbside-container">
                  {addedCarData && 
                    <>
                      <img src={require('../assets/images/take-away.svg').default} className="take-away-icon" />
                      {/* @ts-ignore */}
                      <div className="curbside-header">{tab.customer[0].carMakeAndModel}</div>
                      <div className="curbside-note">We'll send you a text when we're bringing your order out. Hang tight and we'll see you momentarily.</div>
                      <div className="curbside-callus">If you have any questions, give us a ring at {business.phones[0].number}.</div>
                    </>
                  }
                  {!addedCarData && 
                    <>
                      <div className="curbside-header">CURBISDE PICKUP</div>
                      <div className="curbside-note">When you arrive, please enter your car make and model and we’ll bring your order right out to you.</div>
                      <input
                        value={carType}
                        onChange={(e) => {
                          setCarType(e.target.value);
                        }}
                        className="curbside-input"
                        placeholder="Ex: Black Honda Civic"
                      />
                      <Button onClick={()=>{submitCar()}} className="curbside-button">
                        I've Arrived
                      </Button>
                    </>
                  }
                </div>
              }

              <div className="header">
                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{business.businessName}</div>
                <div style={{ fontSize: '14px' }}>{business.location.address}</div>
              </div>

              <div className="carryout_stripe">
                {order.orderType === 'dineIn' && <span>Dine in </span>}
                {order.orderType === 'carryOut' && <span>Carry Out </span>}
                {order.orderType === 'curbside' && <span>Curbside </span>}
                Order
                {order.tableNumber && 
                  <span>
                    <br />
                    Table #{order.tableNumber}
                  </span>
                }
              </div>


              <div className="products_container">
                {allProductsInAllDineInOrders.length > 0 ?
                  <div>
                    {/* SHOW ALL DINE IN PRODUCTS ORDERED */}
                    {allProductsInAllDineInOrders.map((item:any, index:number) => (
                      <div key={index} className="product">
                        <div className="qantity">{item.quantity}</div>
                        <div className="product-header">
                          <div className="product_name">{item.name}</div>
                          <div className="product_price">${(item.price * item.quantity / 100).toFixed(2)}</div>
                        </div>
            
                          
                          {item.selectedModifiers.map((mod: SelectedModifiers) => {
                              return mod.optionNames.map((optionName: string, index:number) => {
                                  return <span key={index} style={{ fontSize: "12px", marginRight: "5px", color: "#888888", fontFamily: "Lato-Bold" }}>{optionName} 
                                    {mod.additionalCost ? 
                                      <span> +${(mod.additionalCost * 0.01)}</span>
                                    : null
                                    },
                                    </span>;
                                  });
                          })}

                          {/* {item.selectedModifiers.map((selectedOption, optionIndex) => (
                            <div  key={optionIndex}>
                              {selectedOption.modifierName} 
                              <span style={{float:'right'}}> ${(selectedOption.additionalCost / 100).toFixed(2)}</span>
                            </div>
                          ))} */}

                      </div>
                    ))}
                  </div>
                  :
                  <div>
                    {/* SHOW ALL DINE IN PRODUCTS ORDERED FOR REGULAR ORDER */}
                    {order.products.map((item, index) => (
                      <div key={index} className="product">
                        <div className="qantity">{item.quantity}</div>
                        <div className="product-header">
                          <div className="product_name">{item.name}</div>
                          <div className="product_price">${(item.price / 100).toFixed(2)}</div>
                        </div>



                        {item.selectedModifiers.map((mod: SelectedModifiers) => {
                              return mod.optionNames.map((optionName: string, index:number) => {
                                  return <span key={index} style={{ fontSize: "12px", marginRight: "5px", color: "#888888", fontFamily: "Lato-Bold" }}>{optionName},</span>;
                                  });
                          })}

                          {item.selectedModifiers.map((selectedOption:any, optionIndex:number) => (
                            <div  key={optionIndex}>
                              {/* {selectedOption.modifierName}  */}
                              <span style={{float:'right', color: "#888888", fontSize:'12px'}}> ${(selectedOption.additionalCost / 100).toFixed(2)}</span>
                            </div>
                          ))}

                        {/* {item.selectedModifiers &&
                          item.selectedModifiers.map((selectedOption, optionIndex) => (
                            <div  key={optionIndex}>
                              {selectedOption.modifierName} 
                              <span style={{float:'right'}}> ${(selectedOption.additionalCost / 100).toFixed(2)}</span>
                            </div>
                          ))} */}


                      </div>
                    ))}
                  </div>  
                }
         

                {order.pickupTime &&

                <div className="stats">
                <span>Pickup Time:</span> <span>{moment(order.pickupTime).format('MMM DD, HH:mm A')}</span>
                </div>
                  // <div className="stats">
                  //   <span>Pickup Time:</span> <span>{new Date(order.pickupTime.seconds * 1000).toLocaleDateString('en-US')}, {new Date(order.pickupTime.seconds * 1000).toLocaleTimeString('en-US')}</span>
                  // </div>
                }
                {order.specialInstructions &&
                  <div className="stats">
                    <span>Order Notes:</span> <span>{order.specialInstructions}</span>
                  </div>
                }

                <div className="stats">
                  <span>Subtotal</span> <span> ${(subtotal / 100).toFixed(2)}</span>
                </div>
                <div className="stats">
                  <span>Tip</span> <span>${(tip / 100).toFixed(2)}</span>
                </div>

                {/* <h4>Tip: ${this.state.order.tip.toFixed(2)}</h4> */}
                <div className="stats">
                  <span>Tax</span> <span>${(tax / 100).toFixed(2)}</span>
                </div>
                <div className="stats">
                  <span>Discount</span> <span>-${(discountTotal / 100).toFixed(2)}</span>
                </div>

                <div className="stats" style={{ paddingBottom: '20px', borderBottom: '1px solid #f5f5f5' }}>
                  <span>Total</span> <span>${((subtotal + tax + tip - discountTotal) / 100).toFixed(2)}</span>
                </div>

                <div style={{ marginTop: '20px' }}>
                <span>Ordered at: 
                  </span> {order.createdAt.toLocaleDateString('en-US')}  {order.createdAt.toLocaleTimeString('en-US')}
                </div>
                {/* <div style={{ marginTop: '20px' }}>
                <span>Time: </span> {order.createdAt.toLocaleTimeString('en-US')}
                  <span></span>
                </div> */}
                <div style={{ marginTop: '20px' }}>
                  <span>Items: </span> 
                    {itemCount}
                    {/* {order.products.length} */}
                </div>
              </div>
            </>
          }
        </div>
      }

      <Dialog isOpen={showSuccessModal} style={{ height: '215px', margin: '0px 20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div
          style={{
            width: '62px',
            height: '62px',
            backgroundColor: '#2FB24C',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '10px',
          }}>
          <Icon icon="tick" iconSize={30} color="#fff" />
        </div>
        <div style={{ fontSize: '17px', fontFamily: 'Axiforma-ExtraBold', color: '#000000' }}>Excellent!</div>
      </Dialog>
    </div>
  );
};
export default OrderConfirmation;
