import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import './index.css';
import { Button, Icon, InputGroup, MenuItem } from "@blueprintjs/core";
import _ from 'lodash';
import { Position } from "@blueprintjs/core";
import lodash from "lodash";

import { Link, useHistory } from 'react-router-dom';
import Header from '../../GiftCards/Header';
import MobileHeader from '../../GiftCards/MobileHeader';
import SearchIcon from '../../assets/images/search-icon.svg';
import { Select } from '@blueprintjs/select';
import LocationIcon from '../../assets/images/location-icon.svg';
import ClockIcon from '../../assets/images/clock.svg';
import { JobPosting, TangoBusinessSettings } from "../../types/types";
import { fetchAllJobPostings, fetchBusinessSettings } from "../../db/GetData";
import { createStructuredSelector } from "reselect";
import { DepartmentId, DEPARTMENTS, getPositionByName, selectBusinessDetails, selectBusinessSettings } from "../../reducers/global/selectors";
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import moment from "moment";
type Props = {
  businessDetails: any;
}

type Filter = {
  searchQuery?: string;
  selectedRole?: string;
  location?: string;
  department?: DepartmentId | null
}

const Jobs = ({ businessDetails }: Props) => {

  const history = useHistory();
  const [jobs, setJobs] = useState<JobPosting[]>([]);
  const [filteredJobs, setFilteredJobs] = useState<JobPosting[]>([]);
  const [jobFunctions, setJobFunctions] = useState<any[]>([]);
  const [locations, setLocations] = useState<string[]>([]);
  const [businessSettings, setBusinessSettings] = useState<TangoBusinessSettings | null>(null)
  const [filter, setFilter] = useState<Filter>({})


  useEffect(() => {
    const fetchJobPostingsAndBusinessSettings = async () => {

      console.log({ businessDetails })

      console.log("businessDetails", businessDetails)

      const allJobPostings = await fetchAllJobPostings(businessDetails.id);
      const businessSettingsData = await fetchBusinessSettings(businessDetails.id);
      if (businessSettingsData?.jobFunctions) {
        setBusinessSettings(businessSettingsData as TangoBusinessSettings)
        //@ts-ignore
        setJobFunctions(Object.keys(businessSettingsData.jobFunctions).map((key) => businessSettingsData.jobFunctions[key].title))
      }
      console.log('allJobPostings', allJobPostings)
      const groupedLocation = _.groupBy(allJobPostings, (item) => item.location.name)
      const filteredLocation = (Object.keys(groupedLocation)).filter((item) => {
        if (item !== 'undefined') return item
      })
      setLocations(filteredLocation)
      const sortedJobsByDate = allJobPostings.sort((b, a) => a.createdAt - b.createdAt)
      setJobs(sortedJobsByDate);
      setFilteredJobs(sortedJobsByDate);
    }
    if (businessDetails)
      fetchJobPostingsAndBusinessSettings();
  }, [businessDetails]);

  useEffect(() => {
    if (filter.searchQuery || filter.selectedRole || filter.location || filter.department) {
      const filteredBySearchQuery = jobs
        .filter((item) => {
          if (filter.searchQuery) {
            return item.jobTitle.toLowerCase().includes(filter.searchQuery.toLowerCase())
          }
          return true
        })
        .filter((item) => {
          if (filter.selectedRole) {
            return item.jobTitle.toLowerCase().includes(filter.selectedRole.toLowerCase());
          }
          return true
        })
        .filter((item) => {
          if (filter.location) {
            return item.location.name?.toLowerCase().includes(filter.location.toLowerCase())
          }
          return true
        })
        .filter((item) => {
          if (filter.department) {
            if (!businessSettings) {
              return false
            }
            const jf = getPositionByName(businessSettings, item.jobTitle)
            return jf?.departmentId === filter.department
          }
          return true
        })

      setFilteredJobs(filteredBySearchQuery);
    } else {
      setFilteredJobs(jobs);
    }
  }, [filter, businessSettings]);

  const renderJobs = () => (
    <div className='jobs-container'>
      <table>
        <tr>
          <th>Job Title</th>
          <th>Job Type</th>
          <th>Job Location</th>
          <th>Posted Date</th>
        </tr>
        {
          filteredJobs.map((item) => {
            if (!businessSettings) {
              return null
            }
            const jf = getPositionByName(businessSettings, item.jobTitle)
            if (item.status !== 'archive' && item.status !== 'delete' && item.status !== 'paused') {
              return (
                <tr key={item.id}>
                  <td>
                    <div>{item.jobTitle}</div>
                    <ul>
                      <li>{jf?.departmentId ? DEPARTMENTS?.[jf.departmentId as DepartmentId]?.shortTitle ?? "" : ""}</li>
                    </ul>
                  </td>
                  <td className='status-column'>
                    {item.jobType}
                  </td>
                  <td className='applicants-column'>
                    {item.location.name ? item.location.name : ''}
                  </td>
                  <td className='date-column'>
                    <div>{moment(item.createdAt).format('MM/DD/YYYY')}</div>
                    <Link to={`/job/${item.id}`}>
                      <div className='view-application'>View Job</div>
                    </Link>
                  </td>
                </tr>
              )
            }
          })
        }
      </table>
    </div>
  )
  if (window.innerWidth <= 960) {
    return (
      <div className='jobs-mobile-page'>
        <MobileHeader />
        <div className='search-header-container'>
          <div className='search-header'>
            <InputGroup color='#424452' placeholder='Search Role' className='search-input'
              leftIcon={<img src={SearchIcon} />} />
            <Select filterable={false} className='location-dropdown' items={["test", "test2", "test3"]}
              itemRenderer={(item) => (
                <MenuItem
                  text={item}
                />
              )} onItemSelect={() => console.log('yey')}>
              <InputGroup placeholder='Search Role' value='Location' className='search-input'
                leftIcon={<img src={LocationIcon} />} />
            </Select>
          </div>
          <div className='jobs-mobile-body'>
            {
              filteredJobs.map((item) => (
                <div className='job-card' key={item.id} onClick={() => history.push('/job')}>
                  <div className='job-title'>{item.jobTitle}</div>
                  <ul>
                    <li>{item.location.name ? item.location.name : ''}</li>
                  </ul>
                  <div className='job-type'>
                    <img src={ClockIcon} />
                    <div>{item.jobType}</div>
                  </div>
                  <div className='job-date'>
                    Posted {moment(item.createdAt).format('MM/DD/YYYY')}
                  </div>
                  <Button text='Apply' className='apply-button' onClick={() => window.open(item?.applicationUrl + "#business_id=" + item?.businessId, '_blank')} />
                </div>
              ))
            }

          </div>
        </div>
      </div>
    )
  }
  return (
    <div id="jobs-page">
      <Header path='jobs' />
      <div className='small-title'>Team</div>
      <div className='big-title'>Hiring</div>
      <div className='back-button'>
        <Icon icon="arrow-left" color='#7C7F93' />
        Back to dashboard
      </div>
      <div className="application-tracker-page" style={{ paddingLeft: 20, paddingRight: 20 }}>
        {/*sidebar*/}
        <div className='sidebar-container'>
          <div className="sidebar">
            <div className="sidebar-title">
              Action
            </div>
            <div className="sidebar-section">
              <InputGroup placeholder="Search" leftIcon="search" intent="none" onChange={e => setFilter(
                {
                  ...filter,
                  searchQuery: e.target.value
                }
              )} />
            </div>
            <div className="sidebar-section">
              <div className="sidebar-section-title">
                Filter By Type:
              </div>
              <ul>
                <li onClick={() => setFilter({ ...filter, department: filter.department === "boh" ? null : "boh" })} className={filter.department === "boh" ? "active" : undefined}>BOH</li>
                <li onClick={() => setFilter({ ...filter, department: filter.department === "foh" ? null : "foh" })} className={filter.department === "foh" ? "active" : undefined}>FOH</li>
                <li onClick={() => setFilter({ ...filter, department: filter.department === "other" ? null : "other" })} className={filter.department === "other" ? "active" : undefined}>Other</li>
              </ul>
            </div>
            <div className="sidebar-section">
              <div className="sidebar-section-title">
                Filter By Role:
              </div>
              <select onChange={(e) => setFilter(
                {
                  ...filter,
                  selectedRole: e.target.value
                }
              )}>
                <option value="">Select a role</option>
                {
                  jobFunctions.map((item, index) => {
                    return <option value={item} key={index}>{item}</option>
                  })
                }
              </select>
            </div>
            <div className="sidebar-section">
              <div className="sidebar-section-title">
                Filter By Location:
              </div>
              <select onChange={(e) => setFilter(
                {
                  ...filter,
                  location: e.target.value
                }
              )}>
                <option value="">Select Location</option>
                {locations.map((item, index) => {
                  if (item) {
                    return (
                      <option key={index}>{item}</option>
                    )
                  }
                })}
              </select>
            </div>
          </div>
        </div>
        {/*sidebar*/}
        {renderJobs()}
      </div>
    </div>

  )
}


const mapStateToProps = createStructuredSelector({
  businessDetails: selectBusinessDetails(),
});
const withConnect = connect(mapStateToProps, null);

export default compose(withConnect)(Jobs);
