import { Button, Classes, Dialog, Drawer, Position } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { appendProductsToOrder, createDineInOrder} from "../../db/AddData";
import { Business, ItemsInCart, Order, Tab, TangoBusinessSettings} from "../../types/types";
import { calculateSubtotalInOrder, createFirebaseJWT } from "../../utils/functions";
import CheckIcon from "../../assets/images/check.png";

interface ViewCartProps {
    business: Business;
    businessSettings: TangoBusinessSettings;
    existingTab: Tab;
    itemsInCart: ItemsInCart[]; 
    existingOrder: Order;
    table: any;
    setOpenViewCart: (shouldShow: boolean) => void;
    removeItemInCart: ([]) => void;
    setCompletedOrder: (completedOrder: boolean) => void;
}

const ViewCart = ({business, businessSettings, existingTab, itemsInCart, existingOrder, table, setOpenViewCart, removeItemInCart, setCompletedOrder}: ViewCartProps) => {

    const sessionId = existingOrder.sessionId ? existingOrder.sessionId : ''//should always exist
    const [loading, setLoading] = useState(false);
    const [successDialog, setSuccessDialog] = useState(false);

    const placeDineInOrder = async () => {

        //if direct send order, make a new order with render order in queue (ie business want items sent to kitchen no matter what)
        if(businessSettings.directSendOrder){
            //Separate alcohol items
            const alcoholItems = itemsInCart.filter((item:ItemsInCart)=>{
                return item.alcohol
            })
            const regularItems = itemsInCart.filter((item:ItemsInCart)=>{
                return !item.alcohol
            })

            console.log('\n make new orders', alcoholItems, regularItems)

            //create dine in order renderOrderInQueue:true for regular items
            const completedRegularOrder = await createDineInOrder(regularItems, existingTab,business, sessionId, table, await createFirebaseJWT())
            //append alcohol products to existingOrder which is renderOrderInQueue:false (ie server must review and send first)
            const completedAlcoholOrder = await appendProductsToOrder(business, existingOrder, existingTab, alcoholItems, await createFirebaseJWT());
            if (completedRegularOrder && completedAlcoholOrder) {
                setSuccessDialog(true);
                setTimeout(() => {
                    setCompletedOrder(true) //clears the cart in Index.tsx
                    setSuccessDialog(false);//closes success alert
                    setLoading(false);
                    itemsInCart = []
                    return setOpenViewCart(false); //closes this component
                }, 1500);
            } else {
                alert('Oops! There was a problem placing your dine in order.')
                return setLoading(false);
            }
        }else{
            console.log('directSendOrder', businessSettings.directSendOrder, '\n append to existing', itemsInCart)
            const completedOrder = await appendProductsToOrder(business, existingOrder, existingTab, itemsInCart, await createFirebaseJWT());
            if (completedOrder) {
                setSuccessDialog(true);
                setTimeout(() => {
                    setCompletedOrder(true) //clears the cart in Index.tsx
                    setSuccessDialog(false);//closes success alert
                    setLoading(false);
                    itemsInCart = []
                    return setOpenViewCart(false); //closes this component
                }, 1500);
            } else {
                alert('Oops! There was a problem placing your dine in order.')
                return setLoading(false);
            }
        }
        setLoading(false);
    };

    const removeItem = (index: number) => {
        removeItemInCart(itemsInCart.filter((_, i) => i !== index));
        if(itemsInCart.filter((_, i) => i !== index).length < 1){
            return setOpenViewCart(false)
        }
    };

    return(
        <Drawer
            className="drawer"
            isOpen={true}
            onClose={() => {
                setOpenViewCart(false);
            }}
            size="100%"
            position={Position.BOTTOM}
        >
            <div className={Classes.DRAWER_BODY} style={{ marginBottom: "12em" }}>
                <div style={{ backgroundColor: "#F5F5F5", padding: ".5em", position: "fixed", top: "0px", width: "100%", zIndex: 1 }}>
                    <Button
                        onClick={() => {
                            setOpenViewCart(false);
                        }}
                        icon="arrow-left"
                        style={{ position: "absolute", left: "10px", top: "11px" }}
                        className={`${Classes.MINIMAL} order-from-button`}
                    >
                    {" "}
                    </Button>
                    <h3 style={{marginLeft: "2em", marginRight: "2em", textAlign: "center", fontFamily: "Axiforma-ExtraBold", fontSize: "15px" }}>
                    {" "}
                    Table {table.tableNumber} at {business.businessName}
                    </h3>
                </div>

                <div className={Classes.DIALOG_BODY} style={{ marginTop: "25%" }}>
                    {itemsInCart.map((product: ItemsInCart, index: number) => {
                        let productCostWithDiscount: number[] = [];

                        if (product.discountedPrice) {
                            productCostWithDiscount = [product.quantity * product.discountedPrice];
                            product.selectedModifiers.map((mod: any) => {
                            productCostWithDiscount.push(product.quantity * mod.additionalCost);
                            });
                        }

                        let productWithModCost = [product.quantity * product.price];
                        product.selectedModifiers.map((mod: any) => {
                            productWithModCost.push(product.quantity * mod.additionalCost);
                        });

                        return (
                            <div key={index}>
                                <span>
                                    <p style={{ float: "left", fontSize: "10px" }}>{product.quantity}</p>

                                    {!productCostWithDiscount.length && <p style={{ float: "right", fontSize: "12px" }}>${(productWithModCost.reduce((a, b) => a + b, 0) * 0.01).toFixed(2)}</p>}

                                    {productCostWithDiscount.length ? (
                                        <span>
                                            <p style={{ float: "right", fontSize: "12px" }}>${(productCostWithDiscount.reduce((a, b) => a + b, 0) * 0.01).toFixed(2)} </p>
                                            <p style={{ float: "right", fontSize: "12px", color: "#DB3737" }}>
                                            <s>${(productWithModCost.reduce((a, b) => a + b, 0) * 0.01).toFixed(2)}</s>
                                            </p>
                                        </span>
                                    ) : null}
                                    
                                    <h3 style={{ paddingLeft: "20px", fontSize: "14px", fontFamily: "Axiforma-ExtraBold", marginBottom: "5px" }}> {product.name}</h3>

                                    {product.selectedModifiers.length ? (
                                        <div style={{ paddingLeft: "20px", paddingBottom: ".5em" }}>
                                            {product.selectedModifiers.map((mod: any) => {
                                                return mod.optionNames.map((optionName: string, index: number) => {
                                                    return (
                                                        <span key={index} style={{ marginRight: ".5em", fontSize: "12px" }}>
                                                            {optionName},
                                                        </span>
                                                    );
                                                });
                                            })}
                                        </div>
                                    ) : null}

                                    {product.note &&
                                        <div style={{ paddingLeft: "20px", fontSize: "10px", paddingBottom: ".5em"}}>
                                            {product.note}
                                        </div>
                                    }

                                    <Button onClick={() => removeItem(index)} minimal intent="danger" style={{ marginLeft: "20px", cursor: "pointer", paddingLeft: "0px", fontFamily: "Axiforma-ExtraBold", fontSize: "10.5px" }}>
                                    Remove
                                    </Button>
                                </span>
                                <hr className="hrLine" />
                            </div>
                        );
                    })}
                </div>
            </div>


            {/*success alert for products added to order  */}
            <Dialog
                isOpen={successDialog}
                className="product-modal"
                onClose={() => {
                    setSuccessDialog(false);
                }}
                style={{ width: "90%", textAlign: "center" }}
            >
                <div style={{ fontFamily: "Axiforma-Regular" }}>
                    <div
                        style={{
                            height: "62px",
                            width: "62px",
                            borderRadius: "50%",
                            backgroundColor: "#2FB24C",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: "30px",
                        }}
                    >
                        <img src={CheckIcon} style={{ width: "30px", height: "30px" }} />
                    </div>
                <div style={{ fontSize: "17px", fontFamily: "Axiforma-ExtraBold", marginTop: "5px" }}>Success!</div>

                {successDialog && <p style={{ fontSize: "12px", fontFamily: "Axiforma-Regular" }}>Your order has been sent to the kitchen.</p>}
                </div>
            </Dialog>


            <div style={{ position: "fixed", bottom: "0px", width: "100%", padding: "1em", backgroundColor: "white" }}>
                <Button
                    disabled={existingTab.paymentComplete || loading}
                    onClick={() => {
                        placeDineInOrder();
                    }}
                    style={{ width: "100%", background: "#2FB24C", boxShadow: "none", borderRadius: "100px", position: "relative" }}
                    intent="success"
                    large
                >
                    <span style={{ fontSize: "12px", position: "absolute", left: "20px", top: "15px" }}>
                        {itemsInCart.reduce((totalCount, item)=> {
                            return totalCount + item.quantity;
                        },0)
                        } 
                        <span> items</span>
                    </span>
                    <span style={{ fontSize: "14px", fontFamily: "Axiforma-Bold" }}>Place Order</span>

                    <span style={{ fontSize: "12px", position: "absolute", right: "20px", top: "15px", fontFamily: "Axiforma-Regular" }}>
                        ${(calculateSubtotalInOrder(itemsInCart) * 0.01).toFixed(2)}
                    </span>
                </Button>
            </div>
        </Drawer>
    ) 
}

export default ViewCart
