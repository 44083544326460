import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from './App';
import reportWebVitals from './reportWebVitals';
import "normalize.css"; //required for blueprint js to work with css
import "@blueprintjs/core/lib/css/blueprint.css"; //required for blueprint js to work
import "@blueprintjs/icons/lib/css/blueprint-icons.css"; //required for blueprint icons js to work
import "@blueprintjs/select/lib/css/blueprint-select.css"; // required for blueprint selects to be styled
import './Index.css';
import configureStore from "./configureStore"; //uses axiforma fonts
import {Provider} from 'react-redux';
import {PersistGate} from "redux-persist/integration/react";

Sentry.init({
  dsn: "https://0ea3107eb4a04168adfa85e228e38e77@o509640.ingest.sentry.io/6293857",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});



// Create redux store with history
const initialState = {};

const {store, persistor} = configureStore(initialState);
ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App/>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
