import React, {useEffect, useState} from 'react';
import './index.css';
import firebase from '../config/firebase'
import {Button} from "@blueprintjs/core";
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {Elements} from '@stripe/react-stripe-js';
import CheckOut from './CheckOut';
import {loadStripe, PaymentIntentResult} from '@stripe/stripe-js';
import {
    addGiftCardTransaction,
    fetchSingleGiftCard,
    GiftCardTransaction,
    GiftCardUser,
    TransactionType,
    updateGiftCardUsersDoc
} from '../db/Cards';
import {getStripePaymentSecret} from "../db/GetData";
import {createStructuredSelector} from "reselect";
import {selectBusinessDetails} from "../reducers/global/selectors";
import {connect} from "react-redux";
import {compose} from "redux";
import Header from './Header';
import MobileHeader from './MobileHeader';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY ? process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY : 'test');

const AddMoneyForm = (props:any) => {
    const history = useHistory();
    const [showCheckout, setShowCheckout] = useState<boolean>(false);
    const {cardId}  = useParams<{cardId: string}>();
    const [giftAmount, setGiftAmount] = useState('');
    const [cardData, setCardData] = useState<GiftCardUser>();
    const [paymentClientSecret, setPaymentClientSecret] = useState<string>();
    const location = useLocation();

    //if formyself flow completed
    //if not for myself
    //fetch card
    //fill up form
    //create card and user
    //perform transaction
    //show success

    useEffect(()=>{
        const fetchData = async()=> {
            const response = await fetchSingleGiftCard(cardId);
            if(response.data){
              setCardData(response.data);
            }
        }
        fetchData();
    }, [])

    const addMoney = async() => {
        // if amount is <= sender card balance
        // cut balance from previous card
        // update next card balanace
        // add transaction in db
        const amount  = parseFloat(giftAmount);
      const data = await getStripePaymentSecret(Number(giftAmount), undefined, "Add money payment");
      setShowCheckout(true);
      setPaymentClientSecret(data);
    }

    const addMoneyStripeCallback = async (paymentInfo?: PaymentIntentResult) => {
      if (!paymentInfo) {
        setShowCheckout(false);
        return;
      }
      const amount  = parseFloat(giftAmount);
      const userId  = localStorage.getItem('tango-mobile-userId');
      if(cardData){
        if(amount && amount > 0 && userId)
        {
          const  newCardData: Partial<GiftCardUser> ={balance: cardData.balance + amount,  updatedAt: firebase.firestore.Timestamp.now()};
          //@ts-ignore
          const cardTransationData:GiftCardTransaction = {
            businessId: props.businessDetails.id,
            createdAt: firebase.firestore.Timestamp.now(),
            updatedAt: firebase.firestore.Timestamp.now(),
            deleted: false,
            enabled: true,
            giftCardId: cardData.id,
            transactionType:TransactionType.reload,
            amount,
            //@ts-ignore
            totalBalance: cardData.balance+amount,
            userId,
            orderId:null,
          }
          const response = await Promise.all([updateGiftCardUsersDoc(newCardData, cardData.id), addGiftCardTransaction(cardTransationData)])
          if(response[1].data){
            history.replace('/checkout/success/'+response[1].data);
          }
        }
      }
    }
    if(window.innerWidth <= 960){
      return (
          <div className='gift-cards-mobile'>
            <MobileHeader />
            <div style={{paddingLeft:'80px', paddingRight:'80px', marginTop:'14px', paddingBottom:'14px', borderBottom:'1px solid #F8F8F8'}}><img src={cardData?.imageUrl}  className={"selected-card-image"}/></div> 
                <div className='auth-form-container create-card-form'>
                    <div className='form-title'>Add Money</div> 
                    <div className="textOnInput">
                        <label htmlFor="inputText">Enter Gift Amount</label>
                        <input className="form-control" type="text" value={`$${giftAmount}`} onChange={(e)=> {
                                  const amount = e.target.value.replace('$', '')
                                  setGiftAmount(amount);
                              }}/>
                    </div>
                </div>
            <Button text={"Add"} className="add-money"  disabled={!giftAmount || giftAmount.length === 0} onClick={addMoney}/>
            {
            paymentClientSecret && <Elements stripe={stripePromise} options={{clientSecret: paymentClientSecret}}>
              <CheckOut showModal={showCheckout} closeModal={addMoneyStripeCallback} amount={Number(giftAmount)} paymentClientSecret={paymentClientSecret} paymentInfos={cardData?.paymentInfo}/>
            </Elements>
          }
          </div>
      )
  }
    return (
        <div className="gift-cards-page">
            <Header path="cards"  />
            <div className="body">
                <div className="left-part">
                    <div className="side-nav">
                        <div className="nav-title">The Coop Cards</div>
                        <div className={`side-nav-item  active`}>Balance</div>
                        <div className={`side-nav-item`}>History</div>
                    </div>
                </div>
                <div className="right-part">
                  <div className="select-card-header">
                      <div className="text-center extra-bold f19">Add Money</div>
                      <div className="text-center bold f12">Choose a card to increase its balance</div>
                  </div>
                  <div className="buy-card-form">
                      <img src={cardData?.imageUrl} className={"card-image"}/>
                      <div className="form-container">
                          <div className="input-container">
                              <div>Gift Amount</div>
                              <input value={`$${giftAmount}`} onChange={(e)=> {
                                  const amount = e.target.value.replace('$', '')
                                  setGiftAmount(amount);
                              }}/>
                          </div>
                      </div>
                  </div>
                  <Button text={"Checkout"} className="add-money" disabled={!giftAmount || giftAmount.length === 0} onClick={addMoney} />
                </div>
            </div>
          {/*{console.log(paymentClientSecret)}*/}
          {
            paymentClientSecret && <Elements stripe={stripePromise} options={{clientSecret: paymentClientSecret}}>
              <CheckOut showModal={showCheckout} closeModal={addMoneyStripeCallback} amount={Number(giftAmount)} paymentClientSecret={paymentClientSecret} paymentInfos={cardData?.paymentInfo}/>
            </Elements>
          }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
  businessDetails: selectBusinessDetails()
});
const withConnect = connect(mapStateToProps, null);

export default compose(withConnect)(AddMoneyForm);
