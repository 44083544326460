import React, {ChangeEvent, useState} from 'react';
import './index.css';
import {Button, Dialog, Icon} from "@blueprintjs/core";
import PhoneInput from "../../components/PhoneInput";
import GiftCardBackSide from '../../assets/images/gift-card-backside.png';

interface Props{
    isOpen: boolean;
    closeModal: () => void;
    openModal: (modalName: string) => void;
    loginWithPhoneNumber:(phoneNumber: string) => void;
}
const LoginModal = ({isOpen, closeModal, openModal, loginWithPhoneNumber}:Props) => {
    const [phone, setPhone] = useState<string>('');
    const [showGiftCardIdentificaton, setShowGiftCardIdentificaton] = useState<boolean>(false);

    return (
        <Dialog
            isOpen={isOpen}
            hasBackdrop
            onClose={()=> {
                setShowGiftCardIdentificaton(false)
                closeModal()
            }}
            className="gift-card-login-modal"
            portalClassName="gift-card-modal-portal"
        >
            <div className="gift-card-modal-body">
                {
                    !showGiftCardIdentificaton  ?
                    <>
                    <div className="modal-title">Verify your gift card</div>
                        <div className="modal-subtitle">Enter your phone number to easily access your card balance</div>
                        <Icon icon="cross" className="close-icon" iconSize={30} color={"#231F20"} onClick={()=> {
                             setShowGiftCardIdentificaton(false)
                             closeModal()
                        }}/>
                        <div style={{display:'flex', marginBottom:'20px'}}>
                            <select className={"gift-card-modal-select"}>
                                <option>+1</option>
                            </select>
                            <PhoneInput
                                className="gift-card-modal-input"
                                phoneNumber={phone}
                                onChange={(number)=> setPhone(number) }
                                style={{width:'100%'}}
                            />
                        </div>
                        <Button text="Continue" className="gift-card-login-button" onClick={()=> {
                            loginWithPhoneNumber(phone.substring(0, 0) + '+1' + phone.substring(0))
                        }}/>
                        <div className='or-divider'>Or</div>
                        <div className='modal-bottom-link' onClick={()=> setShowGiftCardIdentificaton(true)}>type in your gift card code</div>
                    </>
                    :
                    <>
                    <div className="modal-title">Enter the card identification number</div>
                        <div className="modal-subtitle">This is located on the back of the card directly underneath the QR code</div>
                        <Icon icon="cross" className="close-icon" iconSize={30} color={"#231F20"} onClick={()=> {
                             setShowGiftCardIdentificaton(false)
                             closeModal()
                        }}/>
                        <div style={{marginBottom:'20px', textAlign:'center'}}>
                            <img src={GiftCardBackSide} alt="" />
                            <div className='code-input'>
                            ENTER YOUR CODE
                            </div>
                            <PhoneInput
                                className="gift-card-modal-input"
                                phoneNumber={phone}
                                onChange={(number)=> setPhone(number) }
                                style={{width:'100%'}}
                            />
                        </div>
                        <Button text="Continue" className="gift-card-login-button"/>
                        <div className='bottom-back' onClick={()=> setShowGiftCardIdentificaton(false)}>BACK</div>
                    </>
                }
            </div>
        </Dialog>
    )
}
export  default LoginModal;