import { Button, Classes, Collapse, CollapsibleList, Dialog, Drawer, InputGroup, MenuItem, OverflowList, Position } from "@blueprintjs/core";
import { Business, CustomerModel, ItemsInCart, Order, ProductsPaid, SelectedModifiers, Tab, TangoBusinessSettings, User } from "../../types/types";
import CheckIcon from "../../assets/images/check.png";
import { useEffect, useState } from "react";
import { calculateSubtotalInOrder, calculateTaxOnOrder, createFirebaseJWT } from "../../utils/functions";
import { toggleDineInItemToPay, updateTab, expireTableSession, updateOrder} from "../../db/AddData";
import firebase from "firebase";
import { closeTableSession } from "../../db/Order";
import { fetchTab, fetchUser } from "../../db/GetData";
import Card from "../../GiftCards/Card";
import { table } from "console";
import { Select } from "@blueprintjs/select";
const db = firebase.firestore();

interface ViewCheckProps {
    business: Business;
    businessSettings:TangoBusinessSettings;
    deliverectToken: string;
    existingOrder: Order;
    existingTab: Tab;
    taxRate: number
    user: User;
    setOpenViewCheck: (openViewCheck: boolean) => void;
    setOpenPayScreen: (shouldShow: boolean) => void;
    setPaymentTotals:(paymentTotals:any)=>void
    setProductsPaidForTab:(productsPaidForTab:ItemsInCart[])=>void
    setExpiredTableSession: (redirectLink: string) => void;
}

const ViewCheck = ({ business, businessSettings, deliverectToken, existingOrder, existingTab, taxRate, user, setOpenViewCheck, setOpenPayScreen, setPaymentTotals,setProductsPaidForTab, setExpiredTableSession}: ViewCheckProps) => {

    const sessionId = existingOrder.sessionId
    const [openCustomTip, setOpenCustomTip] = useState(false);
    const [openProductInOrder, setOpenProduct] = useState("");
    const [openCollapseProducts, setOpenCollapseProducts] = useState(false);

    const [tax, setTax] = useState<any>(0.0);//default to 0 until they select items

    const [tipSelection, setTip] = useState<number | string>(businessSettings?.onlineTipPresetPercentages[1] ? businessSettings?.onlineTipPresetPercentages[1] : 20);

    // const [tipSelection, setTip] = useState<any>(0.0);//default to 0 until they select items
    const [tipDollarAmount, setTipDollarAmount] = useState(0);
    const [showTipBar, setShowTipBar] = useState(true);

    const [productsInCollapse, setProducstInCollapse] = useState([]);

    const [selectedItemsCost, setSelectedItemsCost] = useState(0);
    const [selectedItemCount, setSelectedItemCount] = useState(0);


    const [alreadyPaidProducts, setPaidProducts] = useState<ProductsPaid[]>([]);
    const [selectedItemsForPayment, setSelectedItemsForPayment] = useState<any[]>([]); //this comes from table session document

    const [productOrderIDsToPayFor, setProductOrderIDsToPayFor] = useState<any>([]);
    const [itemsSelectedByOthers, setItemsSelectedByOthers] = useState(['']); //this is in table session document


    const [customItemsToPayFor, setCustomItemsToPayFor] = useState([]);
    const [subItemsToPayFor, setSubItemsToPayFor] = useState([]);

    const [allProductsInAllOrders, setAllProductsInAllOrders] = useState<any>([]);

    

    //listen for changes in all orders with the tab id
    useEffect(() => {
        console.log('view check', '\nsessionID', sessionId, '\nexistingTab', existingTab)
        
        //reload user to be sure cards propogate after a prior payment
        if( !user.stripe || ( business.location.country === "Canada" || business.location.country === "CA" && user.stripeCanada.cards.length < 1) || (business.location.country === "US" || business.location.country === "USA" && user.stripe.cards.length < 1)){ 
            console.log('user has cards?')
            reloadUser()
        }

        var allItems: any[] = []
        const ordersListener = db.collection("Orders")
        .where("tabId", "==", existingTab.id)
        .onSnapshot((snapshot:any) => {
            if (snapshot.docs) {
                //clear existing array
                // setAllProductsInAllOrders([])
                snapshot.docs.forEach((doc:any) => {
                    const order:Order = doc.data();
                    order.products.forEach((product)=>{
                        allItems.push(product)
                    })
                });
                setAllProductsInAllOrders(allItems)
                allItems = [] //reset back to empty 
            }
        });
        return () => {
            console.log('close order listener after close view check')
            ordersListener()
        };

    },[existingTab])

        
    //listen for changes in table session to show items selected by patrons 
    //wait for all products in all orders to propogat
    useEffect(() => {
        const tableSessionListener = db.collection("TableSessions")
        .where("sessionId", "==", sessionId)
        .onSnapshot((snapshot:any) => {
            if (snapshot.docs) {
                snapshot.docs.forEach((doc:any) => {
                    const session = doc.data();
                    if (session && !!session.isExpired) {
                        console.log('session is expired')
                        //setIsSessionExpired(true);
                    }

                    const currentSelections:any[] = []
                    const otherUserSelections:string[] = []
                    
                    session.selectedItemsForPayment.map((item:any)=>{
                        if(item.userId === user.id){
                            currentSelections.push(item)
                        }else{
                            otherUserSelections.push(item)
                        }
                    })
                    setItemsSelectedByOthers(otherUserSelections)         
                    setSelectedItemsForPayment(currentSelections);
                    // console.log('set slecteded items success -->', currentSelections, 'all products ? ', allProductsInAllOrders)
                    calculateSelectedItemsPaymentAmount(currentSelections)
                    const itemIds = currentSelections.map((item:any)=>{
                        return item.productOrderId
                    })
                    setProductOrderIDsToPayFor(itemIds)
                });
            }
        });
        return () => {
            tableSessionListener()
        };
    },[allProductsInAllOrders])


    //listen for changes in tab to show items that have been paid for 
    //runs after allProductsInAllOrders propogates to check for completed payment
    useEffect(() => {
        const tabListener = db.collection("Tabs")
        .where("id", "==", existingOrder.tabId)
            .onSnapshot((snapshot:any) => {
                if (snapshot.docs) {
                    snapshot.docs.forEach((doc:any) => {

                        if (snapshot.empty) {
                            console.log('not tab found view check')
                        }
                        const tab:Tab = doc.data();

                        console.log('tab in view check, tab listener', tab)

                        const allProductsPaid = tab.customer.map((customer:CustomerModel)=>{
                            return customer.productsPaid
                        })

                        setPaidProducts(allProductsPaid.flat(1))

                        console.log('allProductsInAllOrders', allProductsInAllOrders, 'all products paid?', allProductsPaid.flat(1))

                        //another user may get alert and set tab to complete
                        if(tab.paymentComplete){
                            alert('All products have been paid for and your tab has been closed. You will now be redirected to your receipt.')
                            return finishTableSession();
                        }
                        if (allProductsInAllOrders.length > 0 && allProductsPaid.flat(1).length === allProductsInAllOrders.length){
                            alert('All products have been paid for and your tab has been closed. You will now be redirected to your receipt.')
                            return finishTableSession();
                        }

                    });
                }
            });

            return () => {
                tabListener();
            }
    },[allProductsInAllOrders])

    //recalc totals if tip changes
    useEffect(() => {
        calculateSelectedItemsPaymentAmount(selectedItemsForPayment)
    }, [tipDollarAmount]);

    
    const finishTableSession = async() =>{
        console.log('finishing tab')
        const newTabData = {
            paymentComplete: true,
            'status.data': 'Tab is closed',
            'status.open': false,
            updatedAt: new Date()
        }

        const allOrders = await db.collection('Orders').where('tabId', '==', existingTab.id).get();
        allOrders.forEach(async(doc) => {
            updateOrder(doc.id, {completed:true, updatedAt: new Date()}, await createFirebaseJWT())
        })

        const expired = await expireTableSession(sessionId ? sessionId: '', await createFirebaseJWT())
        const closedTab = await updateTab(existingTab.id, newTabData, await createFirebaseJWT())
        if(closedTab && expired){
            console.log('tab closed and session expired', existingOrder.id)
            setExpiredTableSession(`https://run-tango.web.app/order/${existingOrder.id}`)
            setOpenViewCheck(false)
            return window.location.replace(`https://run-tango.web.app/order/${existingOrder.id}`)
        }else{
            console.log('error clsoing tab and session')
            alert('Please let your server know your tab has been closed.')
            return setOpenViewCheck(false)
        }
    }

    const handleApplyTip = (dollar: boolean, tipSelected: number) => {
        // console.log('apply tip ->', tipSelected, selectedItemsForPayment)
        if(dollar){    
            setTipDollarAmount(tipSelected * 100); 
        }else{
            setTipDollarAmount(selectedItemsCost * tipSelected * 0.01); 
        }
    };

    const handleOpenCollapseProductInOrder = (quantity: number, product: any) => {
        var data = [];
        for (var i = 0; i < quantity; i++) {
          data.push(product);
        }
        //@ts-ignore
        setProducstInCollapse(data);
    };

    const selectAllItemsToPay = async () => {
        //if current user has already selected global item or sub item remove all items from select all (toggle off)
        if (productOrderIDsToPayFor.length || customItemsToPayFor.length || subItemsToPayFor.length) {
          return selectedItemsForPayment.map(async(selectedItem: any) => {
            if (selectedItem.userId === user.id) {
              return toggleDineInItemToPay(sessionId, selectedItem, false, user.id, await createFirebaseJWT()); //table session listener will update the checkmarks in browser
            }
          });
        }
        //else add all items that havent been selected (toggle on)
        else {
          let productsToSelect: any[] = [];
    
            existingOrder.products.forEach((product: any) => {
              let paidOrSelectedProductIds: any[] = [];
              alreadyPaidProducts.forEach((paidProduct: any) => {
                if (parseInt(paidProduct.productOrderId.charAt(0)) === paidProduct.quantity) {
                  paidOrSelectedProductIds.push(paidProduct.productOrderId);
                }
              });
    
              itemsSelectedByOthers.forEach((item: any) => {
                if (parseInt(item.productOrderId.charAt(0)) === item.quantity) {
                  paidOrSelectedProductIds.push(item.productOrderId);
                }
              });
    
              if (!paidOrSelectedProductIds.includes(product.productOrderId)) {
                productsToSelect.push(product);
              }
            });
    
          productsToSelect.map(async(product: any) => {
            let item = {
              productOrderId: product.productOrderId,
              quantity: product.quantity,
              userId: user.id,
              userColor: user.color,
              userName: user.name ? user.name : "Anonymous User",
              subItemIndexes: [null],
            };
    
            alreadyPaidProducts.map((paidProduct: any) => {
              if (paidProduct.productOrderId === item.productOrderId) {
                let originalQuantity = parseInt(paidProduct.productOrderId.charAt(0));
                item.quantity = paidProduct.productOrderId.charAt(0) - paidProduct.quantity;
                item.subItemIndexes = [];
                for (let i = 0; i < originalQuantity; i++) {
                  if (i > paidProduct.quantity - 1) {
                    //@ts-ignore
                    item.subItemIndexes.push(i);
                  }
                }
              }
            });
    
            itemsSelectedByOthers.map((otherItem: any) => {
              if (otherItem.productOrderId === item.productOrderId) {
                item.quantity = otherItem.productOrderId.charAt(0) - otherItem.quantity;
                item.subItemIndexes = [];
                for (let i = 0; i < otherItem.productOrderId.charAt(0); i++) {
                  if (!otherItem.subItemIndexes.includes(i + 1)) {
                    //@ts-ignore
                    item.subItemIndexes.push(i + 1);
                  }
                }
              }
            });
    
            return toggleDineInItemToPay(sessionId, item, true, user.id, await createFirebaseJWT());
          });
        }
    };

    const toggleItemSelectedForPayment = async (item: any, subItem: boolean, subItemIndex: number | null) => {

        const itemToToggle = {
          productOrderId: item.productOrderId,
          quantity: item.quantity,
          userId: user.id,
          userColor: user.color,
          userName: user.name ? user.name : "Anonymous User",
          subItemIndexes: [subItemIndex],
        };
    
        if (subItem) {
          console.log("toggle sub item selected", itemToToggle);
          const selectedItems = selectedItemsForPayment.filter((selectedItem:any) => selectedItem.productOrderId === itemToToggle.productOrderId && selectedItem.userId === user.id);
    
          if (selectedItems.length) {
            console.log('item selected may have sub items')
            for (let i = 0; i < selectedItems.length; i++) {
              let item = selectedItems[i];
    
              if (item.subItemIndexes.includes(subItemIndex) && item.quantity === 1) {
                await toggleDineInItemToPay(sessionId, item, false, user.id, await createFirebaseJWT());
               
              } else if (item.subItemIndexes.includes(null)) {
                await toggleDineInItemToPay(sessionId, item, false, user.id,await createFirebaseJWT());
                item.quantity = 1;
                item.subItemIndexes = [subItemIndex];
                await toggleDineInItemToPay(sessionId, item, true, user.id, await createFirebaseJWT());
          
    
              } else if (item.subItemIndexes.includes(subItemIndex) && item.quantity > 1) {
                await toggleDineInItemToPay(sessionId, item, false, user.id, await createFirebaseJWT());
                item.quantity = item.quantity - 1;
                for (let i = 0; i < item.subItemIndexes.length; i++) {
                  if (item.subItemIndexes[i] === subItemIndex) {
                    item.subItemIndexes.splice(i, 1);
                  }
                }
                await toggleDineInItemToPay(sessionId, item, true, user.id, await createFirebaseJWT());
    
              } else {
                if (item.userId === user.id) {
                  await toggleDineInItemToPay(sessionId, item, false, user.id, await createFirebaseJWT());
    
                  item.quantity = item.quantity + 1;
                  if (item.quantity === parseInt(item.productOrderId.charAt(0))) {
                    itemToToggle.subItemIndexes = [null];
                    await toggleDineInItemToPay(sessionId, itemToToggle, true, user.id, await createFirebaseJWT());
             
                  } else {
                    item.subItemIndexes.push(subItemIndex);
                    await toggleDineInItemToPay(sessionId, item, true, user.id, await createFirebaseJWT());
    
                  }
                } else {
                  itemToToggle.quantity = 1;
                  await toggleDineInItemToPay(sessionId, itemToToggle, true, user.id, await createFirebaseJWT());
                }
              }
            }
          } else {
            itemToToggle.quantity = 1;
            await toggleDineInItemToPay(sessionId, itemToToggle, true, user.id, await createFirebaseJWT());
          }
        } else {
          console.log("toggle global item", selectedItemsForPayment);
    
          const selectedItems = selectedItemsForPayment.filter((selectedItem:any) => selectedItem.productOrderId === itemToToggle.productOrderId);
          if (selectedItems.length) {
            console.log('selected items',selectedItems)
            return selectedItems.map(async(item:any) => {
              if (item.productOrderId === itemToToggle.productOrderId && item.quantity === itemToToggle.quantity) {
                console.log("remove", item);
    
                await toggleDineInItemToPay(sessionId, item, false, user.id, await createFirebaseJWT());
               
              } 
               if (item.productOrderId === itemToToggle.productOrderId && item.subItemIndexes.length) {
                console.log("remove item");
                //remove the sub item selected
                await toggleDineInItemToPay(sessionId, item, false, user.id, await createFirebaseJWT());       
              }
            });
          } else {
            console.log("add item: ", itemToToggle);
            await toggleDineInItemToPay(sessionId, itemToToggle, true, user.id, await createFirebaseJWT());
          
          }
        }
    };

    const calculateSelectedItemsPaymentAmount = (selectedItemsForPayment:any[]) =>{

        const currentSelections: ItemsInCart[] = []
        selectedItemsForPayment.map((selectedItem:any)=>{
           return allProductsInAllOrders.map((product:ItemsInCart)=>{
                if( (selectedItem.userId === user.id ) && (product.productOrderId === selectedItem.productOrderId)){
                    // console.log('found item to pay for', selectedItem, 'vs', product)
                    currentSelections.push(product)
                }
            })
        })

        const quantities = currentSelections.map((item:ItemsInCart)=>{
            return item.quantity
        })

        if(quantities.length < 1){
            setTip(0.0)
            setTipDollarAmount(0.0)
            setTax(0)
            setOpenCustomTip(false)
            // setShowTipBar(false)
        }
      
        setProductsPaidForTab(currentSelections)
        setSelectedItemCount(quantities.reduce((a, b) => a + b, 0))
        setSelectedItemsCost(calculateSubtotalInOrder(currentSelections))
        setTax(calculateTaxOnOrder(currentSelections, taxRate))

        //pass totals back to index.tsx
        console.log('tipDollarAmount', tipDollarAmount)
        setPaymentTotals({
            subtotal:calculateSubtotalInOrder(currentSelections), 
            tax: calculateTaxOnOrder(currentSelections, taxRate), 
            tip: Math.round(tipDollarAmount)
        })
    }

    const reloadUser = async () => {
        try {
            const reloadedUser = await fetchUser();
            if (reloadedUser) {
                console.log("reloaded User --->", reloadedUser);
                user = reloadedUser
            }
        } catch (e) {
            console.error("error getting user", e);
        }
    };


    // const moreOptions = ['Select All', 'Unselect All', 'Unselect Al', 'Uselect All']; 
    // const renderMoreOptions = (option: string) => {
    // console.log('render more', option)
    // return (
    //     <MenuItem
    //     key={option}
    //     onClick={() =>{
    //         console.log('clicked', option)
    //         selectMoreOption(option)
    //     }} //click menu item set status
    //     text={option}
    //     />
    // );
    // };

    // const selectMoreOption = (item: any) => {
    // console.log('selectMoreOption', item)     
    // };

    return (
        <div>
            <Drawer
                isOpen={true}
                onClose={() => {
                    setOpenViewCheck(false);
                }}
                size="100%"
                position={Position.BOTTOM}
                className="drawer"
            >
            <div id="viewProductDrawer" className={Classes.DRAWER_BODY}>
                <div style={{ backgroundColor: "#F5F5F5", padding: ".5em", position: "fixed", top: "0px", width: "100%"}}>
                    
                    {businessSettings.allowPatronDineInOrders &&
                        <Button
                            onClick={() => {
                                setOpenViewCheck(false);
                            }}
                            icon="arrow-left"
                            style={{ position: "absolute", left: "10px", top: "11px" }}
                            className={`${Classes.MINIMAL} order-from-button`}
                        >
                            {" "}
                        </Button>
                    }

                    <h3 style={{ textAlign: "center", fontFamily: "Axiforma-ExtraBold", fontSize: "15px" }}>Check</h3>
                
                </div>


                {/* SHOW ALL PRODCUTS IN ALL ORDERS */}
                <div style={{ marginTop: "15%", paddingLeft: "1em", paddingRight: "1em", overflowY: "scroll", height: "90%" }}>
                
                    {allProductsInAllOrders.length === 0 &&
                        <div  style={{marginTop:'20%', textAlign:'center', marginLeft:'10%', marginRight:'10%'}}>
                            <div style={{ fontSize: "18px", fontFamily: "Lato-Bold", color: "#888888", marginTop: "6px", marginBottom: "25px" }}>
                                Table {existingOrder.tableNumber}
                                <br/><br/>
                                {businessSettings.allowPatronDineInOrders ?
                                    <span>     
                                        Please add items to your order
                                    </span>
                                    :
                                    <span>     
                                        Your server will be with you shortly
                                    </span>
                                }
                            </div>
                            <img style={{width:'30%', paddingTop:'5%',  paddingBottom:'25%'}} src={business.profileImageUrl}></img>
                        </div>
                    }   
                    
        
                    {allProductsInAllOrders.length > 0 && allProductsInAllOrders.map((product: any, index: number) => {
                        let productCostWithDiscount: number[] = [];

                        if (product.discountedPrice) {
                            productCostWithDiscount = [product.quantity * product.discountedPrice];
                            product.selectedModifiers.map((mod: SelectedModifiers) => {
                                productCostWithDiscount.push(product.quantity * mod.additionalCost);
                            });
                        }

                        let productWithModCost = [product.quantity * product.price];
                        product.selectedModifiers.map((mod: SelectedModifiers) => {
                            productWithModCost.push(product.quantity * mod.additionalCost);
                        });

                        return (
                            <div key={product.productOrderId} style={{ marginTop: "3em", position: "relative" }}>
                                {!productCostWithDiscount.length && <p style={{ float: "right", fontSize: "12px", paddingRight: "60px" }}>${(productWithModCost.reduce((a, b) => a + b, 0) * 0.01).toFixed(2)}</p>}

                                {productCostWithDiscount.length ? 
                                    <span>
                                        <p style={{ float: "right", fontSize: "12px", paddingRight: "60px" }}>${(productCostWithDiscount.reduce((a, b) => a + b, 0) * 0.01).toFixed(2)} </p>
                                        <p style={{ float: "right", fontSize: "12px", color: "#DB3737" }}>
                                        <s>${(productWithModCost.reduce((a, b) => a + b, 0) * 0.01).toFixed(2)}</s>
                                        </p>
                                    </span>
                                    : null
                                }

                                <span>
                                <p style={{ float: "left", fontSize: "10px" }}>
                                    {product.quantity} {product.quantity > 1 && <span>x</span>}
                                </p>

                                <div onClick={async() => {
                                    if (itemsSelectedByOthers.filter((item:any) => item.productOrderId === product.productOrderId).length > 0) {                   
                                        if(product.quantity > 1){
                                            return
                                            handleOpenCollapseProductInOrder(product.quantity, product);
                                            setOpenProduct(product.productOrderId);
                                            return setOpenCollapseProducts(true);
                                        }else{
                                            return console.log('do nothing since product only has one item')
                                        }
                                    }

                                    const customPaidFor = alreadyPaidProducts.filter((paidProduct:any) => paidProduct.productOrderId === product.productOrderId);
                                        if (customPaidFor.length) {
                                            if (product.quantity > 1) {
                                                return

                                                console.log("open collapse since sub items selected");
                                                handleOpenCollapseProductInOrder(product.quantity, product);
                                                setOpenProduct(product.productOrderId);
                                                setOpenCollapseProducts(true);
                                            } else {
                                                return console.log("do nothing");
                                            }
                                        }

                                        // for (let i = 0; i < customItemsToPayFor.length; i++) {
                                        //     if (customItemsToPayFor[i].productOrderId === product.productOrderId) {
                                        //         customItemsToPayFor.splice(i, 1);
                                        //         console.log("removed custom item to pay for since selecting all");
                                        //     }
                                        // }
                                        // for (let i = 0; i < subItemsToPayFor.length; i++) {
                                        //     if (subItemsToPayFor[i].productOrderId === product.productOrderId) {
                                        //         subItemsToPayFor.splice(i, 1);
                                        //         console.log("remove sub items from tableSession");
                                        //         return toggleItemSelectedForPayment(product, false, null);
                                        //     }
                                        // }

                                        if (productOrderIDsToPayFor.includes(product.productOrderId)) {
                                            for (let i = 0; i < productOrderIDsToPayFor.length; i++) {
                                                if (productOrderIDsToPayFor[i] === product.productOrderId) {
                                                    productOrderIDsToPayFor.splice(i, 1);
                                                    setProductOrderIDsToPayFor([...productOrderIDsToPayFor]);
                                                    console.log("remove global item from tableSession selected item for payment");
                                                    toggleItemSelectedForPayment(product, false, null);
                                                    // toggleGlobalItemForPayment(sessionId, product, user, false, await createFirebaseJWT())
                                                    break;
                                                }
                                            }
                                        } else {
                                            if (!alreadyPaidProducts.includes(product.productOrderId)) {
                                                setProductOrderIDsToPayFor([...productOrderIDsToPayFor, product.productOrderId]);
                                                console.log("add global item to tableSession selected item for payment");
                                                toggleItemSelectedForPayment(product, false, null);
                                                // toggleGlobalItemForPayment(sessionId, product, user, true, await createFirebaseJWT())
                                            }
                                        }
                                    }}
                                >
                                {/* @ts-ignore */}
                                {!alreadyPaidProducts.filter((paidProduct:any) => 
                                    paidProduct.productOrderId === product.productOrderId && paidProduct.quantity === product.quantity).length > 0 ? (
                                    //already selected by someone checkmark
                                    itemsSelectedByOthers.filter((item:any) => item.productOrderId === product.productOrderId && item.quantity === product.quantity).length > 0 ? (
                                        itemsSelectedByOthers.map((item: any) => {
                                        if (item.productOrderId === product.productOrderId && item.quantity === product.quantity) {
                                            return (
                                                <div key={product.productOrderId}>
                                                    <div
                                                    style={{
                                                        height: "30px",
                                                        width: "30px",
                                                        backgroundColor: item.userColor,
                                                        borderRadius: "50%",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        position: "absolute",
                                                        right: "22px",
                                                        top: "-10px",
                                                    }}
                                                    >
                                                    <img src={CheckIcon} style={{ height: "10px", width: "10px" }} />
                                                    </div>

                                                    <p style={{ fontSize: ".9em", color: "grey", position: "absolute", bottom: "2px", right: "15px" }}>{item.userName}</p>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                        })
                                    ) : (
                                        <div
                                            style={{
                                                height: "30px",
                                                width: "30px",
                                                backgroundColor: productOrderIDsToPayFor.includes(product.productOrderId) ? user.color : "rgb(245, 245, 245)",
                                                borderRadius: "50%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                position: "absolute",
                                                right: "22px",
                                                top: "-10px",
                                            }}
                                        >
                                        {productOrderIDsToPayFor.includes(product.productOrderId) ? 
                                            <img src={CheckIcon} style={{ height: "10px", width: "10px" }} /> : ""
                                        }
                                        </div>
                                    )
                                    ) : (
                                    <span>
                                        {alreadyPaidProducts.map((paidProduct: any, index: number) => {
                                            if (product.productOrderId === paidProduct.productOrderId) {
                                                return (
                                                <span key={index}>
                                                    <div
                                                    style={{
                                                        height: "30px",
                                                        width: "30px",
                                                        backgroundColor: "darkgrey",
                                                        borderRadius: "50%",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        position: "absolute",
                                                        right: "22px",
                                                        top: "-10px",
                                                    }}
                                                    >
                                                    <img src={CheckIcon} style={{ height: "10px", width: "10px" }} />
                                                    </div>

                                                    <span
                                                        style={{
                                                            color: "grey",
                                                            position: "absolute",
                                                            right: "0px",
                                                            paddingRight: "2.5em",
                                                            paddingTop: "2em",
                                                            fontSize: ".8em",
                                                        }}
                                                    >
                                                    Paid 
                                                    {/* {paidProduct.customerName} */}
                                                    </span>
                                                </span>
                                                );
                                            }
                                        })}
                                    </span>
                                    )}
                                </div>

                                <h3 style={{ paddingLeft: "20px", fontSize: "14px", fontFamily: "Axiforma-ExtraBold", marginBottom: "5px" }}> {product.name}</h3>

                                {product.selectedModifiers.length ? 
                                    <div style={{ paddingLeft: "20px", paddingBottom: ".5em" }}>
                                        {product.selectedModifiers.map((mod: any) => {
                                            return mod.optionNames.map((optionName: string, index:number) => {
                                            return (
                                                    <span key={index} style={{ marginRight: ".5em", fontSize: "12px" }}>
                                                        {optionName},
                                                    </span>
                                                );
                                            });
                                        })}
                                    </div>
                                    : 
                                    null
                                }
                                </span>

                                {product.note &&
                                <div style={{ paddingLeft: "20px", fontSize: "10px" }}>
                                    {product.note}
                                </div>
                                }

                                {product.quantity > 1 && 
                                    <div
                                        onClick={() => {
                                            return 
                                        if (alreadyPaidProducts.includes(product.productOrderId)) {
                                            return console.log("already paid");
                                        } else {
                                            if (product.productOrderId === openProductInOrder) {
                                            return setOpenCollapseProducts(!openCollapseProducts);
                                            } else {
                                            setOpenProduct(product.productOrderId);
                                            handleOpenCollapseProductInOrder(product.quantity, product);
                                            setOpenCollapseProducts(true);
                                            }
                                        }
                                        }}
                                    >
                                        {/* <span style={{ fontSize: ".9em" }}>
                                        {" "}
                                        <Button minimal small icon="chevron-right"></Button> See individual items
                                        </span> */}

                                    </div>
                                }

                                {/* OPEN SUB ITEMS */}
                                <Collapse isOpen={openCollapseProducts && product.productOrderId === openProductInOrder}>
                                {productsInCollapse.map((product: any, index: number) => {
                                    let priceToRender = product.price;
                                    product.selectedModifiers.forEach((mod: any) => {
                                        priceToRender = priceToRender + mod.additionalCost;
                                    });
                                    return (
                                    <div key={index}>
                                        {openCollapseProducts && alreadyPaidProducts.filter((paidProduct:any) => paidProduct.productOrderId === product.productOrderId && paidProduct.quantity > index).length > 0 ? 
                                        <div style={{ fontSize: ".8em", marginLeft: "3em", marginTop: "1em" }}>
                                            <span>1x {product.name}</span>
                                            <span
                                            style={{
                                                float: "right",
                                                marginRight: "3em",
                                                marginLeft: "1em",
                                                height: "18px",
                                                width: "18px",
                                                backgroundColor: "darkgrey",
                                                borderRadius: "50%",
                                                alignItems: "center",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                            >
                                            <img src={CheckIcon} style={{ height: "10px", width: "10px" }} />
                                            </span>
                                            <span style={{ float: "right" }}>${(priceToRender * 0.01).toFixed(2)}</span>
                                            <br />
                                        </div>
                                        : 
                                        <div style={{ fontSize: ".8em", marginLeft: "3em", marginTop: "1em" }}>
                                            {itemsSelectedByOthers.filter((item:any, itemsSelectedIndex:number) => item.productOrderId === product.productOrderId && (item.subItemIndexes.includes(index) || item.subItemIndexes.includes(null))).length > 0 ? 
                                            //SUB ITEM SELECTED BY OTHER PERSON
                                            itemsSelectedByOthers.map((item: any, itemsSelectedIndex:number) => {
                                                if (item.productOrderId === product.productOrderId && (item.subItemIndexes.includes(index) || item.subItemIndexes.includes(null))) {
                                                return (
                                                    <span key={itemsSelectedIndex}>
                                                        <span>1x {product.name}</span>
                                                        <span
                                                            style={{
                                                                float: "right",
                                                                marginRight: "3em",
                                                                marginLeft: "1em",
                                                                height: "18px",
                                                                width: "18px",
                                                                backgroundColor: item.userColor,
                                                                borderRadius: "50%",
                                                                alignItems: "center",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                        <img src={CheckIcon} style={{ height: "10px", width: "10px" }} />
                                                        </span>
                                                    </span>
                                                );
                                                }
                                            })
                                            : 
                                                <span>
                                                    <span
                                                        onClick={() => {
                                                            //@ts-ignore
                                                            if (!itemsSelectedByOthers.filter((item) => item.productOrderId === product.productOrderId && (item.subItemIndexes.includes(index) || item.subItemIndexes.includes(null))).length > 0) {
                                                            toggleItemSelectedForPayment(product, true, index);
                                                            }
                                                        }}
                                                    >
                                                    1x {product.name}
                                                    </span>

                                                    <span
                                                    onClick={() => {
                                                        toggleItemSelectedForPayment(product, true, index);
                                                    }}
                                                    style={{
                                                        float: "right",
                                                        marginRight: "3em",
                                                        marginLeft: "1em",
                                                        height: "18px",
                                                        width: "18px",
                                                        backgroundColor: productOrderIDsToPayFor.includes(product.productOrderId) || subItemsToPayFor.filter((subItem:any) => subItem.productOrderId === product.productOrderId && subItem.index === index).length > 0 ? user.color : "rgb(245, 245, 245)",
                                                        borderRadius: "50%",
                                                        alignItems: "center",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }}
                                                    >
                                                    {/* @ts-ignore */}
                                                    {itemsToPayFor.includes(product.productOrderId) || subItemsToPayFor.filter((subItem:any) => subItem.productOrderId === product.productOrderId && subItem.index === index).length > 0 ? <img src={CheckIcon} style={{ height: "10px", width: "10px" }} /> : ""}
                                                    </span>
                                                </span>
                                            }
                                            <span style={{ float: "right" }}>${(priceToRender * 0.01).toFixed(2)}</span>
                                            <br />
                                        </div>
                                        }
                                    </div>
                                    );
                                })}
                                </Collapse>

                                <hr className="hrLine" style={{ marginTop: "1.5em", width: "100%" }} />
                            </div>
                        )
                    })}
                    
           

                    <div style={{ width: "100%", paddingLeft: "1.5em", paddingRight: "1.5em", paddingTop: "1em", backgroundColor: "white", marginBottom: "45%" }}>


                        {/* ORDER TOTALS */}               
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                            <div>
                                Tip
                                {/* <span 
                                    style={{ color: "#4587B2", fontSize: "14px", fontFamily: "Lato-Semibold", marginLeft: "10px", cursor: "pointer" }} onClick={() => {
                                        if(!selectedItemCount || selectedItemCount < 1){
                                            return 
                                        }
                                        setShowTipBar(!showTipBar)
                                    }}>
                                    Change
                                </span> */}
                            </div>
                            <div>${(tipDollarAmount * 0.01).toFixed(2)}</div>
                        </div>
                        
                        {/* TIP SELECTION */}
                        <Dialog
                            // icon="add"
                            isOpen={openCustomTip}
                            onClose={() => setOpenCustomTip(false)}
                            title="Custom Tip Amount"
                            style={{ width: "85%" }}
                        >
                            <div style={{ padding: "2em" }}>
                                <InputGroup
                                onChange={(e: any) => {
                                    //make sure tip is positive #
                                    if (e.currentTarget.value.includes("-")) {
                                        setTipDollarAmount(e.target.value.replace("-", "") * 100);
                                    } else {
                                        setTipDollarAmount(Math.round(e.target.value * 100));
                                    }
                                    // @ts-ignore
                                    setTip("Custom");
                                }} //multiply by 100 to save cents
                                    leftIcon="dollar"
                                    large
                                    id="customTipValue"
                                    placeholder="5.00"
                                    type="number"
                                    //   minimum={0}
                                    style={{ width: "50%" }}
                                />
                                <br />
                                <hr className="hrLine" />
                                <br />
                                <Button onClick={() => setOpenCustomTip(false)} large intent="success">
                                Save Tip
                                </Button>
                            </div>
                        </Dialog>
                        
                        {showTipBar &&
                            <div style={{ display: "flex", backgroundColor: "#F9F9F9", padding: "9px 17px", borderRadius: "5px", marginTop: "18px", marginBottom: "18px" }}>
                                {existingOrder.amount.subTotal > businessSettings.onlineMinimumTipForPercentagePresets * 100 ? (
                                <>
                                    <div
                                    style={{
                                        display: "flex",
                                        flex: "1",
                                        padding: "8px 5px",
                                        fontSize: "14px",
                                        fontFamily: "Lato-Semibold",
                                        backgroundColor: tipSelection === businessSettings.onlineTipPresetPercentages[0] ? "#000000" : "transparent",
                                        color: tipSelection === businessSettings.onlineTipPresetPercentages[0] ? "#fff" : "#231F20",
                                        cursor: "pointer",
                                        justifyContent: "center",
                                        borderRadius: "5px",
                                    }}
                                    onClick={() => {
                                        setTip(businessSettings.onlineTipPresetPercentages[0]);
                                        handleApplyTip(false, businessSettings.onlineTipPresetPercentages[0]);
                                    }}
                                    >
                                    {businessSettings.onlineTipPresetPercentages[0]}%
                                    </div>

                                    <div
                                    style={{
                                        display: "flex",
                                        flex: "1",
                                        padding: "8px 5px",
                                        fontSize: "14px",
                                        fontFamily: "Lato-Semibold",
                                        backgroundColor: tipSelection === businessSettings.onlineTipPresetPercentages[1] ? "#000000" : "transparent",
                                        color: tipSelection === businessSettings.onlineTipPresetPercentages[1] ? "#fff" : "#231F20",
                                        cursor: "pointer",
                                        justifyContent: "center",
                                        borderRadius: "5px",
                                    }}
                                    onClick={() => {
                                        setTip(businessSettings.onlineTipPresetPercentages[1]);
                                        handleApplyTip(false, businessSettings.onlineTipPresetPercentages[1]);
                                    }}
                                    >
                                    {businessSettings.onlineTipPresetPercentages[1]}%
                                    </div>
                                    <div
                                    style={{
                                        display: "flex",
                                        flex: "1",
                                        padding: "8px 5px",
                                        fontSize: "14px",
                                        fontFamily: "Lato-Semibold",
                                        backgroundColor: tipSelection === businessSettings.onlineTipPresetPercentages[2] ? "#000000" : "transparent",
                                        color: tipSelection === businessSettings.onlineTipPresetPercentages[2] ? "#fff" : "#231F20",
                                        cursor: "pointer",
                                        justifyContent: "center",
                                        borderRadius: "5px",
                                    }}
                                    onClick={() => {
                                        setTip(businessSettings.onlineTipPresetPercentages[2]);
                                        handleApplyTip(false, businessSettings.onlineTipPresetPercentages[2]);
                                    }}
                                    >
                                    {businessSettings.onlineTipPresetPercentages[2]}%
                                    </div>
                                    <div
                                    
                                    style={{ display: "flex", flex: "1", padding: "8px 5px", fontSize: "14px", fontFamily: "Lato-Semibold",
                                    backgroundColor: tipSelection === "Custom" ? "#000000" : "transparent", color: tipSelection === "Custom" ? "#fff" : "#231F20", cursor: "pointer", justifyContent: "center", borderRadius: "5px" }}
                                    onClick={() => {
                                        // setTip("Custom");
                                        setOpenCustomTip(true);
                                    }}
                                    >
                                    Custom
                                    </div>
                                </>
                                ) : (
                                <>
                                    <div
                                    style={{
                                        display: "flex",
                                        flex: "1",
                                        padding: "8px 5px",
                                        fontSize: "14px",
                                        fontFamily: "Lato-Semibold",
                                        backgroundColor: tipSelection === businessSettings.onlineTipPresetAmounts[0] ? "#000000" : "transparent",
                                        color: tipSelection === businessSettings.onlineTipPresetAmounts[0] ? "#fff" : "#231F20",
                                        cursor: "pointer",
                                        justifyContent: "center",
                                        borderRadius: "5px",
                                    }}
                                    onClick={() => {
                                        setTip(businessSettings.onlineTipPresetAmounts[0]);
                                        handleApplyTip(true, businessSettings.onlineTipPresetAmounts[0]);
                                    }}
                                    >
                                    ${businessSettings.onlineTipPresetAmounts[0]}
                                    </div>

                                    <div
                                    style={{
                                        display: "flex",
                                        flex: "1",
                                        padding: "8px 5px",
                                        fontSize: "14px",
                                        fontFamily: "Lato-Semibold",
                                        backgroundColor: tipSelection === businessSettings.onlineTipPresetAmounts[1] ? "#000000" : "transparent",
                                        color: tipSelection === businessSettings.onlineTipPresetAmounts[1] ? "#fff" : "#231F20",
                                        cursor: "pointer",
                                        justifyContent: "center",
                                        borderRadius: "5px",
                                    }}
                                    onClick={() => {
                                        setTip(businessSettings.onlineTipPresetAmounts[1]);
                                        handleApplyTip(true, businessSettings.onlineTipPresetAmounts[1]);
                                    }}
                                    >
                                    ${businessSettings.onlineTipPresetAmounts[1]}
                                    </div>
                                    <div
                                    style={{
                                        display: "flex",
                                        flex: "1",
                                        padding: "8px 5px",
                                        fontSize: "14px",
                                        fontFamily: "Lato-Semibold",
                                        backgroundColor: tipSelection === businessSettings.onlineTipPresetAmounts[2] ? "#000000" : "transparent",
                                        color: tipSelection === businessSettings.onlineTipPresetAmounts[2] ? "#fff" : "#231F20",
                                        cursor: "pointer",
                                        justifyContent: "center",
                                        borderRadius: "5px",
                                    }}
                                    onClick={() => {
                                        setTip(businessSettings.onlineTipPresetAmounts[2]);
                                        handleApplyTip(true, businessSettings.onlineTipPresetAmounts[2]);
                                    }}
                                    >
                                    ${businessSettings.onlineTipPresetAmounts[2]}
                                    </div>
                                    <div
                                    style={{ display: "flex", flex: "1", padding: "8px 5px", fontSize: "14px", fontFamily: "Lato-Semibold", 
                                    backgroundColor: tipSelection === "Custom" ? "#000000" : "transparent", color: tipSelection === "Custom" ? "#fff" : "#231F20", cursor: "pointer", justifyContent: "center", borderRadius: "5px" }}
                                    onClick={() => {
                                        // setTip("Custom");
                                        setOpenCustomTip(true);
                                    }}
                                    >
                                    Custom
                                    </div>
                                </>
                                )}
                            </div>
                        }

                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                            <div>Tax</div>
                            <div>${(tax * 0.01).toFixed(2)}</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                            
                            <div>Your Total</div>
                            <div>
                                ${(selectedItemsCost * 0.01 + tipDollarAmount * 0.01 + tax * 0.01).toFixed(2)}
                                {/* ${existingOrder.amount.subTotal && existingOrder.amount.tax ? (existingOrder.amount.subTotal * 0.01 + existingOrder.amount.tax * 0.01 + tipDollarAmount * 0.01).toFixed(2) : "0.00"} */}
                            </div>
                        </div>

                        {/* <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                            <div style={{fontWeight:'bold'}}>Order Total</div>
                            <div style={{fontWeight:'bold'}}>
                                ${(existingOrder.amount.subTotal * 0.01 + existingOrder.amount.tax * 0.01 + tipDollarAmount * 0.01).toFixed(2)}
                            </div>
                        </div> */}
                    </div>
                </div>

                <div style={{ position: "fixed", bottom: "0px", width: "100%", padding: ".5em", backgroundColor: "white" }}>
                {existingTab && !existingTab.paymentComplete ? 
                    <Button
                        onClick={() => {
                            setOpenPayScreen(true)
                            // setOpenViewCheck(false)
                        }}
                        disabled={productOrderIDsToPayFor.length < 1 || selectedItemsCost === 0}
                        style={{
                            width: "100%",
                            background: user.color,
                            boxShadow: "none",
                            opacity: productOrderIDsToPayFor.length < 1 || selectedItemsCost === 0 ? "0.75" : "",
                            borderRadius: "100px",
                            position: "relative",
                        }}
                        intent="success"
                        large
                    >
                        <span style={{ fontSize: "12px", position: "absolute", left: "20px", top: "15px" }}> {selectedItemCount} items</span>
                        <span style={{ fontSize: "14px", fontFamily: "Axiforma-Bold" }}>Pay</span>
                        <span style={{ fontSize: "12px", position: "absolute", right: "20px", top: "15px" }}>${(selectedItemsCost * 0.01 + tipDollarAmount * 0.01 + tax * 0.01).toFixed(2)}</span>
                    </Button>
                 : 
                    <Button 
                        onClick={()=>{
                            return finishTableSession()  
                        }}
                        style={{ width: "100%", background: "#2FB24C", boxShadow: "none", borderRadius: "100px", position: "relative" }} intent="success" large>
                        <span style={{ fontSize: "14px", fontFamily: "Axiforma-Bold" }}>Payment Complete</span>
                    </Button>
                }
                </div>
            </div>
            </Drawer>

        </div>
    )
}

export default ViewCheck