import { Button, Classes, Dialog, Drawer, Icon, Intent, Position, TextArea, Toaster } from "@blueprintjs/core";
import { useState } from "react";
import { Business, ItemsInCart, MenuCategory, ModifierOption, Order, SelectedModifiers, TangoBusinessSettings, TangoMenu, TangoModifier, TangoProduct } from "../../types/types";
import CheckIcon from "../../assets/images/check.png";
import { selectModifierOption } from "../../utils/functions";
import _ from "lodash";

interface ViewProductProps {
    business: Business;
    businessSettings: TangoBusinessSettings;
    itemsInCart: ItemsInCart[]
    menu: TangoMenu;
    menuCategory: MenuCategory;
    modifiers: TangoModifier[]
    modifierOptions: ModifierOption[];
    order: Order;
    selectedProduct: TangoProduct;
    setOpenViewProduct: (shouldShow: boolean) => void;
    setProductAddedToCart: (success: boolean) => void;
}

const ViewProduct = ({business, businessSettings, itemsInCart, menu, menuCategory, modifiers, modifierOptions, order,selectedProduct, setOpenViewProduct, setProductAddedToCart}: ViewProductProps) => {

  console.log('businessSettings', businessSettings)
    var taxRate = 0.0 
    business.tangoProducts.forEach((tangoProduct: any) => {
        if (tangoProduct.name === "mobile") {
            tangoProduct.orderTypes.forEach((type: any) => {
                if (type.name === 'dineIn') {
                  taxRate = type.taxRate;
                }
            })
        }
    })

    const [optionsSelected, setOptionsSelected] = useState(['']);
    const [quantity, setQuantity] = useState(1);
    const [discounts, setDiscounts] = useState([]);
    const [suggestedProducts, setSuggestedProducts] = useState([]);
    const [successfulAddToCart, setSuccessfulAddToCart] = useState(false);
    const [productToAdd, setProductToAdd] = useState<ItemsInCart>({
      alcohol: selectedProduct.alcohol,
      businessId: selectedProduct.businessId,
      course: '',
      fulfilled: false,
      isPrinted: false,
      status: 'received',
      discountsAppliedToProduct: [],
      discountedPrice: selectedProduct.discountedPrice ? selectedProduct.discountedPrice : null,
      imageUrl: selectedProduct.imageUrl,
      quantity: 1,
      sentQuantity: businessSettings && businessSettings.directSendOrder ? 1 : 0,
      orderQuantity: 0,
      menuCategory: menuCategory ? menuCategory.name : '',
      menuName: menu ? menu.nameExternal : '',
      name: selectedProduct.nameExternal,
      price: selectedProduct.price,
      productId: selectedProduct.id,
      productOrderId: '',
      selectedModifiers: [], //default with empty selections for modifiers
      taxRate: selectedProduct.taxRate,
      type: selectedProduct.type,
      plu: selectedProduct.plu ? selectedProduct.plu : '',
      note: ''
    });

    const addToCart = () => {
      if(!businessSettings.allowPatronDineInOrders || !businessSettings.isMobileDineInEnabled){
        return alert ('Oops! It like ' + business.businessName +  'has disabled dine in qr ordering.')
      }

      //if exact item with modifer already exists then increment quantity of existing item
      for (let i = 0; i <itemsInCart.length; i++){
        const existingItem:ItemsInCart = itemsInCart[i]

        if(existingItem.name === productToAdd.name && existingItem.discountedPrice === productToAdd.discountedPrice &&
          _.isEqual(
          _.sortBy(_.flattenDeep(existingItem.selectedModifiers.map((selectedModifier: any) => selectedModifier.optionNames))),
          _.sortBy(_.flattenDeep(productToAdd.selectedModifiers.map((modifier: any) => modifier.optionNames)))
        )){
          existingItem.quantity = existingItem.quantity + quantity //increment quantity of existing with quanity selected in the state
          console.log('incremented existing item', itemsInCart)
          setSuccessfulAddToCart(true);  
          return setTimeout(() => {
            setSuccessfulAddToCart(false);
            setProductAddedToCart(true)
            setOpenViewProduct(false);
          }, 1500);
        }
      }

        //if above conditions not met just add the brand new item       
        productToAdd.quantity = quantity
        itemsInCart.push(productToAdd)
        setSuccessfulAddToCart(true);
        console.log('added items to cart', productToAdd)
        setTimeout(() => {
          setSuccessfulAddToCart(false);
          setProductAddedToCart(true)
          setOpenViewProduct(false);
        }, 1500);
    };
    
    const handleQuantityChange = (addOrSubtract: number) => {
      setQuantity(quantity + addOrSubtract);
    };

    const clickModifier = (productToAdd:ItemsInCart, modifier:TangoModifier, option:ModifierOption) =>{
      const newModifiers = selectModifierOption(productToAdd, modifier, option, optionsSelected.includes(option.id))
      //set option ids in state
      const optionIdsSelected: any[] | ((prevState: string[]) => string[]) = []
      newModifiers.forEach((modifier:SelectedModifiers)=>{
          return modifier.options.forEach((option:any)=>{
              return optionIdsSelected.push(option.id)
          })
      })
      setOptionsSelected(optionIdsSelected)
  }
  
    return (
        <Drawer
            isOpen={true}
            onClose={() => {
              setOpenViewProduct(false);
            }}
            size="100%"
            position={Position.BOTTOM}
            className="drawer"
        >
        <div id="viewProductDrawer" className={Classes.DRAWER_BODY}>
          <div style={{ backgroundColor: "#F5F8FA", padding: ".5em", position: "fixed", top: "0px", width: "100%", zIndex: 1 }}>
            <Button
              onClick={() => {
                setOpenViewProduct(false);
              }}
              icon="arrow-left"
              style={{ position: "absolute", left: "10px", top: "11px" }}
              className={`${Classes.MINIMAL} order-from-button`}
            >
              {" "}
            </Button>
            <h3 style={{ textAlign: "center", fontFamily: "Axiforma-ExtraBold", fontSize: "17px" }}>Customize</h3>
          </div>

          <div className={Classes.DIALOG_BODY} style={{ paddingTop: "20%" }}>
            <div className="product-hero-container">
              <div className="product-hero-banner"></div>
              {selectedProduct.imageUrl ? (
                <img src={selectedProduct.imageUrl} className="product-avatar-image" alt="" />
              ) : (
                <div
                  className="product-avatar-image"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    backgroundColor: "#F5F8FA",
                    fontSize: "20px",
                    fontFamily: "Axiforma-Bold",
                  }}
                >
                  {selectedProduct.nameExternal.charAt(0).toUpperCase()}
                </div>
              )}
              <h2
                style={{
                  paddingLeft: "26px",
                  marginBottom: "10px",
                  marginTop: "20px",
                  fontSize: "24px",
                  fontFamily: "Axiforma-ExtraBold",
                  overflowWrap: "break-word",
                  lineHeight: "30px",
                }}
              >
                {selectedProduct.nameExternal}
              </h2>
              <p style={{ paddingLeft: "26px", margin: "0px", fontSize: "12px" }}>{selectedProduct.description}</p>
            </div>
            <hr className="hrLine" />
            {selectedProduct.modifiers.map((modifier: string, index: number) => {
              return modifiers.map((mod: TangoModifier) => {
                if (mod.enabled && mod.id === modifier) {
                  return (
                    <div key={mod.id} style={{ padding: "0px 26px", paddingTop: "20px" }}>
                      <span>
                        <span id="modifierName">{mod.nameExternal} </span>
                        {mod.min === 1 ? <span style={{ float: "right", fontSize: "1.1em" }}>Required</span> : <span style={{ float: "right", fontSize: "1.1em" }}>Optional</span>}
                        {mod.max > 1 && (
                          <span>
                            {" "}
                            <br /> Select up to {mod.max}
                          </span>
                        )}
                      </span>

                      <div style={{ paddingTop: "20px" }}>
                        {mod.options.map((option: string) => {
                          return modifierOptions.map((o: ModifierOption) => {
                            if (o.enabled && o.id === option) {
                              return mod.max === 1 ? (
                                <div
                                  key={o.id}
                                  onClick={() => clickModifier(productToAdd, mod, o)}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "20px",
                                    borderBottom: "1px solid #F7F7F7",
                                    paddingBottom: "20px",
                                  }}
                                >
                                  <div>
                                    <div style={{ fontSize: "12px", fontFamily: "Axiforma-ExtraBold" }}>{o.name}</div>
                                    <div>${(o.additionalCost * 0.01).toFixed(2)}</div>
                                  </div>
                                  {optionsSelected.includes(o.id) && (
                                    <div
                                      style={{
                                        width: "35px",
                                        height: "35px",
                                        backgroundColor: "#2E7CF6",
                                        borderRadius: "50%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                      }}
                                    >
                                      <img src={CheckIcon} style={{ width: "11px" }} />
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div
                                  key={o.id}
                                  onClick={() => clickModifier(productToAdd, mod, o)}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "20px",
                                    borderBottom: "1px solid #F7F7F7",
                                    paddingBottom: "20px",
                                  }}
                                >
                                  <div>
                                    <div style={{ fontSize: "12px", fontFamily: "Axiforma-ExtraBold" }}>{o.name}</div>
                                    <div>${(o.additionalCost * 0.01).toFixed(2)}</div>
                                  </div>
                                  {optionsSelected.includes(o.id) && (
                                    <div
                                      style={{
                                        width: "35px",
                                        height: "35px",
                                        backgroundColor: "#2E7CF6",
                                        borderRadius: "50%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                      }}
                                    >
                                      <img src={CheckIcon} style={{ width: "11px" }} />
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          });
                        })}
                      </div>
                    </div>
                  );
                }
              });
            })}
          </div>

          <div style={{ marginBottom: "10em", paddingLeft: "2em" }}>
            <h3 style={{ fontSize: "17px", fontFamily: "Axiforma-ExtraBold"}}>Quantity</h3>
            <p style={{ fontSize: "12px" }}>Choose the quantity</p>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Icon
                onClick={() => {
                  if (quantity > 1) {
                    handleQuantityChange(-1);
                  }
                }}
                icon="minus"
                iconSize={11}
                className="quantity-icon"
              />
              <span style={{ padding: "1em", fontSize: "20px", fontFamily: "Axiforma-ExtraBold" }}>{quantity}</span>

              <Icon onClick={() => handleQuantityChange(1)} icon="plus" iconSize={11} className="quantity-icon" />
            </div>

            {businessSettings.allowItemLevelRemarks &&
              <div style={{paddingLeft: "0px", paddingTop:'15px', marginRight:'15px'}}>
                  <div style={{fontSize: "12px", fontFamily: "Axiforma-ExtraBold" }}>Item Notes</div>
                  <div style={{fontSize: "10px", fontFamily: "Axiforma-Regular", paddingTop:"5px", paddingBottom:"5px" }}>Any add-on items (i.e., cheese, bacon) are subject to an additional charge</div>
                  <TextArea style={{width: "95%", marginBottom:"20px", fontSize: "10px", fontFamily: "Axiforma-Regular", backgroundColor: "#F4F4F4"}} 
                  onChange={(e:any)=>{
                      productToAdd.note = e.target.value 
                  }}/>
              </div>
          }
          </div>

          
        </div>




          <Button
            id="addToOrderButton"
            onClick={() => {
              addToCart();
            }}
            intent="primary"
            large
          >
            <span>Add To Cart</span>
            <span style={{ position: "absolute", right: "20px", fontSize: "12px", top: "15px", fontFamily: "Axiforma-Regular" }}>${(((selectedProduct.discountedPrice ? selectedProduct.discountedPrice : selectedProduct.price) + productToAdd.selectedModifiers.reduce((a: any, b: { [x: string]: any; }) => a + (b["additionalCost"] || 0), 0)) * 0.01 * quantity).toFixed(2)}</span>
          </Button>


          <Dialog
              isOpen={successfulAddToCart 
                  // || successfulCreateOrderDoc
              }
              className="product-modal"
              onClose={() => {
                  setSuccessfulAddToCart(false);
                  // setSuccessfulCreateOrderDoc(false);
              }}
              style={{ width: "90%", textAlign: "center" }}
            >
              <div style={{ fontFamily: "Axiforma-Regular" }}>
                <div
                    style={{
                    height: "62px",
                    width: "62px",
                    borderRadius: "50%",
                    backgroundColor: "#2FB24C",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "30px",
                    }}
                >
                  <img src={CheckIcon} style={{ width: "30px", height: "30px" }} />
                </div>
                <div style={{ fontSize: "17px", fontFamily: "Axiforma-ExtraBold", marginTop: "5px" }}>Success!</div>

                {successfulAddToCart && <p style={{ fontSize: "12px", fontFamily: "Axiforma-Regular" }}>Your item has been added to your cart.</p>}
              </div>
            </Dialog>

      </Drawer>
    )

}

export default ViewProduct