import React, {useState} from 'react';
import firebase from "../../config/firebase";
import './index.css';
import {Button, Dialog, Icon} from "@blueprintjs/core";
import Flatpickr from "react-flatpickr";
import {validateNewPickupTime, getListOfTime } from "../../utils/functions";
import moment from 'moment';
import { Business } from '../../types/types';
interface Props{
    business: Business
    isOpen: boolean;
    closeModal: () => void;
    updatePickupTime: (pickupTime: any) => void;
    setOpenSchedulePickup: (open: boolean) => void;
}

const SchedulePickup = ({business, isOpen, closeModal, updatePickupTime, setOpenSchedulePickup}:Props) => {
    const [scheduleDateTime, setScheduleDateTime] = useState<any>();
    const [isScheduleOrderTime, setIsScheduleOrderTime] = useState('');
    const listOfTimes = getListOfTime();
    const [selectedScheduleTime, setSelectedScheduleTime] = useState<any>(null);
    return (
        <Dialog 
            isOpen={isOpen}
            hasBackdrop
            onClose={closeModal}
            className="gift-card-login-modal product-modal" portalClassName="gift-card-modal-portal"
        >
            <div className="gift-card-modal-body change-order-type-body">
            <div className="modal-title">Change Order Time</div>
            <div className="modal-subtitle">Please select your desired order time below</div>
            <Icon
                icon="cross"
                className="close-icon"
                iconSize={30}
                color={"#231F20"}
                onClick={() => {
                    setOpenSchedulePickup(false);
                }}
            />

            <select 
                onChange={(e) => {
                    console.log(e.target.value)
                    setIsScheduleOrderTime(e.target.value)}
                } 
                value={isScheduleOrderTime}
                className="gift-card-modal-select" 
                style={{ width: "100%", marginBottom: "8px" }} 
            >
                {/* only strings are acepted as option values */}
                <option value={'false'}>ASAP</option>
                <option value={'true'}>Scheduled</option>
            </select>


            {isScheduleOrderTime === 'true' && 
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Flatpickr
                        className="flatpickr"
                        value={scheduleDateTime}
                        options={{
                            minDate: "today",
                            maxDate: moment().add(3, "M").format("YYYY-MM-DD"),
                            static: true,
                            altFormat: "F j, Y",
                            dateFormat: "F j, Y",
                        }}
                        placeholder="Select Date"
                        onChange={(defaultDate) => {
                            console.log('selected date', defaultDate[0])
                            setScheduleDateTime(defaultDate[0]);
                        }}
                    />
                    <select
                        className="gift-card-modal-select"
                        style={{ width: "48%" }}
                        value={selectedScheduleTime}
                        onChange={(e) => {
                            console.log('selected time', e.target.value)
                            setSelectedScheduleTime(e.target.value);
                        }}
                    >
                        {listOfTimes && listOfTimes.map((item) => {
                            return (
                            <option value={item} key={item}>
                                {item}
                            </option>
                            );
                        })}
                    </select>
                </div>
            }

            <Button
                text="Save"
                className="gift-card-login-button"
                style={{ marginTop: "20px" }}
                onClick={() => {
                    if (isScheduleOrderTime && scheduleDateTime && selectedScheduleTime) {
                        const selectedDate = moment(scheduleDateTime).format("DD MM YYYY");
                        const pickupTimestamp = moment(selectedDate + " " + selectedScheduleTime, "DD MM YYYY hh:mm A").toDate();
                        //check to see if business is open at pickup time
                        const valid = validateNewPickupTime(pickupTimestamp, business)
                        if(valid){
                            setScheduleDateTime(pickupTimestamp);
                            setOpenSchedulePickup(false);
                            console.log("update pickup time in order to -->", pickupTimestamp, moment.utc(pickupTimestamp).format())

                            console.log("??",  new Date(pickupTimestamp).toISOString())
                        

                            updatePickupTime(new Date(pickupTimestamp).toISOString())
                        }else{
                            setScheduleDateTime(null);
                            setSelectedScheduleTime(null);
                            return alert(business.businessName + " is not open at your requested pickup time.")
                        }
                    }
                    if (isScheduleOrderTime === 'false') {
                        //check to see if business is open at current timestamp
                        const valid = validateNewPickupTime(moment(), business)
                        if(valid){
                            console.log('select asap')
                            setScheduleDateTime(null);
                            setSelectedScheduleTime(null);
                            setOpenSchedulePickup(false);
                            updatePickupTime(null)
                        }else{
                            return alert(business.businessName + " is not currently open. Please schedule a valid pickup time.")
                        }
                    }
                }}
            />
            </div>
        </Dialog>
    )
}
export  default SchedulePickup;