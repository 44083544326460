import { useEffect, useState } from 'react';
import {fetchAccount, fetchBusinesses, fetchBusinessSettings, fetchTableFromQr} from '../db/GetData';
import { Spinner, Overlay, Card } from '@blueprintjs/core';
import DineInSession from './DineInSession';
import DineInSplash from '../assets/images/splash-dinein.png';
import UnavailableSplash from '../assets/images/splash-dinein-unavailable.png';

import { Business, TangoBusinessSettings } from '../types/types';

function getFaviconEl() {
  return document.getElementById('favicon');
}

const Landing = (props: any) => {
  const qrId = props.match.params.qrId;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [splashImage, setSplashImage] = useState('');
  const [business, setBusiness] = useState<Business>();
  const [table, setTable] = useState<any>();
  const [businessSettings, setBusinessSettings] = useState<TangoBusinessSettings>();
  const [showSplashImage, setShowSplashImage] = useState(false);


  //on landing page load fetch account, businesses, and table data
  //generate a session document based on table to save in firestore
  useEffect(() => {
    getAccountAndBusinesses();
  }, []);

  const getAccountAndBusinesses = async()=> {
    try {
      const account = await fetchAccount();
      const businesses = await fetchBusinesses(account);
      console.log('show dine in landing page for - account >>>', account, 'businesses', businesses, 'qr ID', qrId);

      //fetch the parent account and splash image on landing
      if (account && businesses) {
        setSplashImage(
          account.mobileOrders.splashImageUrl ||
          'https://is2-ssl.mzstatic.com/image/thumb/Purple124/v4/a5/3f/48/a53f4872-219e-2d83-f741-78ee7264f152/source/512x512bb.jpg',
        );
        setShowSplashImage(true);
        setTimeout(() => {
          setShowSplashImage(false);
        }, 1400);
        setError(false);

        //set favicon and display name of the browser tab
        if (account.mobileOrders.faviconUrl) {
          const favicon = getFaviconEl();
          //@ts-ignore
          document.getElementById('favicon').href = account.mobileOrders.faviconUrl;
        }
        if (account.mobileOrders.displayName) {
          document.title = account.mobileOrders.displayName + ' - Dine In - Powered by Tango';
        }

        const table = await fetchTableFromQr(qrId)
        console.log('fetchTableFromQr',table )
        if(table?.data.id){
          setTable(table.data);
          businesses.forEach((business:any)=>{
            if(business.id === table.data.businessId){
              return setBusiness(business);
            }
          })
          console.log('fetch business settings for', table.data.businessId)
          const businessSettingsData = await fetchBusinessSettings(table.data.businessId);
          setBusinessSettings(businessSettingsData ? businessSettingsData: null);
          return setLoading(false);
        }else{
          console.log('error fetching table from qr')
          setLoading(false);
          return setError(true);
        }         
      } else {
        console.log('error fetch account and businesses')
        setLoading(false);
        return setError(true);
      }
    } catch (e) {
      setLoading(false);
      console.log('try catch error fetch accound and businesses', e)
      return setError(true);
    }
  }

  if(error && business){
    return (
      <div>
        <Card style={{marginTop:'40%', textAlign:'center', marginLeft:'10%', marginRight:'10%'}}>
            <img style={{width:'36%'}} src={business.profileImageUrl}></img>
            <br/>
            <p >Sorry that doesn't seem to be a valid table</p>
        </Card>
      </div>
    )
  }

  if(businessSettings && !businessSettings.isMobileDineInEnabled){
    return (
      <div>
        <img src={UnavailableSplash} style={{position:'fixed', left:0,right:0,top:0,bottom:0, width:'100%'}}/>
      </div>
    )
  } 

  // if(businessSettings?.serverMustOpenTable){
  //   return (
  //       <div style={{textAlign:'center', fontFamily: "Axiforma-Regular"}}>
  //           <p style={{ marginTop: '25%',marginBottom: '10%', marginLeft: '15%', marginRight: '15%', fontSize:'20px'}}>Your server will be with you shortly.</p>
  //           <p style={{ fontSize: '15px'}}>Table {table.tableNumber}</p>
  //           <br/>
  //           <img style={{width:'30%', paddingTop:'5%',  paddingBottom:'10%'}} src={business?.profileImageUrl}></img>
  //       </div>
  //   )
  // }

  return (
    <div>
      {business && table && !showSplashImage && businessSettings && businessSettings.isMobileDineInEnabled ?
        <DineInSession business={business} businessSettings={businessSettings} table={table} />
        :
        <div style={{ marginTop: '40%' }}>
          <Spinner />
        </div>
      }
      <Overlay isOpen={showSplashImage} onClose={() => setShowSplashImage(false)}>
        <img src={splashImage} alt="" style={{ height: '100%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
      </Overlay>

      {error && business &&
        <div>
          <p style={{ margin: '30%' }}>Sorry that doesn't seem to be a valid table</p>
          <img style={{width:'30%', paddingTop:'5%',  paddingBottom:'10%'}} src={business.profileImageUrl}></img>
        </div>
      }

    </div>
  );
};

export default Landing;
