import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import firebase from '../config/firebase';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from "reselect";
import { selectBusinessDetails } from "../reducers/global/selectors";
import { connect } from "react-redux";
import { compose } from "redux";

import './header.css';
interface Props {
    path?: string;
    businessDetails?: any;
    visitLocation?: () => void;
}

const Header = ({ path, businessDetails, visitLocation }: Props) => {
    const history = useHistory();
    const [business, setBusiness] = useState();
    useEffect(() => {
        setBusiness(businessDetails);
    }, [businessDetails])
    const user = firebase.auth().currentUser;

    return (
        <div className="new-header">
            <div className="left-part">
                {
                    business && <img className="logo" src={businessDetails.profileImageUrl} />
                }
                <Link to="/">
                    <div className={`menu-item ${path === 'order' && 'active'}`}>
                        Order
                    </div>
                </Link>
            {/* <Link to="/reservations">
                <div className={`menu-item ${path === 'reservations' && 'active'}`}>
                    Reservations
                </div>
            </Link> */}
            {/* <Link to="/cards">
                <div className={`menu-item ${path === 'cards' && 'active'}`}>
                    Cards
                </div>
            </Link>
            <Link to="/gift">
                <div className={`menu-item ${path === 'gift' && 'active'}`}>
                    Gift
                </div>
            </Link> */}
            </div>
            <div className="right-part">
                <div className={`menu-item ${path === 'jobs' && 'active'}`} onClick={() => {
                    history.push('/jobs')
                }}>
                    Jobs
                </div>
                <div className={`menu-item`} onClick={() => {
                    // console.log('click locations')
                    // history.push('/', {path:'location'})

                    //just reload app since routing is weird
                    window.location.replace(window.location.origin)
                    //this only works if there are multiple locations
                    // if (visitLocation) {
                    //     visitLocation()
                    // } else {
                    //     history.push('/', {path:'location'})
                    // }
                }}>
                    Locations
                </div>

                {user && !user.isAnonymous ?
                    <div className="menu-item" onClick={async () => {
                        await firebase.auth().signOut();
                        window.location.replace('/');
                    }}>
                        Logout
                    </div>
                    :
                    <div className={`menu-item ${path === 'login' && 'active'}`} onClick={async () => {
                        history.push('/login')
                    }}>
                        Login
                    </div>
                }
            </div>
        </div>
    )
}
const mapStateToProps = createStructuredSelector({
    businessDetails: selectBusinessDetails()
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(Header);