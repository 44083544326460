import { Button, Dialog, Icon, InputGroup } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { Business, ItemsInCart, SelectedModifiers, TangoBusinessSettings, TangoMenu} from "../types/types";
import { calculateSubtotalInOrder, calculateTaxOnOrder} from "../utils/functions";

interface CartProps {
    business: Business
    businessSettings: TangoBusinessSettings;
    itemsInCart: ItemsInCart[];
    menus: TangoMenu[]
    openPayDialog: boolean;
    successfulOrder: boolean;
    taxRate: number;
    removeItemInCart: ([]) => void;
    setOpenPayDialog: (open:boolean) => void;
    setChangeTip: (amount:number) => void;
    setRefreshCart: (refresh:boolean) => void;
}

const Cart = ({business, businessSettings, itemsInCart, menus, openPayDialog, successfulOrder, taxRate, removeItemInCart, setOpenPayDialog, setChangeTip, setRefreshCart}: CartProps) => {
    const [orderType, setOrderType] = useState('carryOut');//default to carryout
    const [openCustomTip, setOpenCustomTip] = useState(false);
    const [tipSelection, setTipSelection] = useState<number | string>(businessSettings?.onlineTipPresetPercentages[1] ? businessSettings?.onlineTipPresetPercentages[1] : 20); 
    const [showTipBar, setShowTipBar] = useState(false);
    const [tipValue, setTipSelectionValue] = useState(0.0); 

    useEffect(() => {
        if(itemsInCart.length > 0){
            //calculate default tip
            if(calculateSubtotalInOrder(itemsInCart) > businessSettings.onlineMinimumTipForPercentagePresets * 100){
                setTipSelectionValue( calculateSubtotalInOrder(itemsInCart) * (businessSettings.onlineTipPresetPercentages[1] * 0.01)  * 0.01)
                setTipSelection(businessSettings.onlineTipPresetPercentages[1])
                handleApplyTip(false, businessSettings.onlineTipPresetPercentages[1]);
            }else{
                setTipSelectionValue(businessSettings.onlineTipPresetAmounts[1])
                setTipSelection(businessSettings.onlineTipPresetAmounts[1])
                handleApplyTip(true, businessSettings.onlineTipPresetAmounts[1]);
            }
        }
    },[itemsInCart])

    const handleApplyTip = (dollar: boolean, amount: number) => {
        if(dollar){
            setTipSelectionValue(amount)
            console.log('apply default dollar tip -->', amount)
            return setChangeTip(amount)
        }else{
            setTipSelectionValue( (calculateSubtotalInOrder(itemsInCart) * (amount * 0.01)) * 0.01 )
            return setChangeTip((calculateSubtotalInOrder(itemsInCart) * (amount * 0.01)) * 0.01)
        }
    };

    const removeItem = (index: number) => {
        removeItemInCart(itemsInCart.filter((_, i) => i !== index));
        if(itemsInCart.filter((_, i) => i !== index).length < 1){
            setShowTipBar(false)
            setOpenCustomTip(false)
            setTipSelectionValue(0.0) //if no items set tip back to 0.0
            setOpenPayDialog(false)
        }
    };

    return (
        <div>
            {itemsInCart.length > 0 ?
                <div style={{ width: "30%", position: "absolute", right: "0px"}}>
                    
                {openPayDialog &&
                    <div style={{ paddingTop: "33px", paddingBottom: "25px", borderBottom: "1px solid #EDEDED", paddingLeft: "39px" }}>
                        <div style={{ fontSize: "19px", color: "#231F20", fontFamily: "Lato-Extrabold" }}>Order Summary</div>
                        <div style={{ fontSize: "12px", color: "#888", fontFamily: "Lato-Bold", marginTop: "6px" }}>
                            Items in your order 
                            ({itemsInCart.reduce((totalCount:number, item:ItemsInCart)=> {
                                    return totalCount + item.quantity;
                                },0)
                            })
                        </div>
                    </div>
                }

                <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                    {itemsInCart.map((product: ItemsInCart, index: number) => {
                        let productCostWithDiscount: number[] = [];
                        if (product.discountedPrice) {
                            productCostWithDiscount = [product.quantity * product.discountedPrice];
                            product.selectedModifiers.map((mod: any) => {
                                productCostWithDiscount.push(product.quantity * mod.additionalCost);
                            });
                        }
    
                        let productWithModCost = [product.quantity * product.price];
                        product.selectedModifiers.map((mod: any) => {
                            productWithModCost.push(product.quantity * mod.additionalCost);
                        });
                        return (
                            <div key={index} style={{ paddingTop: "19px", paddingLeft: "40px", paddingRight: "40px", borderBottom: "1px solid #EDEDED", paddingBottom: "20px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ fontSize: "14px", fontFamily: "Lato-Extrabold", color: "#231F20" }}>
                                ( {product.quantity} ) {` ` + product.name}
                                </div>
                                <div style={{ fontSize: "12px", fontFamily: "Lato-Bold", color: "#888888" }}>${(productWithModCost.reduce((a, b) => a + b, 0) * 0.01).toFixed(2)}</div>
                            </div>
                            {product.selectedModifiers.length ? 
                                <div style={{ marginTop: "6px" }}>
                                {product.selectedModifiers.map((mod: SelectedModifiers) => {
                                    return mod.optionNames.map((optionName: string, index:number) => {
                                        return <span key={index} style={{ fontSize: "12px", marginRight: "5px", color: "#888888", fontFamily: "Lato-Bold" }}>{optionName},</span>;
                                        });
                                })}
                                </div>
                             : null}
                            <div style={{ marginTop: "25px" }}>
                                <Button disabled={successfulOrder} 
                                    onClick={() => {
                                        removeItem(index)
                                    }}
                                    minimal 
                                    intent="primary" 
                                    style={{ cursor: "pointer", margin: "0px", padding: "2px", fontSize: "12px", fontFamily: "Lato-Bold" }}>
                                    Remove
                                </Button>
                            </div>
                            </div>
                        );
                    })}
                </div>

                {/* CUSTOM TIP DIALOG */}
                <Dialog isOpen={openCustomTip} onClose={() => setOpenCustomTip(false)} title={<span style={{ fontSize: ".9em" }}>Custom Tip Amount</span>} style={{ width: "25%" }} className="product-modal">
                    <div style={{ padding: "2em", textAlign: "center" }}>
                        <InputGroup
                            onChange={(e: any) => {
                                //make sure tip is positive #
                                if (e.currentTarget.value.includes("-")) {
                                    setTipSelectionValue(e.target.value.replace("-", ""));
                                } else {
                                    setTipSelectionValue(parseFloat(e.target.value));
                                }
                            }}
                            large
                            id="customTipValue"
                            placeholder="$2.00"
                            type="number"
                        />
                        <Button 
                            onClick={() => {
                                setOpenCustomTip(false)
                                handleApplyTip(true, tipValue)
                            }} 
                            large intent="success" 
                            style={{ width: "100%", marginTop: "20px", fontFamily: "Axiforma-Semibold" }
                        }>
                            Apply Tip
                        </Button>
                    </div>
                </Dialog>

                <div style={{ paddingTop: "20px", paddingLeft: "40px", paddingRight: "40px", borderBottom: "1px solid #F7F7F7", paddingBottom: "19px" }}>

                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "6px" }}>
                        <div style={{ fontSize: "14px", color: "#231F20", fontFamily: "Lato-Semibold" }}>    
                            Tip 
                            {!successfulOrder && 
                                <span style={{ color: "#4587B2", fontSize: "14px", fontFamily: "Lato-Semibold", marginLeft: "10px", cursor: "pointer" }} onClick={() => setShowTipBar(!showTipBar)}>
                                    Change
                                </span>
                            }
                        </div>
                        <div style={{ fontSize: "14px", fontFamily: "Lato-Semibold", color: "#888888" }}>${tipValue.toFixed(2)}</div>
                    </div>

                    {/* EXPANDED TIP SELECTION */}
                    {showTipBar &&
                        <div style={{ display: "flex", backgroundColor: "#F9F9F9", padding: "9px 17px", borderRadius: "5px", marginTop: "18px", marginBottom: "18px" }}>

                            {/* PERCENTAGE OPTIONS */}
                            {calculateSubtotalInOrder(itemsInCart) > businessSettings.onlineMinimumTipForPercentagePresets * 100 ? (
                                <>
                                    <div
                                        style={{
                                            display: "flex",
                                            flex: "1",
                                            padding: "8px 5px",
                                            fontSize: "14px",
                                            fontFamily: "Lato-Semibold",
                                            backgroundColor: tipSelection === businessSettings.onlineTipPresetPercentages[0] ? "#4587B2" : "transparent",
                                            color: tipSelection === businessSettings.onlineTipPresetPercentages[0] ? "#fff" : "#231F20",
                                            cursor: "pointer",
                                            justifyContent: "center",
                                        }}
                                        onClick={() => {
                                            setTipSelection(businessSettings.onlineTipPresetPercentages[0]);    
                                            handleApplyTip(false, businessSettings.onlineTipPresetPercentages[0]);
                                        }}
                                    >    
                                        {businessSettings.onlineTipPresetPercentages[0]}%
                                    </div>
            
                                    <div
                                        style={{
                                            display: "flex",
                                            flex: "1",
                                            padding: "8px 5px",
                                            fontSize: "14px",
                                            fontFamily: "Lato-Semibold",
                                            backgroundColor: tipSelection === businessSettings.onlineTipPresetPercentages[1] ? "#4587B2" : "transparent",    
                                            color: tipSelection === businessSettings.onlineTipPresetPercentages[1] ? "#fff" : "#231F20",
                                            cursor: "pointer",
                                            justifyContent: "center",
                                        }}
                                        onClick={() => {    
                                            setTipSelection(businessSettings.onlineTipPresetPercentages[1]);    
                                            handleApplyTip(false, businessSettings.onlineTipPresetPercentages[1]);
                                        }}
                                    >    
                                        {businessSettings.onlineTipPresetPercentages[1]}%
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            flex: "1",
                                            padding: "8px 5px",
                                            fontSize: "14px",
                                            fontFamily: "Lato-Semibold",    
                                            backgroundColor: tipSelection === businessSettings.onlineTipPresetPercentages[2] ? "#4587B2" : "transparent",    
                                            color: tipSelection === businessSettings.onlineTipPresetPercentages[2] ? "#fff" : "#231F20",
                                            cursor: "pointer",
                                            justifyContent: "center",
                                        }}
                                        onClick={() => {    
                                            setTipSelection(businessSettings.onlineTipPresetPercentages[2]);    
                                            handleApplyTip(false, businessSettings.onlineTipPresetPercentages[2]);
                                        }}
                                    >    
                                        {businessSettings.onlineTipPresetPercentages[2]}%
                                    </div>

                                    <div
                                        style={{ display: "flex", flex: "1", padding: "8px 5px", fontSize: "14px", fontFamily: "Lato-Semibold", backgroundColor: tipSelection === "Custom" ? "#4587B2" : "transparent", color: tipSelection === "Custom" ? "#fff" : "#231F20", cursor: "pointer", justifyContent: "center" }}
                                        
                                        onClick={() => {
                                            setTipSelection("Custom");
                                            setOpenCustomTip(true);
                                        }}
                                    >
                                        Custom
                                    </div>
                                </>
                            ) :
                            //  DOLLAR VALUE OPTIONS
                            (<>
                                <div
                                    style={{
                                        display: "flex",
                                        flex: "1",
                                        padding: "8px 5px",
                                        fontSize: "14px",
                                        fontFamily: "Lato-Semibold",    
                                        backgroundColor: tipSelection === businessSettings.onlineTipPresetAmounts[0] ? "#4587B2" : "transparent",    
                                        color: tipSelection === businessSettings.onlineTipPresetAmounts[0] ? "#fff" : "#231F20",
                                        cursor: "pointer",
                                        justifyContent: "center",
                                    }}
                                    onClick={() => {    
                                        setTipSelection(businessSettings.onlineTipPresetAmounts[0]);    
                                        handleApplyTip(true, businessSettings.onlineTipPresetAmounts[0]);
                                    }}
                                >    
                                    ${businessSettings.onlineTipPresetAmounts[0]}
                                </div>
        
                                <div
                                    style={{
                                        display: "flex",
                                        flex: "1",
                                        padding: "8px 5px",
                                        fontSize: "14px",
                                        fontFamily: "Lato-Semibold",    
                                        backgroundColor: tipSelection === businessSettings.onlineTipPresetAmounts[1] ? "#4587B2" : "transparent",    
                                        color: tipSelection === businessSettings.onlineTipPresetAmounts[1] ? "#fff" : "#231F20",
                                        cursor: "pointer",
                                        justifyContent: "center",
                                    }}
                                    onClick={() => {    
                                        setTipSelection(businessSettings.onlineTipPresetAmounts[1]);    
                                        handleApplyTip(true, businessSettings.onlineTipPresetAmounts[1]);
                                    }}
                                >    
                                    ${businessSettings.onlineTipPresetAmounts[1]}
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        flex: "1",
                                        padding: "8px 5px",
                                        fontSize: "14px",
                                        fontFamily: "Lato-Semibold",    
                                        backgroundColor: tipSelection === businessSettings.onlineTipPresetAmounts[2] ? "#4587B2" : "transparent",    
                                        color: tipSelection === businessSettings.onlineTipPresetAmounts[2] ? "#fff" : "#231F20",
                                        cursor: "pointer",
                                        justifyContent: "center",
                                    }}
                                    onClick={() => {    
                                        setTipSelection(businessSettings.onlineTipPresetAmounts[2]);    
                                        handleApplyTip(true, businessSettings.onlineTipPresetAmounts[2]);
                                    }}
                                >    
                                    ${businessSettings.onlineTipPresetAmounts[2]}
                                </div>

                                <div
                                    style={{ display: "flex", flex: "1", padding: "8px 5px", fontSize: "14px", fontFamily: "Lato-Semibold", backgroundColor: tipSelection === "Custom" ? "#4587B2" : "transparent", color: tipSelection === "Custom" ? "#fff" : "#231F20", cursor: "pointer", justifyContent: "center" }}
                                    onClick={() => {
                                        setTipSelection("Custom");
                                        setOpenCustomTip(true);
                                    }}
                                >
                                Custom
                                </div>
                            </>)}
                        </div>
                    }
    
                    {/* DISPLAY TOTALS */}
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "6px" }}>
                        <div style={{ fontSize: "14px", color: "#231F20", fontFamily: "Lato-Semibold" }}>Tax</div>
                        <div style={{ fontSize: "14px", fontFamily: "Lato-Semibold", color: "#888888" }}>
                            ${(calculateTaxOnOrder(itemsInCart, taxRate) * 0.01).toFixed(2)}
                        </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "6px" }}>
                        <div style={{ fontSize: "14px", color: "#231F20", fontFamily: "Lato-Semibold" }}>Subtotal</div>
                        <div style={{ fontSize: "14px", fontFamily: "Lato-Semibold", color: "#888888" }}>
                            ${(calculateSubtotalInOrder(itemsInCart) * 0.01).toFixed(2)}
                        </div>
                    </div>

                    {/* {order.orderType === "delivery" ?
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "6px" }}>
                            <div style={{ fontSize: "14px", color: "#231F20", fontFamily: "Lato-Semibold" }}>Delivery Fee</div>
                            <div style={{ fontSize: "14px", fontFamily: "Lato-Semibold", color: "#888888" }}>${(order.amount.deliveryFee * 0.01).toFixed(2)}</div>
                        </div>
                        :
                        null
                    }

                    {order && order.amount.discountTotal ?  
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "6px" }}>
                            <div style={{ fontSize: "14px", color: "#231F20", fontFamily: "Lato-Semibold" }}>Discount</div>
                            <div style={{ fontSize: "14px", fontFamily: "Lato-Semibold", color: "#2FB24C" }}>${(order.amount.discountTotal * 0.01).toFixed(2)}</div>
                        </div>
                        :
                        null
                    } */}
    
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "6px" }}>
                        <div style={{ fontSize: "14px", color: "#231F20", fontFamily: "Lato-Semibold" }}>Total</div>
                        <div style={{ fontSize: "14px", fontFamily: "Lato-Semibold", color: "#888888" }}>
                            ${
                                ((calculateSubtotalInOrder(itemsInCart) + calculateTaxOnOrder(itemsInCart, taxRate) ) * 0.01 + tipValue).toFixed(2)
                            }
                        </div>
                    </div>

    
                    {!successfulOrder && !openPayDialog &&
                    <>
                        {/* CHECKOUT BUTTON */}
                        <Button
                            onClick={() => {
                                setOpenPayDialog(true);
                            }}
                            style={{ background: "rgb(47, 178, 76)", boxShadow: "none", position: "relative", padding: "15px 0px", marginTop: "20px" }}
                            disabled={itemsInCart.length < 1}
                            fill
                            intent="success"
                            large
                        >
                            <span style={{ fontSize: "12px", position: "absolute", left: "20px", top: "19px" }}> 
                                {itemsInCart.reduce((totalCount:number, item:ItemsInCart)=> {
                                        return totalCount + item.quantity;
                                    },0)
                                } items
                            </span>

                            <span style={{ fontSize: "15px", fontFamily: "Axiforma-ExtraBold"}}>Checkout</span>

                            <span style={{ fontSize: "12px", position: "absolute", right: "20px", top: "19px", fontFamily: "Axiforma-Regular" }}>
                                ${
                                    ((calculateSubtotalInOrder(itemsInCart) + calculateTaxOnOrder(itemsInCart, taxRate) ) * 0.01 + tipValue).toFixed(2)
                                }
                            </span>
                        </Button>


                        {business.status != 'live' && 
                            <span>
                                <br />
                                <p style={{ color: "red", fontWeight: "bold" }}>{business.businessName} is not currently accepting carry out or delivery orders.</p>
                            </span>
                        }
                    </>}

                    {successfulOrder && 
                        <div style={{ marginTop: "40px"}}>
                            <h2 style={{ textAlign: "center" }}> 🎉 Success! 🎉</h2>
                            {/* {orderType === "dineIn" && <p>Your dine in order has been placed and will be brought to your table shortly</p>}
                            {orderType === "carryOut" && !order.pickupTime && <p>Your carry out order has been placed and will be ready soon. Please pick up your order inside on your arrival.</p>}
                            {orderType === "carryOut" && order.pickupTime && (
                            <p>Your carry out order has been placed and is scheduled for {moment(order.pickupTime).format("MMM DD hh:mm A")}.</p>
                            )} */}
                            {orderType === "bar" && <p>Your bar order has been placed. Please pick up your order at the bar.</p>}
                            {orderType === "delivery" && <p>Your delivery order has been placed. Estimated delivery time is about 40 minutes.</p>}
                            <p>
                            <br /> You will receive an email receipt of your order shortly.
                            </p>

                            <div
                                style={{textAlign:'center', fontFamily: "Axiforma-Regular"}}
                            >
                                <Button
                                    onClick={() => {
                                        console.log('clicked place another order')
                                        setRefreshCart(true)
                                    }}
                                    intent="primary"
                                    >
                                    Place another order
                                </Button>
                            </div>
                       
                        </div>
                    }
                </div>
            </div>
            : 
                <div style={{ textAlign: "center", paddingBottom: "30em", marginTop: "135px", width: "27%", position: "fixed", right: "3em", padding: "30px 10px" }}>
                    <Icon iconSize={50} icon="shopping-cart"></Icon>
                    <h3>Empty Cart</h3>
                    <p>add items to your cart</p>
                </div>
            }

        </div>
    )

}

export default Cart