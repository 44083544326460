import { createSelector } from 'reselect';
import get from 'lodash/get';
import _ from 'lodash'
import {initialState} from './reducer';
import { TangoBusinessSettings } from '../../types/types';

/**
 * Direct selector to the homeContainer state domain
 */

export const selectGlobalContainerDomain = (state: any) => state.globalContainerReducer || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by HomeContainer
 */

export const selectHomeContainer = () => createSelector(selectGlobalContainerDomain, (substate) => substate);

export const selectBusinessDetails = () =>
  createSelector(selectGlobalContainerDomain, (substate) => get(substate, 'businessDetails', {}));
  export const selectBusinessSettings = (state: any) => {
    console.log("state", state)
    return get(state, 'businessSettings', {}) as TangoBusinessSettings
  }
export const selectAccountDetails = () =>
    createSelector(selectGlobalContainerDomain, (substate) => get(substate, 'account', {}));

export type DepartmentId = "foh" | "boh" | "other";

export const DEPARTMENTS = {
  foh: {
    id: "foh",
    title: "Front Of House",
    shortTitle: "FOH",
  },
  boh: {
    id: "boh",
    title: "Back Of House",
    shortTitle: "BOH",
  },
  other: {
    id: "other",
    title: "Other",
    shortTitle: "Other",
  },
};

export const getPositionByName = (
  businessSettings: TangoBusinessSettings,
  positionName: string
) => {
  const jobFunctions = businessSettings?.jobFunctions || {}
  const positionId = _.keys(jobFunctions).find(positionId => {
    return jobFunctions[positionId]?.title === positionName
  })
  if (positionId) {
    return jobFunctions[positionId]
  }
  return null
}

export default selectHomeContainer;
