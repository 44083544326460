import React, { useEffect, useState } from "react";
import SelectLocation from "./SelectLocation";
import { Spinner, Overlay } from "@blueprintjs/core";
import { findBusinessesAndAccountFromDomain, fetchAccount, fetchBusinesses, fetchUrlMappings,fetchTableFromQr } from "../db/GetData";
import ViewLocation from "./ViewLocation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createStructuredSelector } from "reselect";
import { selectBusinessDetails, selectAccountDetails } from "../reducers/global/selectors";
import { connect } from "react-redux";
import { compose } from "redux";
import { globalContainerCreators } from "../reducers/global/reducer";
import { useLocation } from "react-router-dom";
import logo from "../assets/images/tango-bunny.png"

function getFaviconEl() {
  return document.getElementById("favicon");
}
const Landing = (props: any) => {
  const orderType = props.orderType;
  const [account, setAccount] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [splashImage, setSplashImage] = useState("");
  const [showSplashImage, setShowSplashImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const location = useLocation();



   const getAccountAndBusinesses = async()=> {
    try {
      const account = await fetchAccount();
      const businesses = await fetchBusinesses(account);
      const urlMappings = await fetchUrlMappings();

      if (account && businesses && urlMappings) {
        props.updateAccountDetails(account)
        setAccount(account);
  
        if (props.match.params.locationId) {
          const directLocation = businesses.filter((business: any) => {
            return business.id === props.match.params.locationId;
          });
          const urlLocation = businesses.filter((business: any) => {
            if (urlMappings.length > 0 && urlMappings[0].locations[props.match.params.locationId]) {
              return business.id === urlMappings[0].locations[props.match.params.locationId];
            }
          })
          if (directLocation.length || urlLocation.length) {
            if (urlLocation.length) {
              setBusinesses(urlLocation);
            } else {
              setBusinesses(directLocation);
            }
          } else {
            console.log("user on direct location route but biz id is not valid", directLocation)
            setBusinesses(businesses);
          }
        } else {
          console.log('setting businesses in state after getting account and businesses', businesses)
          setBusinesses(businesses);
        }

        setSplashImage(account.mobileOrders.splashImageUrl || "https://is2-ssl.mzstatic.com/image/thumb/Purple124/v4/a5/3f/48/a53f4872-219e-2d83-f741-78ee7264f152/source/512x512bb.jpg");

        setShowSplashImage(true);
        setTimeout(() => {
          setShowSplashImage(false);
        }, 1400);

        if (account.mobileOrders.faviconUrl) {
          const favicon = getFaviconEl();
          //@ts-ignore
          document.getElementById("favicon").href = account.mobileOrders.faviconUrl;
        }
        if (account.mobileOrders.displayName) {
          document.title = account.mobileOrders.displayName + " Online Orders - Powered by Tango";
        }

      } else {
        console.log('ERROR: Account looks like this: ', account)
        //if !account.business then something wrong with getting account/business data based on current url origin
        setError(true);
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
      // setError(true);
      setConnectionError(true);
    }
  }

  useEffect(() => {

    //landing page load the account and businesses based on url
    getAccountAndBusinesses()

    // if (location.state && location.state.path === 'location') {
    //   if (!props.account) {
    //     console.log("no account data loaded yet, probably because user started on jobs route")
    //     getAccountAndBusinesses();
    //   } else {
    //     setBusinesses(props.account.businesses)
    //   }
    // } else {

    //   if (props.businessDetails) {
    //     console.log("no account data")
    //     // @ts-ignore
    //     setBusinesses([props.businessDetails])
    //   } else {
    //     setLoading(true)
    //     getAccountAndBusinesses();
    //   }
    // }
  }, []);

  const showToast = (message: string) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      toastId: "error-toast",
    });
  };

  if (connectionError || error) {
    return (
      <div>
        <div className="error" style={{ display: 'flex', justifyContent: 'center', marginTop: '3em', marginLeft: '1.5em', marginRight: '1.5em', textAlign: 'center', fontSize: 36, fontStyle: 'bold' }}>
          Whoops!
        </div>
        <div className="logo" style={{ display: 'flex', justifyContent: 'center', marginTop: '3em', marginLeft: '1.5em', marginRight: '1.5em' }}>
          <img src={logo}></img>
        </div>
        <div className="error" style={{ display: 'flex', justifyContent: 'center', marginTop: '3em', marginLeft: '1.5em', marginRight: '1.5em', textAlign: 'center' }}>
          Sorry, it looks like there was a problem with your internet connection. Please check your connection and try again.
        </div>
        <br /><br /><br /><br />
      </div >
    )
  }

  return (
    <div>
      <Overlay isOpen={showSplashImage} onClose={() => setShowSplashImage(false)}>
        <img src={splashImage} alt="" style={{ width: "100%", display: "block", marginLeft: "auto", marginRight: "auto", zIndex: 100 }} />
      </Overlay>

      {/* more than one busines user can select from locations list */}
      {businesses && businesses.length >= 2 && !showSplashImage && <SelectLocation account={account} businesses={businesses} orderType={orderType} />}
      {/* just show one location if only one */}
      {businesses && businesses.length === 1 && (
        <div>
          <ViewLocation
            allLocations={businesses}
            business={businesses[0]}
            orderType={orderType}
            showToast={(message: string) => {
              showToast(message);
            }}
            showAllLocation={() => {
              //@ts-ignore
              setBusinesses(props.account.businesses);
              // history.push('/', {path:'location'})
            }}
          />
          <ToastContainer />
        </div>
      )}

      {loading && (
        <div style={{ marginTop: "20%" }}>
          <Spinner />
        </div>
      )}

    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  businessDetails: selectBusinessDetails(),
  account: selectAccountDetails()
});

const mapDispatchToProps = (dispatch: any) => ({
  updateAccountDetails: (account: any) => dispatch(globalContainerCreators.updateAccountDetails(account)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Landing);