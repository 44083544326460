/*
 *
 * HomeContainer reducer
 *
 */
import produce from 'immer';
import {createActions} from 'reduxsauce';
import {Business} from "../../types/types";

interface initialStateInterface {
  businessDetails: Business | null;
  account: any|null;
}

export const { Types: globalContainerTypes, Creators: globalContainerCreators } = createActions({
  updateBusinessDetails: ['businessDetails'],
  updateAccountDetails:['account']
});
export const initialState: initialStateInterface = { businessDetails: null, account:null };

/* eslint-disable default-case, no-param-reassign */
export const globalContainerReducer = (state = initialState, action: any) =>
  produce(state, (draft) => {
    switch (action.type) {
      case globalContainerTypes.UPDATE_BUSINESS_DETAILS:
        draft.businessDetails = action.businessDetails as Business;
        break;
        case globalContainerTypes.UPDATE_ACCOUNT_DETAILS:
            draft.account = action.account;
            break;
    }
  });

export default globalContainerReducer;
