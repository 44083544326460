import React, {useEffect, useState} from 'react';
import './index.css';
import {Button, Icon} from "@blueprintjs/core";
import {Link, useHistory} from 'react-router-dom';
import {Elements} from '@stripe/react-stripe-js';
import CheckOut from './CheckOut';
import {loadStripe} from '@stripe/stripe-js';
import {fetchUsersCard, GiftCardUser} from '../db/Cards';
import firebase from '../config/firebase';
import Header from './Header';
import GiftCardImage from '../assets/images/card-image.png';
import {createStructuredSelector} from "reselect";
import {selectBusinessDetails} from "../reducers/global/selectors";
import {connect} from "react-redux";
import {compose} from "redux";
import MobileHeader from './MobileHeader';
import CardImage from '../assets/images/card-image.png';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY ? process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY : 'test');

const Cards = (props:any) => {
    const history = useHistory();
    const [showCheckout, setShowCheckout] = useState<boolean>(false);
    const [cards, setCards] = useState<GiftCardUser[]>([]);
    const [business, setBusiness] = useState();
    const userId = firebase.auth().currentUser?.uid;
    useEffect(()=> {
        setBusiness(props.businessDetails);
    }, [props.businessDetails])

    useEffect(()=>{
        const fetchData = async()=> {
            if(userId && business){
                console.log(business)
                // @ts-ignore
                const response = await fetchUsersCard(userId, business.id);
                if(response.data){
                    setCards(response.data);
                }
            }
        }
            fetchData();
    }, [])

    if(firebase.auth().currentUser?.isAnonymous){
        if(window.innerWidth <= 960){
            return (
                <div className='gift-cards-mobile'>
                    <MobileHeader />
                    <div className='brand-name'>Coop cards</div>
                    <div style={{paddingLeft:'80px', paddingRight:'80px', marginTop:'14px', paddingBottom:'14px'}}><img src={CardImage} className={"selected-card-image"}/></div>
                    <div className='join-now-box'>
                        <div className="join-now-text">JOIN NOW</div>
                        <div className="join-now-offer-text">
                            Get a free digital card when you join - or log in to view your cards. <span style={{color:"#E12219"}}>Get Started.</span><div/>
                        </div>
                        <div style={{textAlign:'center'}}>
                            <Button text="Sign In" className='sign-in-button' onClick={()=> history.push('/login')}/>
                            <Button text="Join Now" className='join-now-button' onClick={()=> history.push('/signup')}/>
                        </div>
                    </div> 
                </div>
            )
        }
        return (
            <div className="gift-cards-page">
           <Header path="cards"  />
            <div className="body">
                <div className="left-part">
                    <div className="side-nav">
                        <div className="nav-title">The Coop Cards</div>
                    </div>
                </div>
                <div className="right-part">
                   <div className="cards-collection login-card-view">
                        <img src={GiftCardImage} />
                        <div className="offer-text-cards">
                            {   
                                //@ts-ignore
                                business && <img src={business.profileImageUrl}/>
                            }
                            <div>Get a free digital card when you join - or log in to view your cards. Join now. <span style={{fontFamily:"Lato-Bold", color:'#E12219', cursor:'pointer'}}  onClick={()=> history.push('/signup')}>Get Started</span></div>
                        </div>
                   </div>
                   <Button text={"Join Now"} className="add-money" onClick={()=> {
                       history.push('/login')
                    }}/>
                </div>
            </div>
        </div>
        )
    }
    if(window.innerWidth <= 960){
        return (
            <div className='gift-cards-mobile'>
                <MobileHeader />
                <div className="cards-collection">
                {
                        cards.length > 0 && 
                        <>
                       <Link to={{
                           pathname:`card/${cards[0].id}`,
                           state: {card: cards[0], userCards:cards}
                       }}>
                        <div className="card-container">
                            <div className="card-overlay"></div>
                            <img src={cards[0].imageUrl} className="card-image"/>
                            <div className="amount">
                                ${cards[0].balance.toFixed(2)}
                            </div>
                            <div className="card-balance">
                                Card Balance <Icon icon="refresh" iconSize={10} />
                            </div>
                        </div>
                        </Link>

                        <div className="more-cards">
                            <div className="cards-list">
                            {
                                cards.map((item, index)=>{
                                         if(index !== 0){
                                        return (
                                            <Link to={{
                                                pathname:`card/${item.id}`,
                                                state: {card: item, userCards:cards}
                                            }}>
                                                <img src={item.imageUrl} alt="card" key={item.id}/>
                                            </Link>
                                            )
                                    }
                                })
                            }
                            </div>
                        </div>
                        </>
                   
                    }
                    <div className="add-card" onClick={()=> history.push('/add-card')}>
                            Add a card
                    </div>
                    </div>
            </div>
        )
    }
    return (
        <div className="gift-cards-page">
           <Header path="cards"  />
            <div className="body">
                <div className="left-part">
                    <div className="side-nav">
                        <div className="nav-title">The Coop Cards</div>
                        <div className={`side-nav-item  active`}>Balance</div>
                        <div className={`side-nav-item`} onClick={()=> history.push('/add-money')}>Add</div>
                    </div>
                </div>
                <div className="right-part">
                    <div className="select-card-header">
                        <div className="text-center extra-bold f19">Your Barn Card</div>
                        <div className="text-center bold f12">You can find all of your barn cards here, along with their balances.</div>
                    </div>
                    {
                        cards.length === 0 && <div className='cards-collection'>
                            <div className='add-card-container' onClick={()=> history.push('/add-card')}>
                                <Icon icon="plus" iconSize={30} color="#686868"/>
                                <div>Add Card</div>
                            </div>
                        </div>
                    }
                    {
                        cards.length > 0 && <div className="cards-collection">
                       <Link to={{
                           pathname:`card/${cards[0].id}`,
                           state: {card: cards[0], userCards:cards}
                       }}>
                        <div className="card-container">
                            <div className="card-overlay"></div>
                            <img src={cards[0].imageUrl} className="card-image"/>
                            <div className="amount">
                                ${cards[0].balance.toFixed(2)}
                            </div>
                            <div className="card-balance">
                                Card Balance <Icon icon="refresh" iconSize={10} />
                            </div>
                        </div>
                        </Link>

                        <div className="more-cards">
                            <div className="more-cards-title">MORE CARDS</div>
                            <div className="cards-list">
                            {
                                cards.map((item, index)=>{
                                    if(index !== 0){
                                        return (
                                            <Link to={{
                                                pathname:`card/${item.id}`,
                                                state: {card: item, userCards:cards}
                                            }}>
                                                <img src={item.imageUrl} alt="card" key={item.id}/>
                                            </Link>
                                            )
                                    }
                                })
                            }
                            {
                                cards.length > 0 && cards.length <= 2 && <div className='add-card-container' onClick={()=> history.push('/add-card')}>
                                <Icon icon="plus" iconSize={20} color="#686868"/>
                                <div>Add Card</div>
                                </div>
                            }
                            
                            </div>
                        </div>
                        {/* <div className="add-card">
                            Add a card
                        </div> */}
                    </div>
                    }

                    <Button text={"Add Money"} className="add-money" onClick={()=> {
                       history.push('/add-money')
                    }}/>
                </div>
            </div>
               <Elements stripe={stripePromise}>
                    <CheckOut showModal={showCheckout} closeModal={()=> setShowCheckout(false)} amount={100} />
                </Elements>
        </div>
    )
}
const mapStateToProps = createStructuredSelector({
    businessDetails: selectBusinessDetails()
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(Cards);