import { Button, Classes, Dialog, Intent, TextArea } from "@blueprintjs/core";
import { useState } from "react";
import { ItemsInCart, MenuCategory, ModifierOption, Order, SelectedModifiers, TangoBusinessSettings, TangoMenu, TangoModifier, TangoProduct } from "../types/types";
import CheckIcon from "../assets/images/check.png";
import { validateRequiredModifiers, selectModifierOption } from "../utils/functions";

interface ViewProductProps {
    menu: TangoMenu;
    menuCategory: MenuCategory;
    businessSettings: TangoBusinessSettings;
    modifiers: TangoModifier[]
    modifierOptions: ModifierOption[];
    product: TangoProduct;
    setOpenViewProduct: (shouldShow: boolean) => void;
    itemsInCart: ItemsInCart[]
    addItemToCart: ([]) => void;
}

const ViewProduct = ({menu, menuCategory, product, businessSettings, modifiers, modifierOptions, setOpenViewProduct, itemsInCart, addItemToCart}: ViewProductProps) => {

    const [optionsSelected, setOptionsSelected] = useState(['']);
    const [quantity, setQuantity] = useState(1);
    const [productToAdd, setProductToAdd] = useState<ItemsInCart>({
        alcohol: product.alcohol,
        businessId: product.businessId,
        course: '',
        fulfilled: false,
        isPrinted: false,
        status: 'received',
        discountsAppliedToProduct: [],
        discountedPrice: null,
        imageUrl: product.imageUrl,
        quantity: 1,
        sentQuantity: businessSettings && businessSettings.directSendOrder ? 1 : 0,
        orderQuantity: 0,
        menuCategory: menuCategory.name,
        menuName: menu.nameExternal,
        name: product.nameExternal,
        price: product.price,
        productId: product.id,
        productOrderId: '',
        selectedModifiers: [], 
        taxRate: product.taxRate,
        type: product.type,
        plu: product.plu ? product.plu : '',
        note: ''
    });

    const clickModifier = (productToAdd:ItemsInCart, modifier:TangoModifier, option:ModifierOption) =>{
        const newModifiers = selectModifierOption(productToAdd, modifier, option, optionsSelected.includes(option.id))
        //set option ids in state
        const optionIdsSelected: any[] | ((prevState: string[]) => string[]) = []
        newModifiers.forEach((modifier:SelectedModifiers)=>{
            return modifier.options.forEach((option:any)=>{
                return optionIdsSelected.push(option.id)
            })
        })
        setOptionsSelected(optionIdsSelected)
    }

    const addToCart = () => {

        if(!validateRequiredModifiers(productToAdd,product, modifiers)){
            return false
        }

        productToAdd.quantity = quantity
        if(businessSettings.directSendOrder ){
            productToAdd.sentQuantity = quantity
        }
        const newItems = itemsInCart.concat([productToAdd]) //avoid 'pushing' items in cart and mutating state directly
        addItemToCart(newItems)
        setOpenViewProduct(false);
    };

    const handleQuantityChange = (addOrSubtract: number) => {
        setQuantity(quantity + addOrSubtract);
    };

    return (
        <Dialog 
            isOpen={true} 
            className="product-modal" 
            onClose={() => {
                    setOpenViewProduct(false)
                    setQuantity(1)
                } 
            }
            title={"Customize"} 
            style={{ width: "50%", maxHeight: window.innerHeight - 50, overflowY: "auto" }}
        >
            <div style={{ paddingLeft: "20px", paddingBottom: "1em", fontFamily: "Axiforma-Bold", borderBottom: "1px solid #F7F7F7" }}>
                <span style={{ float: "left", width: "60%" }}>
                    <h3 style={{ fontSize: "17px", fontFamily: "Axiforma-ExtraBold", marginBottom: "0px" }}>{product.nameExternal}</h3>
                    <p style={{ fontSize: "14px", fontFamily: "Axiforma-Regular" }}>{product.description}</p>
                </span>

                {product.imageUrl && 
                    <img src={product.imageUrl} alt="" srcSet="" style={{ float: "right", width: "70px", height: "70px", borderRadius: "50%", marginRight: "3em", marginTop: "2%", objectFit: "cover" }} />
                }
            </div>

            <hr className="hrLine" />
            {product.modifiers.map((modifier: string, index: number) => {
            return modifiers.map((mod: any) => {
                if (mod.enabled && mod.id === modifier) {
                return (
                    <div  key={mod.id} style={{padding: "0px 26px", paddingTop: "10px" }}>
                        <span>
                            <span id="modifierName">{mod.nameExternal}</span>
                            {mod.min === 1 ? <span style={{ float: "right", fontSize: "1.1em" }}>Required</span> : <span style={{ float: "right", fontSize: "1.1em" }}>Optional</span>}
                            {mod.max > 1 && (
                            <span>
                                {" "}
                                <br /> Select up to {mod.max}
                            </span>
                            )}
                        </span>

                        <div style={{ paddingTop: "20px", paddingBottom: ".5em" }}>
                            {mod.options.map((option: string) => {
                                return modifierOptions.map((o: ModifierOption) => {
                                    if (o.enabled && o.id === option) {
                                    return mod.max === 1 ? (
                                        <div
                                        key={o.id}
                                        onClick={() => {
                                            clickModifier(productToAdd, mod, o)
                                            // selectRadio(index, mod, o)
                                        }}
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            marginBottom: "20px",
                                            borderBottom: "1px solid #F7F7F7",
                                            paddingBottom: "20px",
                                            cursor: "pointer",
                                        }}
                                        >
                                        <div>
                                            <div style={{ fontSize: "12px", fontFamily: "Axiforma-ExtraBold" }}>{o.name}</div>
                                            <div>${(o.additionalCost * 0.01).toFixed(2)}</div>
                                        </div>


                                        {/* show check for selected radio */}
                                        {optionsSelected.includes(o.id) && 
                                            <div style={{
                                                width: "35px",
                                                height: "35px",
                                                backgroundColor: "#2E7CF6",
                                                borderRadius: "50%",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex",
                                            }}>
                                                <img src={CheckIcon} style={{ width: "11px" }} />
                                            </div>
                                        }


                                        </div>
                                    ) : (
                                        <div
                                        key={o.id}
                                        onClick={() => 
                                            clickModifier(productToAdd, mod, o)
                                        }
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            marginBottom: "20px",
                                            borderBottom: "1px solid #F7F7F7",
                                            paddingBottom: "20px",
                                            cursor: "pointer",
                                        }}
                                        >
                                        <div>
                                            <div style={{ fontSize: "12px", fontFamily: "Axiforma-ExtraBold" }}>{o.name}</div>
                                            <div>${(o.additionalCost * 0.01).toFixed(2)}</div>
                                        </div>

                                        {/* show check for selected checbox */}
                                        {optionsSelected.includes(o.id) && (
                                            <div
                                            style={{
                                                width: "35px",
                                                height: "35px",
                                                backgroundColor: "#2E7CF6",
                                                borderRadius: "50%",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex",
                                            }}
                                            >
                                            <img src={CheckIcon} style={{ width: "11px" }} />
                                            </div>
                                        )}
                                        </div>
                                    );
                                    }
                                });
                            })}
                        </div>
                    </div>
                );
                }
            });
            })}

            {businessSettings.allowItemLevelRemarks &&
                <div style={{paddingLeft: "20px"}}>
                    <div style={{fontSize: "12px", fontFamily: "Axiforma-ExtraBold" }}>Item Notes</div>
                    <div style={{fontSize: "10px", fontFamily: "Axiforma-Regular", paddingTop:"5px", paddingBottom:"5px" }}>Any add-on items (i.e., cheese, bacon) are subject to an additional charge</div>

                    <TextArea style={{width: "95%", marginBottom:"20px", fontSize: "10px", fontFamily: "Axiforma-Regular", backgroundColor: "#F4F4F4"}} 
                    onChange={(e:any)=>{
                        productToAdd.note = e.target.value 
                    }}/>
                </div>
            }

            <div className={Classes.DIALOG_FOOTER} style={{ display: "flex" }}>
                <div style={{ flex: 0.4, justifyContent: "space-between", alignItems: "center", padding: "10px", display: "flex", backgroundColor: "#F2F2F2" }}>
                    <Button
                        onClick={() => {
                            if (quantity > 1) {
                                handleQuantityChange(-1);
                            }
                        }}
                        style={{ marginRight: ".5em" }}
                        className="desktop-quantity-button"
                    >
                    -
                    </Button>

                    <span style={{ fontSize: "15px", fontFamily: "Axiforma-ExtraBold" }}>{quantity}</span>

                    <Button onClick={() => {
                        handleQuantityChange(+1)
                    }} 
                        style={{ marginLeft: ".5em" }} 
                        className="desktop-quantity-button"
                    >
                    +
                    </Button>
                </div>

                <div style={{ flex: 0.6, paddingLeft: "20px" }}>
                    <Button
                        onClick={() => {
                            addToCart();
                        }}
                        disabled={!product.enabled}
                        style={{
                            background: "#2E7CF6",
                            height: "100%",
                            width: "100%",
                            boxShadow: "none",
                            position: "relative",
                            fontSize: "15px",
                            fontFamily: "Axiforma-ExtraBold",
                        }}
                        large
                        intent={Intent.SUCCESS}
                    >
                    <span>Add To Order</span>
             
                        <span style={{ position: "absolute", right: "20px", top: "25px", fontSize: "12px", fontFamily: "Axiforma-Regular" }}>
                            ${((productToAdd.price+ productToAdd.selectedModifiers.reduce((a, b) => a + (b["additionalCost"] || 0), 0)) * 0.01 * quantity).toFixed(2)}
                        </span>


                
                    </Button>
                </div>
            </div>
        </Dialog>
    )
  

}

export default ViewProduct