import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import './index.css';
import firebase from '../config/firebase'
import {Button} from "@blueprintjs/core";
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {
    addGiftCardTransaction,
    fetchCardByUserId,
    getCardsByIds,
    GiftCard,
    GiftCardTransaction,
    GiftCardUser,
    TransactionType,
    updateGiftCardUsersDoc
} from '../db/Cards';
import {fetchAccount, fetchUser, findOrCreateUser} from '../db/GetData';
import {hasError, ValidateEmail, ValidateName, ValidatePhoneNumber} from '../utils/validations'
import {GiftCardUsers} from '../types/types';
import {createStructuredSelector} from "reselect";
import {selectBusinessDetails} from "../reducers/global/selectors";
import {connect} from "react-redux";
import {compose} from "redux";
import Header from './Header';
import MobileHeader from './MobileHeader';
import { createGiftCardUser } from '../db/AddData';
import { createFirebaseJWT } from '../utils/functions';

interface UserDetails {
    name?: string;
    email?: string;
    phoneNumber?: string;
}


interface UserDetailsError {
    name?: string;
    email?: string;
    phoneNumber?: string;
}

const SendMoneyForm = (props:any) => {
    const history = useHistory();
    // const [showCheckout, setShowCheckout] = useState<boolean>(false);
    const [accountId, setAccountId] = useState('');

    const {senderCardId, recipientCardId}  = useParams<{senderCardId: string, recipientCardId: string}>();
    const [giftAmount, setGiftAmount] = useState('');
    const location = useLocation();
    const [senderCardData, setSenderCardData] = useState<GiftCardUser | null>(null);
    const [recipientCardData, setRecipientCardData] = useState<GiftCardUser | GiftCard |null >(null);

    const [businessId, setBusinessId] = useState<string>('');

    const searchParams = new URLSearchParams(location.search);
    const isForMySelf = searchParams.get('isForMyself') as string;

    const [recipient, setRecipient] = useState<UserDetails>();
    const [recipientError, setRecipientError] = useState<UserDetailsError>();

    const [sender, setSender] = useState<UserDetails>();
    const [senderError, setSenderError] = useState<UserDetailsError>();

    // const [currentUser, setCurrentUser] = useState<UserDetails>();
    const [messageError, setMessageError] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    // const [paymentClientSecret, setPaymentClientSecret] = useState<string>();

    //if formyself flow completed
    //if not for myself
    //fetch card
    //fill up form
    //create card and user
    //perform transaction
    //show success

    useEffect(()=>{
        const fetchData = async()=> {
            const response = await getCardsByIds(senderCardId, recipientCardId, isForMySelf)
            const currentUser = await fetchUser();
            const currentUserDetails: UserDetails = {
                name: currentUser.name,
                email: currentUser.email,
                phoneNumber: currentUser.phone
            }
            setSender(currentUserDetails);
            // setCurrentUser(currentUserDetails);
            if(response.data){
                setSenderCardData(response.data.senderCard);
                setRecipientCardData(response.data.recipientCard);
                setGiftAmount((response.data.senderCard.balance).toFixed(2));
            }
        }
        fetchData();
    }, [])


    useEffect(()=> {
        getAccount()
        setBusinessId(props.businessDetails.id);
    }, [props.businessDetails.id])

    const getAccount =async () => {
        const account = await fetchAccount();
        setAccountId(account.id)
    }

    const updateSenderDetails = useCallback(
        (inputKey: keyof UserDetails, value?: string) => {
            setSender((previousInputs) => ({
                ...previousInputs,
                [inputKey]: value,
            }));
        },
        [setSender],
    );

    const updateRecipientDetails = useCallback(
        (inputKey: keyof UserDetails, value?: string) => {
            setRecipient((previousInputs) => ({
                ...previousInputs,
                [inputKey]: value,
            }));
        },
        [setRecipient],
    );

    const sendMoney = async() => {
        // if amount is <= sender card balance
        // cut balance from previous card
        // update next card balanace
        // add transaction in db
        const userId  = localStorage.getItem('tango-mobile-userId');
        const amount = Number(giftAmount);
        if(senderCardData && recipientCardData){
            if(amount && amount <= senderCardData.balance && userId)
            {
                const  newSenderCardData: Partial<GiftCardUser> ={balance: senderCardData.balance - amount,  updatedAt: firebase.firestore.Timestamp.now()};
                //@ts-ignore
                const newRecipientCardData:Partial<GiftCardUser> = {balance: recipientCardData.balance + amount,  updatedAt: firebase.firestore.Timestamp.now()}
                const cardTransationData:GiftCardTransaction = {
                    businessId,
                    createdAt: firebase.firestore.Timestamp.now(),
                    updatedAt: firebase.firestore.Timestamp.now(),
                    deleted: false,
                    enabled: true,
                    giftCardId: recipientCardId,
                    transactionType:TransactionType.send,
                    amount,
                    //@ts-ignore
                    totalBalance: isForMySelf === 'false'? amount: (recipientCardData.balance + amount),
                    userId,
                    orderId:null,
                }
                const response = await Promise.all([updateGiftCardUsersDoc(newSenderCardData, senderCardId), updateGiftCardUsersDoc(newRecipientCardData, recipientCardId), addGiftCardTransaction(cardTransationData)])
                if(response[2].data){
                    history.replace('/checkout/success/'+response[2].data);
                }
            }
        }
    }

    const sendMoneyToFriend = async () => {
        setRecipientError({})
        setSenderError({})
        setMessageError('')

        const recipientError = {
            name: ValidateName(recipient?.name || '') ? undefined : 'Please enter name',
            email: ValidateEmail(recipient?.email || '') ? undefined : 'Please enter email',
            phoneNumber: ValidatePhoneNumber(recipient?.phoneNumber || '') ? undefined : 'Please enter phone number'
        }
        setRecipientError(recipientError)
        if (hasError(recipientError)) {
            return;
        }
        if (!isForMySelf) {
            const senderError = {
                name: ValidateName(sender?.name || '') ? undefined : 'Please enter name',
                email: ValidateEmail(sender?.email || '') ? undefined : 'Please enter email',
                phoneNumber: ValidatePhoneNumber(sender?.phoneNumber || '') ? undefined : 'Please enter phone number'
            }
            setSenderError(senderError)
            if (hasError(senderError)) {
                return;
            }
        }
        if (!isForMySelf && message.length <= 0) {
            setMessageError('Please enter message')
            return;
        }

        // const data = await getStripePaymentSecret(Number(giftAmount), undefined, "Send money payment");
        // setPaymentClientSecret(data);
        // setShowCheckout(true);

        const recipientUser = await findOrCreateUser(recipient?.name!!, recipient?.email!!, recipient?.phoneNumber!!);
        const userId = localStorage.getItem('tango-mobile-userId');
        if (recipientCardData && recipient && senderCardData && userId) {
            const cardResponse = await fetchCardByUserId(recipientCardData.imageUrl, businessId, recipientUser.id);
            if (cardResponse.data) {
                let recieverCard;
                if (cardResponse.data.length > 0) {

                    recieverCard = cardResponse.data[0]
                } else {
                    const giftCard: GiftCardUsers = {
                        id: '',
                        accountId: accountId,
                        businessId,
                        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                        deleted: false,
                        enabled: true,
                        userId: recipientUser.id,
                        paymentInfo: [],
                        balance: parseFloat(giftAmount),
                        boughtForSelf: false,
                        receiverPhoneNumber: recipient.phoneNumber ? recipient.phoneNumber : '',
                        receiverName: recipient.name ? recipient.name : '',
                        receiverEmail: recipient.email ? recipient.email : '',
                        senderPhoneNumber: sender?.phoneNumber!!,
                        senderName: sender?.name!!,
                        senderEmail: sender?.email!!,
                        message: message,
                        imageUrl: recipientCardData.imageUrl,
                    }
                    recieverCard = await createGiftCardUser(giftCard, await createFirebaseJWT())
                }
                const amount = parseFloat(giftAmount);
                const newSenderCardData: Partial<GiftCardUser> = {
                    balance: senderCardData.balance - amount,
                    updatedAt: firebase.firestore.Timestamp.now()
                };
                const newRecipientCardData: Partial<GiftCardUser> = {
                    balance: cardResponse.data.length > 0 ? (recieverCard ? recieverCard.balance : 0) + amount : amount,
                    updatedAt: firebase.firestore.Timestamp.now()
                }

                if(recieverCard && recieverCard.id){
                    const cardTransationData: GiftCardTransaction = {
                        businessId,
                        createdAt: firebase.firestore.Timestamp.now(),
                        updatedAt: firebase.firestore.Timestamp.now(),
                        deleted: false,
                        enabled: true,
                        giftCardId: recieverCard ? recieverCard.id : '',
                        transactionType: TransactionType.send,
                        amount,
                        //@ts-ignore
                        totalBalance: (recieverCard ? recieverCard.balance : 0) + amount,
                        userId,
                        orderId: null,
                    }
                    const response = await Promise.all([updateGiftCardUsersDoc(newSenderCardData, senderCardId), updateGiftCardUsersDoc(newRecipientCardData, recieverCard ? recieverCard.id : ''), addGiftCardTransaction(cardTransationData)])
                    if (response[2].data) {
                        history.replace('/checkout/success/' + response[2].data);
                    }
                }


                // setShowCheckout(false);
            }
        }
    }
    if(window.innerWidth <= 960){
        return (
            <div className='gift-cards-mobile'>
                <MobileHeader />
                   <div style={{paddingLeft:'80px', paddingRight:'80px', marginTop:'14px', paddingBottom:'14px', borderBottom:'1px solid #F8F8F8'}}><img src={recipientCardData?.imageUrl} className={"selected-card-image"}/></div> 
                    <div className='auth-form-container create-card-form'>
                        <div className='form-title'>Send Money</div> 
                        <div className="textOnInput">
                            <label htmlFor="inputText">Enter Gift Amount</label>
                            <input className="form-control" type="text" value={`$${giftAmount}`} onChange={(e)=> {
                                  const amount = e.target.value.replace('$', '')
                                  setGiftAmount(amount);
                              }}/>
                        </div>
                        {
                            isForMySelf === 'false' && <>
                            <div className='to-from-section'>TO</div>
                        <div className="textOnInput">
                            <label htmlFor="inputText">Recipent Full Name</label>
                            <input value={recipient?.name}  className="form-control" type="text" onChange={(e: ChangeEvent<HTMLInputElement>) => updateRecipientDetails('name', e.target.value)}/>
                        </div>
                        <div className="textOnInput">
                            <label htmlFor="inputText">Recipent Phone Number</label>
                            <input value={recipient?.phoneNumber} onChange={(e: ChangeEvent<HTMLInputElement>) => updateRecipientDetails('phoneNumber', e.target.value)} className="form-control" type="text" />
                        </div>
                        <div className="textOnInput">
                            <label htmlFor="inputText">Recipent Email Address</label>
                            <input value={recipient?.email} className="form-control" type="text" onChange={(e: ChangeEvent<HTMLInputElement>) => updateRecipientDetails('email', e.target.value)} />
                        </div>
                        <div className='to-from-section'>FROM</div>
                        <div className="textOnInput">
                            <label htmlFor="inputText">Sender Full Name</label>
                            <input className="form-control" type="text"  value={sender?.name} onChange={(e: ChangeEvent<HTMLInputElement>) => updateSenderDetails('name', e.target.value)} />
                        </div>
                        <div className="textOnInput">
                            <label htmlFor="inputText">Sender Phone Number</label>
                            <input value={sender?.phoneNumber} onChange={(e: ChangeEvent<HTMLInputElement>) => updateSenderDetails('phoneNumber', e.target.value)} className="form-control" type="text" />
                        </div>
                        <div className="textOnInput">
                            <label htmlFor="inputText">Sender Email Address</label>
                            <input value={sender?.email} onChange={(e: ChangeEvent<HTMLInputElement>) => updateSenderDetails('email', e.target.value)} className="form-control" type="text" />
                        </div>
                        <div className="textOnInput">
                            <label htmlFor="inputText">Message</label>
                            <input className="form-control" type="text" onChange={(e: ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)} value={message} />
                        </div>
                            </>
                        }
                        
                    </div>
                    <Button text={"Send"} className="add-money" onClick={()=>  {
                      if(isForMySelf === 'false'){
                        sendMoneyToFriend()
                      }else{
                        sendMoney()
                      }
                  }} />
            </div>
        )
    }
    return (
        <div className="gift-cards-page">
           <Header path="cards"  />
            <div className="body">
                <div className="left-part">
                    <div className="side-nav">
                        <div className="nav-title">The Coop Cards</div>
                        <div className={`side-nav-item  active`}>Balance</div>
                        <div className={`side-nav-item`}>History</div>
                    </div>
                </div>
                <div className="right-part">
                  <div className="select-card-header">
                      <div className="text-center extra-bold f19">Send Money</div>
                      <div className="text-center bold f12">Choose a card to increase its balance</div>
                  </div>
                  <div className="buy-card-form">
                      <img src={recipientCardData?.imageUrl} className={"card-image"}/>
                      <div className="form-container">
                          <div className="input-container">
                              <div>Gift Amount</div>
                              <input value={`$${giftAmount}`} onChange={(e)=> {
                                  const amount = e.target.value.replace('$', '')
                                  setGiftAmount(amount);
                              }}/>
                          </div>
                          {
                            isForMySelf  === "false" && (
                              <>
                                <div className="form-section-title">TO</div>
                                <div className="input-and-error-container">
                                    <div className="input-container">
                                        <div>Recipient Name</div>
                                        <input value={recipient?.name} onChange={(e: ChangeEvent<HTMLInputElement>) => updateRecipientDetails('name', e.target.value)}/>
                                    </div>
                                    <p className="error-p">{recipientError?.name}</p>
                                </div>

                                <div className="input-and-error-container">
                                    <div className="input-container">
                                        <div>Recipient Phone Number</div>
                                        <input value={recipient?.phoneNumber} onChange={(e: ChangeEvent<HTMLInputElement>) => updateRecipientDetails('phoneNumber', e.target.value)} />
                                    </div>
                                    <p className="error-p">{recipientError?.phoneNumber}</p>
                                </div>

                                <div className="input-and-error-container">
                                    <div className="input-container">
                                        <div>Recipient Email Address</div>
                                        <input value={recipient?.email} onChange={(e: ChangeEvent<HTMLInputElement>) => updateRecipientDetails('email', e.target.value)} />
                                    </div>
                                    <p className="error-p">{recipientError?.email}</p>
                                </div>
                                <div className="form-section-title">FROM</div>
                                    <div className="input-and-error-container">
                                        <div className="input-container">
                                            <div>Sender Name</div>
                                            <input value={sender?.name} onChange={(e: ChangeEvent<HTMLInputElement>) => updateSenderDetails('name', e.target.value)} />
                                        </div>
                                        <p className="error-p">{senderError?.name}</p>
                                    </div>

                                    <div className="input-and-error-container">
                                        <div className="input-container">
                                            <div>Sender Phone Number</div>
                                            <input value={sender?.phoneNumber} onChange={(e: ChangeEvent<HTMLInputElement>) => updateSenderDetails('phoneNumber', e.target.value)} />
                                        </div>
                                        <p className="error-p">{senderError?.phoneNumber}</p>
                                    </div>
                                    <div className="input-and-error-container">
                                        <div className="input-container">
                                            <div>Sender Email Address</div>
                                            <input value={sender?.email} onChange={(e: ChangeEvent<HTMLInputElement>) => updateSenderDetails('email', e.target.value)} />
                                        </div>
                                        <p className="error-p">{senderError?.email}</p>
                                    </div>
                                    <div className="form-section-title">MESSAGE</div>
                                    <div className="input-and-error-container">
                                        <div className="input-container">
                                            <div>Add a Message</div>
                                            <textarea onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value)} value={message}/>
                                        </div>
                                        <p className="error-p">{messageError}</p>
                                </div>
                              </>
                            )
                          }
                      </div>
                  </div>
                  <Button text={"Checkout"} className="add-money" onClick={()=>  {
                      if(isForMySelf === 'false'){
                        sendMoneyToFriend()
                      }else{
                        sendMoney()
                      }
                  }} />
                </div>
            </div>
            {/*{*/}
            {/*    paymentClientSecret && <Elements stripe={stripePromise} options={{clientSecret: paymentClientSecret}}>*/}
            {/*        <CheckOut showModal={showCheckout} closeModal={sendMoneyToFriendStripeCallback} amount={Number(giftAmount)} paymentClientSecret={paymentClientSecret}/>*/}
            {/*    </Elements>*/}
            {/*}*/}
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    businessDetails: selectBusinessDetails()
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(SendMoneyForm);
