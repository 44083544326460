import { useState, useEffect } from "react";
import { CardCvcElement, CardElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Spinner, Button } from "@blueprintjs/core";
import { removeStripeCard, updateDineInTabOrderSession } from "../db/AddData";
import { clearTableSessionItems, sendEmailReceipt } from "../utils/functions";
import {createStripePaymentIntent, getCustomerSuccessfulPayment} from './functions'
import {createFirebaseJWT} from '../utils/functions'
import { fetchTab, fetchUser } from "../db/GetData";
import { Business, ItemsInCart, Tab, TangoBusinessSettings, User } from "../types/types";


interface StripeCardDineInProps {
  paymentTotals:any;
  user: User;
  productsPaidForTab:ItemsInCart[];
  order: any;
  tab: Tab;
  taxRate: any;
  business: Business;
  businessSettings: TangoBusinessSettings;
  deliverectToken: string;
  sessionId: string;
  // existingTip: number;
  setSuccessfulPayment:(success: boolean) => void;
}


const StripeCardDineIn = ({ paymentTotals, user, productsPaidForTab, order, tab, taxRate, business, businessSettings, deliverectToken, sessionId, setSuccessfulPayment}: StripeCardDineInProps)=> {
  const stripe = useStripe();
  const elements = useElements();
  const [tipApplied, setTipApplied] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [taxApplied, setTaxApplied] = useState(0);
  const [removedCard, setRemovedCard] = useState(false);
  const [paymentClientSecret, setPaymentClientSecret] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPaymentIntent, setLoadingPaymentIntent] = useState(false);
  const [paymentIntentError, setPaymentIntentError] = useState(false);
  const [disabled, setDisabled] = useState(true);

  var tangoFee = business.tangoProducts.filter((tangoProduct: any) => {
    return tangoProduct.name == "mobile";
  });
  const tangoFeeCents = tangoFee[0].orderTypes[0].tangoFeeCents;
  const tangoFeePercent = tangoFee[0].orderTypes[0].tangoFeePercent;
  const statementDescriptor = business.businessName.replace("'", "") + " online"; //remove ' character

  const emailOrder = {
    amount: {
      subTotal: paymentTotals.subtotal,
      discountTotal: 0,
      tax: paymentTotals.tax,
      tip: paymentTotals.tip,
      netTotal: paymentTotals.subtotal + paymentTotals.tax,
      grossTotal:  paymentTotals.subtotal + paymentTotals.tax + paymentTotals.tip,
      deliveryFee: 0,
      serviceChargeTotal: 0
    },
    orderNumber:order.orderNumber,
    products: productsPaidForTab,
    orderType: 'dineIn',
    createdAt: order.createdAt,
    turnaroundTime: [10,20],
  }


  async function validateItemsForPayment(){
    const tabInFirestore = await fetchTab(tab.id ? tab.id : '')
    if(tabInFirestore.id){


      productsPaidForTab.forEach((item: ItemsInCart) => {
        let alreadyPaidCount = 0;
        tabInFirestore.customer.map((customer: any) => {
          customer.productsPaid && customer.productsPaid.map((alreadyPaidProduct: any) => {
            if (alreadyPaidProduct.productOrderId === item) {
              alreadyPaidCount = alreadyPaidCount + alreadyPaidProduct.amount;
            }
          });
        });
        if (alreadyPaidCount === parseInt(item.productOrderId.charAt(0))) {
          alert("One of your selected items has already been paid for.");
          clearTableSessionItems(sessionId, user.id);
          return window.location.reload();
        }
      });


    }
  }


  useEffect(() => {

    //check to see if items have already been payed for in tab
    // validateItemsForPayment()
    // .catch((error) =>{
    //   console.log('error fetching tab to validate items for payment', error)
    // })

    const totalCost = paymentTotals.subtotal + paymentTotals.tax + paymentTotals.tip
    // console.log('totalCost stripe dine -->', Math.round(totalCost))
    setLoadingPaymentIntent(true);

    const paymentData = {
      amount:  Math.round(totalCost),
      currency: business.currency ? business.currency : "usd",
      confirm: false,
      payment_method_types: ["card"],
      customer: business.location.country === "Canada" || business.location.country === "CA" ? user.stripeCanada.id : user.stripe.id,
      description: "Dine in order at " + business.businessName,
      statement_descriptor: statementDescriptor.length > 22 ? statementDescriptor.substring(0, 22) : statementDescriptor,//22 chars max
      application_fee_amount: Math.round(totalCost * (tangoFeePercent * 0.01) + tangoFeeCents),
      on_behalf_of: business.stripeConnect.id,
      transfer_data: {
        destination: business.stripeConnect.id,
      },
    }

    const country = business.location.country === "Canada" || business.location.country === "CA" ? "Canada" : "USA"
    const fetchPaymentIntent = async () => {
      if(!paymentData.customer){
        console.log('no customer id loaded yet so reload the user')
        const reloadedUser = await fetchUser();
        paymentData.customer = country === 'Canada' ? reloadedUser.stripeCanada.id : reloadedUser.stripe.id
      }
      const paymentIntent = await createStripePaymentIntent(paymentData, country, await createFirebaseJWT());
      console.log('payment intent loaded dine in', paymentIntent)
  
      if(paymentIntent.paymentIntentSecret){
        setPaymentClientSecret(paymentIntent.paymentIntentSecret);
      }
      if(paymentIntent.client_secret){
        setPaymentClientSecret(paymentIntent.client_secret);
      }
      if(!paymentIntent.paymentIntentSecret || !paymentIntent.client_secret){
        setDisabled(true)
      }
      setLoadingPaymentIntent(false);
      return setDisabled(false);
    }

    fetchPaymentIntent()
    .catch((error)=>{
      console.log('payment intent error', error)
      setDisabled(true); //disable pay button if no payment secret
      setLoadingPaymentIntent(false);
      return setPaymentIntentError(true);
    });
  }, [removedCard]);

  const handleCardInput = (e: any) => {
    if (e.complete) {
      // enable payment button
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    if (e.error) {
      setDisabled(true);
    }
  };

  const removeCard = async() => {
    setLoading(true);
    removeStripeCard(user.id, business.location.country, await createFirebaseJWT());
    user.stripe.cards = [];
    user.stripeCanada.cards = [];
    setLoading(false);
    setRemovedCard(true);
  };

  const handleConfirmCardAndPay = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    //@ts-ignore
    const cardElement = elements.getElement(CardNumberElement);

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
    stripe
      .confirmCardPayment(paymentClientSecret, {
        payment_method: {
          //@ts-ignore
          card: cardElement,
          billing_details: {
            name: user.name,
            email: user.email,
            phone: user.phone,
          },
          metadata: {
            tangoUserId: user.id,
          },
        },
        setup_future_usage: "off_session", //this automatically attaches card to stripe customer for future use //webhook in cloud function will listen for event and update user with card data
      })
      .then(async (result) => {
        if (result.error) {
          // Show error to your customer
          console.log(result.error.message);
          alert(result.error.message);
          setLoading(false);
        } else {
          if (result.paymentIntent.status === "succeeded") {
            const chargeSuccess = await getCustomerSuccessfulPayment(result.paymentIntent.id, business, user, order, "stripe",  await createFirebaseJWT());
            if (chargeSuccess) {
              const complete = await updateDineInTabOrderSession(productsPaidForTab, user, chargeSuccess.customer, order, tab, paymentTotals,taxRate, await createFirebaseJWT())
              setLoading(false);
              sendEmailReceipt(business, user, emailOrder);
              if(complete){
                return setSuccessfulPayment(true)
              }
            } else {
              console.log("couldn't find successful completed payment intent in dine in flow");
              setLoading(false);
              return alert("There was a problem processing your payment.");
            }
          } else {
            setLoading(false);
            alert("There was a problem processing your card. You will not be charged.");
          }
        }
      });
  };

  const handlePayWithExisitngCard = async () => {
    if (!paymentClientSecret) {
      return console.log("no payment secret loaded yet");
    }
    setLoading(true);

    //@ts-ignore
    return stripe
      .confirmCardPayment(paymentClientSecret, {
        payment_method: business.location.country === "Canada" || business.location.country === "CA" ? user.stripeCanada.cards[0].paymentMethodId : user.stripe.cards[0].paymentMethodId,
      })
      .then(async (result) => {
        if (result.error) {
          // Show error to your customer
          console.log(result.error.message);
          alert(result.error.message);
          setLoading(false);
        } else {
          if (result.paymentIntent.status === "succeeded") {
            const chargeSuccess = await getCustomerSuccessfulPayment(result.paymentIntent.id, business, user, order, "stripe", await createFirebaseJWT());
            if (chargeSuccess) {
              const complete = await updateDineInTabOrderSession(productsPaidForTab, user, chargeSuccess.customer, order, tab, paymentTotals, taxRate, await createFirebaseJWT())
              setLoading(false);
              sendEmailReceipt(business, user, emailOrder);
              if(complete){
                return setSuccessfulPayment(true)
              }
            } else {
              console.log("couldn't find successful completed payment intent in dine in flow");
              setLoading(false);
              return alert("There was a problem processing your order.");
            }
          } else {
            setLoading(false);
            return alert("There was a problem processing your payment. You will not be charged.");
          }
        }
      })
      .catch((error) => {
        console.log("Error ???:", error);
        setLoading(false);
        return alert("There was a problem processing your payment. You will not be charged.");
      });
  };

  return (
    <div style={{ fontFamily: "Axiforma-Regular" }}>
      {(business.location.country === "USA" && user.stripe && user.stripe.cards.length > 0) || ((business.location.country === "Canada" || business.location.country === "CA") && user.stripeCanada && user.stripeCanada.cards.length > 0) ? (
        <div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "13px", backgroundColor: "#F5F5F5", marginBottom: "30px" }}>
            <span style={{ fontSize: "17px", fontFamily: "Axiforma-ExtraBold" }}>Card Details</span>
          </div>

          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                background: "#FCFCFC",
                padding: "18px",
                marginTop: "20px",
                border: "1px solid #F2F2F2",
                borderRadius: "5px",
              }}
            >
              {business.location.country === "Canada" || business.location.country === "CA" ? (
                <div>
                  <div>&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226; {user.stripeCanada.cards[0].last4}</div>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div>{user.stripeCanada.cards[0].exp_month + " / " + user.stripeCanada.cards[0].exp_year}</div>
                    <Button style={{ marginLeft: "20px" }} icon="cross" minimal small onClick={() => removeCard()}></Button>
                  </div>
                </div>
              ) : (
                <div>
                  <div>&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;{user.stripe.cards[0].last4}</div>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div>{user.stripe.cards[0].exp_month + " / " + user.stripe.cards[0].exp_year}</div>
                    <Button style={{ marginLeft: "20px" }} icon="cross" minimal small onClick={() => removeCard()}></Button>
                  </div>
                </div>
              )}
            </div>

            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <Button onClick={() => handlePayWithExisitngCard()} loading={loading} large minimal style={{ color: "white", backgroundColor: "rgb(47, 178, 76)", width: "100%", fontFamily: "Axiforma-Bold" }}>
                Pay
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {paymentIntentError && <div style={{ padding: "20px" }}>Error loading credit card input</div>}

          {loadingPaymentIntent ? 
            <div>
              <Spinner />
            </div>
           : 
            <form onSubmit={handleConfirmCardAndPay}>
              <br />

              {!paymentIntentError && (
                <div>
                  <div style={{ backgroundColor: "#f9f9f9", padding: "18px", marginBottom: "20px" }}>
                    <CardNumberElement onChange={(e) => handleCardInput(e)} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ backgroundColor: "#f9f9f9", padding: "18px", width: "25%" }}>
                      <CardExpiryElement onChange={(e) => handleCardInput(e)} />
                    </div>
                    <div style={{ backgroundColor: "#f9f9f9", padding: "18px", width: "70%" }}>
                      <CardCvcElement onChange={(e) => handleCardInput(e)} />
                    </div>
                  </div>

                  <div style={{ textAlign: "center", marginTop: "50px" }}>
                    <Button type="submit" loading={loading} disabled={disabled} large minimal style={{ color: "white", backgroundColor: "#2FB24C", width: "100%", fontSize: "13.5px", fontFamily: "Lato-Extrabold", padding: "19px 0px" }}>
                      Pay
                    </Button>
                  </div>
                </div>
              )}
            </form>
          }
        </div>
      )}
    </div>
  );
};

export default StripeCardDineIn;
