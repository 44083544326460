import React, { useEffect, useState } from "react";
import { fetchAccount, fetchBusinesses, fetchUrlMappings } from "../db/GetData";
import SelectLocation from "./locations/SelectLocation";
import ViewLocation from "./locations/ViewLocation";
import { Spinner, Overlay } from "@blueprintjs/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/images/tango-bunny.png"

function getFaviconEl() {
  return document.getElementById("favicon");
}

const Landing = (props: any) => {
  const orderType = props.orderType;
  const [businesses, setBusinesses] = useState([]);
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [splashImage, setSplashImage] = useState("");
  const [showSplashImage, setShowSplashImage] = useState(false);

  //on page load get account and businesses from db/getData
  useEffect(() => {
    async function getAccountAndBusinesses() {
      try {
        const account = await fetchAccount();
        const businesses = await fetchBusinesses(account);
        const urlMappings = await fetchUrlMappings();
        if (account && businesses && urlMappings) {
          setAccount(account);

          if (props.match.params.locationId) {
            const directLocation = businesses.filter((business: any) => {
              return business.id === props.match.params.locationId;
            });
            const urlLocation = businesses.filter((business: any) => {
              if (urlMappings.length > 0 && urlMappings[0].locations[props.match.params.locationId]) {
                return business.id === urlMappings[0].locations[props.match.params.locationId];
              }
            })
            if (directLocation.length || urlLocation.length) {
              if (urlLocation.length) {
                setBusinesses(urlLocation);
              } else {
                setBusinesses(directLocation);
              }
            } else {
              setBusinesses(businesses);
            }
          } else {
            setBusinesses(businesses);
          }

          setSplashImage(account.mobileOrders.splashImageUrl || "https://is2-ssl.mzstatic.com/image/thumb/Purple124/v4/a5/3f/48/a53f4872-219e-2d83-f741-78ee7264f152/source/512x512bb.jpg");

          setShowSplashImage(true);
          setTimeout(() => {
            setShowSplashImage(false);
          }, 1400);

          if (account.mobileOrders.faviconUrl) {
            const favicon = getFaviconEl();
            //@ts-ignore
            document.getElementById("favicon").href = account.mobileOrders.faviconUrl;
          }
          if (account.mobileOrders.displayName) {
            document.title = account.mobileOrders.displayName + " Online Orders - Powered by Tango";
          }
          setError(false);
        } else {
          //if !account.business then something wrong with getting account/business data based on current url origin
          setError(true);
        }
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
        // setError(true);
        setConnectionError(true);
      }
    }
    getAccountAndBusinesses();
  }, []);

  const showToast = (message: string) => {
    toast.error(message, {
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      toastId: "error-toast",
    });
  };

  if (connectionError || error) {
    return (
      <div>
        <div className="error" style={{ display: 'flex', justifyContent: 'center', marginTop: '3em', marginLeft: '1.5em', marginRight: '1.5em', textAlign: 'center', fontSize: 36, fontStyle: 'bold' }}>
          Whoops!
        </div>
        <div className="logo" style={{ display: 'flex', justifyContent: 'center', marginTop: '3em', marginLeft: '1.5em', marginRight: '1.5em' }}>
          <img src={logo}></img>
        </div>
        <div className="error" style={{ display: 'flex', justifyContent: 'center', marginTop: '3em', marginLeft: '1.5em', marginRight: '1.5em', textAlign: 'center' }}>
          Sorry, it looks like there was a problem with your internet connection. Please check your connection and try again.
        </div>
        <br /><br /><br /><br />
      </div >
    )
  }

  return (
    <div>
      {/* if more than one busines user can select from locations list */}
      {businesses && businesses.length >= 2 && !showSplashImage && (
        <div>
          <SelectLocation orderType={orderType} account={account} businesses={businesses} />
        </div>
      )}
      {/* just show one location if only one */}
      {businesses && businesses.length === 1 && !showSplashImage && (
        <div>
          <ViewLocation
            business={businesses[0]}
            orderType={orderType}
            account={account}
            allLocations={[]}
            className={null}
            showToast={(message: string) => {
              showToast(message);
            }}
          />
          <ToastContainer />
        </div>
      )}

      <Overlay isOpen={showSplashImage} onClose={() => setShowSplashImage(false)}>
        <img src={splashImage} alt="" style={{ height: "100%", display: "block", marginLeft: "auto", marginRight: "auto" }} />
      </Overlay>

      {loading && (
        <div style={{ marginTop: "40%" }}>
          <Spinner />
        </div>
      )}

    </div>
  );
};

export default Landing;
