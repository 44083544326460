export function ValidateEmail(emailAddress: string) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)
}

export function ValidateName(name: string) {
    return name.length > 0;
}

export function ValidatePhoneNumber(phoneNumber: string) {
    return phoneNumber.length > 0;
}

export function hasError<T>(errors: T) {
    return Object.values(errors).some((error) => {
        return error !== undefined;
    });
}