import axios from "axios";
import { CustomerModel } from "../types/types";
import {nestJS_BaseURL} from "../utils/consts"

export const createStripePaymentIntent = async (paymentData: any, country:string, firebaseJWT:string) => {
    try {
        const paymentIntentUrl = country === 'USA' ? '/stripe/usa/paymentIntent' : '/stripe/ca/paymentIntent'
        // console.log('paymentIntentUrl', paymentIntentUrl)
        const paymentIntent = await axios.post(`${nestJS_BaseURL}${paymentIntentUrl}`,paymentData,{
            headers: {'Authorization': `Bearer ${firebaseJWT}`},
        })
        return paymentIntent.data
    } catch (error) {
        console.log('payment intent error ',error);
        return false
    }
};

export const createStripeWalletPaymentIntent = async (paymentData: any, country:string, firebaseJWT:string) => {
    try {
        const paymentIntentUrl = country === 'USA' ? '/stripe/usa/walletPaymentIntent' : '/stripe/ca/walletPaymentIntent'
        const paymentIntent = await axios.post(`${nestJS_BaseURL}${paymentIntentUrl}`,paymentData,{
            headers: {'Authorization': `Bearer ${firebaseJWT}`},
        })
        return paymentIntent.data
    } catch (error) {
        console.log('payment intent error ',error);
        return false
    }
};
  
export const getCustomerSuccessfulPayment = async(paymentIntentId: string, business: any, user: any, order: any, paymentType: string, firebaseJWT:string) => {

    //if it's a gift card payment return the customer object with fields populated with gift-card 
    //if it's a stripe payment fetch the successful payment intent object and populate fields with stripe data
    if (paymentType === "gift-card") {
        //return a customer object similar to stripe logic below, but change respective fields for gift cards
        const customer: CustomerModel = {
          amount: {
            netTotal: order.amount.netTotal,
            refundPayload: null,
            // deliveryFee: 0,
            refundedAmount: 0,
            tip: order.amount.tip,
            tax: order.amount.tax,
          },
          email: user.email,
          firstName: user.name,
          lastName: "",
          isDineInOrder: order.orderType === "dineIn" ? true : false,
          orderId: order.id,
          orderChannel: order.orderChannel,
          orderType: order.orderType,
          phone: user.phone,
          productsPaid: order.products,
          userId: user.id,
          splitType: "quantity",
          payment: {
            paymentType: {
              amount: order.amount.netTotal,
              applicationFeeAmount: 0, //is this being calculated ??
              brand: "gift-card",
              cardholderName: user.name,
              chargeId: "",
              clientSecret: "",
              country: business.location.country, //location of biz or card user?
              created: new Date(),
              currency: business.currency,
              customerId: "", //stripe id not applicable
              fingerprint: "",
              funding: "gift-card",
              generatedCardId: "",
              lastFour: "", //do git cards have numbers?
              isInteracPayment: false, //always false from PWA
              readMethod: "", // is capture method the same? always automatic for online payment
              stripeId: "",
            },
            successfulCharge: {
              amount: order.amount.netTotal,
              applicationFeeAmount: 0,
              id: "",
              paymentVendor: "gift-card", //could be gift-card || cash || stripe
              timestampCharged: new Date(),
            },
            type: "gift-card",
          },
        };
        return { customer };
    } else {
        const getCompletedPaymentIntentUrl = business.location.country === 'USA' ? '/stripe/usa/paymentIntent/' : '/stripe/ca/paymentIntent/'

        try {
            const paymentIntent = await axios.get(`${nestJS_BaseURL}${getCompletedPaymentIntentUrl}${paymentIntentId}`,{
                headers: {'Authorization': `Bearer ${firebaseJWT}`},
            })
            // console.log('found completed payment intent', paymentIntent)
            const charge = paymentIntent.data.charges.data[0];
            const customer: CustomerModel = {
                amount: {
                    netTotal: order.amount.netTotal,
                    refundPayload: null,
                    refundedAmount: 0,
                    tip: order.amount.tip,
                    tax: order.amount.tax,
                },
                email: user.email,
                firstName: user.name,
                lastName: "",
                isDineInOrder: order.orderType === "dineIn" ? true : false,
                orderId: order.id,
                orderChannel: order.orderChannel,
                orderType: order.orderType,
                phone: user.phone,
                productsPaid: order.products,
                userId: user.id,
                splitType: "quantity",
                payment: {
                    paymentType: {
                        amount: paymentIntent.data.amount,
                        applicationFeeAmount: paymentIntent.data.application_fee_amount,
                        brand: charge.payment_method_details.card.brand,
                        cardholderName: charge.billing_details.name,
                        chargeId: charge.id,
                        clientSecret: paymentIntent.data.client_secret,
                        country: business.location.country, //location of biz or card user?
                        created: paymentIntent.data.created,
                        currency: paymentIntent.data.currency,
                        customerId: paymentIntent.data.customer,
                        fingerprint: charge.payment_method_details.card.fingerprint,
                        funding: charge.payment_method_details.card.funding,
                        generatedCardId: paymentIntent.data.payment_method,
                        lastFour: charge.payment_method_details.card.last4,
                        isInteracPayment: false, //always false from PWA
                        readMethod: paymentIntent.data.capture_method, // is capture method the same? always automatic for online payment
                        stripeId: paymentIntent.data.id,
                    },
                    successfulCharge: {
                        amount: paymentIntent.data.amount,
                        applicationFeeAmount: paymentIntent.data.application_fee_amount,
                        id: charge.id,
                        paymentVendor: "stripe", //could be giftcard || cash
                        timestampCharged: new Date(),
                    },
                    type: "stripe",
                },
            };
    
            return { customer };
        
        } catch (error) {
            console.log('payment intent error ',error);
            return false
        }
    }
}

export const refundPayment = async(country: string, paymentIntent: any) =>{

    const refundCountry = country === 'CA' || country === 'Canada' ? 'CA' : 'US'
    try {
        const refundData = {
            country: refundCountry,
            amount: paymentIntent.amount,
            paymentIntentId: paymentIntent.id,
            reverseTransfer: true,
            isTest: false
        }
        const refund = await axios.post(`https://us-central1-tango-2.cloudfunctions.net/globalRefundStripePayment`,refundData,{
            headers: {'Authorization': `Bearer ${'firebaseJWT'}`},
        })
        if(refund.data){
            console.log("refund processed?", refund.data)
            return refund.data
        }else{
            return false
        }
    } catch (error) {
        console.log('refund error ',error);
        return false
    }
}