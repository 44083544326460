
import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Spinner, Classes, Button, Popover, Position, PopoverInteractionKind, Menu, MenuItem, } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { fetchAllMenuData, fetchUser, fetchBusinessSettings, fetchDeliverectToken } from "../../db/GetData";
import { createPopularInCategory, createFeaturedProducts } from "../../models/products";
import "./Index.css";
import "react-datepicker/dist/react-datepicker.css";
import MobileHeader from "../../GiftCards/MobileHeader";
import { Business, ItemsInCart, MenuCategory, ModifierOption, Order, Tab, TangoBusinessSettings, TangoMenu, TangoProduct, User } from "../../types/types";
import ViewMenuCategory from "./ViewMenuCategory"
import { calculateSubtotalInOrder, calculateTaxOnOrder } from "../../utils/functions";
import Pay from "./Pay";
import ViewCart from "./ViewCart";
var stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY ? process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY : "test")

interface ViewLocationProps {
  business: Business;
  allLocations: any;
  orderType: any;
  className: any;
  account: any;
  showToast: (message: string) => void;
}

const ViewLocation = (props: ViewLocationProps) => {
  const business = props.business;
  if (business.location.country === "Canada" || business.location.country === "CA") {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CANADA_LIVE_PUBLISHABLE_KEY ? process.env.REACT_APP_STRIPE_CANADA_LIVE_PUBLISHABLE_KEY : '');
  }

  const allLocations = props.allLocations;
  const [orderType, setOrderType] = useState(props.orderType);
  const [loading, setLoading] = useState(true);
  const [deliverectToken, setDeliverectToken] = useState("");
  const [menuCategories, setMenuCategories] = useState([]);
  const [modifiers, setModifiers] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openViewCart, setOpenViewCart] = useState(false);
  const [businessSettings, setBusinessSettings] = useState<TangoBusinessSettings>();
  const [discounts, setDiscounts] = useState<any>([]);
  const [featuredList, setFeaturedList] = useState<any[]>();
  const [itemsInCart, setItemsInCart] = useState<ItemsInCart[]>([])
  const [menus, setMenus] = useState<TangoMenu[]>([]);
  const [modifierOptions, setModifierOptions] = useState<ModifierOption[]>([]);
  const [newItems, removeItemInCart] = useState<ItemsInCart[]>([])
  const [openViewMenuCategory, setOpenViewMenuCategory] = useState(false);
  const [popularList, setPopularList] = useState<any>([]);
  const [products, setProducts] = useState<TangoProduct[]>([]);
  const [selectedMenu, setSelectedMenu] = useState<TangoMenu>();
  const [selectedMenuCategory, setSelectedMenuCategory] = useState<MenuCategory>();
  const [user, setUser] = useState<User>();
  const [addedItems, addItemToCart] = useState<ItemsInCart[]>([])
  const [order, updateOrder] = useState<Order>()
  const [tipAmount, setChangeTip] = useState(0.0);
  const [successfulOrder, setSuccessfulOrder] = useState(false);
  const [openPayDialog, setOpenPayDialog] = useState(false);
  const [taxRate, setTaxRate] = useState(0.0);
  const [updateTaxRate, setNewTaxRate] = useState(0.0);


  async function getAllMenuData() {
    try {
      const allMenuData = await fetchAllMenuData(business, false);
      setMenus(allMenuData?.menusToDisplay);
      setSelectedMenu(allMenuData?.menusToDisplay[0]);
      setMenuCategories(allMenuData?.menuCategories);
      setProducts(allMenuData?.products);
      setDiscounts(allMenuData?.discountsToDisplay);
      setModifiers(allMenuData?.modifiers)
      setModifierOptions(allMenuData?.modifierOptions)
      setLoading(false);
    } catch (e) {
      console.error('error fetch all menu data',e);
      setLoading(false);
    }
  }

  async function getUser() {
    try {
      const user = await fetchUser();
      // console.log("user >>>", user, businessPaymentMethod)
      if (user) {
        setUser(user);
      }
    } catch (e) {
      console.error("error getting user", e);
    }
  }
  async function getBusinessSettings() {
    try {
      const settings = await fetchBusinessSettings(business.id);
      if (settings) {
        setBusinessSettings(settings);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function getDeliverectToken(){
    try {
      const token = await fetchDeliverectToken();
      if (token) {
        setDeliverectToken(token);
      } else {
        setDeliverectToken("");
      }
    } catch (e) {
      console.error("error getting token", e);
      setDeliverectToken("");
    }
  };

  useEffect(() => {
    
  },[openPayDialog])

  //propogate order when load view cart
  useEffect(() => {
    console.log('update order - viewlocation.tsx', order)
  },[order])

  useEffect(() => {
    setTaxRate(updateTaxRate)
  },[updateTaxRate])

  //if add item 
  useEffect(() => {
    setItemsInCart(addedItems)
  },[addedItems])

  //remove item in view cart
  useEffect(() => {
    setItemsInCart(newItems)
  },[newItems])


  //load data with functions in folder db/getdata
  useEffect(() => {

    if (business.deliverect && business.deliverect.api === "channel") {
      getDeliverectToken();
    }

    //get tax rate
    business.tangoProducts.forEach((tangoProduct: any) => {
      if (tangoProduct.name === "mobile") {
        return (
          tangoProduct.orderTypes.length &&
          tangoProduct.orderTypes.forEach((type: any) => {
            if (type.name === orderType) {
              setTaxRate(type.taxRate);
              setNewTaxRate(type.taxRate)
            }
          })
        );
      }
    });

    getBusinessSettings();
    getUser();
    getAllMenuData()

  }, []);

  useEffect(() => {
    if (products) {
      const list = createFeaturedProducts(products);
      setFeaturedList(list);
    }
  }, [products]);

  const renderMenuDropdown = (menus: any) => {
    return (
      <MenuItem
        key={menus.id}
        onClick={() => selectMenuInDropdown(menus)} //click menu item set status
        text={menus.nameExternal}
      />
    );
  };
  const selectMenuInDropdown = (menu: any) => {
    setSelectedMenu(menu);
    const list = createFeaturedProducts(products);
    setFeaturedList(list);
  };


  return (
    <div>
      {/* Default parallax with menu names */}
      {!openViewMenuCategory && 
        <>
          <MobileHeader />
          <div style={{ paddingTop: "58.5px" }}>
            {business.bannerImageUrl ? (
              <>
                <div className="hero-banner">
                  <img src={business.bannerImageUrl} className="business-banner-image" />
                  <img src={business.profileImageUrl} className="business-avatar-image" alt="" />
                  <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    <h2 style={{ marginTop: "10px", marginBottom: "10px" }}>{business.businessName}</h2>
                    <div style={{ textAlign: "right" }}>
                      <Select className="select-menu-dropdown mobile-select-menu" items={menus} itemRenderer={renderMenuDropdown} filterable={false} onItemSelect={(e: any) => selectMenuInDropdown(e.target)}>
                        <Button text={selectedMenu ? selectedMenu.nameExternal : "Select Menu"} rightIcon="chevron-down" />
                      </Select>
                    </div>
                  </div>
                  <div></div>
                </div>
              </>
            ) : (
              <div id="noParallaxContent" style={{ marginBottom: "2em" }}>
                <br />
                <br />
                <br />

                <img src={business.profileImageUrl} id="profileImage" alt="" />
                <h2 style={{ float: "left", color: "black", marginLeft: "2em" }}>{business.businessName}</h2>

                <div style={{ float: "right", marginRight: "1em", marginTop: "1em" }}>
                  <Select items={menus} itemRenderer={renderMenuDropdown} filterable={false} onItemSelect={(e: any) => selectMenuInDropdown(e.target)}>
                    <Button text={selectedMenu ? selectedMenu.nameExternal : "Select Menu"} rightIcon="caret-down" />
                  </Select>
                </div>
              </div>
            )}
          </div>
        </>
      }

      {loading && 
        <div style={{ marginTop: "30%" }}>
          <Spinner />
        </div>
      }


      {/* If No menus - shouldnt ever happen unless maybe all menus get disabled*/}
      {!menus.length && !loading && 
        <div>
          <br />
          <br />
          <h3 style={{ textAlign: "center" }}>No Menus Available</h3>
        </div>
      }


      {/* Default to show menu categories under parallax with menu names */}
      {selectedMenu && !openViewMenuCategory &&
        <div style={{ marginBottom: "5em", borderTop: "1px solid #F7F7F7", paddingTop: "10px" }}>
          
          {featuredList && featuredList.length ? 
            <div style={{ paddingLeft: "1em" }}>
              <h4 style={{ marginBottom: "1em", marginTop: ".5em", fontSize: "14px", fontFamily: "Axiforma-ExtraBold" }}>Featured items</h4>
            </div>
            : 
            null
          }

          <div style={{ display: "flex", overflow: "scroll", marginLeft: "17px", marginRight: "17px", marginBottom: "20px" }}> 
            {featuredList &&
              featuredList.map((product: any) => {
                return (
                  <div
                    className="popular-item-container"
                    onClick={() => {
                      if (product.enabled) {
                        setSelectedProduct(product);
                      }
                    }}
                    key={product.id}
                    style={{ cursor: "pointer", marginRight: "11px" }}
                  >
                    {product.imageUrl ? <img src={product.imageUrl} alt="" style={{ objectFit: "cover", width: "135px", height: "127px", marginBottom: "5px" }} /> : <div style={{ width: "135px", height: "127px", backgroundColor: "#F2F2F2", marginBottom: "12px" }}></div>}
                    <div style={{ fontSize: "12px", fontFamily: "Axiforma-ExtraBold", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{product.nameExternal}</div>
                    <div style={{ fontSize: "10px", fontFamily: "Axiforma-Regular" }}>${(product.price * 0.01).toFixed(2)}</div>
                  </div>
                );
              })}
          </div>


          {featuredList && featuredList.length ? <hr className="hrLine" /> : null}
          <br />

          <h2 style={{ margin: "0px", padding: "0px 20px", fontSize: "17px", fontFamily: "Axiforma-ExtraBold" }}>Menu Categories</h2>
          {selectedMenu.categories.map((category: string) => {
            return menuCategories.map((cat: MenuCategory) => {
              if (category === cat.id) {
                return (
                  <div
                    key={cat.id}
                    style={{ margin: "26px 0px", paddingLeft: "20px", paddingTop: "2px" }}
                    onClick={() => {
                      setSelectedMenuCategory(cat);
                      const list = createPopularInCategory(cat, products);
                      setPopularList(list);
                      setOpenViewMenuCategory(true)
                      window.scrollTo(0, 0); //after click category bring them to top
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingBottom: "10px",
                      }}
                    >
                      <div>
                        <h3 style={{ margin: "0px", fontSize: "14px", fontFamily: "Axiforma-Bold" }}>{cat.name}</h3>
                        <p style={{ fontSize: "12px", marginTop: "5px" }}>{cat.products.length} items</p>
                      </div>
                      <Button icon="arrow-right" className={`${Classes.MINIMAL} order-from-button `}>
                        {" "}
                      </Button>
                    </div>
                    <hr className="hrLine" />
                  </div>
                );
              }
            });
          })}
        </div>
      }

      {openViewMenuCategory && businessSettings && selectedMenu && selectedMenuCategory &&
        <ViewMenuCategory
          addItemToCart={addItemToCart}
          business={business}
          businessSettings={businessSettings}
          itemsInCart={itemsInCart}
          modifiers={modifiers}
          modifierOptions={modifierOptions}
          popularList={popularList}
          products={products}
          selectedMenu={selectedMenu}
          selectedMenuCategory={selectedMenuCategory}
          setOpenViewMenuCategory={setOpenViewMenuCategory}
        />
      }

      {/* Floating Checkout button when user cart has items */}      
      {itemsInCart.length > 0 && 
        <div style={{ position: "fixed", bottom: "0px", width: "100%", backgroundColor: "white", padding: "20px" }}>
          <Button
            id="checkout-cart-button"
            onClick={() => {
              setOpenPayDialog(false)
              setOpenViewCart(true);
            }}
            style={{ width: "100%", margin: "0px" }}
            intent="primary"
            large
          >
            <span style={{ fontSize: "12px", position: "absolute", left: "20px", top: "15px", fontFamily: "Axiforma-Regular" }}>  
              {itemsInCart.reduce((totalCount:number, item:ItemsInCart)=> {
                  return totalCount + item.quantity;
                },0)
              } items
            </span>
            <span style={{ paddingLeft: "4em", paddingRight: "4em" }}>Checkout</span>
            <span style={{ fontSize: "12px", position: "absolute", right: "20px", top: "15px", fontFamily: "Axiforma-Regular" }}>
              ${(calculateSubtotalInOrder(itemsInCart ) * 0.01).toFixed(2)}
            </span>
          </Button>
        </div>
      }

      {openViewCart && itemsInCart.length > 0 && businessSettings && user && !openPayDialog &&
        <ViewCart 
          business={business}
          businessSettings={businessSettings}
          itemsInCart={itemsInCart}
          menus={menus}
          taxRate={taxRate}
          user={user}
          tipAmount={tipAmount}
          removeItemInCart={removeItemInCart}
          setChangeTip={setChangeTip}
          setUser={setUser} 
          setOpenPayDialog={setOpenPayDialog} 
          setOpenViewCart={setOpenViewCart}
          setSuccessfulOrder={setSuccessfulOrder}
          successfulOrder={successfulOrder}    
          updateOrder={updateOrder}   
          setNewTaxRate={setNewTaxRate} 
          setItemsInCart={setItemsInCart}
        />
      }


      {/** PAY COMPONENT */}
      {openPayDialog && businessSettings && menus && user && order &&
        <Pay 
          business={business}
          businessSettings={businessSettings}
          deliverectToken={deliverectToken}
          itemsInCart={itemsInCart}
          menus={menus} 
          taxRate={taxRate}
          tipAmount={tipAmount}
          user={user}
          setOpenViewCart={setOpenViewCart}
          setOpenPayDialog={setOpenPayDialog}
          order={order}   
          setSuccessfulOrder={setSuccessfulOrder}    
        />
      }
    </div>
  );
};

export default ViewLocation;
