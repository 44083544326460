import {Button, ButtonGroup, Classes, Dialog, Drawer, Icon, InputGroup, Position, TextArea } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { Business, ItemsInCart, ModifierOption, Order, SelectedModifiers, TangoBusinessSettings, TangoMenu, TangoModifier, TangoProduct, User } from "../../types/types";
import { calculateDeliveryDistance, calculateSubtotalInOrder, calculateTaxOnOrder, getBusinessHours, validateNewPickupTime } from "../../utils/functions";
import moment from "moment";
import { fetchUser } from "../../db/GetData";
import Flatpickr from "react-flatpickr";

interface ViewCartProps {
    business: Business
    businessSettings: TangoBusinessSettings;
    itemsInCart: ItemsInCart[];
    menus: TangoMenu[];
    taxRate: number;
    user: User;
    tipAmount: number;
    setNewTaxRate : (amount:number) => void;
    updateOrder: (newOrder:Order) => void;
    removeItemInCart: ([]) => void;
    setChangeTip: (amount:number) => void;
    setUser:(user:User) => void;
    setOpenPayDialog: (open:boolean) => void;
    setOpenViewCart:(open:boolean) => void;
    setSuccessfulOrder:(successfulOrder:boolean) => void;
    successfulOrder: boolean;
    setItemsInCart: ([]) => void;
}

const ViewCart = ({business, businessSettings, itemsInCart, menus, taxRate, tipAmount, user, setNewTaxRate, removeItemInCart, updateOrder, setChangeTip, setUser, setOpenPayDialog, setOpenViewCart, setSuccessfulOrder, successfulOrder, setItemsInCart}: ViewCartProps) => {

    const [appliedGiftCardAmount, setAppliedGiftCardAmount] = useState<number>(0);
    const [availableOrderTypes, setAvailableOrderTypes] = useState<string[]>([''])
    const [businessClosedWarning, setBusinessClosedWarning] = useState("");
    const [deliveryAddress, setDeliveryAddress] = useState("");
    const [deliveryFee, setDeliveryFee] = useState(businessSettings.deliveryFee ? businessSettings.deliveryFee : 0);
    const [isGiftCardAppliedFully, setIsGiftCardAppliedFully] = useState<boolean>(false);
    const [openSchedulePickup, setOpenSchedulePickup] = useState(false);
    const [orderType, setOrderType] = useState('carryOut');
    const [pickupWarning, setPickupWarning] = useState("");
    const [scheduleDateTime, setScheduleDateTime] = useState<any>();
    const [openCustomTip, setOpenCustomTip] = useState(false);
    const [tipselection, setTipSelection] = useState<any>(businessSettings.onlineTipPresetPercentages[1]); 
    const [showTipBar, setShowTipBar] = useState(false);

    //@ts-ignore
    const [selectedGiftCard, setSelectedGiftCard] = useState<GiftCardUser>(null);
    const [totalAmount, setTotalAmount] = useState(0.0); //default to grand total in order
    const [unavailableWarning, setUnavailableWarning] = useState("");
    const [waitTimes, setWaitTime] = useState([15, 25]); //to do - update these on load component
    const [order, setOrder] = useState<Order>({
        DSP: null,
        amount: {
          currency: business.currency === "CAD" || business.currency === "cad" ? "CAD" : "USD",
          deliveryFee: 0,
          discountTotal: 0,
          grossTotal: 0,
          netTotal: 0,
          serviceChargeTotal: 0,
          subTotal: calculateSubtotalInOrder(itemsInCart),
          tax: 0,
          tip: Math.round(tipAmount * 100), //convert back to cents
        },
        businessId: business.id,
        cancelled: false,
        completed: true, //default to true
        createdAt: new Date(),
        deleted: false,
        deliverect: null,
        deliveryAddress: null,
        discountsAppliedToOrder: [],
        discountedStaffMemberId: '',
        drawerId: null,
        id: '',
        isPrinted: false,
        isStaffMemberDiscount: false,
        orderApp: "pwa",
        orderChannel: orderType === "dineIn" ? "tangoPOS" : "mobile", //if dine in change to tangoPOS?
        orderNumber: JSON.stringify(Math.floor(Math.random() * 99999) + 1),
        orderType: orderType,
        pickupTime: null,
        products: itemsInCart,
        renderOrderInQueue: true,
        serviceAreaId: "none",
        sessionId: null,
        specialInstructions: "",
        staffId: "",
        status: "received",
        tabId: "",
        tableId: "",
        tableNumber: null,
        updatedAt: new Date(),
        turnaroundTime: waitTimes,
        customerName: "",
        extraInformation: null,
        voidedProducts: []
    });
    
    useEffect(() => {

      if(successfulOrder){
        //dont reset default variables after a successful order - this way user can see the order they just placed
        return
      }
        if(itemsInCart.length > 0){
            //calculate default tip
            if(calculateSubtotalInOrder(itemsInCart) > businessSettings.onlineMinimumTipForPercentagePresets * 100){
                setTipSelection(businessSettings.onlineTipPresetPercentages[1])
                handleApplyTip(false, businessSettings.onlineTipPresetPercentages[1]);
            }else{
                setTipSelection(businessSettings.onlineTipPresetAmounts[1])
                handleApplyTip(true, businessSettings.onlineTipPresetAmounts[1]);
            }
        }
        order.products = itemsInCart
    },[itemsInCart])

    useEffect(()=>{
        //set available order types user can pick from
        const orderTypes: string[] = [];
        business.tangoProducts.forEach((tangoProduct: any) => {
            if (tangoProduct.name === "mobile") {
                tangoProduct.orderTypes.forEach((type: any) => {
                    if (type.name === orderType) {
                      setNewTaxRate(type.taxRate);
                    }
                })
                tangoProduct.orderTypes.forEach((orderType: any) => {
                    if ((orderType.name === "carryOut" || orderType.name === "curbside") && orderType.enabled) {
                      orderTypes.push(orderType.name);
                    }
                });
            }
        });
        setAvailableOrderTypes(orderTypes);


        if(successfulOrder){
          //dont reset default variables after a successful order - this way user can see the order they just placed
          return
        }
        
        order.amount.tax = calculateTaxOnOrder(itemsInCart, taxRate);
        order.amount.grossTotal = order.amount.subTotal + order.amount.tax + order.amount.discountTotal;
        order.amount.netTotal = order.amount.grossTotal - order.amount.discountTotal;
        setTotalAmount(calculateSubtotalInOrder(order.products) + calculateTaxOnOrder(order.products, taxRate) + order.amount.tip)

        //need to refactor check product availablity
        // checkProductAvailability()

        if(!user.stripe.id || user.stripe.cards.length < 1){
          reloadUser()
        }

        updateOrder(order)
    },[])

    const reloadUser = async () => {
        try {
          const reloadedUser = await fetchUser();
          if (reloadedUser) {
            setUser(reloadedUser);
          }
        } catch (e) {
          console.error("error getting user", e);
        }
    };

    const changeOrderType = (selectedOrderType: string) => {
        setOrderType(selectedOrderType);
        getTaxRate(selectedOrderType);
        order.orderType = selectedOrderType;
        if (selectedOrderType !== "delivery") {
          setDeliveryAddress("");
          order.deliveryAddress = null;
        }
    };

    //get new tax rate and recalculate
    const getTaxRate = (selectedType:string) => {
        business.tangoProducts.forEach((tangoProduct: any) => {
            if (tangoProduct.name === "mobile") {
              return tangoProduct.orderTypes.forEach((type: any) => {
                if (type.name === selectedType) {  
                  setNewTaxRate(type.taxRate);
                  order.amount.subTotal = calculateSubtotalInOrder(itemsInCart);
                  order.amount.tax = calculateTaxOnOrder(itemsInCart, type.taxRate);
                  order.amount.grossTotal = order.amount.subTotal + order.amount.tax + order.amount.discountTotal;
                  order.amount.netTotal = order.amount.grossTotal - order.amount.discountTotal;
                }
              })
            }
        });
    }

    const handleApplyTip = (dollar: boolean, amount: number) => {
        if(dollar){
            setChangeTip(amount)
            order.amount.tip = amount * 100
        }else{
            setChangeTip((calculateSubtotalInOrder(itemsInCart) * (amount * 0.01)) * 0.01)
            order.amount.tip = (calculateSubtotalInOrder(itemsInCart) * (amount * 0.01)) * 0.01
        }
    };

    const checkProductAvailability = () => {
        if (order.pickupTime) {
          setUnavailableWarning("");
          setPickupWarning("");
          setBusinessClosedWarning(""); 
          let pickupDay = moment(order.pickupTime).format("dddd").toLowerCase();
          let pickupTime = parseFloat(moment(order.pickupTime).format("HH.mm"));
          itemsInCart.forEach((product: ItemsInCart) => {
            let menuDays: string[] = [];
            menus.forEach((menu: TangoMenu) => {
              if (product.menuName === menu.nameExternal) {
                menu.hours.forEach((timeslot: any) => {
                  menuDays.push(timeslot.day);
                  if (timeslot.day === pickupDay) {
                    if (pickupTime < timeslot.openTime || pickupTime > timeslot.closeTime) {
                        console.log('not available')
                      setPickupWarning("Items from " + menu.nameExternal + " menu are not available at that pickup time");
                    }
                  }
                });
              }
            });
            if (!menuDays.includes(pickupDay)) {
                console.log('not available')
              setPickupWarning("Items in your order are not available at that pickup time");
            }
          });
        } else{
            setPickupWarning("");
            let warning = "";
            itemsInCart.forEach((product: ItemsInCart) => {
                menus.forEach((menu: TangoMenu) => {
                    if (menu.currentlyUnavailable && product.menuName === menu.nameExternal) {
                        console.log("product in order is on a menu currently unavailable");
                        warning = "Items from " + menu.nameExternal + " menu are currently unavailable - please schedule a delivery or pickup time";
                    }
                });
            });
            setUnavailableWarning(warning);
        }
    };

    const removeItem = (index: number) => {
        removeItemInCart(itemsInCart.filter((_, i) => i !== index));
        if(itemsInCart.filter((_, i) => i !== index).length < 1){
          setOpenViewCart(false)
        }
    };
    

    return(
      <Drawer
        className="drawer"
        isOpen={true}
        onClose={() => {
          setOpenViewCart(false);
        }}
        // title=""
        size="100%"
        position={Position.BOTTOM}
      >
        <div className={Classes.DRAWER_BODY}>
          <div style={{ backgroundColor: "#F5F5F5", padding: ".5em", position: "fixed", top: "0px", width: "100%", zIndex: 1 }}>
            <Button
              onClick={() => {
                setOpenViewCart(false);
              }}
              icon="arrow-left"
              style={{ position: "absolute", left: "10px", top: "11px" }}
              className={`${Classes.MINIMAL} order-from-button`}
            >
              {" "}
            </Button>
            <h3 style={{ marginLeft: "2em", marginRight: "2em", textAlign: "center", fontFamily: "Axiforma-ExtraBold", fontSize: "15px" }}>
              {" "}
              {orderType === "bar" ? "Bar order at" : orderType === "dineIn" ? "Dine In at " : orderType === "carryOut" ? "Carry Out from " : orderType === "delivery" ? "Delivery from " : ""} {business.businessName}
            </h3>
          </div>

          <div style={{ marginLeft: "1em", marginRight: "1em", backgroundColor: "white" }}>
            <ButtonGroup fill style={{ backgroundColor: "white", borderRadius: "100px", marginTop: "6.5em" }}>
              {availableOrderTypes &&
                availableOrderTypes.map((availableType, index) => {
                  return (
                    <Button
                      disabled={successfulOrder}
                      onClick={() => {
                        if(availableType === "delivery"){
                          //and if previous selection is not delivery
                          if (order.orderType !== "delivery") {
                            order.amount.deliveryFee = businessSettings.deliveryFee;
                            setTotalAmount(totalAmount + businessSettings.deliveryFee);
                          }
                        }else{
                          if (order.orderType === "delivery") {
                            order.amount.deliveryFee = 0;
                            setTotalAmount(totalAmount - businessSettings.deliveryFee);
                          }
                        }
                        changeOrderType(availableType);
                      }}
                      key={index}
                      minimal
                      style={{
                        borderRadius: "100px",
                        color: orderType === availableType ? "white" : "black",
                        backgroundColor: orderType === availableType ? "black" : "white",
                      }}
                    >
                      {availableType === "carryOut" ? "Carry Out" : availableType === "dineIn" ? "Dine In" : availableType === "bar" ? "Bar" : availableType === "curbside" ? "Curbside" : availableType === "delivery" ? "Delivery" : ""}
                    </Button>
                  );
                })}
            </ButtonGroup>
          </div>

          {unavailableWarning && !businessClosedWarning && 
            <div onClick={() => setOpenSchedulePickup(true)} style={{ borderRadius: "3px", color: "white", backgroundColor: "#daa520", textAlign: "center", margin: "1em", cursor: "pointer" }}>
              <p style={{ padding: "3px" }}>{unavailableWarning}</p>
            </div>
          }
          {pickupWarning && 
            <div onClick={() => setOpenSchedulePickup(true)} style={{ borderRadius: "3px", color: "white", backgroundColor: "#daa520", textAlign: "center", margin: "1em", cursor: "pointer" }}>
              <p style={{ padding: "3px" }}>{pickupWarning}</p>
            </div>
          }

          {businessClosedWarning && !order.pickupTime && 
            <div onClick={() => setOpenSchedulePickup(true)} style={{ borderRadius: "3px", color: "white", backgroundColor: "#daa520", textAlign: "center", margin: "1em", cursor: "pointer" }}>
              <h4 style={{ padding: "6px" }}>{businessClosedWarning}</h4>
            </div>
          }


        {/* SHOW iTEMS IN CART */}
          <div className={Classes.DIALOG_BODY} style={{ marginTop: "8%" }}>
            {itemsInCart.map((itemInCart: ItemsInCart, index: number) => {
              return (
                <div key={index}>
                  <span>
                    <p style={{ float: "left", fontSize: "10px" }}>{itemInCart.quantity}</p>

                    <h3 style={{ paddingLeft: "20px", fontSize: "14px", fontFamily: "Axiforma-ExtraBold", marginBottom: "5px" }}> {itemInCart.name}</h3>

                    {itemInCart.selectedModifiers.length ?
                      <div style={{ paddingLeft: "20px", paddingBottom: ".5em" }}>
                        {itemInCart.selectedModifiers.map((mod: SelectedModifiers) => {
                          return mod.optionNames.map((optionName: string, index:number) => {
                            return (
                              <span key={index} style={{ marginRight: ".5em", fontSize: "12px" }}>
                                {optionName},
                              </span>
                            );
                          });
                        })}
                      </div>
                     : 
                     null
                    }
                    <Button 
                      disabled={successfulOrder}
                      onClick={() => removeItem(index)} 
                      minimal 
                      intent="danger" 
                      style={{ marginLeft: "20px", cursor: "pointer", paddingLeft: "0px", fontFamily: "Axiforma-ExtraBold", fontSize: "10.5px" }}
                    >
                      Remove
                    </Button>
                  </span>
                  <hr className="hrLine" />
                </div>
              );
            })}

            <div style={{ width: "100%", paddingTop: "5%", backgroundColor: "white" }}>
              <div style={{ fontFamily: "Axiforma-Regular", fontSize: "12px", paddingBottom: "10px" }}>
                {businessSettings && businessSettings.scheduledOrders && (orderType === "carryOut" || orderType === "curbside" || orderType === "delivery") && (availableOrderTypes.includes("carryOut") || availableOrderTypes.includes("curbside") || availableOrderTypes.includes("delivery")) && 
                  <div
                    style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", cursor:'pointer' }}
                    onClick={() => {
                      console.log('click open pickup')
                      if(successfulOrder){
                        return
                      }
                      setOpenSchedulePickup(true);
                    }}
                  >
                    <div>Schedule the order for later?</div>
                    <div>
                      {businessSettings && businessSettings.scheduledOrders && !order.pickupTime && <Icon icon="chevron-right" style={{ color: "gray" }}></Icon>}
                      {order.pickupTime && !openSchedulePickup && moment(order.pickupTime).format("MMMM Do[,] hh:mm a")}
                      {order.pickupTime && 
                        <Button
                          onClick={() => {
                            if(successfulOrder){
                              return
                            }
                            order.pickupTime = null;
                            setOpenSchedulePickup(false);
                            setScheduleDateTime(null);
                            // checkProductAvailability();
                          }}
                          disabled={businessSettings.scheduledOrdersRequired || successfulOrder}
                          icon="cross"
                          small
                          minimal
                        ></Button>
                      }
                    </div>
                  </div>
                }


                {/* ORDER TOTALS */}
                {order && order.amount.discountTotal ?
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                        <div>Discount</div>
                    <div style={{ color: "#2FB24C" }}>- ${(order.amount.discountTotal * 0.01).toFixed(2)}</div>
                    </div>
                : 
                    null
                }

                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                  <div>Subtotal</div>
                  <div>${(calculateSubtotalInOrder(itemsInCart) * 0.01).toFixed(2)}</div>
                </div>

                {order.orderType === "delivery" && 
                  <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div>Delivery Fee</div>
                    <div>${(order.amount.deliveryFee * 0.01).toFixed(2)}</div>
                  </div>
                }

                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                  <div>
                    Tip 
                      <span style={{ color: "#2FB24C", fontSize: "14px", fontFamily: "Lato-Semibold", marginLeft: "10px", cursor: "pointer" }} onClick={() => {
                        if(successfulOrder){
                          return
                        }
                        setShowTipBar(!showTipBar)}
                      }>
                        Change
                      </span>
                  </div>
                  <div>${(tipAmount).toFixed(2)}</div>
                </div>



                <Dialog
                  // icon="add"
                  isOpen={openCustomTip}
                  onClose={() => setOpenCustomTip(false)}
                  title="Custom Tip Amount"
                  style={{ width: "85%" }}
                >
                  <div style={{ padding: "2em" }}>
                    <InputGroup
                      onChange={(e: any) => {
                        //make sure tip is positive #
                        if (e.currentTarget.value.includes("-")) {
                          handleApplyTip(true, parseFloat(e.target.value.replace("-", "")) )
                        } else {
                          handleApplyTip(true, parseFloat(e.target.value ))
                        }
                      }}
                      leftIcon="dollar"
                      large
                      id="customTipValue"
                      placeholder="5.00"
                      type="number"
                      // minimum="0.0"
                      style={{ width: "50%" }}
                    />
                    <br />
                    <hr className="hrLine" />
                    <br />
                    <Button onClick={() => {
                        setOpenCustomTip(false) 
                      }} 
                      large intent="success">
                      Save Tip
                    </Button>
                  </div>
                </Dialog>

                {showTipBar &&
                  <div style={{ display: "flex", backgroundColor: "#F9F9F9", padding: "9px 17px", borderRadius: "5px", marginTop: "18px", marginBottom: "18px" }}>
                    {calculateSubtotalInOrder(itemsInCart) > businessSettings.onlineMinimumTipForPercentagePresets * 100 ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flex: "1",
                            padding: "8px 5px",
                            fontSize: "14px",
                            fontFamily: "Lato-Semibold",
                            backgroundColor: tipselection === businessSettings.onlineTipPresetPercentages[0] ? "#000000" : "transparent",
                            color: tipselection === businessSettings.onlineTipPresetPercentages[0] ? "#fff" : "#231F20",
                            cursor: "pointer",
                            justifyContent: "center",
                            borderRadius: "5px",
                          }}
                          onClick={() => {
                            setTipSelection(businessSettings.onlineTipPresetPercentages[0]);
                            handleApplyTip(false, businessSettings.onlineTipPresetPercentages[0]);
                          }}
                        >
                          {businessSettings.onlineTipPresetPercentages[0]}%
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flex: "1",
                            padding: "8px 5px",
                            fontSize: "14px",
                            fontFamily: "Lato-Semibold",
                            backgroundColor: tipselection === businessSettings.onlineTipPresetPercentages[1] ? "#000000" : "transparent",
                            color: tipselection === businessSettings.onlineTipPresetPercentages[1] ? "#fff" : "#231F20",
                            cursor: "pointer",
                            justifyContent: "center",
                            borderRadius: "5px",
                          }}
                          onClick={() => {
                            setTipSelection(businessSettings.onlineTipPresetPercentages[1]);
                            handleApplyTip(false, businessSettings.onlineTipPresetPercentages[1]);
                          }}
                        >
                          {businessSettings.onlineTipPresetPercentages[1]}%
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flex: "1",
                            padding: "8px 5px",
                            fontSize: "14px",
                            fontFamily: "Lato-Semibold",
                            backgroundColor: tipselection === businessSettings.onlineTipPresetPercentages[2] ? "#000000" : "transparent",
                            color: tipselection === businessSettings.onlineTipPresetPercentages[2] ? "#fff" : "#231F20",
                            cursor: "pointer",
                            justifyContent: "center",
                            borderRadius: "5px",
                          }}
                          onClick={() => {
                            setTipSelection(businessSettings.onlineTipPresetPercentages[2]);
                            handleApplyTip(false, businessSettings.onlineTipPresetPercentages[2]);
                          }}
                        >
                          {businessSettings.onlineTipPresetPercentages[2]}%
                        </div>
                        <div
                          style={{ display: "flex", flex: "1", padding: "8px 5px", fontSize: "14px", fontFamily: "Lato-Semibold", backgroundColor: tipselection === "Custom" ? "#000000" : "transparent", color: tipselection === "Custom" ? "#fff" : "#231F20", cursor: "pointer", justifyContent: "center", borderRadius: "5px" }}
                          onClick={() => {
                            setTipSelection("Custom");
                            setOpenCustomTip(true);
                          }}
                        >
                          Custom
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flex: "1",
                            padding: "8px 5px",
                            fontSize: "14px",
                            fontFamily: "Lato-Semibold",
                            backgroundColor: tipselection === businessSettings.onlineTipPresetAmounts[0] ? "#000000" : "transparent",
                            color: tipselection === businessSettings.onlineTipPresetAmounts[0] ? "#fff" : "#231F20",
                            cursor: "pointer",
                            justifyContent: "center",
                            borderRadius: "5px",
                          }}
                          onClick={() => {
                            setTipSelection(businessSettings.onlineTipPresetAmounts[0]);
                            handleApplyTip(true, businessSettings.onlineTipPresetAmounts[0]);
                          }}
                        >
                          ${businessSettings.onlineTipPresetAmounts[0]}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flex: "1",
                            padding: "8px 5px",
                            fontSize: "14px",
                            fontFamily: "Lato-Semibold",
                            backgroundColor: tipselection === businessSettings.onlineTipPresetAmounts[1] ? "#000000" : "transparent",
                            color: tipselection === businessSettings.onlineTipPresetAmounts[1] ? "#fff" : "#231F20",
                            cursor: "pointer",
                            justifyContent: "center",
                            borderRadius: "5px",
                          }}
                          onClick={() => {
                            setTipSelection(businessSettings.onlineTipPresetAmounts[1]);
                            handleApplyTip(true, businessSettings.onlineTipPresetAmounts[1]);
                          }}
                        >
                          ${businessSettings.onlineTipPresetAmounts[1]}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flex: "1",
                            padding: "8px 5px",
                            fontSize: "14px",
                            fontFamily: "Lato-Semibold",
                            backgroundColor: tipselection === businessSettings.onlineTipPresetAmounts[2] ? "#000000" : "transparent",
                            color: tipselection === businessSettings.onlineTipPresetAmounts[2] ? "#fff" : "#231F20",
                            cursor: "pointer",
                            justifyContent: "center",
                            borderRadius: "5px",
                          }}
                          onClick={() => {
                            setTipSelection(businessSettings.onlineTipPresetAmounts[2]);
                            handleApplyTip(true, businessSettings.onlineTipPresetAmounts[2]);
                          }}
                        >
                          ${businessSettings.onlineTipPresetAmounts[2]}
                        </div>
                        <div
                          style={{ display: "flex", flex: "1", padding: "8px 5px", fontSize: "14px", fontFamily: "Lato-Semibold", backgroundColor: tipselection === "Custom" ? "#000000" : "transparent", color: tipselection === "Custom" ? "#fff" : "#231F20", cursor: "pointer", justifyContent: "center", borderRadius: "5px" }}
                          onClick={() => {
                            setTipSelection("Custom");
                            setOpenCustomTip(true);
                          }}
                        >
                          Custom
                        </div>
                      </>
                    )}
                  </div>
                }

                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                  <div>Tax</div>
                  <div>${(calculateTaxOnOrder(itemsInCart, taxRate) * 0.01).toFixed(2)}</div>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                  <div>Total</div>
                  <div>
                    ${((calculateSubtotalInOrder(itemsInCart) + calculateTaxOnOrder(itemsInCart, taxRate) ) * 0.01 + tipAmount).toFixed(2)}
                  </div>
                </div>

              </div>


              {/* SELECT PICKUP TIME  */}
              <div style={{ fontFamily: "Axiforma-Regular", fontSize: "12px" }}>
                {(orderType === "carryOut" || orderType === "curbside" || orderType === "delivery") && (availableOrderTypes.includes("carryOut") || availableOrderTypes.includes("curbside") || availableOrderTypes.includes("delivery")) && 
                  <p>
                    <span>
                      <Dialog icon="calendar" isOpen={openSchedulePickup} onClose={() => setOpenSchedulePickup(false)} canOutsideClickClose={false} title="Pickup Time" style={{ width: "95%", marginBottom: "70%" }}>
                        <div style={{ fontSize: "16px", padding: "1em", textAlign: "center" }}>
                          <div
                            style={{
                              fontSize: "15px",
                              fontFamily: "Axiforma-Bold",
                              color: "#000",
                              paddingLeft: "20px",
                              marginTop: "20px",
                              marginBottom: "5px",
                              textAlign: "left",
                            }}
                          >
                            Select pickup time
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              fontFamily: "Axiforma-Bold",
                              color: "#5F5F5F",
                              paddingLeft: "20px",
                              marginBottom: "30px",
                              textAlign: "left",
                            }}
                          >
                            {getBusinessHours(business.hours)}
                          </div>

                          <Flatpickr
                            data-enable-time
                            value={scheduleDateTime}
                            onChange={([date]:any) => {
                              // this.setState({ date });
                              order.pickupTime = date
                            }}
                          />


                          <br />
                          <br />
                          <br />

                          <Button
                            minimal
                            onClick={() => {
                              const valid = validateNewPickupTime(order.pickupTime, business)
                              if(valid){
                                setUnavailableWarning('')
                                setBusinessClosedWarning('')
                                setPickupWarning('')
                                setOpenSchedulePickup(false);
                              }else{
                                alert(business.businessName + " is not open at your requested pickup time.")
                                order.pickupTime = null
                                setScheduleDateTime(null);
                              }
                            }}
                            style={{ margin: "auto", width: "50%", color: "white", backgroundColor: "#137CBD", fontFamily: "Axiforma-Regular" }}
                          >
                            Save
                          </Button>

                          <br />
                          <br />
                        </div>
                      </Dialog>
                    </span>
                  </p>
                }
              </div>


            {/* SUCCESS ORDER MESSAGE */}
            {successfulOrder &&
              <div style={{textAlign:'center', padding: "2em", fontFamily: "Axiforma-Regular"}}>
                <h2> 🎉  Success!  🎉</h2>
                <p>Your order has been placed.</p>
                <p> You will receive an email receipt of your order shortly.</p>

                <br/>
          
                <img style={{width:'30%'}} src={business.profileImageUrl} alt="" />

                <br/> <br/> <br/>

                <Button 
                  large
                  onClick={() => {
                    setSuccessfulOrder(false)
                    setItemsInCart([])
                  }} 
                >
                  Place another order
                </Button>
              </div>
            }
              

            </div>
          </div>
        </div>

        {/* PAY BUTTON */}
        {!successfulOrder &&
        <div style={{ position: "fixed", bottom: "0px", width: "100%", padding: "1em", marginTop: "10px", backgroundColor: "white" }}>
            <Button
                disabled={successfulOrder}
                onClick={() => {
                    if (businessSettings && businessSettings.scheduledOrdersRequired && !order.pickupTime) {
                        return alert(business.businessName + " requires a pickup time for all online orders. Please schedule a pickup date and time.");
                    }
                  setOpenPayDialog(true);         
                }}
                style={{ width: "100%", background: "#2FB24C", boxShadow: "none", borderRadius: "100px", position: "relative" }}
                intent="success"
                large
            >
                <span style={{ fontSize: "12px", position: "absolute", left: "20px", top: "15px" }}> 
                    {itemsInCart.reduce((totalCount:number, item:ItemsInCart)=> {
                        return totalCount + item.quantity;
                        },0)
                    } items
                </span>

                <span style={{ fontSize: "14px", fontFamily: "Axiforma-Bold" }}>Pay</span>
                <span style={{ fontSize: "12px", position: "absolute", right: "20px", top: "15px" }}>
                    ${((calculateSubtotalInOrder(itemsInCart) + calculateTaxOnOrder(itemsInCart, taxRate) ) * 0.01 + tipAmount).toFixed(2)}
                </span>
            </Button>
        </div>
        }
        

      </Drawer>
    )

}

export default ViewCart

