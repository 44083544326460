import React, { useEffect, useState } from 'react';
import './index.css';
import {Button, Icon} from "@blueprintjs/core";
import {useHistory, useParams, useLocation} from 'react-router-dom';
import { fetchSingleGiftCard, GiftCardUser } from '../db/Cards';
import MobileHeader from './MobileHeader';

interface StateType{
    card: GiftCardUser | null;
    userCards: GiftCardUser[] | null;
}
const Card = (props:any) => {
    const history = useHistory();
    const location = useLocation();
    const state  = location.state as StateType;
    const {id} = useParams<{id: string}>();
    const [card, setCard] = useState<GiftCardUser| null>(null);
    const [userCards, setUserCards] = useState<GiftCardUser[]>([]);

    useEffect(()=>{
        const fetchData = async()=> {
            const userId  = localStorage.getItem('tango-mobile-userId');
            if(userId){
                const response = await fetchSingleGiftCard(id);
                if(response.data){
                    setCard(response.data);
                }
            }
        }
        if(state){
           if(state.card){
               setCard(state.card)
           }
           if(state.userCards){
               setUserCards(state.userCards)
           }
        }else{
            fetchData();
        }
    }, [])
    if(window.innerWidth <= 960){
        return (
            <div className='gift-cards-mobile'>
                <MobileHeader />
                {
                    card &&  <div className='single-card-page'>
                    <div className='card-amount'>${card.balance.toFixed(2)}</div>
                    <img src={card.imageUrl}/>
                    <div style={{display:'flex', flexDirection:'column', marginTop: '38px'}}>
                        <Button text="Send Money" className="send-money-button" onClick={()=> history.push(`/sendMoney/${card.id}`,{userCards: userCards, card: card})} />
                        <Button text="Add Money" className="add-money-button" onClick={()=> history.push('/add-money-form/'+card?.id) } />
                    </div>
               </div>
                }
            </div>
        )
    }
    return (
        <div className="gift-cards-page">
            <div className="header">
                <div className="left-part">
                    <img className="logo" src={"https://firebasestorage.googleapis.com/v0/b/tango-2.appspot.com/o/businesses%2F5f57add1f3555707185dafae%2FprofileImage.jpeg?alt=media&token=3a348ab5-9669-4e66-b47c-ab21d6cce337"} />
                    <div className="menu-item">
                        Order
                    </div>
                    <div className="menu-item">
                        Reservations
                    </div>
                    <div className="menu-item active">
                        Cards
                    </div>
                    <div className="menu-item">
                        Gift
                    </div>
                </div>
                <div className="right-part">
                    <div className="menu-item">
                        Locations
                    </div>
                    <div className="menu-item active">
                        Account
                    </div>
                </div>
            </div>
            <div className="body">
                <div className="left-part">
                    <div className="side-nav">
                        <div className="nav-title">The Coop Cards</div>
                        <div className={`side-nav-item  active`}>Balance</div>
                        <div className={`side-nav-item`} onClick={()=> {
                       history.push('/add-money-form/'+card?.id)
                    }}>Add</div>
                    </div>
                </div>
                <div className="right-part">
                    <div className="select-card-header no-border">
                        <div className="back-button" onClick={()=> history.goBack()}>
                            <Icon icon="chevron-left" /> Back
                        </div>
                    </div>
                    {
                       card && <div className="cards-collection">
                        <div className="card-container">
                            <div className="card-overlay"></div>
                            <img src={card.imageUrl} className="card-image"/>
                            <div className="amount">
                                ${card.balance.toFixed(2)}
                            </div>
                            <div className="card-balance">
                                Card Balance <Icon icon="refresh" iconSize={10} />
                            </div>
                        </div>
                        <div className="add-card" onClick={()=> history.push(`/sendMoney/${card.id}`,{userCards: userCards, card: card})}>
                            Send Money
                        </div>
                    </div>
                    }
                    
                    <Button text={"Add Money"} className="add-money" onClick={()=> {
                       history.push('/add-money-form/'+card?.id)
                    }}/>
                </div>
            </div>
        </div>
    )
}
export  default  Card;
