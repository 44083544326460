import { User } from '../Auth/SignUp';
import firebase from '../config/firebase';
import { checkHours, checkIfOnlineOrderingEnabled } from '../utils/functions';
import {
  TangoAccount,
  JobPosting,
  TangoTax,
  TangoMenu,
  DiscountDoc,
  Business,
  URLMapping,
  Tab,
  Order
} from '../types/types';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import { uuid4 } from '@sentry/utils';
import { nestJS_BaseURL } from '../utils/consts';
const db = firebase.firestore();

export const getGiftCards = async (id?: string) => {
  //todo fetch by business id
  return db
    .collection('GiftCards')
    .where('businessId', '==', id)
    .get()
    .then((querySnapshot) =>
      querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    );
};

//fetchAccount account based on current url origin - then calls get business
export const fetchAccount = async () => {
  var domain = window.location.host;
  // Temporary work around to access the coop account via localhost
  // const testDomain = 'thecoopwickedchicken.menu'; //"order.mytrophypizza.com";
  // if (domain.includes('localhost')) {
  //   domain = testDomain;
  // }

  // if (domain.includes("mobile-orders-staging")) {
  //   domain = testDomain
  // }

  // if (domain.includes("ngrok")) {
  //   domain = testDomain
  // }

  console.log('fetching account for domain -->', domain);
  try {
    const account = await axios.get(
      `${nestJS_BaseURL}/accountFromDomain/${domain}`,
      {
        headers: {
          Authorization: `Bearer ${'firebaseJWT'}`
        }
      }
    );
    return account.data;
  } catch (error) {
    console.log('Error getting account:', error);
    return null;
  }
};

export const fetchBusiness = async (id: string) => {
  try {
    const business = await axios.get(`${nestJS_BaseURL}/business/${id}`, {
      headers: {
        Authorization: `Bearer ${'firebaseJWT'}`
      }
    });
    return business.data;
  } catch (error) {
    console.log('Error getting account:', error);
    return null;
  }
};

export const fetchBusinesses = async (account: TangoAccount) => {
  try {
    const businesses = await axios.get(
      `${nestJS_BaseURL}/account/${account.id}/businesses`,
      {
        headers: {
          Authorization: `Bearer ${'firebaseJWT'}`
        }
      }
    );

    const formattedBusinesses = businesses.data.map((business: Business) => {
      //time check logic to make sure businesses are open and set a flag if they are closed
      const isOpen = checkHours(business.hours, business.timezone);
      if (isOpen) {
        business.currentlyClosed = false;
      } else {
        business.currentlyClosed = true;
      }

      //pause online ordering if status is not live - user could still place scheduled order
      if (business.status !== 'live') {
        business.currentlyClosed = true;
      }

      const onlineOrderingEnabled = checkIfOnlineOrderingEnabled(business);
      if (onlineOrderingEnabled) {
        business.onlineOrderingEnabled = true;
      } else {
        business.onlineOrderingEnabled = false;
      }
      return business;
    });

    return formattedBusinesses;
  } catch (error) {
    console.log('Error getting businesses:', error);
    return null;
  }
};

export const fetchBusinessSettings = async (businessId: any) => {
  try {
    const businessSettings = await axios.get(
      `${nestJS_BaseURL}/business/${businessId}/businessSettings`,
      {
        headers: {
          Authorization: `Bearer ${'firebaseJWT'}`
        }
      }
    );
    return businessSettings.data;
  } catch (error) {
    console.log('Error getting BusinessSettings:', error);
    return null;
  }
};

export const fetchAllMenuData = async (business: any, dineIn: boolean) => {
  const timeZone = business.timezone ? business.timezone : 'America/New_York';
  const menusToDisplay: TangoMenu[] = [];
  const discountsToDisplay: DiscountDoc[] = [];

  try {
    const allMenuData = await axios.get(
      `${nestJS_BaseURL}/business/${business.id}/allMenuData`,
      {
        headers: {
          Authorization: `Bearer ${'firebaseJWT'}`
        }
      }
    );

    const menus = allMenuData.data.menus;
    const menuCategories = allMenuData.data.menuCategories;
    const products = allMenuData.data.products;
    const modifiers = allMenuData.data.modifiers;
    const modifierOptions = allMenuData.data.modifierOptions;
    const stitchedProducts = allMenuData.data.stitchedProducts;
    const discounts = allMenuData.data.discounts;

    //FORMAT MENUS TO DISPLAY
    menus.map((menu: TangoMenu) => {
      // time check logic here to make sure menus are open and set a flag if they are closed
      const isAvailable = checkHours(menu.hours, timeZone);
      if (isAvailable) {
        menu.currentlyUnavailable = false;
      } else {
        menu.currentlyUnavailable = true;
      }

      if (dineIn) {
        //only show isDineInPwaMenu menus
        if (menu.isDineInPwaMenu) {
          console.log('fetched isDineInPwaMenu');
          menusToDisplay.push(menu);
        }
      } else {
        //only show isDeliveryAndCarryOutMenu menus
        if (menu.isDeliveryAndCarryOutMenu) {
          menusToDisplay.push(menu);
        }
      }
    });

    if (dineIn && menusToDisplay.length === 0) {
      console.log(
        'no isDineInPwaMenu were fetched for menus to display, use isDineInMenu as backup'
      );
      for (const menu of menus) {
        if (menu.isDineInMenu) {
          menusToDisplay.push(menu);
        }
      }
    }

    menusToDisplay.sort((x, y) => {
      // put currently unavailable menus at end
      return x.currentlyUnavailable === y.currentlyUnavailable
        ? 0
        : x.currentlyUnavailable
        ? 1
        : -1;
    });

    //FORMAT DISCOUNTS TO DISPLAY
    discounts.map((discount: DiscountDoc) => {
      discount.createdAt = new Date(discount.createdAt._seconds * 1000);
      discount.updatedAt = new Date(discount.updatedAt._seconds * 1000);

      discount.datesToExclude = discount.datesToExclude.map((date: any) => {
        return date.dateOrDay === 'date'
          ? {
              ...date,
              startDate: new Date(date.startDate._seconds * 1000),
              endDate: new Date(date.endDate._seconds * 1000)
            }
          : date;
      });

      discount.hours = discount.hours.map((date: any) => {
        //PWA error because startDate is sometimes null so just hardcode date in past to avoid potential crash
        if (!date.startDate && !date.endDate) {
          return date.dateOrDay === 'date'
            ? {
                ...date,
                startDate: moment(
                  '2022-03-01T15:00:00.000Z',
                  'YYYY MM DD'
                ).toDate(),
                endDate: moment(
                  '2022-03-02T15:00:00.000Z',
                  'YYYY MM DD'
                ).toDate()
              }
            : date;
        } else {
          return date.dateOrDay === 'date'
            ? {
                ...date,
                startDate: new Date(date.startDate._seconds * 1000),
                endDate: new Date(date.endDate._seconds * 1000)
              }
            : date;
        }
      });

      discountsToDisplay.push(discount);
    });

    return {
      menusToDisplay,
      menuCategories,
      products,
      stitchedProducts,
      modifiers,
      modifierOptions,
      discountsToDisplay
    };
  } catch (error) {
    console.log('Error fetchAllMenuData', error);
    return {
      menusToDisplay,
      menuCategories: [],
      products: [],
      stitchedProducts: [],
      modifiers: [],
      modifierOptions: [],
      discountsToDisplay
    };
  }
};

export const fetchMenus = async (
  businessId: string,
  timeZone: string,
  dineIn: boolean
) => {
  try {
    const menus = await axios.get(
      `${nestJS_BaseURL}/business/${businessId}/menus`,
      {
        headers: {
          Authorization: `Bearer ${'firebaseJWT'}`
        }
      }
    );

    let menusToDisplay: TangoMenu[] = [];
    menus.data.map((menu: TangoMenu) => {
      // time check logic here to make sure menus are open and set a flag if they are closed
      const isAvailable = checkHours(menu.hours, timeZone);
      if (isAvailable) {
        menu.currentlyUnavailable = false;
      } else {
        menu.currentlyUnavailable = true;
      }

      if (dineIn) {
        //only show "public" and isDineInMenu menus
        if (!menu.privateMenu && menu.isDineInMenu) {
          menusToDisplay.push(menu);
        }
      } else {
        //only show "public" and isDeliveryAndCarryOutMenu menus
        if (!menu.privateMenu && menu.isDeliveryAndCarryOutMenu) {
          menusToDisplay.push(menu);
        }
      }
    });

    menusToDisplay.sort((x, y) => {
      // put currently unavailable menus at end
      return x.currentlyUnavailable === y.currentlyUnavailable
        ? 0
        : x.currentlyUnavailable
        ? 1
        : -1;
    });
    return menusToDisplay;
  } catch (error) {
    console.log('Error getting menus:', error);
    return [];
  }
};

export const fetchMenuCategories = async (businessId: string) => {
  try {
    const menuCategories = await axios.get(
      `${nestJS_BaseURL}/business/${businessId}/MenuCategories`,
      {
        headers: {
          Authorization: `Bearer ${'firebaseJWT'}`
        }
      }
    );
    return menuCategories.data;
  } catch (error) {
    console.log('Error getting BusinessSettings:', error);
    return [];
  }
};

export const fetchProducts = async (businessId: string) => {
  try {
    const products = await axios.get(
      `${nestJS_BaseURL}/business/${businessId}/products`,
      {
        headers: {
          Authorization: `Bearer ${'firebaseJWT'}`
        }
      }
    );
    return products.data;
  } catch (error) {
    console.log('Error getting products:', error);
    return [];
  }
};

export const fetchStitchedProducts = async (businessId: string) => {
  try {
    const stitchedProducts = await axios.get(
      `${nestJS_BaseURL}/business/${businessId}/stitchedProducts`,
      {
        headers: {
          Authorization: `Bearer ${'firebaseJWT'}`
        }
      }
    );
    return stitchedProducts.data;
  } catch (error) {
    console.log('Error getting products:', error);
    return [];
  }
};

export const fetchDiscounts = async (businessId: string) => {
  try {
    const discounts = await axios.get(
      `${nestJS_BaseURL}/business/${businessId}/discounts`,
      {
        headers: {
          Authorization: `Bearer ${'firebaseJWT'}`
        }
      }
    );
    let discountsToDisplay: DiscountDoc[] = [];

    discounts.data.map((discount: DiscountDoc) => {
      discount.createdAt = new Date(discount.createdAt._seconds * 1000);
      discount.updatedAt = new Date(discount.updatedAt._seconds * 1000);

      discount.datesToExclude = discount.datesToExclude.map((date: any) => {
        return date.dateOrDay === 'date'
          ? {
              ...date,
              startDate: new Date(date.startDate._seconds * 1000),
              endDate: new Date(date.endDate._seconds * 1000)
            }
          : date;
      });

      discount.hours = discount.hours.map((date: any) => {
        //PWA error because startDate is sometimes null so just hardcode date in past to avoid potential crash
        if (!date.startDate && !date.endDate) {
          return date.dateOrDay === 'date'
            ? {
                ...date,
                startDate: moment(
                  '2022-03-01T15:00:00.000Z',
                  'YYYY MM DD'
                ).toDate(),
                endDate: moment(
                  '2022-03-02T15:00:00.000Z',
                  'YYYY MM DD'
                ).toDate()
              }
            : date;
        } else {
          return date.dateOrDay === 'date'
            ? {
                ...date,
                startDate: new Date(date.startDate._seconds * 1000),
                endDate: new Date(date.endDate._seconds * 1000)
              }
            : date;
        }
      });

      discountsToDisplay.push(discount);
    });
    return discountsToDisplay;
  } catch (error) {
    console.log('Error getting discounts:', error);
    return [];
  }
};

export const fetchModifiers = async (businessId: string) => {
  try {
    const modifiers = await axios.get(
      `${nestJS_BaseURL}/business/${businessId}/modifiers`,
      {
        headers: {
          Authorization: `Bearer ${'firebaseJWT'}`
        }
      }
    );
    return modifiers.data;
  } catch (error) {
    console.log('Error getting modifiers:', error);
    return [];
  }
};

export const fetchModifierOptions = async (businessId: string) => {
  try {
    const modifierOptions = await axios.get(
      `${nestJS_BaseURL}/business/${businessId}/modifierOptions`,
      {
        headers: {
          Authorization: `Bearer ${'firebaseJWT'}`
        }
      }
    );
    return modifierOptions.data;
  } catch (error) {
    console.log('Error getting modifierOptions:', error);
    return [];
  }
};

export const createUser = async (currentUser: string) => {
  console.log('creating empty user in firestore', currentUser);

  const newUser = {
    name: '',
    color:
      'rgb(' +
      Math.floor(Math.random() * 255) +
      ',' +
      Math.floor(Math.random() * 255) +
      ',' +
      Math.floor(Math.random() * 255) +
      ')',
    createdAt: new Date(),
    deleted: false,
    enabled: true,
    email: '',
    id: currentUser,
    imageUrl: '',
    phone: '',
    stripe: {
      id: '',
      cards: []
    },
    stripeCanada: {
      id: '',
      cards: []
    },
    updatedAt: new Date(),
    uid: '',
    userType: 'mobile'
  };

  try {
    const createdUser = await axios.post(`${nestJS_BaseURL}/user`, newUser, {
      headers: {
        Authorization: `Bearer firebaseJWT`
      }
    });
    if (createdUser) {
      console.log('created empty user in firestore', newUser, createdUser);
      return newUser;
    } else {
      return null;
    }
  } catch (error) {
    console.log('Error getting user:', error);
    return null;
  }
};

export const fetchUser = async () => {
  var currentUser = firebase.auth().currentUser?.uid;
  if (!currentUser) {
    currentUser = uuid4();
  }

  try {
    const user = await axios.get(`${nestJS_BaseURL}/user/${currentUser}`, {
      headers: {
        Authorization: `Bearer ${'firebaseJWT'}`
      }
    });
    if (!user.data || !user.data.id) {
      console.log('no user in firestore with id', currentUser);
      return createUser(currentUser);
    } else {
      // console.log('fetched user', currentUser, user.data)
      if (user.data.phone) {
        user.data.phone = user.data.phone.replace('+1', ''); //replace country code so it displays in html correctly
      }
      if (!user.data.stripe.cards) {
        user.data.stripe.cards = []; //make sure cards array exists for 'legacy users' who may not have had this field created
      }
      if (!user.data.stripeCanada.cards) {
        user.data.stripeCanada.cards = []; //make sure cards array exists for 'legacy users' who may not have had this field created
      }
      return user.data;
    }
  } catch (error) {
    console.log('Error getting user:', error);
    return null;
  }
};

export const fetchUserForDineIn = async () => {
  console.log('fetch user dine in');

  const currentUser = firebase.auth().currentUser?.uid;

  return (
    db
      .collection('Users')
      //@ts-ignore
      .doc(currentUser)
      .get()
      .then((doc: any) => {
        if (doc.exists) {
          let user = doc.data();
          return user;
        } else {
          let userColor =
            'rgb(' +
            Math.floor(Math.random() * 255) +
            ',' +
            Math.floor(Math.random() * 255) +
            ',' +
            Math.floor(Math.random() * 255) +
            ')';
          return (
            db
              .collection('Users')
              //@ts-ignore
              .doc(currentUser)
              .set({
                id: currentUser,
                color: userColor,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                disabled: false,
                deleted: false,
                userType: 'mobile',
                name: '',
                phone: '',
                email: '',
                imageUrl: '',
                paymentMethods: [],
                uid: null,
                accountId: ''
              })
              .then(() => {
                console.log('Document successfully written!');
                return {
                  id: currentUser,
                  color: userColor,
                  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                  updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                  disabled: false,
                  deleted: false,
                  userType: 'mobile',
                  name: '',
                  phone: '',
                  email: '',
                  imageUrl: '',
                  paymentMethods: [],
                  uid: null,
                  accountId: ''
                };
              })
              .catch((error) => {
                console.error('Error writing document: ', error);
              })
          );
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error);
        return error;
      })
  );
};

export const fetchDeliverectToken = async () => {
  try {
    const deliverectToken = await axios.get(
      `${nestJS_BaseURL}/deliverectToken`,
      {
        headers: {
          Authorization: `Bearer firebaseJWT`
        }
      }
    );
    if (deliverectToken) {
      return deliverectToken.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log('Error getting user:', error);
    return null;
  }
};

export const fetchTableSessionUpdates = async (sessionId: string) => {
  const tableSessionListener = db
    .collection('TableSessions')
    .where('sessionId', '==', sessionId)
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'modified') {
          return change.doc.data();
        }
      });
    });

  return () => tableSessionListener();
};

export const fetchTab = async (tabId: string) => {
  try {
    const tab = await axios.get(`${nestJS_BaseURL}/tab/${tabId}`, {
      headers: {
        Authorization: `Bearer ${'firebaseJWT'}`
      }
    });
    return tab.data;
  } catch (error) {
    console.log('Error fetchTab', error);
    return null;
  }
};

export const getUpdatedPaidProducts = async (order: any) => {
  const dineInOrder = order[0];
  let paidItemCount = 0; //paid item count = total item count tab should be automatically closed
  let totalItemCount = 0;
  let closeTab = false;

  if (Array.isArray(order)) {
    order.forEach((order) => {
      order.products.forEach((prod: any) => {
        totalItemCount = totalItemCount + prod.quantity;
      });
    });
  } else {
    order.products.forEach((prod: any) => {
      totalItemCount = totalItemCount + prod.quantity;
    });
  }

  console.log('getUpdatedPaidProducts', totalItemCount, paidItemCount);

  try {
    const tab = await fetchTab(dineInOrder.tabId);
    if (tab.id) {
      let customers = tab.customer;
      let completedProducts: any[] = [];

      for (let i = 0; i < customers.length; i++) {
        customers[i].productsPaid.map((product: any) => {
          product.customerIndex = i;
          product.customerName = customers[i].firstName;
          console.log('customer paid product', product);

          let alreadyPushed = false;
          completedProducts.map((completedProduct: any) => {
            if (completedProduct.productOrderId === product.productOrderId) {
              console.log('completed product', completedProduct);
              completedProduct.quantity =
                completedProduct.quantity + product.quantity;
              alreadyPushed = true;
            }
          });
          if (!alreadyPushed) {
            completedProducts.push(product);
          }
        });
      }

      completedProducts.map((paidProduct: any) => {
        paidItemCount = paidItemCount + paidProduct.quantity;
      });

      if (
        !tab.paymentComplete &&
        tab.status.open &&
        totalItemCount === paidItemCount &&
        totalItemCount > 0
      ) {
        console.log('tab is open and not paid but now all products paid');
        closeTab = true;
      }
      return { completedProducts, closeTab };
    } else {
      console.log('Error finding tab:');
      return false;
    }
  } catch (error) {
    console.log('Error finding tab and checking for paid products', error);
    return false;
  }
};

export const findUserByPhoneNumber = async (phone: string) => {
  try {
    const user = await axios.get(`${nestJS_BaseURL}/userByPhone/${phone}`, {
      headers: {
        Authorization: `Bearer ${'firebaseJWT'}`
      }
    });
    console.log('found user by phone --->', user.data);
    if (user.data) {
      return user.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log('Error getting user by phone', error);
    return null;
  }
};

export const updateUserInDatabase = async (user: User) => {
  try {
    const userId = firebase.auth().currentUser?.uid;
    if (userId) {
      const userSnapShot = await db.collection('Users').doc(userId).get();
      if (userSnapShot.data()) {
        //@ts-ignore
        if (userSnapShot.data().phone && userSnapShot.data().phone.length > 0) {
          return { error: 'User Already Exists.' };
        }
      }
      const data = {
        name: user.firstName + ' ' + user.lastName,
        phone: user.phoneNumber,
        email: user.email,
        updatedAt: new Date()
      };
      const documentSnapShot = await db
        .collection('Users')
        .doc(userId)
        .update(data);
      return { data: true };
    } else {
      return { error: true };
    }
  } catch (error) {
    return { error: (error as Error).message };
  }
};

export const fetchTableSession = async (id: string | null) => {
  try {
    const tableSession = await axios.get(
      `${nestJS_BaseURL}/tableSession/${id}`,
      {
        headers: {
          Authorization: `Bearer ${'firebaseJWT'}`
        }
      }
    );
    console.log('found table sesion', tableSession.data);
    if (tableSession.data) {
      return tableSession.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log('Error getting table session:', error);
    return null;
  }
};

export const findBusinessesAndAccountFromDomain = async () => {
  const domain = window.location.host;
  try {
    const accountAndBusinesses = await axios.get(
      `${nestJS_BaseURL}/accountAndBusinessFromDomain/${domain}`,
      {
        headers: {
          Authorization: `Bearer ${'firebaseJWT'}`
        }
      }
    );
    console.log(
      'account and businesses from domain after fail to fetch single business',
      accountAndBusinesses.data
    );
    if (
      accountAndBusinesses.data &&
      accountAndBusinesses.data.businesses &&
      accountAndBusinesses.data.businesses.length
    ) {
      return accountAndBusinesses.data.businesses[0];
    } else {
      return null;
    }
  } catch (error) {
    console.log('Error getting account:', error);
    return null;
  }
};

export const findBusinessDetailsByDomainName = async () => {
  let domainName = window.location.hostname;
  let hostName = window.location.href;
  // if (domainName.includes("mobile-orders-staging")) {
  //   domainName = "https://thecoopwickedchicken.menu";
  // }
  // domainName = 'thecoopwickedchicken.menu';

  // console.log('find single business by domain ---->', domainName, 'host name -->', hostName);

  try {
    const allBusiness = await axios.get(
      `${nestJS_BaseURL}/businessFromDomain/${domainName}`,
      {
        headers: {
          Authorization: `Bearer ${'firebaseJWT'}`
        }
      }
    );

    if (allBusiness.data.length) {
      if (hostName.includes('locations/')) {
        var location = hostName.split('locations/')[1];
        if (location.length && location.split('/').length) {
          const locationName = location.split('/')[0].toLowerCase();
          allBusiness.data.map((business: any) => {
            if (
              business.businessName
                .toLowerCase()
                .replace(/ /g, '')
                .includes(locationName)
            ) {
              return business;
            }
          });
          //if business name in url doesnt match any business just return first business
          return allBusiness.data[0];
        } else {
          console.log(
            'couldnt split url for location passed in hostname',
            location
          );
          return allBusiness.data[0];
        }
      } else {
        console.log(
          'no locations in url, return first location for this domain'
        );
        return allBusiness.data[0];
      }
    } else {
      console.log('no businesses returned for this domain');
      return findBusinessesAndAccountFromDomain();
    }
  } catch (error) {
    console.log('Error getting businesses from domain:', error);
    return findBusinessesAndAccountFromDomain();
  }
};

export const findOrCreateUser = async (
  name: string,
  email: string,
  phoneNumber: string
) => {
  console.log('find user, create if no user');
  return db
    .collection('Users')
    .where('phone', '==', phoneNumber)
    .get()
    .then((docs: any) => {
      if (!docs.empty) {
        return docs.docs[0].data();
      } else {
        let userColor =
          'rgb(' +
          Math.floor(Math.random() * 255) +
          ',' +
          Math.floor(Math.random() * 255) +
          ',' +
          Math.floor(Math.random() * 255) +
          ')';
        return db
          .collection('Users')
          .add({
            id: null,
            color: userColor,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            disabled: false,
            deleted: false,
            userType: 'mobile',
            name,
            phone: phoneNumber,
            email,
            imageUrl: '',
            paymentMethods: []
          })
          .then((docRef) => {
            db.collection('Users').doc(docRef.id).update({ id: docRef.id });
            return {
              id: docRef.id,
              color: userColor,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
              disabled: false,
              deleted: false,
              userType: 'mobile',
              name,
              phone: phoneNumber,
              email,
              imageUrl: '',
              paymentMethods: []
            };
          })
          .catch((error) => {
            console.error('Error writing document: ', error);
          });
      }
    })
    .catch((error) => {
      console.log('Error getting document:', error);
      return error;
    });
};

export const getStripePaymentSecret = async (
  amount: number,
  stripeId?: string,
  description?: string
) => {
  const { data } = await axios.post(
    'https://us-central1-tango-2.cloudfunctions.net/stripeUtilsTest',
    {
      type: 2, //send 2 for payment intent
      data: {
        amount: amount * 100, //use cents for stripe
        currency: 'USD',
        confirm: false, //send as false since no payment method (card) exists yet
        payment_method_types: ['card'],
        // customer: stripeId,
        description
      }
      // tangoStripeAPI: (business.location.country === "Canada" || business.location.country === "CA") ? "Canada" : "USA"
    }
  );
  console.log({ data });
  return data;
};

export const getPaymentMethodDetails = async (paymentMethodId: string) => {
  const { data } = await axios.post(
    'https://us-central1-tango-2.cloudfunctions.net/stripeUtils',
    {
      type: 1,
      paymentMethodId
    }
  );
  return data;
};

export const fetchAllPricingModels = async (businessId: string) => {
  try {
    const businessDetails = await fetchBusiness(businessId);
    if (businessDetails) {
      const allPricingModels: TangoTax[] = [].concat.apply(
        [],
        businessDetails.tangoProducts.map((model: { [T: string]: any }) => {
          const { name } = model;
          return model.orderTypes.map((data: { [T: string]: any }) => {
            return {
              orderChannel: name,
              orderType: data.name,
              taxRate: data.taxRate,
              tangoFeeCents: data.tangoFeeCents,
              tangoFeePercent: data.tangoFeePercent
            };
          });
        })
      );
      return allPricingModels;
    }
    return [];
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const fetchAllJobPostings = async (
  businessId: string
): Promise<JobPosting[]> => {
  try {
    const jobPostings = await axios.get(
      `${nestJS_BaseURL}/business/${businessId}/jobPostings`,
      {
        headers: {
          Authorization: `Bearer ${'firebaseJWT'}`
        }
      }
    );
    jobPostings.data.map((posting: JobPosting) => {
      posting.createdAt = new Date(posting.createdAt._seconds * 1000);
      posting.updatedAt = new Date(posting.updatedAt._seconds * 1000);
    });
    return jobPostings.data;
  } catch (error) {
    console.log('Error getting jobPostings:', error);
    return [];
  }
};

export const fetchJobPosting = async (
  id: string
): Promise<JobPosting | null> => {
  try {
    const jobPosting = await axios.get(`${nestJS_BaseURL}/jobPosting/${id}`, {
      headers: {
        Authorization: `Bearer ${'firebaseJWT'}`
      }
    });
    jobPosting.data.createdAt = new Date(
      jobPosting.data.createdAt._seconds * 1000
    );
    jobPosting.data.updatedAt = new Date(
      jobPosting.data.updatedAt._seconds * 1000
    );
    return jobPosting.data;
  } catch (error) {
    console.log('Error getting jobPostings:', error);
    return null;
  }
};

export const fetchOrder = async (id: string) => {
  try {
    const order = await axios.get(`${nestJS_BaseURL}/order/${id}`, {
      headers: {
        Authorization: `Bearer ${'firebaseJWT'}`
      }
    });
    return order.data;
  } catch (error) {
    console.log('Error getting account:', error);
    return null;
  }
};

export const fetchUrlMappings = async () => {
  try {
    const mappings = await axios.get(`${nestJS_BaseURL}/urlMappings`, {
      headers: {
        Authorization: `Bearer ${'firebaseJWT'}`
      }
    });
    const formattedMappings: URLMapping[] = [];
    mappings.data.map((mapping: URLMapping) => {
      const invertedLocations = _.invert(mapping.locations);
      formattedMappings.push({
        ...mapping,
        locations: invertedLocations
      });
    });
    return formattedMappings;
  } catch (error) {
    console.log('Error getting modifierOptions:', error);
    return [];
  }
};

export const fetchTableFromQr = async (qrId: string) => {
  try {
    const table = await axios.get(`${nestJS_BaseURL}/tableFromQr/${qrId}`, {
      headers: {
        Authorization: `Bearer ${'firebaseJWT'}`
      }
    });
    return table;
  } catch (error) {
    console.log('Error getting table from qr id:', error);
    return null;
  }
};
