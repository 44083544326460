import React, {useEffect, useState} from 'react';
import './index.css';
import {Link, useHistory} from 'react-router-dom';
import { Button, Icon } from '@blueprintjs/core';
import ReactCodeInput from 'react-code-input';
import MobileHeader from '../GiftCards/MobileHeader';
import Header from "../GiftCards/Header";
const OtpConfirm = (props:any) => {
  const [code, setCode] = useState<string|null>(null);
  const history = useHistory();
  useEffect(()=> {
    if(code?.length === 6){
        //@ts-ignore
        window.confirmationResult.confirm(code).then((result)=> {
            console.log(result);
            history.replace('/cards');
        }).catch((e:any)=> {
            console.log(e.message)
            alert('invalid otp');
        })
    }
  }, [code])
  if(window.innerWidth <= 960){
    return (
        <div className='gift-cards-mobile'>
            <MobileHeader/>
          <div className='auth-form-container'>
              <div className='form-title'>We sent you a code</div> 
              <ReactCodeInput
                name="code-input"
                inputMode="numeric"
                type="text"
                fields={6}
                onChange={(code)=> setCode(code)}
                />
              <div className='contact-support'>
                  Need help? <span style={{fontFamily:'Lato-Bold'}}>Contact support.</span>
              </div>
          </div>
          <div className='join-now-box'>
              <div className="join-now-text">JOIN NOW</div>
              <div className="join-now-offer-text">
                  Get a free digital card when you join - or log in to view your cards. <span style={{color:"#E12219"}}>Get Started.</span><div/>
              </div>
              <div style={{textAlign:'center'}}>
                  <Button text="Join Now" className='join-now-button' onClick={()=> history.push('/signup')}/>
              </div>
          </div>
        </div>
    )
}
    return (
        <div className="gift-cards-page welcome-screen">
            <Header path="login"/>
            <div className="body">
                <div className="left-part">
                    <div className="side-nav">
                        <div className="nav-title">Welcome!</div> 
                        <div className={`side-nav-item`}>Log In</div>
                        <div className={`side-nav-item`}>Join now</div>
                    </div>
                </div>
                <div className="right-part">
                      <div className="login-form">
                      <div className="select-card-header">
                          <div className="back-button" onClick={()=> history.goBack()}>
                              <Icon icon="chevron-left" /> Back
                          </div>
                      </div>
                      <div className="buy-card-form">
                        <img src={"https://globalassets.starbucks.com/assets/ebcf7efc3d1c4171aa52bf3d223de198.jpg"} className={"card-image"}/>
                        <div className="form-container otp-confirmation-container">    
                            <div className="form-header">
                              <Icon icon="cross" color="#4A5054" iconSize={12}/>
                            </div>
                            <div style={{padding: '13px'}}>
                              <div className="confirm-pin-title">
                                CONFIRM PIN
                              </div>
                            <ReactCodeInput
                            name="code-input"
                            inputMode="numeric"
                            type="text"
                            fields={6}
                            onChange={(code)=> setCode(code)}
                            />
                              <div className="otp-instruction text-center">
                                This is the first time you are authorizing this device, so we have sent you a confirmation text to
                              </div>
                              <div className="otp-instruction text-center" style={{marginTop:0,fontSize: '14px', fontFamily:"Lato-Extrabold"}}>your phone number.</div> 
                              {/* pass in real phone */}
                              <div className="text-center signup-link">Didn't get it? <span style={{color:"#4F8CCA"}}>Send via email</span></div>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export  default  OtpConfirm;
