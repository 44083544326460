import React, {useEffect, useState} from 'react';
import './index.css';
import {Button, Icon} from "@blueprintjs/core";
import {useHistory, useParams} from 'react-router-dom';
import Header from '../../GiftCards/Header';
import LocationIcon from '../../assets/images/location-icon.svg';
import ClockIcon from '../../assets/images/clock.svg';
import CalendarIcon from '../../assets/images/calendar-icon.svg';
import MobileHeader from '../../GiftCards/MobileHeader';
import {fetchJobPosting} from "../../db/GetData";
import {JobPosting} from "../../types/types";
import moment from "moment";


const Job = () => {

    const history = useHistory();

    const {jobPostingId} = useParams<{jobPostingId: string}>();




    const [jobPosting, setJobPosting] = useState<JobPosting | null>(null);

    useEffect(() => {
        const getJobPosting = async (id: string) => {
            const jobPost = await fetchJobPosting(id);
            setJobPosting(jobPost);
        }
        if (jobPostingId) {
            getJobPosting(jobPostingId);
        }
    }, [jobPostingId]);

const renderJobs = () => (
  <div className='jobs-container single-job-body'>
    <div className='job-title'>
        {jobPosting?.jobTitle}
    </div>
    <div className='job-detail-title'>
        Job Details
    </div>
    <div className='job-detail-description' dangerouslySetInnerHTML={{
        __html: jobPosting?.jobDescription || ''
    }}>
    </div>

      <div className='job-detail-title'>
          Qualifications
      </div>
      <div className='job-detail-description' dangerouslySetInnerHTML={{
          __html: jobPosting?.qualifications || ''
      }}>
      </div>
  </div>
)

  return (
    <div className='single-job-page'>
      <MobileHeader />
      <Header path='jobs' />
      <div className="application-tracker-page" style={{paddingLeft: 20, paddingRight: 20}}>
        {/*sidebar*/}
        <div className='sidebar-container'>
            <div className='back-button' onClick={()=> history.goBack()}>
                <Icon icon="arrow-left" color='#424452'/>
                Back to dashboard
            </div>
            <ul>
                <li>
                    <img src={LocationIcon} />
                    {jobPosting?.location.name ? jobPosting.location.name:''}
                </li>
                <li>
                    <img src={ClockIcon} />
                    {jobPosting?.jobType}
                </li>
                <li>
                    <img src={CalendarIcon} />
                    Posted {moment(jobPosting?.createdAt).format('MM/DD/YYYY')}
                </li>
            </ul>

            {
                jobPosting && jobPosting.formId ?
                    <Button text="Apply" className='apply-button' onClick={
                        () => {
                            window.open(`https://hq.runtango.com/form/${jobPosting.formId}/${jobPosting.businessId}`)
                        }

                    }/>
                    :
                    null
            }

        </div>
         {/*sidebar*/}
         {renderJobs()}
      </div>

      </div>
  )
}
export default  Job;
