export const createPopularProducts = (
    menuCategories: any,
    products: any
  ) => {

    console.log("creating lists of popular products")

    const popularLists: any[] = []
    //for each menu category create a sub list of 6 prodcuts that are popular
    menuCategories.forEach((category:any) => {
        if(category.products.length > 6){

            let popularList = {
                categoryId: category.id,
                categoryName: category.name,
                description: "Popular items in " + category.name,
                products: []
            }
            // let popularList: any[] = []
            
            products.forEach((product:any) => {
                category.products.forEach((productId:string) => {
                    if(product.enabled && product.id === productId && popularList.products.length < 5 && product.imageUrl){
                        //@ts-ignore
                        popularList.products.push(product)
                    }
                });
            });

            //after creating popular list put any "promoted" products at the start of popular
            products.forEach((product:any) => {
                category.products.forEach((productId:string) => {
                    if(product.id === productId && product.promoted && product.enabled){
                        //if promoted item already on list dont append it to popular list
                        //@ts-ignore
                        if(popularList.products.includes(product.id)){
                            return console.log('promoted item already on popualr list')
                        }
                        //if popualr list is already 5 items long remove last item and put promoted item at beginning
                        if(popularList.products.length === 5){
                            popularList.products.splice(popularList.products.length - 1, 1)
                        }
                        //@ts-ignore
                        popularList.products.unshift(product);
                    }
                })
            });
            // console.log("create popular lists with each menu category", popularList)
            popularLists.push(popularList)
        }
    });

    return popularLists
  
}
export const createPopularInCategory = (category:any, products:any)=>{
    console.log("create popular list for specific cat")
    if (category.products.length > 6) {
        let popularList: any[] = [];
  
        //make sure product has image
        products.forEach((product: any) => {
          category.products.forEach((productId: string) => {
            if (product.enabled && (product.id === productId) && popularList.length < 5 && product.imageUrl) {
              popularList.push(product);
            }
          });
        });
  
        //after creating popular list put any "promoted" products at the start of popular
        products.forEach((product: any) => {
          category.products.forEach((productId: string) => {
            if (product.enabled && product.id === productId && product.promoted) {
              //if promoted item already on list dont append it to popular list
              for (let i = 0; i < popularList.length; i++) {
                if (popularList[i].id === product.id) {
                  return false
                }
              }
              //if popular list is already 5 items long remove last item and put promoted item at beginning
              if (popularList.length === 5) {
                popularList.splice(popularList.length - 1, 1);
              }
  
              popularList.unshift(product);
            }
          });
        });

        return popularList

      } else {
          return null
      }
}
export const createFeaturedProducts = (products:any) =>{
    const featuredList: any[] = [];
    
    products.forEach((product: any) => {
      if (product.enabled && product.featured && featuredList.length < 5) {
        featuredList.push(product);
      }
    });

    if(featuredList.length > 0){
        return featuredList
    }else{
        return []
    }
}
export const selectModifier = (existingSelection: any, radioGroup:any, index:number, modifer: any, optionSelected:any, radio:boolean, selectedProduct:any, modifiers:any)=>{

  // console.log("?", existingSelection, index, modifer, optionSelected, radio)
  
  //if radio it means only one modifier can be selected (radio button)
  if(radio){

    //if radio has minimum dont let user toggle off
    if(existingSelection.includes(optionSelected.id) && modifer.min > 0){
        return console.log("radio group requires at least one option")
    }

    //if modifier has 0 minimum toggle modifier off and remove additional cost and name // effectively toggle modifier
    if(existingSelection.includes(optionSelected.id) && modifer.min === 0){
      for(let i=0;i<existingSelection.length;i++){

          if(existingSelection[i] === optionSelected.id){
              existingSelection.splice(i,1)

              // setRadio ([...existingSelection] )

              selectedProduct.selectedModifiers[index].additionalCost = selectedProduct.selectedModifiers[index].additionalCost - optionSelected.additionalCost

              for(let j=0; j<selectedProduct.selectedModifiers[index].optionNames.length;j++){
                  if(selectedProduct.selectedModifiers[index].optionNames[j] === optionSelected.name){
                    selectedProduct.selectedModifiers[index].optionNames.splice(j,1)
                    // return setProductToAdd(selectedProduct)
                  }
              }
          }
      }
    }


    if(!radioGroup.includes(index)){  
      // setRadioGroup ( [...radioGroup, index] )
    }

    //if already clicked radio group replace existing mods and prices with new one (avoids two radio buttons clicked per modifier group)
    if(radioGroup.includes(index)){
        modifiers.forEach((modifier:any) => { 
            if(modifier.id === selectedProduct.selectedModifiers[index].modifierId){
                modifier.options.forEach((option:any) => {
                    for (let i = 0; i < existingSelection.length; i++){
                        console.log("???",existingSelection)
                        if (existingSelection[i] === option){
                          existingSelection.splice(i, 1)
                          break;
                        }
                    }
                })
            }
        });
    }

    console.log("existingSelection", existingSelection, "product", selectedProduct, "radioGroup", radioGroup)

  }else{

  }

  //else its checkbox so multiple can be selected
}