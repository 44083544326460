import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './index.css';
import {Button, Dialog, Icon, MenuItem} from "@blueprintjs/core";
import {Select} from "@blueprintjs/select";
interface Props{
    isOpen: boolean;
    closeModal: () => void;
    selectedOrderType: string;
    availableOrderTypes: string[];
    updateOrderType: (orderType: string) => void;
}
const ChangeOrderType = ({isOpen, closeModal, selectedOrderType, availableOrderTypes, updateOrderType}:Props) => {
    const [phone, setPhone] = useState<string>('');
    const [orderType, setOrderType] = useState<string>(selectedOrderType);

    const getOrderLabel = useCallback((orderType: string) =>{
        if(orderType === 'carryOut')
            return 'Carry Out';
        if(orderType === 'delivery')
            return  'Delivery';
        if(orderType === 'curbside'){
            return  'Curbside';
        }
    },[])
    return (
        <Dialog
            isOpen={isOpen}
            hasBackdrop
            onClose={closeModal}
            className="gift-card-login-modal"
            portalClassName="gift-card-modal-portal"
        >
            <div className="gift-card-modal-body change-order-type-body">
                <div className="modal-title">Change Order Type</div>
                <div className="modal-subtitle">Please select your desired order method below</div>
                <Icon icon="cross" className="close-icon" iconSize={30} color={"#231F20"} onClick={closeModal}/>
                <select className="gift-card-modal-select" style={{width:'100%', marginBottom:'8px'}} value={orderType} onChange={(e)=> setOrderType(e.target.value)}>
                    {availableOrderTypes.map((item)=>(
                        <option key={item} value={item}>{getOrderLabel(item)}</option>
                    ))}
                </select>
                <Button text="Save" className="gift-card-login-button" onClick={()=> {
                    updateOrderType(orderType)
                }}/>
            </div>
        </Dialog>
    )
}
export  default ChangeOrderType;