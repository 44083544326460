
import React, { createRef, useEffect, useState } from "react";
import { Card, Button, MenuItem, Spinner } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { fetchAllMenuData, fetchUser, fetchBusinessSettings, fetchDeliverectToken, findUserByPhoneNumber } from "../db/GetData";
import { createPopularProducts, createFeaturedProducts } from "../models/products";
import ViewProduct from "./ViewProduct";
import "./Index.css";
import Header from "../GiftCards/Header";
import moment from "moment";
import "flatpickr/dist/themes/material_blue.css"; //need this to render the calendar in flatpicker
import LoginModal from "./modals/LoginModal";
import OtpModal from "./modals/Otp";
import firebase from "../config/firebase";
import Cart from './Cart'
import {TangoMenu, BusinessPaymentAPI, DiscountDoc, MenuCategory, ItemsInCart, StitchedProduct, TangoBusinessSettings, User, TangoProduct, Business} from "../types/types";
import Pay from "./Pay";

firebase.auth().useDeviceLanguage();

interface ViewLocationProps {
  business: Business;
  allLocations: any;
  orderType: string;
  showAllLocation: () => void;
  showToast: (message: string) => void;
}

const ViewLocation = (props: ViewLocationProps) => {
  const allLocations = props.allLocations;
  const business = props.business;
  const [orderType, setOrderType] = useState(props.orderType);
  const [paymentAPI, setPaymentAPI] = useState<BusinessPaymentAPI>({name: "stripe", apiKey: "", hostedPaymentsToken: "", merchantId: ""});
  const [taxRate, setTaxRate] = useState(0.0);
  const [user, setUser] = useState<User>();
  const [deliverectToken, setDeliverectToken] = useState("");
  const [menus, setMenus] = useState<any[]>([]);
  const [selectedMenu, setSelectedMenu] = useState<TangoMenu>()
  const [selectedMenuCategories, setSelectedMenuCategories] = useState<MenuCategory[]>([])
  const [selectedMenuCategory, setSelectedMenuCategory] = useState<MenuCategory>({
    businessId: '',
    createdAt: new Date(),
    deleted: false,
    description: '',
    enabled: true,
    id: '',
    name: '',
    products: [],
    updatedAt: new Date()
  });
  const [popularLists, setPopularLists] = useState<any[]>([])
  const [featuredList, setFeaturedList] = useState<TangoProduct[]>([])
  const [menuCategories, setMenuCategories] = useState([]);
  const [discounts, setDiscounts] = useState<DiscountDoc[]>([])
  const [stitchedProducts, setStitchedProducts] = useState<StitchedProduct[]>([]);
  const [products, setProducts] = useState([]);
  const [modifiers, setModifiers] = useState([]);
  const [modifierOptions, setModifierOptions] = useState([]);
  const [businessSettings, setBusinessSettings] = useState<TangoBusinessSettings>();
  const [selectedProduct, setSelectedProduct] = useState<TangoProduct>();
  const [openViewProduct, setOpenViewProduct] = useState(false);
  const [loadingMenuData, setLoadingMenuData] = useState(true);
  const [openPayDialog, setOpenPayDialog] = useState(false);
  const [tipAmount, setChangeTip] = useState(0.0);
  const [successfulOrder, setSuccessfulOrder] = useState(false);
  const [categoryRefs, setCategoryRefs] = useState<any>([]);
  const [businessClosedWarning, setBusinessClosedWarning] = useState("");
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [showOtpModal, setShowOtpModal] = useState<boolean>(false);
  const [showGiftCardModal, setShowGiftCardModal] = useState<boolean>(false);
  const [confirmationResult, setConfirmationResult] = useState<firebase.auth.ConfirmationResult | null>(null);
  const [itemsInCart, setItemsInCart] = useState<ItemsInCart[]>([])
  const [removedItem, removeItemInCart] = useState<ItemsInCart[]>([])
  const [addedItems, addItemToCart] = useState<ItemsInCart[]>([])
  const [refreshCart, setRefreshCart] = useState<boolean>(false)

  //if add item 
  useEffect(() => {
    setItemsInCart(addedItems)
  },[addedItems])

  //if remove item 
  useEffect(() => {
    // console.log('removed item - viewlocation.tsx', removedItem)
    setItemsInCart(removedItem)
  },[removedItem])

  //if change tip
  useEffect(() => {
    // console.log('changedTip view location --> ', tipAmount)
  },[tipAmount])

  useEffect(() => {
    if(successfulOrder){

    }
  },[successfulOrder])

  useEffect(() => {
    if(refreshCart){
        setItemsInCart([])
        setSuccessfulOrder(false)
        setRefreshCart(false) //set back to false after clearing cart to listen for place another order refresh cart again
    }
  },[refreshCart])

  async function getAllMenuData() {
    try {
      const allMenuData = await fetchAllMenuData(business, false);
      //set menu and product data in local state
      //TO DO, set stitched products and use those instead of products, modifiers and options - then won't need to fetch from those collections
      setMenus(allMenuData.menusToDisplay);
      setMenuCategories(allMenuData.menuCategories);
      setProducts(allMenuData.products);
      setDiscounts(allMenuData.discountsToDisplay);
      setModifiers(allMenuData.modifiers)
      setModifierOptions(allMenuData.modifierOptions)
      setStitchedProducts(allMenuData.stitchedProducts)

      const categories: MenuCategory[] = [];

      if(allMenuData.menusToDisplay.length > 0){
        setSelectedMenu(allMenuData.menusToDisplay[0]);
        //set selected menu category in state and category refs, allow user to jump between categories in html
        allMenuData.menusToDisplay[0].categories.forEach((cat: string, index: number) => {
          allMenuData.menuCategories.forEach((category: any) => {
            if (category.id === cat) {
              categories.push(category);
            }
            if (index === 0 && category.id === cat) {
              setSelectedMenuCategory(category);
            }
          });
        });
      }else{
        console.log('no menus to display')
      }

      setSelectedMenuCategories(categories);
      const categoriesRefsArray = categories.map(() => {
        const ref = createRef();
        return ref;
      });
      setCategoryRefs(categoriesRefsArray);
      setLoadingMenuData(false);

    } catch (e) {
      console.error('error fetch all menu data',e);
      setLoadingMenuData(false);
    }
  }

  async function getUser() {
    try {
      const user = await fetchUser();
      if (user) {
        setUser(user);
      }
    } catch (e) {
      console.error("error getting user", e);
    }
  }

  async function getBusinessSettings() {
    try {
      const settings = await fetchBusinessSettings(business.id);
      if (settings) {
        setBusinessSettings(settings);
      }
    } catch (e) {
      console.error('error fetching biz settings', e);
    }
  }

  async function getDeliverectToken(){
    try {
      const token = await fetchDeliverectToken();
      if (token) {
        setDeliverectToken(token);
      } else {
        setDeliverectToken("");
      }
    } catch (e) {
      console.error("error getting token", e);
      setDeliverectToken("");
    }
  };

  useEffect(() => {
    //@ts-ignore
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("captcha-container", {
      size: "invisible",
    });
  }, []);

  //load data with functions in folder db/getdata
  useEffect(() => {
  
    if (business.currentlyClosed) {
      setBusinessClosedWarning("This location is currently closed - please schedule a pickup or delivery time.");
    }
    if (business.deliverect && business.deliverect.api === "channel") {
      getDeliverectToken();
    }
    //set available order types based on mobile tango product - on desktop view only show carryOut,delivery, curbside,
    //set tax based on orderType in state
    business.tangoProducts.forEach((tangoProduct: any) => {
      if (tangoProduct.name === "mobile") {
        tangoProduct.orderTypes.forEach((type: any) => {
          if (type.name === orderType) {
            setTaxRate(type.taxRate);
          }
        })
      }
    });

    if (business.stripeConnect && business.stripeConnect.enabled) {
      setPaymentAPI({ name: "stripe", apiKey: business.stripeConnect.id, hostedPaymentsToken: "", merchantId: "" });
    } 

    getUser()
    getBusinessSettings();
    getAllMenuData()
  }, []);

  //call this when selected menu category changes and if all products exists
  useEffect(() => {
    if (selectedMenuCategory && products && menus && menuCategories && discounts) {
      const featuredList = createFeaturedProducts(products);
      setFeaturedList(featuredList);
      const lists = createPopularProducts(menuCategories, products);
      setPopularLists(lists);
    }
  }, [selectedMenuCategory, products, menus, menuCategories, discounts]);


  const renderMenuDropdown = (menus: any) => {
    return (
      <MenuItem
        key={menus.id}
        onClick={() => selectMenuInDropdown(menus)} //click menu item set status
        text={menus.nameExternal}
      />
    );
  };

  const selectMenuInDropdown = (menu: any) => {
    setSelectedMenu(menu);
    const featuredList = createFeaturedProducts(products);
    setFeaturedList(featuredList);
    const categories: MenuCategory[] = [];
    menu.categories.forEach((cat: string, index: number) => {
      menuCategories.forEach((category: any) => {
        if (category.id === cat) {
          categories.push(category);
        }
        if (index === 0 && category.id === cat) {
          setSelectedMenuCategory(category);
        }
      });
    });
    setSelectedMenuCategories(categories);
  };

  const renderCategoriesDropdown = (selectedMenuCategories: any) => {
    return (
      <MenuItem
        key={selectedMenuCategories.id}
        onClick={() => selectCategoryInDropdown(selectedMenuCategories)} //click menu item set status
        text={selectedMenuCategories.name}
      />
    );
  };

  const selectCategoryInDropdown = (category: any) => {
    setSelectedMenuCategory(category);
    if (categoryRefs.length) {
      const categoryIndex = selectedMenuCategories.findIndex((item: any) => item.id === category.id);
      const formHandler = categoryRefs[categoryIndex].current;
      setTimeout(() => {
        window.scrollTo({
          top: formHandler.offsetTop,
          behavior: "smooth",
        });
      }, 200);
    }
  };

  const handClickedProduct = (product: any) => {
    setSelectedProduct(product)
    setOpenViewProduct(true)
  };

  const getHours = (hours: any) => {
    if(!hours){
      return ""
    }
    const currentDay = moment().format("dddd");
    const selectedDay = hours.filter((item: any) => item.day === currentDay.toLowerCase());
    // console.log(selectedDay)
    if (selectedDay.length && selectedDay[0].open) {
      return currentDay + " Hours:" + " " + moment(selectedDay[0].openTime, "hh:mm").format("hh:mm a") + " - " + moment(selectedDay[0].closeTime, "hh:mm").format("hh:mm a");
    } else {
      return currentDay + " Hours:" + " closed";
    }
  };
  const openModal = (modalName: string) => {
    if (modalName === "otpModal") {
      setShowOtpModal(true);
    }
    if (modalName === "giftCardsModal") {
      setShowGiftCardModal(true);
    }
  };
  const loginWithPhoneNumber = async (phoneNumber: string) => {
    const response = await findUserByPhoneNumber(phoneNumber);
    //@ts-ignore
    const appVerifier = window.recaptchaVerifier;
    if (response.data) {
      try {
        await firebase.auth().signOut();
        const confirmationResult = await firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier);
        setConfirmationResult(confirmationResult);
        setShowLoginModal(false);
        setShowOtpModal(true);
      } catch (e) {
        console.log(e);
      }
    }
  };
  const verifyOtp = async (otpCode:any) => {
    if (otpCode?.length === 6 && confirmationResult) {
      try {
        const response = await confirmationResult.confirm(otpCode);
        if (response) {
          setShowOtpModal(false);
          setShowGiftCardModal(true);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  
  return (
    <div style={{ paddingLeft: openPayDialog ? "0px" : "3em", paddingRight: "3em" }}>
      
      {/*new navbar*/}
      <Header path="order" visitLocation={props.showAllLocation} />

      <div id="captcha-container" />
      <LoginModal isOpen={showLoginModal} closeModal={() => setShowLoginModal(false)} openModal={openModal} loginWithPhoneNumber={loginWithPhoneNumber} />
      <OtpModal isOpen={showOtpModal} closeModal={() => setShowOtpModal(false)} openModal={openModal} verifyOtp={verifyOtp} />

      {/* MENUS TOP SELECTION BAR AND CART PREVIEW*/}
      {!openPayDialog &&
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "fixed",
            left: "0px",
            right: "0px",
            paddingLeft: "3em",
            paddingRight: "3em",
            background: "#fff",
            zIndex: 10,
            top: "78px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", width: "70%", borderRight: "1px solid rgb(245, 245, 245)", borderBottom: "1px solid #F5F5F5" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "20px 20px 20px 0px" }}>

              <div>
                <h2 style={{ fontSize: "23px", fontFamily: "Axiforma-Bold", margin: "0px" }}>{business.businessName}</h2>

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", fontSize: "12px", fontFamily: "Axiforma-Regular", color: "#000" }}>
                  {business.phones &&
                  <div style={{ marginRight: "10px" }}>{business.phones[0].number}</div>
                  }
                  <div className="business-hours">{getHours(business.hours)}</div>
          
                </div>
              </div>

              <div>
                <Select className="select-menu-dropdown menu" items={menus} itemRenderer={renderMenuDropdown} filterable={false} onItemSelect={(e: any) => selectMenuInDropdown(e.target)}>
                  <div style={{ fontSize: "11px", fontFamily: "Axiforma-ExtraBold", textAlign: "center" }}>
                    <span>Menu</span>
                  </div>
                  <Button text={selectedMenu ? selectedMenu.nameExternal : "Select Menu"} rightIcon="chevron-down"></Button>
                </Select>

                <Select className="select-menu-dropdown category" items={selectedMenuCategories} itemRenderer={renderCategoriesDropdown} filterable={false} onItemSelect={(e: any) => selectCategoryInDropdown(e.target)}>
                  <div style={{ fontSize: "11px", fontFamily: "Axiforma-ExtraBold", textAlign: "center" }}>Category</div>
                  <Button text={selectedMenuCategory ? selectedMenuCategory.name : "Select Category"} rightIcon="chevron-down" />
                </Select>
              </div>
            </div>
          </div>
          <div style={{ width: "30%", borderBottom: "1px solid #F5F5F5", padding: "0px", display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div style={{ display: "flex", flex: 1, padding: "20px", flexDirection: "column" }}>
              <div style={{ fontSize: "17px", fontFamily: "Axiforma-ExtraBold", marginBottom: "5px" }}>Your Order</div>
            </div>
            {itemsInCart.length > 0 ? (
              <div style={{ paddingTop: "10px" }}>
                <div style={{ fontFamily: "Axiforma-ExtraBold", fontSize: "11px", textAlign: "right" }}>Your Cart</div>
                <div style={{ fontSize: "11px", textAlign: "right" }}>
                  {itemsInCart.reduce((totalCount:number, item:ItemsInCart)=> {
                      return totalCount + item.quantity;
                  },0)
                  } items
                </div>
              </div>
            ) : (
              <div style={{ paddingTop: "10px" }}>
                <div style={{ fontFamily: "Axiforma-ExtraBold", fontSize: "11px", textAlign: "right" }}>Your Cart</div>
                <div style={{ fontSize: "11px", textAlign: "right" }}>0 item</div>
              </div>
            )}
          </div>
        </div>
      }

      {/*BOX CONTAINING PRODUCTS AND PAY COMPONENT*/}
      <div style={{ display: "flex", marginTop: `${openPayDialog ? "78px" : itemsInCart.length > 0 ? "182px" : "152px"}`, position: "relative" }}>


        {loadingMenuData &&
          <div style={{ marginTop:"20%", marginLeft: '35%'}}>
            <Spinner/>
          </div>
        }


        {/* LIST OF PRODUCTS - DOESNT SHOW WHEN ON VIEWING PAY COMPONENT*/}
        {selectedMenuCategory && !openPayDialog && 
          <div style={{ width: "70%", paddingRight: "2em", borderRight: "1px solid #f5f5f5", paddingTop: "10px" }}>
            {featuredList && featuredList.length ? (
              <div>
                <h4 style={{ marginBottom: "1em", marginTop: "2em", fontSize: "14px", fontFamily: "Axiforma-ExtraBold" }}>Featured items</h4>
              </div>
            ) : null}
            <div style={{ display: "flex", overflow: "auto" }}>
              {featuredList &&
                featuredList.map((product: any, featuredIndex:number) => {
                  return (
                    <div
                      key={featuredIndex}
                      className="popular-item-container"
                      onClick={() => {
                        if (product.enabled) {
                          handClickedProduct(product);
                        }
                      }}
                      style={{ cursor: "pointer", marginRight: "11px", backgroundColor: "transparent" }}
                    >
                      {product.imageUrl ? <img src={product.imageUrl} alt="" style={{ objectFit: "cover", width: "135px", height: "127px", marginBottom: "5px" }} /> : <div style={{ width: "135px", height: "127px", backgroundColor: "#F2F2F2", marginBottom: "12px" }}></div>}
                      <div style={{ fontSize: "12px", fontFamily: "Axiforma-ExtraBold", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{product.nameExternal}</div>
                      <div style={{ fontSize: "10px", fontFamily: "Axiforma-Regular" }}>${(product.price * 0.01).toFixed(2)}</div>
                    </div>
                  );
                })}
            </div>

            {selectedMenuCategories.map((item, index) => {
              return (
                <div style={{ marginBottom: "30px", borderBottom: "1px solid #F5F5F5", paddingBottom: "30px" }} ref={categoryRefs[index]}>
                  {popularLists &&
                    popularLists.map((popularList: any) => {
                      if (popularList.products.length && popularList.categoryId === item.id) {
                        return (
                          <div>
                            <h4 style={{ marginBottom: "1em", marginTop: "3em", fontSize: "14px", fontFamily: "Axiforma-ExtraBold" }}>{popularList.description}</h4>
                            <div style={{ display: "flex", overflow: "auto" }}>
                              {popularList.products.map((product: any) => {
                                return (
                                  <div
                                    className="popular-item-container"
                                    onClick={() => {
                                      if (product.enabled) {
                                        handClickedProduct(product);
                                      }
                                    }}
                                    key={product.id}
                                    style={{ cursor: "pointer", marginRight: "11px" }}
                                  >
                                    {product.imageUrl ? <img src={product.imageUrl} alt="" style={{ objectFit: "cover", width: "135px", height: "127px", marginBottom: "5px" }} /> : <div style={{ width: "135px", height: "127px", backgroundColor: "#F2F2F2", marginBottom: "12px" }}></div>}
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontFamily: "Axiforma-ExtraBold",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {product.nameExternal}
                                    </div>
                                    <div style={{ fontSize: "10px", fontFamily: "Axiforma-Regular" }}>${(product.price * 0.01).toFixed(2)}</div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}

                  <h4 style={{ marginBottom: "5px", fontSize: "14px", fontFamily: "Axiforma-ExtraBold" }}>{item.name}</h4>
                  <p style={{ fontSize: "12px" }}>{item.products.length} items</p>
                  <br />

                  <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 40 }}>
                    {item.products.map((productId: string) => {
                      // console.log('map over products in cat',productId)
                      return products.map((product: any) => {
                        if (product.id === productId) {
                          return (
                            <Card
                              className="product-card-mobile"
                              onClick={() => {
                                if (product.enabled) {
                                  handClickedProduct(product);
                                }
                              }}
                              key={product.id}
                              interactive={product.enabled}
                              style={{
                                backgroundColor: product.enabled ? "#FCFCFC" : "lightgrey",
                                padding: "20px",
                                height: "112px",
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "column",
                                border: "1px solid #F2F2F2",
                                boxShadow: "none",
                              }}
                            >
                              <h3 style={{ margin: "0px" }}>{product.nameExternal}</h3>

                              {product.description && product.description !== "" && (
                                <p style={{ margin: "0px" }}>
                                  <p>{product.description.length > 90 ? `${product.description.slice(0, 90)}...` : product.description}</p>
                                </p>
                              )}

                              {product.discountedPrice ? (
                                <p style={{ margin: "0px", color: "#F55656" }}>
                                  <s>${(product.price * 0.01).toFixed(2)}</s>
                                </p>
                              ) : (
                                <p style={{ margin: "0px" }}>${(product.price * 0.01).toFixed(2)}</p>
                              )}

                              {product.discountedPrice && <p style={{ margin: "0px" }}>${(product.discountedPrice * 0.01).toFixed(2)}</p>}
                            </Card>
                          );
                        } else {
                          return null;
                        }
                      });
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        }


        {/* VIEW PRODUCT DIALOG */}
        {openViewProduct && selectedProduct && businessSettings && selectedMenu && selectedMenuCategory &&
          <ViewProduct
              menu={selectedMenu}
              menuCategory={selectedMenuCategory}
              product={selectedProduct}
              businessSettings={businessSettings}
              modifiers={modifiers}
              modifierOptions={modifierOptions}
              setOpenViewProduct={setOpenViewProduct}
              itemsInCart={itemsInCart}
              addItemToCart={addItemToCart}
          />
        }


        {/** PAY COMPONENT */}
        {openPayDialog && businessSettings && menus && user &&
          <Pay 
            business={business}
            businessSettings={businessSettings}
            deliverectToken={deliverectToken}
            itemsInCart={itemsInCart}
            menus={menus} 
            taxRate={taxRate}
            tipAmount={tipAmount}
            user={user}
            setOpenPayDialog={setOpenPayDialog}
            setSuccessfulOrder={setSuccessfulOrder}  
            setUser={setUser}        
          />
        }

        {/* VIEW CART */}
        {business && businessSettings && menus && discounts &&
          <Cart 
            business={business} 
            businessSettings={businessSettings} 
            itemsInCart={itemsInCart}
            menus={menus} 
            openPayDialog={openPayDialog}
            successfulOrder={successfulOrder}
            taxRate={taxRate}
            removeItemInCart={removeItemInCart}
            setOpenPayDialog={setOpenPayDialog}
            setChangeTip={setChangeTip}
            setRefreshCart={setRefreshCart}
          />
        }

      </div>
    </div>
  );
};
export default ViewLocation;
