import React, { useEffect, useState } from "react";
import { Classes, Button, Dialog, MenuItem, Card, Spinner} from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { expireTableSession, joinTableSession, updateTab } from "../../db/AddData";
import { createPopularInCategory, createFeaturedProducts } from "../../models/products";
import { fetchAllMenuData, fetchDeliverectToken, fetchBusinessSettings, fetchTab, getUpdatedPaidProducts, fetchUser, fetchTableSession } from "../../db/GetData";
import { calculateSubtotalInOrder, checkForScheduledDiscounts, createFirebaseJWT } from "../../utils/functions";
import "../Index.css";
import firebase from "../../config/firebase";
import CheckIcon from "../../assets/images/check.png";
import "react-datepicker/dist/react-datepicker.css";
import ViewCart from "./ViewCart"
import ViewCheck from "./ViewCheck";
import ViewMenuCategory from "./ViewMenuCategory"
import { Business, ItemsInCart, MenuCategory, Order, Tab, TangoBusinessSettings, TangoMenu, User } from "../../types/types";
import MobileHeader from "../../GiftCards/MobileHeader";
import DineInPay from "./DineInPay";
const db = firebase.firestore();

const DineIn = (props: any) => {

  const business = props.location.state.business;
  // const businessSettings = props.location.state.businessSettings;
  const table = props.location.state.table;
  const sessionId = props.location.state.sessionId;
  const orderType = "dineIn";
  const [businessSettings, setBusinessSettings] = useState<TangoBusinessSettings>();
  const [existingTab, setExistingTab] = useState<Tab>(props.location.state.tabThatAlreadyExists);
  const [existingOrder, setExistingOrder] = useState<Order>(props.location.state.orderThatAlreadyExists);
  const [loadingMenuData, setLoadingMenuData] = useState(true);
  const [user, setUser] =  useState<User>();
  const [deliverectToken, setDeliverectToken] = useState("");
  const [taxRate, setTaxRate] = useState(0.0);
  const [menus, setMenus] = useState<TangoMenu[]>([]); 
  const [selectedMenu, setSelectedMenu] = useState<TangoMenu>();
  const [selectedMenuCategory, setSelectedMenuCategory] = useState<MenuCategory>();
  const [menuCategories, setMenuCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [modifiers, setModifiers] = useState([]);
  const [modifierOptions, setModifierOptions] = useState([]);
  const [tip, setTip] = useState(0);
  const [popularList, setPopularList] = useState(null);
  const [featuredList, setFeaturedList] = useState<any[]>();
  const [successfulPaidOrder, setSuccesfulPaidOrder] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [openViewCheck, setOpenViewCheck] = useState(false);
  const [openViewCart, setOpenViewCart] = useState(false);
  const [openPayScreen, setOpenPayScreen] = useState(false);
  const [expiredTableSession, setExpiredTableSession] = useState('');//will be set to redirect link
  const [productsPaidForTab, setProductsPaidForTab] = useState<ItemsInCart[]>();
  
  const [paymentTotals, setPaymentTotals] = useState({
    subtotal: 0, 
    tax: 0, 
    tip: 0
  });

  
  const [openViewMenuCategory, setOpenViewMenuCategory] = useState(false);
  const [itemsInCart, setItemsInCart] = useState<ItemsInCart[]>([])
  const [newItems, removeItemInCart] = useState<ItemsInCart[]>([])
  const [completedOrder, setCompletedOrder] = useState(false)


  useEffect(() => {

    const businessSettingsListener = db.collection("BusinessSettings")
    .where("id", "==", business.id)
        .onSnapshot((snapshot:any) => {
            if (snapshot.docs) {
                snapshot.docs.forEach((doc:any) => {

                    if (snapshot.empty) {
                        console.log('no biz settings found')
                    }else{
                      console.log('found biz settings and set state')
                      setBusinessSettings(doc.data())
                    }


                });
            }
        });

        return () => {
          businessSettingsListener();
        }

  },[])

  //remove item in view cart
  useEffect(() => {
    setItemsInCart(newItems)
  },[newItems])

  // completed add items to order 
  useEffect(() => {
    if(completedOrder){
      console.log('completed new dine in order', completedOrder)
      setItemsInCart([])
      setCompletedOrder(false)
    }
  },[completedOrder])

  useEffect(() => {
    if(expiredTableSession){
      console.log('expiredTableSession', expiredTableSession)
      setItemsInCart([])
      setOpenViewMenuCategory(false)
      setOpenViewCart(false)
      setOpenPayScreen(false)
      setOpenViewCheck(false)
      setExpiredTableSession('')
      return window.location.replace(expiredTableSession)
    }
  },[expiredTableSession])


  //load menus, products, tab, and orders listener - need a lister here to propogate existing products in view cart page
  useEffect(() => {
    if (business.deliverect && business.deliverect.api === "channel") {
      getDeliverectToken();
    }

    //get tax rate
    business.tangoProducts.forEach((tangoProduct: any) => {
      if (tangoProduct.name === "mobile") {
        return (
          tangoProduct.orderTypes.length &&
          tangoProduct.orderTypes.forEach((type: any) => {
            if (type.name === orderType) {
              // console.log("apply tax rate,", type)
              setTaxRate(type.taxRate);
            }
          })
        );
      }
    });

    getUser();
    getAllMenuData()

    const orderListener = db.collection("Orders")
    .where("sessionId", "==", sessionId)
    .onSnapshot((snapshot:any) => {
      const allOrders: Order[] = []

        if (snapshot.docs) {
            snapshot.docs.forEach((doc:any) => {
                const order:Order = doc.data();  
                console.log('found existing order',order.id)        
                allOrders.push(order)
            });
        }

        //if there is an order with renderOrderInQueue:false use that one, that way alcohol items will append to this one
        const defaultOrder = allOrders.filter((order:Order)=>{
          return !order.renderOrderInQueue
        })
        if(defaultOrder.length>0){
          console.log('set exisitng order to the renderOrderInQueue:false')
          setExistingOrder(defaultOrder[0])
        }else{
          setExistingOrder(allOrders[0])
        }

    });

    return () => {
        // console.log('close order listener')
        orderListener()
    };
  }, []);

  //load discounts after menus, product, mods, etc
  useEffect(() => {
    if (products && menus && discounts) {
      const list = createFeaturedProducts(products);
      //@ts-ignore
      setFeaturedList(list);

      const results = checkForScheduledDiscounts(
        products.map((product: any) => [product.id, product.price]),
        discounts,
        new Date(),
        new Date(),
        null, //moment().format('HH:mm').toString(), //start time for user moment().format("HH:mm")
        null, //moment().format('HH:mm').toString(), //end time for user
        null,
        orderType //'dineIn'
      );
      //@ts-ignore
      results.forEach((discountedProduct: any) => {
        let productId = Object.keys(discountedProduct)[0];
        products.forEach((product: any) => {
          if (product.id === productId) {
            product.discountedPrice = Math.trunc(discountedProduct[product.id].discountedPrice);
          }
        });
      });
    }
  }, [products, menus, discounts]);

  const getAllMenuData = async()=> {
    try {
      const allMenuData = await fetchAllMenuData(business, true);
      //set menu and product data in local state
      //TO DO, set stitched products and use those instead of products, modifiers and options - then won't need to fetch from those collections
      setMenus(allMenuData?.menusToDisplay);
      setSelectedMenu(allMenuData?.menusToDisplay[0]);
      setMenuCategories(allMenuData?.menuCategories);
      setProducts(allMenuData?.products);
      //@ts-ignore
      setDiscounts(allMenuData?.discountsToDisplay);
      setModifiers(allMenuData?.modifiers)
      setModifierOptions(allMenuData?.modifierOptions)
      setLoadingMenuData(false);
    } catch (e) {
      console.error('error fetch all menu data',e);
      setLoadingMenuData(false);
    }
  }
  const getUser = async()=> {
    try {
      const user = await fetchUser();
      if (user) {
        setUser(user);
        joinTableSession(sessionId, user.id, await createFirebaseJWT())
      }
    } catch (e) {
      console.error("error getting user", e);
    }
  }

  const getDeliverectToken = async () => {
    try {
      const token = await fetchDeliverectToken();
      if (token) {
        setDeliverectToken(token);
      } else {
        setDeliverectToken("");
      }
    } catch (e) {
      console.error("error getting deliverect token", e);
      setDeliverectToken("");
    }
  };

  const renderMenuDropdown = (menus: any) => {
    return (
      <MenuItem
        key={menus.id}
        onClick={() => selectMenuInDropdown(menus)} //click menu item set status
        text={menus.nameExternal}
      />
    );
  };

  const selectMenuInDropdown = (menu: any) => {
    setSelectedMenu(menu);
    const list = createFeaturedProducts(products);
    //@ts-ignore
    setFeaturedList(list);
  };

  const checkForEpiredSession = async() => {
    const session = await fetchTableSession(sessionId)
    if(session && session.isExpired){
      setIsSessionExpired(true)
    }
  }
  if (isSessionExpired && business) {
    return (
      <Card style={{marginTop:'40%', textAlign:'center', marginLeft:'10%', marginRight:'10%'}}>
        <div style={{fontSize: "18px", fontFamily: "Lato-Bold", marginTop: "6px", marginBottom: "25px" }}>This order is closed. Thanks for visiting!</div>
        <a style={{fontWeight:'bold'}} href={`https://run-tango.web.app/order/${existingOrder.id}`}>View Receipt</a>
        <br/>
        <br/>
        <br/>
        <img style={{width:'40%'}} src={business.profileImageUrl}></img>
      </Card>
    );
  }

  if(businessSettings && !businessSettings.allowPatronDineInOrders){
    return (
      <div>
        {businessSettings && existingOrder && existingTab && user &&
          <ViewCheck
            business={business}
            businessSettings={businessSettings}
            deliverectToken={deliverectToken}
            existingOrder={existingOrder}   
            existingTab={existingTab}
            taxRate={taxRate}
            user={user}   
            setOpenViewCheck={setOpenViewCheck} 
            setOpenPayScreen={setOpenPayScreen} 
            setPaymentTotals={setPaymentTotals} 
            setProductsPaidForTab={setProductsPaidForTab}
            setExpiredTableSession={setExpiredTableSession}
          />
        }
      </div>
    )
  }


  return (
    <div>        
      
      {!openViewMenuCategory &&
        <MobileHeader />
      }

      {/* payment processed alert */}
      <Dialog
        isOpen={successfulPaidOrder}
        className="product-modal"
        onClose={() => {
          setSuccesfulPaidOrder(false);
        }}
        style={{ width: "90%", textAlign: "center" }}
      >
        <div style={{ fontFamily: "Axiforma-Regular" }}>
          <div
            style={{
              height: "62px",
              width: "62px",
              borderRadius: "50%",
              backgroundColor: "#2FB24C",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "30px",
            }}
          >
            <img src={CheckIcon} style={{ width: "30px", height: "30px" }} />
          </div>
          <div style={{ fontSize: "17px", fontFamily: "Axiforma-ExtraBold", marginTop: "5px" }}>Success!</div>
          <p style={{ fontSize: "12px", fontFamily: "Axiforma-Regular" }}>Your payment has been processed.</p>
        </div>
      </Dialog>


      {/* business header with menu dropdown  */}
      {/* Dont show when user is viewing category */}
      {!openViewMenuCategory && 
        <div style={{ paddingTop: "58.5px" }}>
          {business.bannerImageUrl ? 
            <>
              <div className="hero-banner">
                <img src={business.bannerImageUrl} className="business-banner-image" />
                <img src={business.profileImageUrl} className="business-avatar-image" alt="" />
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <h2 style={{ marginTop: "10px", marginBottom: "10px" }}>{business.businessName}</h2>
                  <p>Table {table.tableNumber}</p>
                  <div style={{ textAlign: "right" }}>
                    <Select className="select-menu-dropdown mobile-select-menu" items={menus} itemRenderer={renderMenuDropdown} filterable={false} onItemSelect={(e: any) => selectMenuInDropdown(e.target)}>
                      {/* @ts-ignore */}
                      <Button text={selectedMenu ? selectedMenu.nameExternal : "Select Menu"} rightIcon="chevron-down" />
                    </Select>
                  </div>
                </div>
                <div></div>
              </div>
            </>
           : 
            <div id="noParallaxContent" style={{ marginBottom: "2em" }}>
              <div style={{ float: "left" }}></div>
              <br />
              <br />
              <br />

              <img src={business.profileImageUrl} id="profileImage" alt="" />
              <h2 style={{ float: "left", color: "black", marginLeft: "2em" }}>{business.businessName}</h2>

              <div style={{ float: "right", marginRight: "1em", marginTop: "1em" }}>
                <Select items={menus} itemRenderer={renderMenuDropdown} filterable={false} onItemSelect={(e: any) => selectMenuInDropdown(e.target)}>
                  {/* @ts-ignore */}
                  <Button text={selectedMenu ? selectedMenu.nameExternal : "Select Menu"} rightIcon="caret-down" />
                </Select>
              </div>
            </div>
          }
        </div>
      }

      {/* Spinner when menu data is loading */}
      {loadingMenuData &&
        <div style={{ marginTop:"20%"}}>
          <Spinner/>
        </div>
      }

      {/* List of menu categories including featured lists */}
      {selectedMenu && !openViewMenuCategory && (
        <div style={{ marginBottom: "5em", borderTop: "1px solid #F7F7F7", paddingTop: "10px" }}>
          {featuredList && featuredList.length > 0 ? 
            <div style={{ paddingLeft: "1em" }}>
              <h4 style={{ marginBottom: "1em", marginTop: ".5em", fontSize: "14px", fontFamily: "Axiforma-ExtraBold" }}>Featured items</h4>
            </div>
           : 
            null
           }
          <br />

          <h2 style={{ margin: "0px", padding: "0px 20px", fontSize: "17px", fontFamily: "Axiforma-ExtraBold" }}>Menu Categories</h2>
          <p style={{ margin: "0px", padding: "5px 20px", fontSize: "12px", fontFamily: "Axiforma-Regular" }}>Select a menu to view the items available</p>
          {selectedMenu.categories.map((category: string) => {
            return menuCategories.map((cat: any) => {
              if (category === cat.id) {
                return (
                  <div
                    key={cat.id}
                    style={{ margin: "26px 0px", paddingLeft: "20px", paddingTop: "2px" }}
                    onClick={() => {
                      checkForEpiredSession()
                      setSelectedMenuCategory(cat);
                      const list = createPopularInCategory(cat, products);
                        //@ts-ignore
                      setPopularList(list);
                      window.scrollTo(0, 0);
                      setOpenViewMenuCategory(true)
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingBottom: "10px",
                      }}
                    >
                      <div>
                        <h3 style={{ margin: "0px", fontSize: "14px", fontFamily: "Axiforma-Bold" }}>{cat.name}</h3>
                        <p style={{ fontSize: "12px", marginTop: "5px" }}>{cat.products.length} items</p>
                      </div>
                      <Button icon="arrow-right" className={`${Classes.MINIMAL} order-from-button `}>
                        {" "}
                      </Button>
                    </div>
                    <hr className="hrLine" />
                  </div>
                );
              }
            });
          })}
        </div>
      )}


      {openViewMenuCategory && business && businessSettings && selectedMenu && selectedMenuCategory &&
        <ViewMenuCategory
          business={business}
          businessSettings={businessSettings}
          itemsInCart={itemsInCart}
          modifiers={modifiers}
          modifierOptions={modifierOptions}
          order={existingOrder}
          popularList={popularList}
          products={products}
          selectedMenu={selectedMenu}
          selectedMenuCategory={selectedMenuCategory}
          setOpenViewMenuCategory={setOpenViewMenuCategory}
        />
      }

      {/* Floating Checkout button when user cart has items */}
      {itemsInCart.length > 0 && 
        <div style={{position: "fixed", bottom: "0px", width: "100%", backgroundColor: "white", padding: "20px" }}>
          <Button
            id="checkout-cart-button"
            onClick={() => {
              setOpenViewCart(true);
            }}
            style={{ width: "100%", margin: "0px" }}
            intent="primary"
            large
          >
            <span style={{fontSize: "12px", position: "absolute", left: "20px", top: "15px", fontFamily: "Axiforma-Regular" }}> 
              {itemsInCart.reduce((totalCount:number, item:ItemsInCart)=> {
                  return totalCount + item.quantity;
                },0)
              }
              <span> items</span>
            </span>
            
            <span style={{ paddingLeft: "4em", paddingRight: "4em" }}>View Cart</span>
            <span style={{ fontSize: "12px", position: "absolute", right: "20px", top: "15px", fontFamily: "Axiforma-Regular" }}>
              ${(calculateSubtotalInOrder(itemsInCart) * 0.01) .toFixed(2)}
            </span>
          </Button>
        </div>
      }

      {openViewCart && existingTab && businessSettings &&
        <ViewCart 
            business={business}
            businessSettings={businessSettings}
            existingTab={existingTab}
            itemsInCart={itemsInCart}
            existingOrder={existingOrder}
            table={table}
            setOpenViewCart={setOpenViewCart}
            removeItemInCart={removeItemInCart}
            setCompletedOrder={setCompletedOrder}
        />
      }

      {/* View Check Button */}
      {existingOrder && existingTab && itemsInCart.length < 1 && 
        <div style={{ position: "fixed", bottom: "0px", width: "100%", padding: ".5em", backgroundColor: "white" }}>
          <Button
            onClick={() => {
              checkForEpiredSession()
              // console.log('open view check', businessSettings, existingOrder, existingTab, user)
              setOpenViewCheck(true);
            }}
            style={{ width: "100%", background: "#2FB24C", boxShadow: "none", borderRadius: "100px", position: "relative" }}
            intent="success"
            large
          >
            <span style={{ fontSize: "14px", fontFamily: "Axiforma-Bold" }}>View Check</span>
          </Button>
        </div>
      }


      {openViewCheck && businessSettings && existingOrder && existingTab && user && !openPayScreen &&
        <ViewCheck
          business={business}
          businessSettings={businessSettings}
          deliverectToken={deliverectToken}
          existingOrder={existingOrder}   
          existingTab={existingTab}
          taxRate={taxRate}
          user={user}    
          setOpenViewCheck={setOpenViewCheck} 
          setOpenPayScreen={setOpenPayScreen} 
          setPaymentTotals={setPaymentTotals} 
          setProductsPaidForTab={setProductsPaidForTab}
          setExpiredTableSession={setExpiredTableSession}
        />
      }


      {openPayScreen && productsPaidForTab && user && existingOrder && existingTab && businessSettings &&
          <DineInPay
              paymentTotals={paymentTotals}
              productsPaidForTab={productsPaidForTab}
              business={business}
              businessSettings={businessSettings}
              deliverectToken={deliverectToken}
              existingOrder={existingOrder}
              existingTab={existingTab}
              taxRate={taxRate}
              user={user}
              setOpenPayScreen={setOpenPayScreen}       
              setOpenViewCheck={setOpenViewCheck} 
              setItemsInCart={setItemsInCart}
          />
      }


    </div>
  );
};

export default DineIn;
