import React, {ChangeEvent, useEffect, useState} from 'react';
import {Button, Drawer, Icon} from '@blueprintjs/core';
import {
  CardElement,
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';
import {GiftCardsType, PaymentInfoGiftCardUsers} from "../types/types";
import {PaymentIntentResult} from "@stripe/stripe-js";
import capitalize from "lodash/capitalize";
interface Props {
  showModal: boolean;
  closeModal: (paymentIntent?: PaymentIntentResult) => void;
  cardDetails?: GiftCardsType;
  amount?: number;
  paymentClientSecret?: string;
  shouldShowSelectCard?: boolean;
  paymentInfos?: PaymentInfoGiftCardUsers[];
}

const CheckOut = ({showModal, closeModal, paymentClientSecret, amount = 100, shouldShowSelectCard = true, paymentInfos = []}: Props) => {
  const stripe = useStripe();
  const [isAddCard, setIsAddCard] = useState<boolean>(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState<number>(-1);
  // const [paymentRequest, setPaymentRequest] = useState(null);
  // const [paymentClientSecret, setPaymentClientSecret] = useState<string>()

  const [isPaymentLoading, setPaymentLoading] = useState(false);


  const elements = useElements();

  useEffect(() => {
    if (!stripe) {
      return;
    }
    if (stripe) {
        const pr = stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
                label: 'Demo total',
                amount: amount * 100,
            },
            requestPayerName: true,
            requestPayerEmail: true,
        });
        // Check the availability of the Payment Request API.
        pr.canMakePayment().then(result => {
            // console.log({result})
            if (result) {
                // @ts-ignore
                setPaymentRequest(pr);
            }
        }).catch((error)=> {
            console.log(error);
        })
    }
  }, [stripe]);

  const checkout = async () => {
    try {
      if (!stripe || !elements) {
        return;
      }
      // CardElement.
      setPaymentLoading(true);
      const paymentResult = await stripe.confirmCardPayment(paymentClientSecret!!, {
        payment_method: selectedCardIndex === -1 ? {
          card: elements.getElement(CardNumberElement)!!
        } : paymentInfos[selectedCardIndex].paymentMethodId
      })
      if (paymentResult.error) {
        alert(paymentResult.error.message);
      } else {
        if (paymentResult.paymentIntent.status === "succeeded") {
          // alert("Success!");
          closeModal(paymentResult);
        }
      }
    } catch (e) {
      alert(e);
    } finally {
      setPaymentLoading(false);
    }
  }

  // useEffect(() => {
  //
  // }, [amount]);

  return (
    <Drawer
      isOpen={showModal}
      position="bottom"
      className="checkout-drawer"
      hasBackdrop
      onClose={() => closeModal()}
    >
      <div className="checkout-container">
        <div className="checkout-header">
          <Icon icon="cross" color="#231F20" onClick={() => closeModal()}/>
          <div className="header-title">Payment Method</div>
          <div className="header-subtitle">Please select a payment below</div>
        </div>
        <div className="checkout-body" style={{overflowY: 'scroll', height: 360}}>
          {/*{*/}
          {/*  //@ts-ignore*/}
          {/*  paymentRequest && <PaymentRequestButtonElement options={{paymentRequest}}/>*/}
          {/*}*/}
          {/*{*/}
          {/*  shouldShowSelectCard && <>*/}
          {/*    <div className="or-text">Or pay with card</div>*/}
          {/*    <div className="input-container">*/}
          {/*      <div>Payments</div>*/}
          {/*      /!*<div onClick={() => setIsAddCard(true)}>Add Payment method</div>*!/*/}
          {/*      <select onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedCardIndex(Number(e.target.value))} value={selectedCardIndex}>*/}
          {/*        {*/}
          {/*          paymentInfos?.map((paymentInfo, index) => {*/}
          {/*            return <option value={index} selected={selectedCardIndex===-1}>{`${capitalize(paymentInfo.brand)} - ${paymentInfo.last4}`}</option>;*/}
          {/*          })*/}
          {/*        }*/}
          {/*        <option value={-1} selected={selectedCardIndex===-1}>Add Payment method</option>*/}
          {/*      </select>*/}
          {/*      /!*<Icon icon="plus" color="#231F20" iconSize={14}/>*!/*/}
          {/*    </div>*/}
          {/*  </>*/}
          {/*}*/}

          {
            selectedCardIndex === -1 && (
              <div className={'card-element-container'}>
                {/*<CardElement options={{hidePostalCode: true}} />*/}
                <div className={'card-input-field-container'}>
                  <CardNumberElement />
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div className={'card-input-field-container'}>
                    <CardExpiryElement />
                  </div>
                  <div className={'card-input-field-container'} style={{marginLeft: 10}}>
                    <CardCvcElement />
                  </div>
                </div>
              </div>
            )
          }

          <div className="checkout-total">
            <div style={{textAlign: 'right'}}>Total</div>
            <div style={{display: 'flex', justifyContent: 'space-between'}} className="payment-of">
              <div>Payment of</div>
              <div>${amount}</div>
            </div>
          </div>

          <Button text={`Checkout $${amount}`} className="checkout-btn" onClick={checkout} loading={isPaymentLoading}/>
        </div>
      </div>
    </Drawer>
  )
}
export default CheckOut;
