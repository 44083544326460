import React, { useState, useEffect } from "react";
import { PaymentRequestButtonElement, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { clearTableSessionItems, getDineInProductsPaid, sendEmailReceipt } from "../utils/functions";
import {getCustomerSuccessfulPayment} from "../stripe/functions"
import { createAuthenticatedUser, updateDineInTabOrderSession } from "../db/AddData";
import {createFirebaseJWT} from '../utils/functions'
import { fetchTab } from "../db/GetData";
import { nestJS_BaseURL } from "../utils/consts";
import { Business, ItemsInCart, Order, Tab, TangoBusinessSettings, User } from "../types/types";

interface WalletPaymentsDineInProps {
  user: User;
  order: Order;
  tab: Tab;
  taxRate: number;
  business: Business;
  businessSettings: TangoBusinessSettings;
  deliverectToken: string;
  productsPaidForTab: ItemsInCart[];
  paymentTotals: any;
  setSuccessfulPayment:(success: boolean) => void;
  setShowExpressCheckout: (shouldShow: boolean) => void;
}

const WalletPaymentsDineIn = ({ user, order, tab, taxRate, business, businessSettings, deliverectToken, productsPaidForTab, paymentTotals,setSuccessfulPayment, setShowExpressCheckout }: WalletPaymentsDineInProps) => {

  console.log('load wallet payment', productsPaidForTab, paymentTotals)
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const country = business.location ? (business.location.country ? (business.location.country.toLowerCase() === "canada" || business.location.country.toLowerCase() === "ca" ? "ca" : "usa") : "usa") : "usa"

  var tangoFee = business.tangoProducts.filter((tangoProduct: any) => {
    return tangoProduct.name == "mobile";
  });
  const tangoFeeCents = tangoFee[0].orderTypes[0].tangoFeeCents;
  const tangoFeePercent = tangoFee[0].orderTypes[0].tangoFeePercent;

  const emailOrder = {
    amount: {
      subTotal: paymentTotals.subtotal,
      discountTotal: 0,
      tax: paymentTotals.tax,
      tip: paymentTotals.tip,
      netTotal: paymentTotals.subtotal + paymentTotals.tax,
      grossTotal:  paymentTotals.subtotal + paymentTotals.tax + paymentTotals.tip,
      deliveryFee: 0,
      serviceChargeTotal: 0
    },
    orderNumber:order.orderNumber,
    products: productsPaidForTab,
    orderType: 'dineIn',
    createdAt: order.createdAt,
    turnaroundTime: [10,20],
  }

  const validateInformation = () => {
    if (user.name === "" || user.phone === "" || user.email === "") {
      alert("Please enter name, email and phone number before making a payment.");
      return false;
    }
    return true;
  };

  async function validateItemsForPayment(){
    const tabInFirestore = await fetchTab(tab.id ? tab.id : '')
    if(tabInFirestore.id && !tabInFirestore.paymentComplete){
      console.log('validate items for payment dine in wallet component')

      // productOrderIDsToPayFor.map((item: any) => {
      //   let alreadyPaidCount = 0;
      //   tabInFirestore.customer.map((customer: any) => {
      //     customer.productsPaid && customer.productsPaid.map((alreadyPaidProduct: any) => {
      //       if (alreadyPaidProduct.productOrderId === item) {
      //         alreadyPaidCount = alreadyPaidCount + alreadyPaidProduct.amount;
      //       }
      //     });
      //   });
      //   if (alreadyPaidCount === parseInt(item.charAt(0))) {
      //     alert("One of your selected items has already been paid for.");
      //     clearTableSessionItems(sessionId, user.id);
      //     return window.location.reload();
      //   }
      // });
    }else{
      return alert ('Your tab is complete and all items are payed for.')
    }
  }
  
  useEffect(() => {
    setPaymentRequest(null);
    if (stripe) {
     
      validateItemsForPayment()
      .catch((error) =>{
        console.log('error fetching tab to validate items for payment', error)
      })
  
      const totalCost = paymentTotals.subtotal + paymentTotals.tax + paymentTotals.tip

      const pr = stripe.paymentRequest({
        country: business.location ? (business.location.country ? (business.location.country.toLowerCase() === "canada" || business.location.country.toLowerCase() === "ca" ? "CA" : "US") : "US") : "US",
        currency: business.currency,
        total: {
          label: business.businessName,
          amount: Math.round(Number(totalCost)),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // console.log('pr', pr)

      pr.canMakePayment().then((result) => {
        if (result) {
          setShowExpressCheckout(true);
          setPaymentRequest(pr);

          pr.on("paymentmethod", async (ev) => {

            try {
              const paymentIntentRequest = {
                amount: Math.round(Number(totalCost)),
                currency: business.currency,
                description: 'Dine in order (wallet) at ' + business.businessName,
                application_fee_amount: Math.round(totalCost * (tangoFeePercent * 0.01) + tangoFeeCents),
                transfer_data: {
                  destination:  business.stripeConnect.id,
                },
              }
              const walletPaymentIntent = await axios
              .post(`${nestJS_BaseURL}/stripe/${country}/walletPaymentIntent`, paymentIntentRequest,{
                headers: {
                  'Authorization': `Bearer ${await createFirebaseJWT()}`,
                },
              })

              if(walletPaymentIntent.data){
                const clientSecret = walletPaymentIntent.data.client_secret
                const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(clientSecret, { payment_method: ev.paymentMethod.id }, { handleActions: false });

                if (confirmError) {
                  // Report to the browser that the payment failed, prompting it to
                  // re-show the payment interface, or show an error message and close
                  // the payment interface.
                  ev.complete("fail");
                  alert("Payment failed, please try again or try different payment method");
                  return false
                } else {
                  // Check if the PaymentIntent requires any actions and if so let Stripe.js
                  // handle the flow. If using an API version older than "2019-02-11"
                  // instead check for: `paymentIntent.status === "requires_source_action"`.
                  if (paymentIntent && paymentIntent.status === "requires_action") {
                    // Let Stripe.js handle the rest of the payment flow.
                    const { error } = await stripe.confirmCardPayment(clientSecret);
                    if (error) {
                      // The payment failed -- ask your customer for a new payment method.
                      ev.complete("fail");
                      alert("There was a problem with your payment method. You will not be charged.");
                    } else {
                      if (user.name === "") {
                        user.name = ev.payerName || "Guest";
                      }

                      if (user.phone === "") {
                        user.phone = ev.payerPhone || "";
                      }

                      if (user.email === "") {
                        user.email = ev.payerEmail || "";
                      }

                      user.id = user.email;
                    
                      ev.complete('success');//this returns the success message to broswer window


                      const chargeSuccess = await getCustomerSuccessfulPayment(paymentIntent.id, business, user, order, "stripe", await createFirebaseJWT());

                      if (chargeSuccess) {
                        const complete = await updateDineInTabOrderSession(productsPaidForTab, user, chargeSuccess.customer, order, tab, paymentTotals, taxRate, await createFirebaseJWT())
                        sendEmailReceipt(business, user, emailOrder);

                        if(complete){
                          return setSuccessfulPayment(true)
                        }
  
                      }

                      //to do - handle if getCustomerSuccessfulPayment doesnt return anything
                      //it means the wallet payment went through but stripe din't return any chargeSuccess 
                    }
                  } else {
                    if (user.name === "") {
                      user.name = ev.payerName || "Guest";
                    }

                    if (user.phone === "") {
                      user.phone = ev.payerPhone || "";
                    }

                    if (user.email === "") {
                      user.email = ev.payerEmail || "";
                    }

                    user.id = user.email;

                    ev.complete('success');//this returns the success message to broswer window
                    if(paymentIntent){
                        const chargeSuccess = await getCustomerSuccessfulPayment(paymentIntent.id, business, user, order, "stripe", await createFirebaseJWT());
                        if (chargeSuccess) {
                          const complete = await updateDineInTabOrderSession(productsPaidForTab, user, chargeSuccess.customer, order, tab, paymentTotals, taxRate, await createFirebaseJWT())
                          sendEmailReceipt(business, user, emailOrder);
                          if(complete){
                            return setSuccessfulPayment(true)
                          }
                        }
                      //to do - handle if getCustomerSuccessfulPayment doesnt return anything
                      //it means the wallet payment went through but stripe din't return any chargeSuccess
                    }else{
                      console.log('no wallet payment intent data loaded')
                      return alert("Payment failed, please try again or try different payment method")
                    }
                  }
                  // Report to the browser that the confirmation was successful, prompting
                  // it to close the browser payment method collection interface.
                  ev.complete("success");
                }
  
              }else{
                console.log('no wallet payment intent data loaded')
                return alert("Payment failed, please try again or try different payment method");
              }
              
            } catch (error) {
              console.log('error creating dine in wallet payment intent --->', error)
              setShowExpressCheckout(false);
            }
          });

        } else {
          setShowExpressCheckout(false);
        }
      });
    }
  }, [stripe, order, user]);


  if (paymentRequest) {
    return (
      <PaymentRequestButtonElement
        onClick={(event) => {
          if (!validateInformation()) {
            event.preventDefault();
            return;
          }
        }}
        options={{ paymentRequest }}
      />
    );
  }

  return null;
};

export default WalletPaymentsDineIn;
