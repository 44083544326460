import React, { useState, useEffect } from "react";
import { PaymentRequestButtonElement, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { sendEmailReceipt, formatAndSendTextMessage } from "../utils/functions";
import { getCustomerSuccessfulPayment, refundPayment } from "../stripe/functions";
import { createAuthenticatedUser, createDeliverectOrder, createOnlineTabAndOrder, updateTab } from "../db/AddData";
import {createFirebaseJWT} from '../utils/functions'
import {nestJS_BaseURL} from "../utils/consts";
import { Business, Order, TangoBusinessSettings } from "../types/types";

interface WalletPaymentsProps {
  total: number;
  business: Business;
  user: { name: string; phone: string; email: string; id: string };
  order: Order;
  apartmentNumber: string;
  deliverectToken: string;
  businessSettings: TangoBusinessSettings;
  onChange: (successfulPayment: boolean, user: any, orderId: string) => void;
  setShowExpressCheckout: (shouldShow: boolean) => void;
}

const WalletPayments = ({ total, business, user, order, apartmentNumber, deliverectToken, businessSettings, onChange, setShowExpressCheckout }: WalletPaymentsProps) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const [successfulPayment, setSuccessfulPayment] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState<string | null>(null);

  const tangoFee = business.tangoProducts.filter((tangoProduct: any) => {
    return tangoProduct.name == "mobile";
  });
  const tangoFeeCents = tangoFee[0].orderTypes[0].tangoFeeCents;
  const tangoFeePercent = tangoFee[0].orderTypes[0].tangoFeePercent;
  const deliverect = business.deliverect && business.deliverect.api === 'channel' ? business.deliverect.channelLinkId : false; //if business uses Tango as a deliverect, set the channel link id to send the order to deliverect
  const country = business.location ? (business.location.country ? (business.location.country.toLowerCase() === "canada" || business.location.country.toLowerCase() === "ca" ? "ca" : "usa") : "usa") : "usa"
  const validateInformation = () => {
    if (user.name === "" || user.phone === "" || user.email === "") {
      alert("Please enter name, email and phone number before making a payment.");
      return false;
    }

    if (businessSettings && businessSettings.scheduledOrdersRequired && !order.pickupTime) {
      alert(business.businessName + " requires a pickup time for all online orders. Please schedule a pickup date and time.");
      return false;
    }

    if (order.orderType === "delivery" && !order.deliveryAddress) {
      alert("Please enter an address for your delivery order.");
      return false;
    }

    return true;
  };

  async function finishWalletPaymentDeliverect(paymentIntent:any) {
    console.log('finish wallet payment for deliverect order')
    const chargeSuccess = await getCustomerSuccessfulPayment(paymentIntent.id, business, user, order, "stripe", await createFirebaseJWT());
    if (chargeSuccess) {
      const completedTabOrder = await createDeliverectOrder(order, user, business, chargeSuccess.customer, deliverectToken, apartmentNumber, await createFirebaseJWT());
      if (completedTabOrder) {
        sendEmailReceipt(business, user, order);
        formatAndSendTextMessage(completedTabOrder.orderId, user, business, order);
        setCurrentOrderId(completedTabOrder.orderId);
        createAuthenticatedUser(user, await createFirebaseJWT());
        setSuccessfulPayment(true);
      }
      else{
        const refundSuccess = await refundPayment(business.location.country, paymentIntent)
        if(refundSuccess){
          alert("There was a problem placing your order. You will not be charged.");
        }else{
          alert("There was a problem placing your order. Please contact " + business.businessName);
        }
      }
    }
  }

  async function finishWalletPayment(paymentIntent: any, tabOrder: any) {
    if(!tabOrder){
      const refundSuccess = await refundPayment(business.location.country, paymentIntent)
        if(refundSuccess){
          return alert("There was a problem placing your order. You will not be charged.");
        }else{
          return alert("There was a problem placing your order. Please contact " + business.businessName);
        }
    }
    console.log('finish wallet payment for tango order')
    const chargeSuccess = await getCustomerSuccessfulPayment(paymentIntent.id, business, user, order, "stripe", await createFirebaseJWT());
    if (chargeSuccess) {
      //update the tab with payment info
      chargeSuccess.customer.orderId = tabOrder.orderId;
      const updatedTab = await updateTab(tabOrder.tabId, {customer: [chargeSuccess.customer]}, await createFirebaseJWT())
      if(updatedTab){
        console.log('updated tab with wallet payment data', updatedTab)
      }else{
        console.log('error updating tab with wallet payment data')
      }
      sendEmailReceipt(business, user, order);
      formatAndSendTextMessage(tabOrder.orderId, user, business, order);
      createAuthenticatedUser(user, await createFirebaseJWT());
      setCurrentOrderId(tabOrder.orderId);
      return setSuccessfulPayment(true);
    } else {
      console.log('fail to fetch successful charge - tab and order are created but tab wont have up to date payment data')
      sendEmailReceipt(business, user, order);
      formatAndSendTextMessage(tabOrder.orderId, user, business, order);
      createAuthenticatedUser(user, await createFirebaseJWT());
      setCurrentOrderId(tabOrder.orderId);
      return setSuccessfulPayment(true);
    }
  }

  useEffect(() => {
    setPaymentRequest(null);
    console.log("wallet payment total", total)

    if(!total || !Number(total)){
      setShowExpressCheckout(false);
      return alert("There was a problem accessing your mobile wallet. Please use a credit card in standard checkout.")
    }
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: business.location ? (business.location.country ? (business.location.country.toLowerCase() === "canada" || business.location.country.toLowerCase() === "ca" ? "CA" : "US") : "US") : "US",
        currency: business.currency,
        total: {
          label: business.businessName,
          amount: Math.round(Number(total)), //somehow this is NAN
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setShowExpressCheckout(true);
          setPaymentRequest(pr);

          pr.on("paymentmethod", async (ev) => {
            try {
              const paymentIntentRequest = {
                amount: Math.round(Number(total)),
                currency: business.currency,
                description: 'Online order (wallet) at ' + business.businessName,
                application_fee_amount: Math.round((order.amount.netTotal + order.amount.tip) * (tangoFeePercent * 0.01) + tangoFeeCents),
                transfer_data: {
                  destination:  business.stripeConnect.id,
                },
              }
              const walletPaymentIntent = await axios
              .post(`${nestJS_BaseURL}/stripe/${country}/walletPaymentIntent`, paymentIntentRequest,{
                headers: {
                  'Authorization': `Bearer ${await createFirebaseJWT()}`,
                },
              })
  
              if(walletPaymentIntent.data){
                const clientSecret = walletPaymentIntent.data.client_secret
                const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(clientSecret, { payment_method: ev.paymentMethod.id }, { handleActions: false });

                if (confirmError) {
                  // Report to the browser that the payment failed, prompting it to
                  // re-show the payment interface, or show an error message and close
                  // the payment interface.
                  ev.complete("fail");
                  alert("Payment failed, please try again or try different payment method");
                  return false
                } else {
                  // Check if the PaymentIntent requires any actions and if so let Stripe.js
                  // handle the flow. If using an API version older than "2019-02-11"
                  // instead check for: `paymentIntent.status === "requires_source_action"`.
                  if (paymentIntent && paymentIntent.status === "requires_action") {
                    // Let Stripe.js handle the rest of the payment flow.
                    const { error } = await stripe.confirmCardPayment(clientSecret);
                    if (error) {
                      // The payment failed -- ask your customer for a new payment method.
                      ev.complete("fail");
                      alert("There was a problem with your payment method. You will not be charged.");
                    } else {
                      if (user.name === "") {
                        user.name = ev.payerName || "Guest";
                      }

                      if (user.phone === "") {
                        user.phone = ev.payerPhone || "";
                      }

                      if (user.email === "") {
                        user.email = ev.payerEmail || "";
                      }

                      user.id = user.email;
                      // The payment has succeeded.
                      ev.complete("success");
                      if (deliverect) {
                        await finishWalletPaymentDeliverect(paymentIntent);
                      } else {
                        const completedTabAndOrder = await createOnlineTabAndOrder(user, order, await createFirebaseJWT());
                        finishWalletPayment(paymentIntent, completedTabAndOrder);
                      }
                    }
                  } else {
                    if (user.name === "") {
                      user.name = ev.payerName || "Guest";
                    }

                    if (user.phone === "") {
                      user.phone = ev.payerPhone || "";
                    }

                    if (user.email === "") {
                      user.email = ev.payerEmail || "";
                    }

                    user.id = user.email;

                    // The payment has succeeded.
                    ev.complete("success");
                    if (deliverect) {
                      await finishWalletPaymentDeliverect(paymentIntent);
                    } else {
                      const completedTabAndOrder = await createOnlineTabAndOrder(user, order, await createFirebaseJWT());
                      finishWalletPayment(paymentIntent, completedTabAndOrder);
                    }
                  }
                  // Report to the browser that the confirmation was successful, prompting
                  // it to close the browser payment method collection interface.
                  ev.complete("success");
                }
  
              }else{
                console.log('no wallet payment intent data loaded')
                return alert("Payment failed, please try again or try different payment method");
              }
              
            } catch (error) {
                console.log("ERROR: ", error);
                ev.complete("fail");
                alert("There was a problem with your payment method. You will not be charged.");
                return false
            }
          });
        } else {
          setShowExpressCheckout(false);
        }
      });
    }
  }, [stripe, total]);

  useEffect(() => {
    if (successfulPayment && currentOrderId) {
      onChange(successfulPayment, user, currentOrderId);
      setSuccessfulPayment(false);
      setCurrentOrderId(null);
    }
  }, [successfulPayment, currentOrderId]);

  if (paymentRequest) {
    return (
      <PaymentRequestButtonElement
        onClick={(event) => {
          if (!validateInformation()) {
            event.preventDefault();
            return;
          }
        }}
        options={{ paymentRequest }}
      />
    );
  }

  return null;
};

export default WalletPayments;
