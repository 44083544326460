import React, { useState, useEffect } from "react";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Spinner, Button } from "@blueprintjs/core";
import CreditCardIcon from "../assets/images/credit-card.png";
import { createOnlineTabAndOrder, createDeliverectOrder, removeStripeCard, createAuthenticatedUser, updateTab} from "../db/AddData";
import { formatAndSendTextMessage, sendEmailReceipt } from "../utils/functions";
import {createStripePaymentIntent, getCustomerSuccessfulPayment, refundPayment} from "./functions"
import {createFirebaseJWT} from '../utils/functions'
import { Business, Order, TangoBusinessSettings } from "../types/types";


const StripeCardDetails = (props: any) => {
  const stripe = useStripe();
  const elements = useElements();
  var user = {...props.user};
  // console.log("user ?", user)
  if(user && user.phone && !user.phone.includes('+1')){
    user.phone = user.phone.substring(0, 0) + '+1' + user.phone.substring(0)
  }
  const businessSettings:TangoBusinessSettings = props.businessSettings
  const order:Order = props.order;
  const apt = props.apt;
  const business:Business = props.business;
  const deliverectToken = props.deliverectToken;
  const statementDescriptor = business.businessName.replace("'", "") + " online"; //remove ' character
  const [successfulPayment, setSuccessfulPayment] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [removedCard, setRemovedCard] = useState(false);
  const [paymentClientSecret, setPaymentClientSecret] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPaymentIntent, setLoadingPaymentIntent] = useState(false);
  const [paymentIntentError, setPaymentIntentError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isCardNumberCompleted, setIsCardNumberCompleted] = useState(false);
  const [isExpiryCompleted, setIsExpiryCompleted] = useState(false);
  const [isCvvCompleted, setIsCvvCompleted] = useState(false);

  var tangoFee = business.tangoProducts.filter((tangoProduct: any) => {
    return tangoProduct.name == "mobile";
  });


  order.customerName = user.name
  const country =  business.location.country === "Canada" || business.location.country === "CA" ? 'Canada' : 'USA'
  const grandTotal = order.amount.netTotal + order.amount.tip + order.amount.deliveryFee - order.amount.discountTotal + order.amount.serviceChargeTotal
  const tangoFeeCents = tangoFee[0].orderTypes[0].tangoFeeCents;
  const tangoFeePercent = tangoFee[0].orderTypes[0].tangoFeePercent;
  const deliverect = business.deliverect && business.deliverect.api === 'channel' ? business.deliverect.channelLinkId : false; //if business uses Tango as a deliverect, set the channel link id to send the order to deliverect

  // console.log(stripe)
  // console.log('stripe card details --> ', '\norder ',order, '\business ',business, '\deliverect ',deliverect, '\deliverect token ',deliverectToken)

  if (props.onChange && successfulPayment && orderId) {
    props.onChange(successfulPayment, user, orderId);
  }

  
  useEffect(() => {
    if (isCardNumberCompleted && isExpiryCompleted && isCvvCompleted) {
      console.log('entered valid card data')
      setDisabled(false);
    }
  }, [isCardNumberCompleted, isExpiryCompleted, isCvvCompleted]);

  //When loading the stripe modal fetch a payment intent from nest js - may have to reload user to be sure stripe id is propogated
  useEffect(() => {
    setLoadingPaymentIntent(true);    
    const paymentData = {
      amount: Math.round(grandTotal),
      currency: business.currency ? business.currency : "usd",
      confirm: false,
      payment_method_types: ["card"],
      customer: business.location.country === "Canada" || business.location.country === "CA" ? user.stripeCanada.id : user.stripe.id,
      description: "Online order at " + business.businessName,
      statement_descriptor: statementDescriptor.length > 22 ? statementDescriptor.substring(0, 22) : statementDescriptor,//22 chars max
      application_fee_amount: Math.round(grandTotal * (tangoFeePercent * 0.01) + tangoFeeCents),
      on_behalf_of: business.stripeConnect.id,
      transfer_data: {
        destination: business.stripeConnect.id,
      },
    }
    fetchPaymentIntent(paymentData)
    .catch((error)=>{
      setDisabled(true); //disable pay button if no payment secret
      setPaymentIntentError(true);
      console.log('payment intent catch error', error)
      return error
    });
  }, [removedCard, grandTotal]);

  const fetchPaymentIntent = async (paymentData:any) => {
    // console.log('fetch payment intent', paymentData, country)
    const paymentIntent = await createStripePaymentIntent(paymentData, country, await createFirebaseJWT());

    if(paymentIntent.paymentIntentSecret){
      setPaymentClientSecret(paymentIntent.paymentIntentSecret);
      setLoadingPaymentIntent(false);
      console.log('payment intent loaded')
      return setDisabled(false);
    }
    if(paymentIntent.client_secret){
      setPaymentClientSecret(paymentIntent.client_secret);
      setLoadingPaymentIntent(false);
      console.log('payment intent loaded!')
      return setDisabled(false);
    }else{
      console.log('problem loading payment intent', paymentIntent)
      setDisabled(true); //disable pay button if no payment secret
      setLoadingPaymentIntent(false);
      return setPaymentIntentError(true);
    }
  }

  //payment for "first time" user with no cards
  const handleConfirmCardAndPay = async (event: any) => {

    event.preventDefault();

    if(!(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im).test(user.phone)){
      return alert ("Please enter a valid phone number.")
    }
    if(order.orderType === "delivery" && !order.deliveryAddress){
      return alert ("Please enter a valid address for your delivery order.")
    }

    if (!validateDetails()) {
      console.log('no user details entered')
      return false;
    }
    setLoading(true);
    //@ts-ignore
    const cardElement = elements.getElement(CardNumberElement);
    if (!stripe || !elements) {
      console.log('no stripe or elements')
      setLoading(false);
      return;
    }

    if(!paymentClientSecret){
      alert('There was a problem processing your order. You will not be charged.')
      return false
    }
    console.log('paymentClientSecretloaded',)
    // console.log('confirm first time card for user', user)
    // console.log('deliverect', deliverect)

    //process stripe charge for deliverect order for new card user
    if (deliverect) {
      stripe
        .confirmCardPayment(paymentClientSecret, {
          payment_method: {
            //@ts-ignore
            card: cardElement,
            billing_details: {
              name: user.name,
              email: user.email,
              phone: user.phone,
            },
            metadata: {
              tangoUserId: user.id,
            },
          },
          setup_future_usage: "off_session", //this automatically attaches card to stripe customer for future use //webhook in cloud function will listen for event and update user with card data
        })
        .then(async(result) => {
          if (result.error) {
            // Show error to your customer
            console.log(result.error.message);
            alert(result.error.message);
            setLoading(false);
            return false
          } else {



            if (result.paymentIntent.status === "succeeded") {
              const completedPayment = await getCustomerSuccessfulPayment(result.paymentIntent.id, business, user, order, "stripe", await createFirebaseJWT());
              if (completedPayment) {
                const completedTabOrder = await createDeliverectOrder(order, user, business, completedPayment.customer, deliverectToken, apt, await createFirebaseJWT());
                if (completedTabOrder) {
                  sendEmailReceipt(business, user, order);
                  formatAndSendTextMessage(completedTabOrder.orderId, user, business, order);
                  setOrderId(completedTabOrder.orderId);
                  createAuthenticatedUser(user, await createFirebaseJWT());
                  return setSuccessfulPayment(true);
                } else {
                  const refundSuccess = await refundPayment(business.location.country, result.paymentIntent)
                  if(refundSuccess){
                    alert("There was a problem placing your order. You will not be charged.");
                  }else{
                    alert("There was a problem placing your order. Please contact " + business.businessName);
                  }
                  console.log("no result from create Deliverect Order");
                  setLoading(false);
                  return false
                }
              }else{
                console.log("no completed payment returned from stripe, couldn't update tab ")
                const refundSuccess = await refundPayment(business.location.country, result.paymentIntent)
                if(refundSuccess){
                  alert("There was a problem placing your order. You will not be charged.");
                }else{
                  alert("There was a problem placing your order. Please contact " + business.businessName);
                }
                setLoading(false);
                return false
              }
            } else {
              console.log("first time deliverect order no error or success from stripe?");
              alert("There was a problem placing your order. You will not be charged.");
              setLoading(false);
              return false
            }





          }
        });
    }
    // //process stripe charge for regular Tango order and new card user
    else {
      try {
        stripe
          .confirmCardPayment(paymentClientSecret, {
            payment_method: {
              //@ts-ignore
              card: cardElement,
              billing_details: {
                name: user.name,
                email: user.email,
                phone: user.phone,
              },
              metadata: {
                tangoUserId: user.id,
              },
            },
            setup_future_usage: "off_session", //this automatically connects card to stripe customer for future use //webhook in cloud function will listen for event and update user with card data
          })
          .then(async(result) => {
            if (result.error) {
              console.log('error ?',result.error);
              alert(result.error.message);
              setLoading(false);
              return false
            } else {

              if (result.paymentIntent.status === "succeeded") {
                //after payment success write the tab and order to firestore
                const completedTabOrder = await createOnlineTabAndOrder(user, order, await createFirebaseJWT());
                if (completedTabOrder) {
                  //after payment and order created call getCustomerSuccessfulPayment to return the customer object with entire payment object
                  //possible this step may fail and we won't have full customer object in tab
                  const completedPayment = await getCustomerSuccessfulPayment(result.paymentIntent.id, business, user, order, "stripe", await createFirebaseJWT());
                  if (completedPayment) {
                    completedPayment.customer.orderId = completedTabOrder.orderId;
                    const updateTabSuccess = await updateTab(completedTabOrder.tabId, { customer: [completedPayment.customer]}, await createFirebaseJWT())
                    console.log('updated tab with complete payment data', updateTabSuccess)
                  }
                  createAuthenticatedUser(user, await createFirebaseJWT());
                  setOrderId(completedTabOrder.orderId);
                  formatAndSendTextMessage(completedTabOrder.orderId, user, business, order);
                  sendEmailReceipt(business, user, order);
                  setLoading(false);
                  return setSuccessfulPayment(true);
                } else {
                  console.log("no tab and order created after successful payment");
                  //Process refund since payment succeeded but order and tab didn't get created
                  const refundSuccess = await refundPayment(business.location.country, result.paymentIntent)
                  if(refundSuccess){
                    alert("There was a problem placing your order. You will not be charged.");
                  }else{
                    alert("There was a problem placing your order. Please contact " + business.businessName);
                  }
                  setLoading(false);
                  return false
                }
                
              } else{
                console.log('payment does not have succeeded status')
                setLoading(false);
                alert("There was a problem processing your payment.");
                return false
              }


            }
          });
      }
      catch (error) {
        console.log('catch error process payment')
        setLoading(false);
        alert("There was a problem processing your payment.");
        return false
      }
    }

  };


  //payment for "returning" user with credit card cards
  const handlePayWithExisitngCard = async () => {
 
    if(!(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im).test(user.phone)){
      return alert ("Please enter a valid phone number.")
    }
    if(order.orderType === "delivery" && !order.deliveryAddress){
      return alert ("Please enter a valid address for your delivery order.")
    }
    if(!stripe){
      console.log('no stripe loaded')
      return setDisabled(true)
    }

    setLoading(true);
    
    if (deliverect) {
      //process payment first then create a deliverect order and save to firestore
      try {
        return stripe
        .confirmCardPayment(paymentClientSecret, {
          payment_method: business.location.country === "Canada" || business.location.country === "CA" ? user.stripeCanada.cards[0].paymentMethodId : user.stripe.cards[0].paymentMethodId,
        })
        .then(async (result) => {
          if (result.error) {
            console.log("error with stripe charge for deliverect order", result.error.message);
            alert(result.error.message);
            setLoading(false);
            return false
          } else {



            if (result.paymentIntent.status === "succeeded") {

              const completedPayment = await getCustomerSuccessfulPayment(result.paymentIntent.id, business, user, order, "stripe", await createFirebaseJWT());
              if (completedPayment) {
                const completedTabOrder = await createDeliverectOrder(order, user, business, completedPayment.customer, deliverectToken, apt, await createFirebaseJWT());
                if (completedTabOrder) {
                  sendEmailReceipt(business, user, order);
                  formatAndSendTextMessage(completedTabOrder.orderId, user, business, order);
                  setOrderId(completedTabOrder.orderId);
                  return setSuccessfulPayment(true);
                } else {
                  const refundSuccess = await refundPayment(business.location.country, result.paymentIntent)
                  if(refundSuccess){
                    alert("There was a problem placing your order. You will not be charged.");
                  }else{
                    alert("There was a problem placing your order. Please contact " + business.businessName);
                  }
                  console.log("no result from create Deliverect Order");
                  setLoading(false);
                  return false
                }
              } else {
                const refundSuccess = await refundPayment(business.location.country, result.paymentIntent)
                if(refundSuccess){
                  alert("There was a problem placing your order. You will not be charged.");
                }else{
                  alert("There was a problem placing your order. Please contact " + business.businessName);
                }
                console.log("Stripe processed payment but didn't return completedPayment");
                setLoading(false)
                return false
              }
            } else {
              alert("There was a problem processing your order. You will not be charged.");
              console.log("result from stripe is not error or succeeded ??");
              setLoading(false);
              return false
            }


          }
        });
        
      } catch (error) {
        alert("There was a problem processing your order. You will not be charged.");
        console.log("catch error", error);
        setLoading(false);
        return false
      }
     
    }
    //for returning credit card user, create normal tango tab and order process payment when click submit
    else {
      try {
        stripe
          .confirmCardPayment(paymentClientSecret, {
            payment_method: business.location.country === "Canada" || business.location.country === "CA" ? user.stripeCanada.cards[0].paymentMethodId : user.stripe.cards[0].paymentMethodId,
          })
          .then(async (result) => {
            if (result.error) {
              console.log('stripe charge error, returning card user',result.error.message);
              alert(result.error.message);
              setLoading(false);
              return false
            } else {



              if (result.paymentIntent.status === "succeeded") {
                //after payment success write the tab and order to firestore
                const completedTabOrder = await createOnlineTabAndOrder(user, order, await createFirebaseJWT());
                if (completedTabOrder) {
                  console.log('completedTabOrder', completedTabOrder)
                  //after payment and order created call getCustomerSuccessfulPayment to return the customer object with entire payment object
                  //possible this step may fail and we won't have full customer object in tab
                  const completedPayment = await getCustomerSuccessfulPayment(result.paymentIntent.id, business, user, order, "stripe", await createFirebaseJWT());
                  if (completedPayment) {
                    completedPayment.customer.orderId = completedTabOrder.orderId;
                    const updateTabSuccess = await updateTab(completedTabOrder.tabId, { customer: [completedPayment.customer]}, await createFirebaseJWT())
                    console.log('updated tab with complete payment data', updateTabSuccess)
                  }
                  createAuthenticatedUser(user, await createFirebaseJWT());
                  setOrderId(completedTabOrder.orderId);
                  formatAndSendTextMessage(completedTabOrder.orderId, user, business, order);
                  sendEmailReceipt(business, user, order);
                  setLoading(false);
                  return setSuccessfulPayment(true);
                } else {
                  console.log("no tab and order created");
                  //Process refund since payment succeeded but order and tab didn't get created
                  const refundSuccess = await refundPayment(business.location.country, result.paymentIntent)
                  if(refundSuccess){
                    alert("There was a problem placing your order. You will not be charged.");
                  }else{
                    alert("There was a problem placing your order. Please contact " + business.businessName);
                  }
                  setLoading(false);
                  return false
                }
              } else{
                console.log("returning customer payment does not have succeeded status");
                setLoading(false);
                alert("There was a problem processing your payment.");
                return false
              }



            }
          });
        } catch (error) {
          console.log('catch error process payment')
          setLoading(false);
          alert("There was a problem processing your payment");
          return false
        }
    }
  };

  const handleCardInput = (e: any) => {
    if (e.elementType === "cardNumber") {
      if (e.elementType.complete) {
        setIsCardNumberCompleted(true);
      } else {
        setIsCardNumberCompleted(false);
      }
    }
    if (e.elementType === "cardExpiry") {
      if (e.elementType.complete) {
        setIsExpiryCompleted(true);
      } else {
        setIsExpiryCompleted(false);
      }
    }
    if (e.elementType === "cardCvc") {
      if (e.elementType.complete) {
        setIsCvvCompleted(true);
      } else {
        setIsCvvCompleted(false);
      }
    }
    if (e.complete) {
      // enable payment button
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    if (e.error) {
      console.log('error handle card input')
      setDisabled(true);
    }
  };

  const removeCard = async() => {
    setLoading(true);
    removeStripeCard(user.id, business.location.country, await createFirebaseJWT());
    user.stripe.cards = [];
    user.stripeCanada.cards = []
    setLoading(false);
    setRemovedCard(true);
  };

  const validateDetails = () => {
    if (!user.email || !user.name) {
      alert("Please enter name and email before entering payment information.");
      return false;
    }
    if (businessSettings && businessSettings.scheduledOrdersRequired && !order.pickupTime) {
      alert(business.businessName + " requires a pickup time for all online orders. Please schedule a pickup date and time.");
      return false;
    }
    if (order.orderType === "delivery" && !order.deliveryAddress) {
      alert("Please enter an address for your delivery order.");
      return false;
    }
    return true
};

  return (
    <div style={{ fontFamily: "Axiforma-Regular" }}>
      {(business.location.country === "USA" && user.stripe && user.stripe.cards.length > 0) || ((business.location.country === "Canada" || business.location.country === "CA") && user.stripeCanada && user.stripeCanada.cards.length > 0) ? 
        <div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "13px", backgroundColor: "#F5F5F5", marginBottom: "30px" }}>
            <img src={CreditCardIcon} style={{ height: "14.96px", marginRight: "10px", paddingLeft: "10px" }} />
            <span style={{ fontSize: "17px", fontFamily: "Axiforma-ExtraBold" }}>Card Details</span>
          </div>

          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                background: "#FCFCFC",
                padding: "18px",
                marginTop: "20px",
                border: "1px solid #F2F2F2",
                borderRadius: "5px",
              }}
            >
              {business.location.country === "Canada" || business.location.country === "CA" ? 
                <div>
                  <div>&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226; {user.stripeCanada.cards[0].last4}</div>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div>{user.stripeCanada.cards[0].exp_month + " / " + user.stripeCanada.cards[0].exp_year}</div>
                    <Button style={{ marginLeft: "20px" }} icon="cross" minimal small onClick={() => removeCard()}></Button>
                  </div>
                </div>
               : 
                <div>
                  <div>&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;{user.stripe.cards[0].last4}</div>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div>{user.stripe.cards[0].exp_month + " / " + user.stripe.cards[0].exp_year}</div>
                    <Button style={{ marginLeft: "20px" }} icon="cross" minimal small onClick={() => removeCard()}></Button>
                  </div>
                </div>
              }
            </div>

            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <Button onClick={() => handlePayWithExisitngCard()} disabled={disabled} loading={loading} large minimal style={{ color: "white", backgroundColor: "rgb(47, 178, 76)", width: "100%", fontFamily: "Axiforma-Bold" }}>
                Place Order
              </Button>
            </div>
          </div>
        </div>
       : 
        <div>
          {paymentIntentError && <div style={{ padding: "20px" }}>Error loading credit card input</div>}

          {loadingPaymentIntent ? 
            <div>
              <Spinner />
            </div>
           : 
            <form onSubmit={handleConfirmCardAndPay}>
              <br />

              {!paymentIntentError && 
                <div>
                  <div style={{ backgroundColor: "#f9f9f9", padding: "18px", marginBottom: "20px" }}>
                    <CardNumberElement onChange={(e) => handleCardInput(e)} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ backgroundColor: "#f9f9f9", padding: "18px", width: "25%" }}>
                      <CardExpiryElement onChange={(e) => handleCardInput(e)} />
                    </div>
                    <div style={{ backgroundColor: "#f9f9f9", padding: "18px", width: "70%" }}>
                      <CardCvcElement onChange={(e) => handleCardInput(e)} />
                    </div>
                  </div>
                  <div style={{ textAlign: "center", marginTop: "50px" }}>
                    <Button type="submit" loading={loading} disabled={disabled} large minimal style={{ color: "white", backgroundColor: "#2FB24C", width: "100%", fontSize: "13.5px", fontFamily: "Lato-Extrabold", padding: "19px 0px" }}>
                      Pay
                    </Button>
                  </div>
                </div>
              }
            </form>
          }
        </div>
      }
    </div>
  );
};

export default StripeCardDetails;
