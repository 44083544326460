/**
 * Combine all reducers in this file and export the combined reducers.
 */

import {combineReducers} from 'redux';
import {globalContainerReducer} from "./reducers/global/reducer";

// import history from 'utils/history';
// import languageProviderReducer from 'containers/LanguageProvider/reducer';
// import homeContainerReducer from 'containers/HomeContainer/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducer = {}) {
  return combineReducers({
    ...injectedReducer,
    globalContainerReducer
  });
}
